import "./SidebarLeft.css";
import TuneIcon from "@mui/icons-material/Tune";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { useEffect, useState } from "react";

const SidebarLeft = ({ handleOpenProductInfo, pTemplates, products }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [priceRange, setPriceRange] = useState([0, 100]);
  const [maxPrice, setMaxPrice] = useState(100);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [tempSelectedFilters, setTempSelectedFilters] = useState({});

  useEffect(() => {
    if (products) {
      const prices = products.map((product) => product.prix);
      const max = Math.max(...prices);
      setMaxPrice(max);
      setPriceRange([0, max]);
      setFilteredProducts(products);
    }
  }, [products]);

  const calculateLastYearChange = (graphData) => {
    if (graphData.length < 2) return null;
    const sortedGraphData = graphData.sort((a, b) => a.year - b.year);
    const lastValue = sortedGraphData[sortedGraphData.length - 1].value;
    const lastYearValue = sortedGraphData[sortedGraphData.length - 2].value;
    return ((lastValue - lastYearValue) / lastYearValue) * 100;
  };

  const handleFilterChange = (type, value, isRange = false) => {
    setTempSelectedFilters((prev) => {
      if (isRange) {
        return { ...prev, [type]: { ...prev[type], ...value } };
      }
      return { ...prev, [type]: value };
    });
  };

  const applyFilters = () => {
    setSelectedFilters(tempSelectedFilters);

    const newFilteredProducts = products.filter((product) => {
      const matchesPrice =
        product.prix >= priceRange[0] && product.prix <= priceRange[1];
      return (
        matchesPrice &&
        Object.entries(tempSelectedFilters).every(([key, value]) => {
          return product.attributs.some(
            (attr) => attr.key === key && attr.value === value
          );
        })
      );
    });
    setFilteredProducts(newFilteredProducts);
    setOpenFilter(false);
  };

  const removeFilter = (type) => {
    const newFilters = { ...selectedFilters };
    delete newFilters[type];

    setSelectedFilters(newFilters);
    setTempSelectedFilters(newFilters);

    applyFilters(newFilters);
  };

  const handlePriceChange = (event) => {
    const value = event.target.value.split(",").map(Number);
    setPriceRange(value);
  };

  const selectedTemplate =
    pTemplates.find((template) => template.isSelected) || pTemplates[0];

  const renderProductInfo = (product) => {
    const sortedTypes = [...selectedTemplate.types].sort(
      (a, b) => a.indexPresentation - b.indexPresentation
    );
    const vignetteAttributes = [];
    const regularAttributes = [];

    sortedTypes.forEach((type) => {
      const productAttribute = product.attributs.find(
        (attr) => attr.key === type.name
      );
      let attributeValue = productAttribute ? productAttribute.value : "";

      if (type.abbreviation) {
        attributeValue += ` ${type.abbreviation}`;
      }

      const templateAttribute = type.attributs.find(
        (attr) => attr.value === productAttribute?.value
      );
      const attributeStyle = {
        color: templateAttribute?.textColor || "#000",
        backgroundColor: templateAttribute?.backgroundColor || "#fff",
      };

      if (type.isVignette) {
        vignetteAttributes.push(
          <span className="asset-chip asset-chip-color5" style={attributeStyle}>
            {attributeValue}
          </span>
        );
      } else {
        regularAttributes.push(attributeValue);
      }
    });

    const priceChangePercentage = calculateLastYearChange(product.graphData);

    const formattedPrice = product.prix.toLocaleString("fr-FR");

    return (
      <>
        <span className="asset-ti">{regularAttributes.join(" - ")}</span>
        <span className="asset-de">{product.description}</span>
        <div className="asset-cnt-price">
          {vignetteAttributes}
          {priceChangePercentage !== null && (
            <span
              className={`asset-percent ${
                priceChangePercentage < 0
                  ? "asset-price-color-down"
                  : "asset-price-color-up"
              }`}
            >
              {priceChangePercentage < 0 ? (
                <TrendingDownIcon className="trend-size-color-down" />
              ) : (
                <TrendingUpIcon className="trend-size-color-up" />
              )}
              <span>{priceChangePercentage.toFixed(1)}%</span>
            </span>
          )}
          <span className="asset-price asset-price-color-up">
            € {formattedPrice}
          </span>
        </div>
      </>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const pickerElement = document.querySelector(".filterSection");
      if (pickerElement && !pickerElement.contains(event.target)) {
        setOpenFilter(false);
      }
    };

    if (openFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  if (!products || !pTemplates || pTemplates.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="sidebar-left">
      <div className="title-section-sidebarLeft">
        <h1 className="titles">Market Place</h1>
        <div className="filterContainer">
          <div className="filterSelected">
            {Object.entries(selectedFilters).map(([key, value]) => {
              if (typeof value === "object" && value !== null) {
                // Pour les filtres de type range
                return (
                  <span key={key} className="filterBadge">
                    {`${key}: ${value.min || "min"} - ${value.max || "max"}`}
                    <span
                      className="removeFilter"
                      onClick={() => removeFilter(key)}
                    >
                      ✖
                    </span>
                  </span>
                );
              } else {
                // Pour les autres types de filtres
                return (
                  <span key={key} className="filterBadge">
                    {`${key}: ${value}`}
                    <span
                      className="removeFilter"
                      onClick={() => removeFilter(key)}
                    >
                      ✖
                    </span>
                  </span>
                );
              }
            })}
          </div>
        </div>
        <TuneIcon
          className="filter"
          onClick={() => setOpenFilter(!openFilter)}
        />
      </div>
      {openFilter && (
        <div className="filterSection">
          {selectedTemplate.types.map((type) => {
            switch (type.typeCategory) {
              case "Type":
                return (
                  <div key={type.name}>
                    <span>{type.name}</span>
                    <select
                      onChange={(e) =>
                        handleFilterChange(type.name, e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      {type.attributs.map((attribut, index) => (
                        <option key={index} value={attribut.value}>
                          {attribut.value}
                        </option>
                      ))}
                    </select>
                  </div>
                );
              case "UM":
              case "numeric":
                return (
                  <div
                    key={type.name}
                    className="filterTestContainerWrapperItem"
                  >
                    <span>{`${type.name} Range`}</span>
                    <input
                      type="number"
                      placeholder="Min"
                      onChange={(e) =>
                        handleFilterChange(
                          type.name,
                          { min: e.target.value },
                          true
                        )
                      }
                    />
                    <input
                      type="number"
                      placeholder="Max"
                      onChange={(e) =>
                        handleFilterChange(
                          type.name,
                          { max: e.target.value },
                          true
                        )
                      }
                    />
                  </div>
                );
              case "freeValue":
                return (
                  <div
                    key={type.name}
                    className="filterTestContainerWrapperItem"
                  >
                    <span>{type.name}</span>
                    <input
                      type="text"
                      placeholder={`Enter ${type.name}`}
                      onChange={(e) =>
                        handleFilterChange(type.name, e.target.value)
                      }
                    />
                  </div>
                );
              default:
                return null;
            }
          })}
          <div className="priceRangeFilter">
            <label>Price Range: {`${priceRange[0]} - ${priceRange[1]}`}</label>
            <input
              type="range"
              min="0"
              max={maxPrice}
              value={priceRange.join(",")}
              onChange={handlePriceChange}
              multiple
            />
          </div>
          <button onClick={applyFilters}>Valider</button>
        </div>
      )}
      <div className="marketplace-list">
        {filteredProducts
          .filter((p) => !p.isHide)
          .map((product, index) => (
            <div
              key={index}
              className="asset"
              onClick={() => handleOpenProductInfo(product)}
            >
              {renderProductInfo(product)}
            </div>
          ))}
      </div>
    </div>
  );
};

export default SidebarLeft;
