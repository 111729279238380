import React from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";

const LeadDashboardChart = ({ allStats, chartData }) => {
  if (!allStats) {
    return <div>Loading...</div>;
  }
  return (
    <div className="dashboardContainerTopLeftWrapperBottomItem">
      <div className="dashboardContainerTopLeftWrapperBottomItemLeft">
        <span>Leads</span>
        <span>{allStats?.totalLeads || 0}</span>
        {/* <span>+12%(+104)</span> */}
      </div>
      <div className="dashboardContainerTopLeftWrapperBottomItemRight">
        <ResponsiveContainer width="100%" height={113}>
          <LineChart
            width={730}
            height={250}
            data={chartData}
            margin={{
              top: 10,
              right: 0,
              left: -28,
              bottom: -9,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#f9f9f9" />
            <XAxis
              dataKey="month"
              tickLine={false}
              tick={{ fill: "#c0c0c0", fontSize: 10, dx: 0, dy: 5 }}
            />
            <YAxis
              axisLine={{ stroke: "#e3e3e3", strokeWidth: 0.5 }}
              tickLine={false}
              tick={{ fill: "#c0c0c0", fontSize: 10, dx: -5 }}
              tickFormatter={(value) => {
                if (value >= 1000) {
                  return `${value / 1000}k`;
                }
                return value;
              }}
            />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="totalLeads"
              stroke="#7ec5cf"
              dot={false}
              strokeWidth={2}
              strokeOpacity={1}
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          </LineChart>
        </ResponsiveContainer>{" "}
      </div>
    </div>
  );
};

export default LeadDashboardChart;
