import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useContext, useState } from "react";
import { AuthContextLead } from "../../../../../context/leadContextAuth";
import axios from "axios";
import { useSocket } from "../../../../../context/SocketContext";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PropagateLoader from "react-spinners/PropagateLoader";

const DeclineOffer = ({ setOpen, selectedData }) => {
  const { lead } = useContext(AuthContextLead);
  const { emitOfferUpdated } = useSocket();
  const [isLoading, setIsLoading] = useState(false);

  const handlePopupClick = (e) => {
    e.stopPropagation();
  };

  const handleDeclineOffer = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/leadPlatform/${lead._id}/declineOffer`,
        { offerId: selectedData._id }
      );

      if (response.data) {
        setOpen(false);
      }
    } catch (err) {
      console.error("Error while adding product on hold:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="buyButton" onClick={handlePopupClick}>
          <div className="closeIconBuyButton">
            <CloseIcon
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
          {isLoading ? (
            <div className="loading-container">
              <span>Offer Declined...</span>
              <div className="loading-spinner">
                <PropagateLoader color="#93a1ca" />
              </div>
            </div>
          ) : (
            <>
              <div className="buyButtonWrapper">
                <div className="topBuyButton">
                  <span>Decline Offer</span>
                </div>
                <div className="middleBuyButton">
                  <div className="middleBuyButtonTop">
                    <LocalOfferIcon />
                  </div>
                  <div className="middleBuyButtonMiddle">
                    <span style={{ fontSize: "25px" }}> Decline Offer</span>
                    <span>
                      Decline the offer for{" "}
                      <span style={{ color: "#93a1ca" }}>
                        #{selectedData.product.reference}
                      </span>
                      ?
                    </span>
                  </div>
                </div>
                <div className="bottomBuyButton">
                  <button
                    className="cancelButtonBuyButton"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="saveButtonBuyButton"
                    onClick={handleDeclineOffer}
                  >
                    <CheckIcon />
                    Comfirm
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DeclineOffer;
