import "./SettingsTable.css";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import axios from "axios";

const SettingsTable = ({
  data,
  topTitle,
  onAddClick,
  onDeleteClick,
  onSetDefault,
  onEditClick,
}) => {
  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>{topTitle}</span>
            </div>

            <div className="rightElements">
              <button onClick={onAddClick}>
                <AddIcon />
                <span>New</span>
              </button>
            </div>
          </div>
          <table className="tableData">
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="headerWithIcon">
                    Name <KeyboardArrowDownIcon />
                  </div>
                </th>
                <th>
                  <div className="headerWithIcon">
                    Default <KeyboardArrowDownIcon />
                  </div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <span>{item.title}</span>
                  </td>
                  <td onClick={() => onSetDefault(item._id)}>
                    {item.isDefault ? (
                      <StarIcon style={{ color: "gold" }} />
                    ) : (
                      <StarBorderIcon />
                    )}
                  </td>

                  <td>
                    <span
                      style={{
                        color: "var(--primary-color)",
                        marginRight: "10px",
                      }}
                      onClick={() => onEditClick(item._id)}
                    >
                      edit
                    </span>
                    <span
                      style={{ color: "var(--primary-color)" }}
                      onClick={() => onDeleteClick(item._id)}
                    >
                      del
                    </span>
                  </td>
                </tr>
              ))}
              {data.length === 0 && (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SettingsTable;
