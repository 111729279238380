import "./ImagesTable.css";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
const ImagesTable = ({
  handleChangeImages,
  imagePreviews,
  handleDeleteImage,
}) => {
  return (
    <table className="tablePlatformConfiguration">
      <thead>
        <tr>
          <td colSpan={6}>IMAGES :</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span>Sidebar Open Logo</span>
          </td>
          {imagePreviews.favicon ? (
            <>
              <td>
                <div className="image-uploader small landscape">
                  <div className="image-uploader-image">
                    <img src={imagePreviews.favicon} alt="" />
                    <DeleteForeverIcon
                      onClick={() => handleDeleteImage("favicon")}
                      className="material-icons delete-icon"
                    />
                  </div>
                </div>
              </td>
            </>
          ) : (
            <td>
              <div className="image-uploader-dropzone">
                <div
                  className="image-uploader-image"
                  style={{ position: "relative" }}
                >
                  <CloudDownloadIcon />
                  <input
                    accept="image/jpeg, image/png, image/svg+xml"
                    type="file"
                    autoComplete="off"
                    style={{
                      position: "absolute",
                      inset: "0px",
                      opacity: "0",
                      pointerEvents: "auto",
                    }}
                    onChange={handleChangeImages.handleFaviconChange}
                    name="loginBackground"
                  />
                </div>
              </div>
            </td>
          )}
          <td>
            <span>Sidebar Closed Logo</span>
          </td>
          {imagePreviews.storeLogo ? (
            <>
              <td>
                <div className="image-uploader small landscape">
                  <div className="image-uploader-image">
                    <img src={imagePreviews.storeLogo} alt="" />
                    <DeleteForeverIcon
                      onClick={() => handleDeleteImage("storeLogo")}
                      className="material-icons delete-icon"
                    />
                  </div>
                </div>
              </td>
            </>
          ) : (
            <td>
              <div className="image-uploader-dropzone">
                <div
                  className="image-uploader-image"
                  style={{ position: "relative" }}
                >
                  <CloudDownloadIcon />
                  <input
                    accept="image/jpeg, image/png, image/svg+xml"
                    type="file"
                    autoComplete="off"
                    style={{
                      position: "absolute",
                      inset: "0px",
                      opacity: "0",
                      pointerEvents: "auto",
                    }}
                    onChange={handleChangeImages.handleStoreLogoChange}
                    name="storeLogo"
                  />
                </div>
              </div>
            </td>
          )}

          <td>
            <span>Invoice Logo</span>
          </td>
          {imagePreviews.invoiceLogo ? (
            <>
              <td>
                <div className="image-uploader small landscape">
                  <div className="image-uploader-image">
                    <img src={imagePreviews.invoiceLogo} alt="" />
                    <DeleteForeverIcon
                      onClick={() => handleDeleteImage("invoiceLogo")}
                      className="material-icons delete-icon"
                    />
                  </div>
                </div>
              </td>
            </>
          ) : (
            <td>
              <div className="image-uploader-dropzone">
                <div
                  className="image-uploader-image"
                  style={{ position: "relative" }}
                >
                  <CloudDownloadIcon />
                  <input
                    accept="image/jpeg, image/png, image/svg+xml"
                    type="file"
                    autoComplete="off"
                    style={{
                      position: "absolute",
                      inset: "0px",
                      opacity: "0",
                      pointerEvents: "auto",
                    }}
                    onChange={handleChangeImages.handleInvoiceLogoChange}
                    name="invoiceLogo"
                  />
                </div>
              </div>
            </td>
          )}
        </tr>
        <tr>
          <td>
            <span>Login Logo</span>
          </td>
          {imagePreviews.loginLogo ? (
            <>
              <td>
                <div className="image-uploader small landscape">
                  <div className="image-uploader-image">
                    <img src={imagePreviews.loginLogo} alt="" />
                    <DeleteForeverIcon
                      onClick={() => handleDeleteImage("loginLogo")}
                      className="material-icons delete-icon"
                    />
                  </div>
                </div>
              </td>
            </>
          ) : (
            <td>
              <div className="image-uploader-dropzone">
                <div
                  className="image-uploader-image"
                  style={{ position: "relative" }}
                >
                  <CloudDownloadIcon />
                  <input
                    accept="image/jpeg, image/png, image/svg+xml"
                    type="file"
                    autoComplete="off"
                    style={{
                      position: "absolute",
                      inset: "0px",
                      opacity: "0",
                      pointerEvents: "auto",
                    }}
                    onChange={handleChangeImages.handleLoginLogoChange}
                    name="loginLogo"
                  />
                </div>
              </div>
            </td>
          )}
          <td>
            <span>Login Background</span>
          </td>
          {imagePreviews.loginBackground ? (
            <>
              <td>
                <div className="image-uploader small landscape">
                  <div className="image-uploader-image">
                    <img src={imagePreviews.loginBackground} alt="" />
                    <DeleteForeverIcon
                      onClick={() => handleDeleteImage("loginBackground")}
                      className="material-icons delete-icon"
                    />
                  </div>
                </div>
              </td>
            </>
          ) : (
            <td>
              <div className="image-uploader-dropzone">
                <div
                  className="image-uploader-image"
                  style={{ position: "relative" }}
                >
                  <CloudDownloadIcon />
                  <input
                    accept="image/jpeg, image/png, image/svg+xml"
                    type="file"
                    autoComplete="off"
                    style={{
                      position: "absolute",
                      inset: "0px",
                      opacity: "0",
                      pointerEvents: "auto",
                    }}
                    onChange={handleChangeImages.handleLoginBackgroundChange}
                    name="loginBackground"
                  />
                </div>
              </div>
            </td>
          )}
          <td>
            <span>Favicon Logo</span>
          </td>
          {imagePreviews.mobileStoreLogo ? (
            <>
              <td>
                <div className="image-uploader small landscape">
                  <div className="image-uploader-image">
                    <img src={imagePreviews.mobileStoreLogo} alt="" />
                    <DeleteForeverIcon
                      onClick={() => handleDeleteImage("mobileStoreLogo")}
                      className="material-icons delete-icon"
                    />
                  </div>
                </div>
              </td>
            </>
          ) : (
            <td>
              <div className="image-uploader-dropzone">
                <div
                  className="image-uploader-image"
                  style={{ position: "relative" }}
                >
                  <CloudDownloadIcon />
                  <input
                    accept="image/jpeg, image/png, image/svg+xml"
                    type="file"
                    autoComplete="off"
                    style={{
                      position: "absolute",
                      inset: "0px",
                      opacity: "0",
                      pointerEvents: "auto",
                    }}
                    onChange={handleChangeImages.handleMobileStoreLogoChange}
                    name="mobileStoreLogo"
                  />
                </div>
              </div>
            </td>
          )}
        </tr>
      </tbody>
    </table>
  );
};

export default ImagesTable;
