import "./Chart.css";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Chart = ({ productData }) => {
  const data = productData.graphData;

  const values = data.map((item) => item.value);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  const currentValue = data[data.length - 1].value; 


  const midValue = Math.round((minValue + currentValue) / 2);

  // --- On force YAxis à n’afficher que ces 3 ticks ---
  const yTicks = [minValue, midValue, currentValue];

  // --- Même logique que votre code pour l’axe X ---
  const startYear = data[0].year;
  const endYear = data[data.length - 1].year;
  const totalYears = endYear - startYear;
  const step = Math.round(totalYears / 4);
  const xTicks = [];
  for (let i = 0; i < 5; i++) {
    xTicks.push(startYear + i * step);
  }
  if (xTicks[xTicks.length - 1] !== endYear) {
    xTicks[xTicks.length - 1] = endYear;
  }

  // Custom ticks pour X (inchangé)
  const CustomTickX = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x - 5},${y + 10})`}>
        <text
          dy={16}
          textAnchor="middle"
          fill="#93a1ca"
          fontSize="10px"
          fontWeight="300"
          fontFamily="Roboto, sans-serif"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  // Custom ticks pour Y (inchangé)
  const CustomTickY = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x - 5},${y})`}>
        <text
          x={-12}
          dx={-10}
          textAnchor="end"
          fill="#93a1ca"
          fontSize="10px"
          fontWeight="300"
          fontFamily="Roboto, sans-serif"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={data}>
        <Line type="monotone" dataKey="value" stroke="#8884d8" />
        <CartesianGrid stroke="#0000000a" horizontal vertical={false} />
        <XAxis
          dataKey="year"
          type="number"
          domain={[startYear, endYear]}
          ticks={xTicks}
          tickLine={false}
          axisLine={false}
          tick={<CustomTickX />}
        />
        <YAxis
          // On fixe le domaine pour qu’il couvre au moins le min et le max
          domain={[Math.min(minValue, currentValue), Math.max(maxValue, currentValue)]}
          // On force à n’avoir que 3 ticks
          ticks={yTicks}
          tickLine={false}
          axisLine={false}
          tick={<CustomTickY />}
        />
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
