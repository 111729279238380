import "./StatusPage.css";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import NavigationTabs from "../../../components/Navigation/NavigationTabs/NavigationTabs";
import { SettingsTitles } from "../../../constants/navigationTitles";
import useFetch from "../../../hooks/useFetch";
import SettingsTable from "../../../components/Settings/SettingsTable/SettingsTable";
import { useState } from "react";
import CreateStatus from "../../../components/Settings/Status/CreateStatus/CreateStatus";
import axios from "axios";
import DeleteMessage from "../../../components/DeleteMessage/DeleteMessage";
import EditStatus from "../../../components/Settings/Status/EditStatus/EditStatus";
import { useData } from "../../../context/DataContext";

const StatusPage = () => {
  const { status, reFetchStatus } = useData();
  const [showStatusCreatePopup, setShowStatusCreatePopup] = useState(false);
  const [showStatusEditPopup, setShowStatusEditPopup] = useState(false);
  const [showDeleteStatusMessage, setShowDeleteStatusMessage] = useState(false);
  const [selectedStatusId, setSelectedStatusId] = useState(null);

  const openCreateStatusPopup = () => setShowStatusCreatePopup(true);

  const openDeleteStatusPopup = (statusId) => {
    setSelectedStatusId(statusId);
    setShowDeleteStatusMessage(true);
  };
  const openEditStatusPopup = (statusId) => {
    setSelectedStatusId(statusId);
    setShowStatusEditPopup(true);
  };

  const handleSetDefault = async (statusId) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/status/set-default/${statusId}`
      );
      reFetchStatus();
    } catch (error) {
      console.error("Error updating default status:", error);
    }
  };

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <div className="navigationAndFilter">
            <NavigationTabs tabs={SettingsTitles} />
          </div>
        </div>
        <div className="bottomCrmPage">
          <SettingsTable
            data={status}
            reFetch={reFetchStatus}
            onAddClick={openCreateStatusPopup}
            onEditClick={openEditStatusPopup}
            onDeleteClick={openDeleteStatusPopup}
            onSetDefault={handleSetDefault}
          />

          {showStatusCreatePopup && (
            <CreateStatus
              setOpen={setShowStatusCreatePopup}
              reFetchFromTable={reFetchStatus}
            />
          )}
          {showStatusEditPopup && (
            <EditStatus
              setOpen={setShowStatusEditPopup}
              reFetchFromTable={reFetchStatus}
              id={selectedStatusId}
            />
          )}
          {showDeleteStatusMessage && (
            <DeleteMessage
              setOpen={setShowDeleteStatusMessage}
              id={selectedStatusId}
              reFetchFromTable={reFetchStatus}
              endPoint={"status"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StatusPage;
