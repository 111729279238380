import "./SingleUserPerfomance.css";
import CloseIcon from "@mui/icons-material/Close";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import CreditCardIcon from "@mui/icons-material/CreditCard";

const SingleUserPerfomance = ({ setOpen, data }) => {
  return (
    <>
      <div
        className="overlayPopupCustomer"
        onClick={() => setOpen(false)}
      ></div>
      <div className="singleUserPerfomanceContainer">
        <div className="closeIconPopupCustomer" onClick={() => setOpen(false)}>
          <CloseIcon />
        </div>
        <div className="singleUserPerfomanceWrapper">
          <div className="singleUserPerfomanceLeftSide">
            <div className="popupLeftSideTop">
              <div className="ppCustomer">
                <img src={data.userPicture} alt={data.userPicture} />
              </div>
              <div className="popupLeftSideTopName">
                <span>{data.userName}</span>
              </div>
            </div>
            <div className="popupLeftSideBottom">
              <div className="popupLeftSideBottomFormRow">
                <label className="popupLeftSideBottomFormLabel">Username</label>
                <input
                  className="popupLeftSideBottomFormInput"
                  type="text"
                  value={data.username}
                />
              </div>

              <div className="popupLeftSideBottomFormRow">
                <label className="popupLeftSideBottomFormLabel">Email</label>
                <input className="popupLeftSideBottomFormInput" type="email"   value={data.userEmail}/>
              </div>
            </div>
          </div>
          <div className="singleUserPerfomanceRightSide">
            <div className="singleUserPerfomanceRightSideWrapperTop">
              <div className="singleUserPerfomanceRightSideTopTile">
                <span>Overview</span>
              </div>
              <div className="singleUserPerfomanceRightSideTopWidget">
                <div className="salesPerfomancePageItem">
                  <div className="salesPerfomancePageItemTop">
                    <AccountBoxIcon />
                  </div>
                  <div className="salesPerfomancePageItemBottom">
                    <span>LEADS</span>
                    <span>{data.leadsCreatedInRange.length}</span>
                  </div>
                </div>
                <div className="salesPerfomancePageItem">
                  <div className="salesPerfomancePageItemTop">
                    <AccountBalanceIcon />
                  </div>
                  <div className="salesPerfomancePageItemBottom">
                    <span>DEPOSITS</span>
                    <span>{data.numberOfDeposit}</span>
                  </div>
                </div>
                <div className="salesPerfomancePageItem">
                  <div className="salesPerfomancePageItemTop">
                    <StarHalfIcon />
                  </div>
                  <div className="salesPerfomancePageItemBottom">
                    <span>FTDS</span>
                    <span>{data.totalFtds}</span>
                  </div>
                </div>
                <div className="salesPerfomancePageItem">
                  <div className="salesPerfomancePageItemTop">
                    <CreditCardIcon />
                  </div>
                  <div className="salesPerfomancePageItemBottom">
                    <span>CONVERSION</span>
                    <span>{data.conversionRate || 0}%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="singleUserPerfomanceRightSideWrapperBottom">
              <div className="singleUserPerfomanceRightSideWrapperBottomTop">
                <div className="singleUserPerfomanceRightSideWrapperBottomItem">
                  <div className="singleUserPerfomancePageItem">
                    <div className="singleUserPerfomancePageItemTop">
                      <CreditCardIcon />
                    </div>
                    <div className="singleUserPerfomancePageItemBottom">
                      <span>FTDS REVENUE</span>
                      <span> {data.conversionRate || 0}%</span>
                    </div>
                  </div>
                </div>
                <div className="singleUserPerfomanceRightSideWrapperBottomItem">
                  <div className="singleUserPerfomancePageItem">
                    <div className="singleUserPerfomancePageItemTop">
                      <CreditCardIcon />
                    </div>
                    <div className="singleUserPerfomancePageItemBottom">
                      <span>FTDS VALUE</span>
                      <span>{data.ftdsValuePerUser.toFixed(2) || 0} €</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="singleUserPerfomanceRightSideWrapperBottomBottom">
                <div className="singleUserPerfomanceRightSideWrapperBottomItem">
                  <div className="singleUserPerfomancePageItem">
                    <div className="singleUserPerfomancePageItemTop">
                      <CreditCardIcon />
                    </div>
                    <div className="singleUserPerfomancePageItemBottom">
                      <span>BUYING BACK</span>
                      <span>TO DO €</span>
                    </div>
                  </div>
                </div>
                <div className="singleUserPerfomanceRightSideWrapperBottomItem">
                  <div className="singleUserPerfomancePageItem">
                    <div className="singleUserPerfomancePageItemTop">
                      <CreditCardIcon />
                    </div>
                    <div className="singleUserPerfomancePageItemBottom">
                      <span>SALES</span>
                      <span> {data.numberOfSales || 0}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleUserPerfomance;

{
  /* <ResponsiveContainer width="102%" height="86%">
<LineChart
  width={500}
  height={300}
  data={dataChart}
  margin={{
    top: 5,
    right: 30,
    left: 20,
    bottom: 5,
  }}
>
  <CartesianGrid strokeDasharray="3 3" stroke="#f9f9f9" />
  <XAxis
    axisLine={{ stroke: "#e3e3e3", strokeWidth: 0.5 }}
    tickLine={false}
    tick={{ fill: "#c0c0c0", fontSize: 10, dx: 0 }}
  />
  <YAxis
    axisLine={{ stroke: "#e3e3e3", strokeWidth: 0.5 }}
    tickLine={false}
    tick={{ fill: "#c0c0c0", fontSize: 10, dx: 0 }}
  />
  <Tooltip />
  <Line type="monotone" dataKey="pv" stroke="#009fb09c" />
</LineChart>
</ResponsiveContainer> */
}
