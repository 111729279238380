import { useState } from "react";
import "./Sold.css";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
const Sold = ({ sold, selectedTemplate }) => {
  const [textOpen, setTextOpen] = useState(false);

  const handleMouseEnter = () => setTextOpen(true);
  const handleMouseLeave = () => setTextOpen(false);

  const renderProductInfo = (product, index) => {
    const truncateText = (text, limit) => {
      return text.length > limit ? text.substring(0, limit) + "..." : text;
    };
    const sortedTypes = [...selectedTemplate.types].sort(
      (a, b) => a.indexPresentation - b.indexPresentation
    );

    const vignetteAttributes = [];
    const regularAttributes = [];

    sortedTypes.forEach((type) => {
      const productAttribute = product.product.attributs.find(
        (attr) => attr.key === type.name
      );
      let attributeValue = productAttribute ? productAttribute.value : "";

      if (type.abbreviation) {
        attributeValue += ` ${type.abbreviation}`;
      }

      const templateAttribute = type.attributs.find(
        (attr) => attr.value === productAttribute?.value
      );
      const attributeStyle = {
        color: templateAttribute?.textColor || "#000",
        backgroundColor: templateAttribute?.backgroundColor || "#fff",
      };

      if (type.isVignette) {
        vignetteAttributes.push(
          <span
            className="asset-chip3 asset-chip-color5"
            style={attributeStyle}
          >
            {attributeValue}
          </span>
        );
      } else {
        regularAttributes.push(attributeValue);
      }
    });

    const formattedPrice = product.originPrice.toLocaleString("fr-FR");

    // Combine attributes into a single string
    const attributesString = regularAttributes.join(" - ");

    // Truncate the combined string if necessary
    const truncatedAttributesString = truncateText(attributesString, 16); // Assuming a limit of 50 characters

    const fullAttributesString = product.product.attributs
      .map((attr) => ` ${attr.value}`)
      .join(", ");

    const differencePourcentage =
      ((product.salesPrice - product.originPrice) / product.originPrice) * 100;
    const difference = product.salesPrice - product.originPrice;

    const isOfferLower = product.salesPrice < product.product.prix;

    return (
      <>
        <div className="item-safe" key={index}>
          <div className="item-safe-title">
            <span
              className="asset-ti-safe"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {truncatedAttributesString}
              <span className="question-mark">?</span>
            </span>
            {textOpen && (
              <div
                className="tooltipSidebarRight"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {fullAttributesString}
              </div>
            )}
            {vignetteAttributes}
            <span className="price-asset-safe">€ {formattedPrice}</span>
          </div>

          {product.isAccepted ? (
            <div className="item-offer-details">
              <span className="name-offer">{product.companyName}</span>
              <span
                className={`asset-percent2 ${
                  isOfferLower
                    ? "asset-price-color-down"
                    : "asset-price-color-up"
                }`}
              >
                {isOfferLower ? (
                  <TrendingDownIcon className="trend-size-color-down" />
                ) : (
                  <TrendingUpIcon className="trend-size-color-up" />
                )}
                {Math.abs(differencePourcentage).toFixed(2)}%
              </span>
              <span
                className={`offer-profit ${isOfferLower ? "offer-loss" : ""}`}
              >
                {isOfferLower ? "- € " : "+ € "}
                {Math.abs(difference).toLocaleString("en-US")}
              </span>
              <span className="price-offer-offer">€ {product.salesPrice}</span>
            </div>
          ) : (
            <div style={{ textAlign: "left" }}>
              <span style={{ fontSize: "10px", color: "grey" }}>
                En attente de confirmation...
              </span>
            </div>
          )}
        </div>
      </>
    );
  };
  return (
    <div className="safe-list">
      {sold.length === 0 ? (
        <div className="messageNoNothing">
          <AccountBalanceIcon />
          <span>0 PRODUIT</span>
        </div>
      ) : (
        sold.map((product, index) => <>{renderProductInfo(product, index)}</>)
      )}
    </div>
  );
};

export default Sold;
