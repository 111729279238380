import "./CreateOffers.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import axios from "axios";

const CreateOffers = ({
  setOpen,
  currentData,
  selectedData,
  reFetchAllLead,
}) => {
  const [amount, setAmount] = useState("");
  const [percentage, setPercentage] = useState("");
  const [amountDifference, setAmountDifference] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(
    selectedData.product._id
  );
  const [selectedDate, setSelectedDate] = useState("");

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const createOffer = async (e) => {
    e.preventDefault();

    if (!selectedCompany || selectedCompany.trim() === "") {
      alert("Error: Company name is required.");
      return;
    }

    if (!amount || amount.trim() === "") {
      alert("Error: Offer amount is required.");
      return;
    }

    const newOffer = {
      amount,
      selectedCompany,
      selectedProduct,
      expirationDate: selectedDate,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/leadPlatform/${currentData._id}/createOffer`,
        newOffer
      );

      if (response.data) {
        // Create a notification for the deposit
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/notification`,
          {
            typeNotification: "offre",
            lead: currentData._id,
            text: `Nouvelle offre pour ${selectedData.product.reference} de la part de ${selectedCompany} à `,
            amount: amount.toLocaleString(),
          }
        );

        setAmount("");
        setSelectedCompany("");
        setSelectedDate("");
        setPercentage("");
        setAmountDifference("");
        reFetchAllLead();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAmountChange = (e) => {
    const newAmountValue = e.target.value;
    setAmount(newAmountValue);
    if (selectedData && newAmountValue) {
      const newAmount = parseFloat(newAmountValue);
      const newPercentage = (newAmount / selectedData.product.prix) * 100;
      setPercentage(Math.round(newPercentage).toString());
    } else if (!newAmountValue) {
      setPercentage("");
    }
  };

  const handlePercentageChange = (e) => {
    const newPercentageValue = e.target.value;
    setPercentage(newPercentageValue);
    if (selectedData && newPercentageValue) {
      const newPercentage = parseFloat(newPercentageValue);
      const originalPrice = selectedData.product.prix;
      const newAmount = originalPrice + (originalPrice * newPercentage) / 100;
      setAmount(Math.round(newAmount).toString());

      const difference = newAmount - originalPrice; // Calculer la différence de montant
      setAmountDifference(Math.round(difference).toString()); // Stocker la différence arrondie
    } else if (!newPercentageValue) {
      setAmount("");
      setAmountDifference(0); // Réinitialiser la différence si aucun pourcentage n'est entré
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createOffer" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createOfferWrapper">
            <div className="topCreateStatus">
              <span>Add Offer</span>
            </div>
            <div className="middleCreateOffer">
              <label>Product Price : {selectedData.product.prix} € </label>
              <label>Name</label>
              <input
                type="text"
                onChange={(e) => setSelectedCompany(e.target.value)}
                value={selectedCompany}
                placeholder="Enter Name"
              />
              <label>
                Percentage
                {percentage !== 0 && (
                  <span
                    style={{
                      color:
                        amountDifference >= 0 ? "var(--primary-color)" : "red", // Si amountDifference est positif, utilisez la couleur primaire, sinon rouge
                      marginLeft: "10px",
                    }}
                  >
                    {amountDifference >= 0
                      ? `+ ${amountDifference}`
                      : amountDifference}{" "}
                    €
                  </span>
                )}
              </label>
              <input
                type="number"
                value={percentage}
                onChange={handlePercentageChange}
                placeholder="Enter percentage for offer"
                step="1"
              />
              <label>Amount</label>
              <input
                type="number"
                value={amount}
                onChange={handleAmountChange}
                placeholder="Enter amount of offer"
                step="1"
              />
              <label>Expiration Date</label>
              <input
                type="datetime-local"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </div>
            <div className="bottomCreateOffer">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button
                className="saveButton"
                type="submit"
                onClick={createOffer}
              >
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOffers;
