import "./BottomBar.css";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SellIcon from "@mui/icons-material/Sell";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MySafeQuery from "./MySafeQuery/MySafeQuery";
import PendingQuery from "./PendingQuery/PendingQuery";
import OfferQuery from "./OfferQuery/OfferQuery";
import SoldQuery from "./SoldQuery/SoldQuery";
const BottomBar = ({
  onSafe,
  onHold,
  selectedTemplate,
  handleOpenProductInfo,
  offers,
  sold,
}) => {
  const [panel, setPanel] = useState({ isOpen: false, content: null });
  const [activeTab, setActiveTab] = useState("store");
  const [filteredOffers, setFilteredOffers] = useState([]);

  const handleShowOffers = (productId) => {
    const newFilteredOffers = offers.filter(
      (offer) => offer.product._id === productId
    );
    setFilteredOffers(newFilteredOffers);
    handlePanelOpen("offers"); // Directly use handlePanelOpen to set the panel and tab state
  };
  const handlePanelOpen = (content) => {
    if (content === "store") {
      setPanel({ isOpen: false, content: null });
      setActiveTab("store");
    } else {
      const isSameContent = panel.isOpen && content === panel.content;
      setPanel({
        isOpen: !isSameContent,
        content: isSameContent ? null : content,
      });
      setActiveTab(isSameContent ? "store" : content);
    }
  };

  const closePanel = () => {
    setPanel({ isOpen: false, content: null });
    setActiveTab("store");
  };

  const renderPanelContent = () => {
    switch (panel.content) {
      case "mySafe":
        return (
          <MySafeQuery
            handleOpenProductInfo={handleOpenProductInfo}
            selectedTemplate={selectedTemplate}
            offers={offers}
            onSafe={onSafe}
            handleShowOffers={handleShowOffers}
          />
        );
      case "onHold":
        return (
          <PendingQuery
            handleOpenProductInfo={handleOpenProductInfo}
            selectedTemplate={selectedTemplate}
            onHold={onHold}
          />
        );
      case "sold":
        return (
          <SoldQuery
            handleOpenProductInfo={handleOpenProductInfo}
            selectedTemplate={selectedTemplate}
            sold={sold}
          />
        );
      case "offers":
        return (
          <OfferQuery
            handleOpenProductInfo={handleOpenProductInfo}
            selectedTemplate={selectedTemplate}
            offers={filteredOffers}
          />
        );

      default:
        return null;
    }
  };

  const getTabClass = (tabName) =>
    activeTab === tabName ? "menbotin" : "menbot";

  return (
    <>
      {panel.isOpen && (
        <div className={`overlay-panel ${panel.isOpen ? "open" : ""}`}>
          <CloseIcon className="overlay-panelCloseIcon" onClick={closePanel} />
          {renderPanelContent()}
        </div>
      )}
      <div className="bottombar">
        <div
          className={getTabClass("store")}
          onClick={() => handlePanelOpen("store")}
        >
          <StorefrontIcon className="botimg" />
          <br />
          <span className="txtmenbot">Store</span>
        </div>
        <div
          className={getTabClass("mySafe")}
          onClick={() => handlePanelOpen("mySafe")}
        >
          <AccountBalanceWalletIcon className="botimg" />
          <br />
          <span className="txtmenbot">
            My Safe{" "}
            {onSafe.length < 1 ? (
              ""
            ) : (
              <span className="chipnot">{onSafe.length}</span>
            )}
          </span>
        </div>
        <div
          className={getTabClass("onHold")}
          onClick={() => handlePanelOpen("onHold")}
        >
          <ScheduleIcon className="botimg" />
          <br />
          <span className="txtmenbot">
            On Hold{" "}
            {onHold.length < 1 ? (
              ""
            ) : (
              <span className="chipnot">{onHold.length}</span>
            )}
          </span>
        </div>
        <div
          className={getTabClass("offers")}
          onClick={() => handlePanelOpen("offers")}
        >
          <SellIcon className="botimg" />
          <br />
          <span className="txtmenbot">
            Offers{" "}
            {filteredOffers.length < 1 ? (
              ""
            ) : (
              <span className="chipnot">{filteredOffers.length}</span>
            )}
          </span>
        </div>
        <div
          className={getTabClass("sold")}
          onClick={() => handlePanelOpen("sold")}
        >
          <DomainVerificationIcon className="botimg" />
          <br />
          <span className="txtmenbot">
            Sold{" "}
            {sold.length < 1 ? (
              ""
            ) : (
              <span className="chipnot">{sold.length}</span>
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default BottomBar;
