import "./CustomerTable.css";
import {
  handleExportButtonClick,
  handleCheckboxChange,
} from "../../../../utils/datatable.utils";
import SearchIcon from "@mui/icons-material/Search";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import moment from "moment";
import SelectOptionsTable from "../../../SelectOptionsTable/SelectOptionsTable";
import DeleteMessage from "../../../DeleteMessage/DeleteMessage";
import CommentPopup from "../CommentPopup/CommentPopup";
import DeleteAllMessageCustomers from "../DeleteAllMessageCustomers/DeleteAllMessageCustomers";
import AssignAllLeadCustomers from "../AssignAllLeadCustomers/AssignAllLeadCustomers";
import { countries } from "countries-list";
import PulseLoaderPopup from "../../../Loaders/PulseLoaderPopup/PulseLoaderPopup";
import ClimbingBoxLoaderPopup from "../../../Loaders/ClimbingBoxLoaderPopup/ClimbingBoxLoaderPopup";
import PopupCustomerContainer from "../../PopupCustomer/PopupClientContainer/PopupCustomerContainer";
import { useSocket } from "../../../../context/SocketContext";
import { AuthContext } from "../../../../context/auth.context";

const CustomerTable = ({
  data,
  reFetch,
  currentPage,
  setPage,
  totalLeads,
  selectedFilters,
  setSelectedFilters,
  userId,
  campaigns,
  status,
  users,
  reFetchUsers,
  allLeads,
  loading,
  isFetching,
  reFetchFromTable
}) => {
  const [perPage, setPerPage] = useState(50);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [isAllRowsHighlighted, setIsAllRowsHighlighted] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [openDeleteAllMessage, setOpenDeleteAllMessage] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [selectedAssignee, setSelectedAssignee] = useState({});
  const [selectedStatus, setSelectedStatus] = useState({});
  const [selectedData, setSelectedData] = useState(null);
  const [openCommentPopUp, setOpenCommentPopUp] = useState(null);
  const [openAssignAllLead, setOpenAssignAllLead] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Attr");
  const [openPopupCustomer, setOpenPopupCustomer] = useState(false);
  const [showPulseLoader, setShowPulseLoader] = useState(false);
  const [showClimbingLoader, setShowClimbingLoader] = useState(false);
  const [leadsOnlineStatus, setLeadsOnlineStatus] = useState({});
  const [searchContainerRef, setSearchContainerRef] = useState(null);
  const { socket } = useSocket();
  const { user } = useContext(AuthContext);
  const [selectedLead, setSelectedLead] = useState(null);

  const tableRef = useRef(null);

  useEffect(() => {
    reFetch();
  }, [currentPage]);

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage.selected + 1);
  };

  const handleExportCsv = () => {
    if (checkedItems.length > 0) {
      handleExportButtonClick(data, checkedItems);
    } else {
      alert("Please select at least one checkbox to export.");
    }
  };

  const handleCheckboxChangeWrapper = (event, itemId) => {
    handleCheckboxChange(
      event,
      itemId,
      data,
      checkedItems,
      setIsAllChecked,
      setCheckedItems
    );
    setHighlightedRows((prevRows) => {
      if (event.target.checked) {
        return [...prevRows, itemId];
      } else {
        return prevRows.filter((rowId) => rowId !== itemId);
      }
    });
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setHighlightedRows([]);
    }
  }, [checkedItems]);

  const handleHeaderCheckboxClick = (e) => {
    e.preventDefault();
    if (checkedItems.length > 0 || isAllChecked) {
      setCheckedItems([]);
      setIsAllChecked(false);
    } else {
      setShowSelectAllPopup(true);
    }
  };

  const selectCurrentPage = () => {
    const currentPageItems = data.map((item) => item._id);
    setCheckedItems(currentPageItems);
  };

  const selectAllPages = async () => {
    try {
      const queryParams = {
        userId: userId,
        user: selectedFilters.user,
        campaign: selectedFilters.campaign,
        status: selectedFilters.status,
        country: selectedFilters.country,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/userLeadsWithFilters`,
        { params: queryParams }
      );
      const allFilteredLeads = response.data;
      const allItems = allFilteredLeads.map((item) => item._id);
      setCheckedItems(allItems);
    } catch (err) {
      console.error("Erreur lors de la récupération des leads filtrés:", err);
    }
  };

  const handleOpenDeleteMessage = (leadId) => {
    setOpenDeleteMessage(true);
    setSelectedDeletedId(leadId);
  };

  const handleCampaignChange = async (event, leadId) => {
    const newCampaignId = event.target.value;
    setSelectedCampaign((prev) => ({ ...prev, [leadId]: newCampaignId }));

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/${leadId}`,
        { campaign: newCampaignId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      if (response.data) {
        // Plus besoin de emitRefreshData() ni reFetch()
        // Le socket émet 'leadUpdated', le LeadSocketHandler invalide les données.
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleAssignmentChange = async (event, leadId) => {
    const newAssigneeId = event.target.value;

    try {
      const storedToken = localStorage.getItem("authToken");
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/assign-lead`,
        { leadId, newAssigneeId },
        { headers: { Authorization: `Bearer ${storedToken}` } }
      );

      if (response.data) {
        // NE PLUS appeler emitRefreshData ou reFetch
        // Le socket émet 'leadAssigned', le LeadSocketHandler invalide la query.
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'assignation :", error);
    }
  };

  const handleStatusChange = async (event, leadId) => {
    const newStatutId = event.target.value;
    setSelectedStatus((prev) => ({ ...prev, [leadId]: newStatutId }));

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/${leadId}`,
        { status: newStatutId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      if (response.data) {
        // Plus besoin de emitRefreshData() ni reFetch()
        // Le socket émet 'leadUpdated', le LeadSocketHandler invalide les données.
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Filter
  const handleUserFilterChange = (e) => {
    setSelectedFilters((filters) => ({ ...filters, user: e.target.value }));
    setPage(1);
  };

  const handleCampagneFilterChange = (e) => {
    setSelectedFilters((filters) => ({ ...filters, campaign: e.target.value }));
    setPage(1);
  };

  const handleStatusFilterChange = (e) => {
    setSelectedFilters((filters) => ({ ...filters, status: e.target.value }));
    setPage(1);
  };

  const handleCountryFilterChange = (e) => {
    setSelectedFilters((filters) => ({ ...filters, country: e.target.value }));
    setPage(1);
  };

  const handleReferenceClick = (data) => {
    setSelectedData(data);
    setOpenCommentPopUp(true);
  };

  const handleOpenPopupCustomer = (lead) => {
    setSelectedLead(lead._id);
    setOpenPopupCustomer(true);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  function getCountryNameByISO(code) {
    return countries[code]?.name || code;
  }

  const countriesArray = Object.keys(countries).map((code) => ({
    code: code,
    name: countries[code].name,
  }));

  const sortedCountries = countriesArray.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  // Filtrer pour obtenir uniquement les leads non attribués
  const unassignedLeads = allLeads.filter((lead) => lead.isAttribued);

  // Filtrer les leads non attribués basé sur la recherche
  const filteredLeads =
    searchValue.length > 0
      ? unassignedLeads.filter(
          (lead) =>
            lead.reference.toLowerCase().includes(searchValue.toLowerCase()) ||
            lead.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            lead.email.toLowerCase().includes(searchValue.toLowerCase())
        )
      : unassignedLeads;

  const handleClickOutside = (event) => {
    if (searchContainerRef && !searchContainerRef.contains(event.target)) {
      setSearchActive(false);
      setSearchValue("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchContainerRef]);

  const getInitials = (name) => {
    return name.charAt(0).toUpperCase();
  };
  const getColorClass = (name) => {
    const colorClasses = [
      "red",
      "blue",
      "purple",
      "green",
      "pink",
      "orange",
      "teal",
      "yellow",
      "brown",
      "gray",
    ];
    const index = name.length % colorClasses.length;
    return colorClasses[index];
  };

  useEffect(() => {
    if (!socket) return;

    // Quand on reçoit "updateLeadsStatus", on met à jour leadsOnlineStatus
    const handleUpdateLeadsStatus = (connectedLeads) => {
      // connectedLeads est un objet { email1: socketId, email2: socketId, ... }
      // On transforme ça en { email1: true, email2: true, ... } si on veut
      let statusMap = {};
      Object.keys(connectedLeads).forEach((email) => {
        statusMap[email] = true;
      });
      setLeadsOnlineStatus(statusMap);
    };

    // S'abonner
    socket.on("updateLeadsStatus", handleUpdateLeadsStatus);

    // Au montage, on peut demander l’état actuel
    socket.emit("requestCurrentLeadsStatus");

    // Quand on reçoit la liste courante (db: isOnline)
    const handleCurrentLeadsStatus = (leads) => {
      const initialStatus = {};
      leads.forEach((lead) => {
        initialStatus[lead.email] = lead.isOnline;
      });
      setLeadsOnlineStatus(initialStatus);
    };
    socket.on("currentLeadsStatus", handleCurrentLeadsStatus);

    // Cleanup
    return () => {
      socket.off("updateLeadsStatus", handleUpdateLeadsStatus);
      socket.off("currentLeadsStatus", handleCurrentLeadsStatus);
    };
  }, [socket]);

  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>Customers list : {totalLeads} result(s)</span>
              <select
                value={selectedFilters.country}
                onChange={handleCountryFilterChange}
                className={
                  selectedFilters.country !== "All Countries"
                    ? "greenBackgroundAfterSelect"
                    : ""
                }
              >
                <option value="All Countries">All Countries</option>
                {sortedCountries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
              {user.isSuperAdmin && (
                <select
                  value={selectedFilters.user}
                  onChange={handleUserFilterChange}
                  className={
                    selectedFilters.user !== "All Sales"
                      ? "greenBackgroundAfterSelect"
                      : ""
                  }
                >
                  <option value="All Sales">All Sales</option>
                  {users
                    .filter((user) => user.isSales)
                    .map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.name}
                      </option>
                    ))}
                </select>
              )}
              <select
                value={selectedFilters.campaign}
                onChange={handleCampagneFilterChange}
                className={
                  selectedFilters.campaign !== "All Campaign"
                    ? "greenBackgroundAfterSelect"
                    : ""
                }
              >
                <option value="All Campaign">All Campaign</option>
                {campaigns.map((camp) => (
                  <option key={camp._id} value={camp._id}>
                    {camp.title}
                  </option>
                ))}
              </select>

              <select
                value={selectedFilters.status}
                onChange={handleStatusFilterChange}
                className={
                  selectedFilters.status !== "All Status"
                    ? "greenBackgroundAfterSelect"
                    : ""
                }
              >
                <option value="All Status">All Status</option>
                {status.map((stat) => (
                  <option key={stat._id} value={stat._id}>
                    {stat.title}
                  </option>
                ))}
              </select>
              {user.isSuperAdmin && (
                <select onChange={handleOptionChange}>
                  <option value="Attr">Attribution</option>
                  <option value="Reg">Registration</option>
                </select>
              )}
            </div>

            <div className="rightElements">
              <div className="searchContainer" ref={setSearchContainerRef}>
                {!searchActive && (
                  <button onClick={() => setSearchActive(true)}>
                    <SearchIcon />
                    <span>Search</span>
                  </button>
                )}
                {searchActive && (
                  <div>
                    <input
                      type="text"
                      className="searchInput"
                      onChange={(e) => setSearchValue(e.target.value)}
                      autoFocus
                    />
                    <div className="searchResults">
                      {searchValue.length > 0 &&
                        filteredLeads.map((lead, index) => (
                          <div
                            key={index}
                            className="searchResultItem"
                            onClick={() => handleOpenPopupCustomer(lead, index)}
                          >
                            {lead.reference} - {lead.name} - {lead.email}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              {user.isSuperAdmin && checkedItems.length > 0 && (
                <>
                  <button onClick={() => setOpenAssignAllLead(true)}>
                    <KeyboardArrowRightIcon />
                    <span>Assign To</span>
                  </button>
                  <button onClick={() => setOpenDeleteAllMessage(true)}>
                    <KeyboardArrowRightIcon />
                    <span>Delete All</span>
                  </button>
                </>
              )}
              {user.isSuperAdmin && (
                <button onClick={handleExportCsv}>
                  <ImportExportIcon />
                  <span>Export</span>
                </button>
              )}
            </div>
          </div>
          <table className="tableData" ref={tableRef}>
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="placeCheckbox">
                    <label
                      className="container"
                      onClick={handleHeaderCheckboxClick}
                    >
                      <input type="checkbox" checked={isAllChecked} readOnly />

                      <div className="checkmark"></div>
                    </label>
                  </div>
                </th>
                <th>
                  <div className="headerWithIcon">Full name</div>
                </th>
                <th></th>
                <th>
                  <div className="headerWithIcon">Country</div>
                </th>
                <th>
                  <div className="headerWithIcon">Phone</div>
                </th>
                <th>
                  <div className="headerWithIcon">
                    {selectedOption === "Attr" ? "Attr" : "Reg"}
                  </div>
                </th>

                <th>
                  <div className="headerWithIcon">Campaign</div>
                </th>
                {user.isSuperAdmin && (
                  <th>
                    <div className="headerWithIcon">Sales Agent</div>
                  </th>
                )}
                <th>
                  <div className="headerWithIcon">Status</div>
                </th>
                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                Array.from({ length: 0 }).map((_, i) => (
                  <tr key={i}>
                    <td colSpan="8" style={{ textAlign: "center" }}>
                      <div className="skeleton-line"></div>
                    </td>
                  </tr>
                ))
              ) : data.length === 0 ? (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              ) : (
                data.map((item, index) => (
                  <tr
                    key={index}
                    className={
                      isAllRowsHighlighted || highlightedRows.includes(item._id)
                        ? "highlighted"
                        : ""
                    }
                  >
                    <td>
                      <label className="container">
                        <input
                          type="checkbox"
                          checked={checkedItems.includes(item._id)}
                          onChange={(e) =>
                            handleCheckboxChangeWrapper(e, item._id)
                          }
                        />
                        <div className="checkmark"></div>
                      </label>
                    </td>
                    <td onClick={() => handleOpenPopupCustomer(item)}>
                      <div className="nameAndStatus">
                        <div
                          className={`circleAgence ${getColorClass(item.name)}`}
                        >
                          {getInitials(item.name)}
                        </div>
                        <span style={{ color: "var(--primary-color)" }}>
                          {item.reference} - {item.name}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        className={`rondConnectStatus ${
                          leadsOnlineStatus[item.email] ? "online" : ""
                        }`}
                      ></div>
                    </td>
                    <td>
                      <span>{getCountryNameByISO(item.country)}</span>
                    </td>
                    <td>
                      <span>{item.phone}</span>
                    </td>
                    <td>
                      <span>
                        {moment(
                          selectedOption === "Attr"
                            ? item.attributionDate
                            : item.createdAt
                        ).format("DD/MM/YYYY")}
                      </span>
                    </td>
                    <td>
                      {user.isSuperAdmin ? (
                        <select
                          className="statusLead"
                          value={
                            selectedCampaign[item._id] ||
                            (item.campaign ? item.campaign._id : "default")
                          }
                          onChange={(e) => handleCampaignChange(e, item._id)}
                        >
                          {item.campaign && (
                            <option value={item.campaign._id}>
                              {item.campaign.title}
                            </option>
                          )}
                          {campaigns.map((camp) => {
                            if (
                              !item.campaign ||
                              camp._id !== item.campaign._id
                            ) {
                              return (
                                <option key={camp._id} value={camp._id}>
                                  {camp.title}
                                </option>
                              );
                            }
                            return null;
                          })}
                        </select>
                      ) : (
                        <span> {item.campaign.title}</span>
                      )}
                    </td>
                    {user.isSuperAdmin && (
                      <td>
                        <select
                          className="statusLead"
                          value={
                            selectedAssignee[item._id] ||
                            (item.assignTo ? item.assignTo._id : "Not assign")
                          }
                          onChange={(e) => handleAssignmentChange(e, item._id)}
                        >
                          {item.assignTo ? (
                            <option value={item.assignTo._id}>
                              {item.assignTo.name}
                            </option>
                          ) : (
                            <option value="Not assign">Not assign</option>
                          )}
                          {users
                            .filter(
                              (user) =>
                                (user.isSales || user.isAdmin) &&
                                (!item.assignTo ||
                                  user._id !== item.assignTo._id)
                            )
                            .map((user) => (
                              <option key={user._id} value={user._id}>
                                {user.name}
                              </option>
                            ))}
                        </select>
                      </td>
                    )}
                    <td>
                      <select
                        className="statusLead"
                        value={
                          selectedStatus[item._id] ||
                          (item.status ? item.status._id : "default")
                        }
                        onChange={(e) => handleStatusChange(e, item._id)}
                      >
                        {item.status && (
                          <option value={item.status._id}>
                            {item.status.title}
                          </option>
                        )}
                        {status.map((stat) => {
                          if (!item.status || stat._id !== item.status._id) {
                            return (
                              <option key={stat._id} value={stat._id}>
                                {stat.title}
                              </option>
                            );
                          }
                          return null;
                        })}
                      </select>
                    </td>
                    <td>
                      <span
                        className="hover-effect"
                        onClick={() => handleReferenceClick(item)}
                      >
                        com({item.comment.length}){" "}
                      </span>

                      {item.name !== "Protected Lead" && (
                        <span
                          className="hover-effect"
                          onClick={() => handleOpenDeleteMessage(item._id)}
                        >
                          del
                        </span>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={Math.ceil(totalLeads / perPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            forcePage={currentPage - 1}
          />
          {isFetching && !loading && (
            <div className="loading-indicator">Mise à jour...</div>
          )}
          {openAssignAllLead && (
            <AssignAllLeadCustomers
              setOpen={setOpenAssignAllLead}
              reFetchFromTable={reFetch}
              checkedItems={checkedItems}
              setIsAllChecked={setIsAllChecked}
              setShowLoader={setShowClimbingLoader}
            />
          )}
          {showClimbingLoader && <ClimbingBoxLoaderPopup />}

          {showSelectAllPopup && (
            <SelectOptionsTable
              setOpen={setShowSelectAllPopup}
              selectCurrentPage={selectCurrentPage}
              selectAllPages={selectAllPages}
              allPageData={totalLeads}
              pageData={data.length}
              setIsAllChecked={setIsAllChecked}
              name="customer"
            />
          )}

          {openPopupCustomer && selectedLead && (
            <PopupCustomerContainer
              setOpen={setOpenPopupCustomer}
              leadId={selectedLead}
              reFetchFromTable={reFetchFromTable}
            />
          )}
          {openDeleteMessage && (
            <DeleteMessage
              setOpen={setOpenDeleteMessage}
              reFetchFromTable={reFetch}
              endPoint={"lead"}
              id={selectedDeletedId}
              allLeads={allLeads}
            />
          )}
          {openDeleteAllMessage && (
            <DeleteAllMessageCustomers
              setOpen={setOpenDeleteAllMessage}
              reFetchFromTable={reFetch}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              setShowLoader={setShowPulseLoader}
              setIsAllChecked={setIsAllChecked}
              allLeads={allLeads}
            />
          )}
          {showPulseLoader && <PulseLoaderPopup />}
          {openCommentPopUp && (
            <CommentPopup
              data={selectedData}
              setOpen={setOpenCommentPopUp}
              reFetchFromTable={reFetch}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerTable;
