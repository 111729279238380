import "./EditTemplateProduct.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const EditTemplateProduct = ({ setOpen, data }) => {
  const handleClickInside = (e) => {
    e.stopPropagation();
  };
  const [types, setTypes] = useState([]);
  const [selectedTypeIndex, setSelectedTypeIndex] = useState(null);
  const [newTypeName, setNewTypeName] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [newUMName, setNewUMName] = useState("");
  const [newNumericValueName, setNewNumericValueName] = useState("");
  const [newFreeValueName, setNewFreeValueName] = useState("");
  const [newAttribute, setNewAttribute] = useState("");
  const [editingAttribut, setEditingAttribut] = useState(null);
  const [abbreviation, setAbbreviation] = useState("");
  const [ordreElements, setOrdreElements] = useState([]);
  const [selectedAttributesForPreview, setSelectedAttributesForPreview] =
    useState({});
  const [selectedAttributeForVignette, setSelectedAttributeForVignette] =
    useState(null);
  const [tempVignetteIndex, setTempVignetteIndex] = useState(null);

  const [typeVignette, setTypeVignette] = useState("");

  useEffect(() => {
    if (data?.name) {
      setTemplateName(data.name);
    }

    if (data?.types) {
      setTypes(data.types);

      const initialOrder = data.types.map((type) => type.name);
      setOrdreElements(initialOrder);

      const vignetteIndex = data.types.findIndex((type) => type.isVignette);
      setTempVignetteIndex(vignetteIndex);
    }
  }, [data]);

  const handleAddType = (typeCategory, name, abbreviation) => {
    const newType = {
      name: name,
      abbreviation: typeCategory === "UM" ? abbreviation : "",
      typeCategory,
      attributs: [],
      indexPresentation: types.length,
      isVignette: false,
    };
    setTypes((prevTypes) => [...prevTypes, newType]);

    switch (typeCategory) {
      case "Type":
        setNewTypeName("");
        break;
      case "UM":
        setNewUMName("");
        setAbbreviation("");
        break;
      case "numeric":
        setNewNumericValueName("");
        break;
      case "freeValue":
        setNewFreeValueName("");
        break;
      default:
        break;
    }
  };

  const handleAddAttribute = () => {
    if (selectedTypeIndex != null) {
      const updatedTypes = types.map((type, index) => {
        if (index === selectedTypeIndex) {
          const updatedAttributs = [
            ...type.attributs,
            {
              value: newAttribute,
              textColor: "#000000",
              backgroundColor: "#ffffff",
            },
          ];
          return { ...type, attributs: updatedAttributs };
        }
        return type;
      });
      setTypes(updatedTypes);
      setNewAttribute("");
    }
  };

  const handleUpdateOrder = (elementName, newIndex) => {
    // Retirer l'élément de l'ordre actuel s'il existe déjà
    let newOrder = ordreElements.filter((el) => el !== elementName);

    // Insérer l'élément à la nouvelle position
    // Assurez-vous que newIndex est traité comme un entier
    newIndex = parseInt(newIndex, 10);

    // Ajout de la logique pour gérer les indices hors des limites existantes
    if (newIndex >= 0 && newIndex <= newOrder.length) {
      newOrder.splice(newIndex, 0, elementName);
    } else {
      // Ajouter à la fin si l'index est hors des limites
      newOrder.push(elementName);
    }

    setOrdreElements(newOrder);
  };

  const handleDeleteAttribut = (typeIndex, attrIndex) => {
    const updatedTypes = types.map((type, index) => {
      if (index === typeIndex) {
        // Filtre pour supprimer l'attribut
        const updatedAttributs = type.attributs.filter(
          (_, i) => i !== attrIndex
        );
        return { ...type, attributs: updatedAttributs };
      }
      return type;
    });

    setTypes(updatedTypes);

    if (
      selectedAttributeForVignette &&
      selectedAttributeForVignette.typeIndex === typeIndex &&
      selectedAttributeForVignette.attrIndex === attrIndex
    ) {
      setSelectedAttributeForVignette(null);
    }
  };

  // Entrer en mode édition
  const startEdit = (typeIndex, attrIndex, attribut) => {
    setEditingAttribut({
      typeIndex,
      attrIndex,
      value: attribut.value,
      color: attribut.color,
    });
  };

  // Modifier la valeur temporaire de l'attribut en cours d'édition
  const editAttributValue = (newValue) => {
    setEditingAttribut((prev) => ({ ...prev, value: newValue }));
  };

  // Sauvegarder la modification et sortir du mode édition
  const saveEdit = () => {
    const { typeIndex, attrIndex, value, color } = editingAttribut;
    const newTypes = types.map((type, idx) => {
      if (idx === typeIndex) {
        return {
          ...type,
          attributs: type.attributs.map((attr, index) => {
            if (index === attrIndex) {
              return { ...attr, value, color }; // Met à jour à la fois la valeur et la couleur
            }
            return attr;
          }),
        };
      }
      return type;
    });
    setTypes(newTypes);
    setEditingAttribut(null); // Sortir du mode édition
  };

  const handleToggleVignette = (index) => {
    let newOrdreElements = [...ordreElements];
    const updatedTypes = types.map((type, idx) => {
      const isVignetteNow = idx === index ? !type.isVignette : type.isVignette;

      if (isVignetteNow) {
        newOrdreElements = newOrdreElements.filter(
          (name) => name !== type.name
        );
      }

      return { ...type, isVignette: isVignetteNow };
    });

    setTypes(updatedTypes);
    setOrdreElements(newOrdreElements);

    setTypeVignette(updatedTypes[index].isVignette ? types[index].name : "");
    setTempVignetteIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleColorChange = (newColor, typeIndex, attrIndex, colorType) => {
    const updatedTypes = types.map((type, idx) => {
      if (idx === typeIndex) {
        return {
          ...type,
          attributs: type.attributs.map((attr, i) => {
            if (i === attrIndex) {
              return { ...attr, [colorType]: newColor };
            }
            return attr;
          }),
        };
      }
      return type;
    });
    setTypes(updatedTypes);
  };

  const handleDeleteType = (indexToDelete) => {
    const filteredTypes = types.filter((_, index) => index !== indexToDelete);
    setTypes(filteredTypes);

    if (selectedTypeIndex === indexToDelete) {
      setSelectedTypeIndex(null);
    }
  };

  const handlePreviewIconClick = (typeName, attrIndex) => {
    setSelectedAttributesForPreview((prev) => ({
      ...prev,
      [typeName]: attrIndex,
    }));
  };

  const handleAbbreviationChange = (index, newAbbreviation) => {
    setTypes((currentTypes) =>
      currentTypes.map((type, idx) => {
        if (idx === index) {
          return { ...type, abbreviation: newAbbreviation };
        }
        return type;
      })
    );
  };

  const updateTemplate = async () => {
    const presentationTemplateData = {
      name: templateName,
      types: types.map((type) => ({
        name: type.name,
        abbreviation: type.typeCategory === "UM" ? type.abbreviation : "",
        typeCategory: type.typeCategory,
        attributs: type.attributs.map((attribut) => ({
          value: attribut.value,
          textColor: attribut.textColor || "#000000",
          backgroundColor: attribut.backgroundColor || "#ffffff",
        })),
        indexPresentation: ordreElements.findIndex((e) => e === type.name),
        isVignette: type.isVignette,
      })),
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/templateProduct/${data._id}`,
        presentationTemplateData
      );
      if (response.status === 200 || response.status === 201) {
        setOpen(false); // Fermer le composant EditTemplateProduct
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const sortedTypes = [...data.types].sort(
      (a, b) => a.indexPresentation - b.indexPresentation
    );
    const order = sortedTypes.map((type) => type.name);
    setOrdreElements(order);
  }, [data.types]);


  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createPtemplate" onClick={handleClickInside}>
          <div className="closeIconPtemplate">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createPtemplateWrapper">
            <div className="topCreatePtemplate">
              <span>Edit Product Template</span>
            </div>

            <div className="middleCreatePtemplate">
              <div className="middleCreatePtemplateLeft">
                <div className="middleCreatePtemplateLeftWrapper">
                  <div className="middleCreatePtemplateLeftWrapperTop">
                    <input
                      type="text"
                      placeholder="Template Name"
                      style={{ padding: "6px", width: "97%" }}
                      value={templateName}
                      onChange={(e) => setTemplateName(e.target.value)}
                    />
                    <div className="middleCreatePtemplateLeftWrapperInputs">
                      <div className="templateTopInputs">
                        <input
                          type="text"
                          placeholder="Type"
                          onChange={(e) => setNewTypeName(e.target.value)}
                        />
                        <AddIcon
                          onClick={() => handleAddType("Type", newTypeName)}
                        />
                      </div>
                      <div className="templateTopInputs">
                        <input
                          type="text"
                          placeholder="UM"
                          value={newUMName}
                          onChange={(e) => setNewUMName(e.target.value)}
                        />
                        <AddIcon
                          onClick={() => handleAddType("UM", newUMName)}
                        />{" "}
                      </div>
                      <div className="templateTopInputs">
                        <input
                          type="text"
                          placeholder="Numeric Value"
                          value={newNumericValueName}
                          onChange={(e) =>
                            setNewNumericValueName(e.target.value)
                          }
                        />

                        <AddIcon
                          onClick={() =>
                            handleAddType("numeric", newNumericValueName)
                          }
                        />
                      </div>
                      <div className="templateTopInputs">
                        <input
                          type="text"
                          placeholder="Free Value"
                          onChange={(e) => setNewFreeValueName(e.target.value)}
                          value={newFreeValueName}
                        />
                        <AddIcon
                          onClick={() =>
                            handleAddType("freeValue", newFreeValueName)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="middleCreatePtemplateLeftWrapperBottom">
                    {types.map((type, index) => (
                      <div
                        key={index}
                        className="templateEditor-typeAttributesContainer"
                      >
                        {type.typeCategory === "Type" && (
                          <>
                            <div className="typeDetails">
                              <span className="typeName">
                                {type.name || "New Type"}
                              </span>
                              <div
                                onClick={() => setSelectedTypeIndex(index)}
                                className="addAttributesContainer"
                              >
                                <AddIcon style={{ fontSize: "18px" }} /> Add
                                Attributes
                              </div>
                              {!type.isVignette && (
                                <input
                                  type="text"
                                  placeholder="Index"
                                  className="indexInputTemplateComponent"
                                  value={
                                    ordreElements.findIndex(
                                      (el) => el === type.name
                                    ) >= 0
                                      ? ordreElements.findIndex(
                                          (el) => el === type.name
                                        )
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleUpdateOrder(type.name, e.target.value)
                                  }
                                />
                              )}
                              <button
                                className={`vignetteButton ${
                                  tempVignetteIndex === index
                                    ? "vignetteButtonActive"
                                    : ""
                                }`}
                                onClick={() => handleToggleVignette(index)}
                              >
                                Set as Vignette
                              </button>
                              <CloseIcon
                                onClick={() => handleDeleteType(index)}
                                style={{
                                  color: "red",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                            <div className="typeDetailsBottom">
                              {selectedTypeIndex === index && (
                                <div className="addAttributeInput">
                                  <input
                                    type="text"
                                    placeholder="Add New Attribute"
                                    value={newAttribute}
                                    onChange={(e) =>
                                      setNewAttribute(e.target.value)
                                    }
                                    className="newAttributeInput"
                                  />
                                  <AddIcon
                                    onClick={handleAddAttribute}
                                    className="addAttributeIcon"
                                  />
                                </div>
                              )}

                              <div className="typeDetailsBottomBottom">
                                {type.attributs.map((attribut, attrIndex) => (
                                  <div
                                    key={attrIndex}
                                    className="attributeContainer"
                                  >
                                    <input
                                      type="text"
                                      value={
                                        editingAttribut &&
                                        editingAttribut.typeIndex === index &&
                                        editingAttribut.attrIndex === attrIndex
                                          ? editingAttribut.value
                                          : attribut.value
                                      }
                                      readOnly={
                                        !editingAttribut ||
                                        editingAttribut.typeIndex !== index ||
                                        editingAttribut.attrIndex !== attrIndex
                                      }
                                      className={`attributeInput ${
                                        editingAttribut &&
                                        editingAttribut.typeIndex === index &&
                                        editingAttribut.attrIndex === attrIndex
                                          ? "editing"
                                          : ""
                                      }`}
                                      onChange={(e) =>
                                        setEditingAttribut({
                                          ...editingAttribut,
                                          value: e.target.value,
                                        })
                                      }
                                    />
                                    {type.isVignette ? (
                                      <div
                                        className="previewTriggerIcon"
                                        onClick={() =>
                                          setSelectedAttributeForVignette({
                                            typeIndex: index,
                                            attrIndex,
                                          })
                                        }
                                      >
                                        <RemoveRedEyeIcon />
                                      </div>
                                    ) : (
                                      <div
                                        className="previewTriggerIcon"
                                        onClick={() =>
                                          handlePreviewIconClick(
                                            type.name,
                                            attrIndex,
                                            type.isVignette
                                          )
                                        }
                                      >
                                        <RemoveRedEyeIcon />
                                      </div>
                                    )}
                                    {type.isVignette && (
                                      <>
                                        T
                                        <input
                                          type="color"
                                          value={
                                            attribut.textColor || "#000000"
                                          }
                                          onChange={(e) =>
                                            handleColorChange(
                                              e.target.value,
                                              index,
                                              attrIndex,
                                              "textColor"
                                            )
                                          }
                                          className="colorPicker"
                                        />
                                        B
                                        <input
                                          type="color"
                                          value={
                                            attribut.backgroundColor ||
                                            "#ffffff"
                                          }
                                          onChange={(e) =>
                                            handleColorChange(
                                              e.target.value,
                                              index,
                                              attrIndex,
                                              "backgroundColor"
                                            )
                                          }
                                          className="colorPicker"
                                        />
                                      </>
                                    )}
                                    {editingAttribut &&
                                    editingAttribut.typeIndex === index &&
                                    editingAttribut.attrIndex === attrIndex ? (
                                      <CheckIcon
                                        className="checkAttributeIcon"
                                        onClick={() => saveEdit()}
                                      />
                                    ) : (
                                      <EditIcon
                                        className="editAttributeIcon"
                                        onClick={() =>
                                          startEdit(index, attrIndex, attribut)
                                        }
                                      />
                                    )}
                                    <CloseIcon
                                      className="closeAttributeIcon"
                                      onClick={() =>
                                        handleDeleteAttribut(index, attrIndex)
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        )}
                        {type.typeCategory === "numeric" && (
                          <>
                            <div className="typeDetails">
                              <span className="typeName">
                                {type.name || "New Type"}
                              </span>

                              <input
                                type="text"
                                placeholder="Index"
                                className="indexInputTemplateComponent"
                                value={
                                  ordreElements.findIndex(
                                    (el) => el === type.name
                                  ) >= 0
                                    ? ordreElements.findIndex(
                                        (el) => el === type.name
                                      )
                                    : ""
                                }
                                onChange={(e) =>
                                  handleUpdateOrder(type.name, e.target.value)
                                }
                              />
                              <CloseIcon
                                onClick={() => handleDeleteType(index)}
                                style={{
                                  color: "red",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </>
                        )}
                        {type.typeCategory === "freeValue" && (
                          <>
                            <div className="typeDetails">
                              <span className="typeName">
                                {type.name || "New Type"}
                              </span>

                              <input
                                type="text"
                                placeholder="Index"
                                className="indexInputTemplateComponent"
                                value={
                                  ordreElements.findIndex(
                                    (el) => el === type.name
                                  ) >= 0
                                    ? ordreElements.findIndex(
                                        (el) => el === type.name
                                      )
                                    : ""
                                }
                                onChange={(e) =>
                                  handleUpdateOrder(type.name, e.target.value)
                                }
                              />
                              <CloseIcon
                                onClick={() => handleDeleteType(index)}
                                style={{
                                  color: "red",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </>
                        )}
                        {type.typeCategory === "UM" && (
                          <>
                            <div className="typeDetails">
                              <span className="typeName">
                                {type.name || "New Type"}
                              </span>
                              <input
                                type="text"
                                placeholder="Abbreviation"
                                value={type.abbreviation}
                                onChange={(e) =>
                                  handleAbbreviationChange(
                                    index,
                                    e.target.value
                                  )
                                }
                                className="indexInputTemplateComponent"
                              />
                              <input
                                type="text"
                                placeholder="Index"
                                className="indexInputTemplateComponent"
                                value={
                                  ordreElements.findIndex(
                                    (el) => el === type.name
                                  ) >= 0
                                    ? ordreElements.findIndex(
                                        (el) => el === type.name
                                      )
                                    : ""
                                }
                                onChange={(e) =>
                                  handleUpdateOrder(type.name, e.target.value)
                                }
                              />

                              <CloseIcon
                                onClick={() => handleDeleteType(index)}
                                style={{
                                  color: "red",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="middleCreatePtemplateRight">
                <div className="middleCreatePtemplateRightTop">
                  {ordreElements.map((element, typeIndex) => {
                    const type = types.find((t) => t.name === element);
                    if (type) {
                      const attrIndex = selectedAttributesForPreview[type.name];
                      const contentToShow =
                        attrIndex !== undefined
                          ? type.attributs[attrIndex].value
                          : type.name;

                      return <span key={typeIndex}>{contentToShow}</span>;
                    }
                    return null;
                  })}
                </div>

                <span style={{ color: "#ccc" }}>
                  Description: Lorem ipsum dolor sit amet...
                </span>

                <div className="vignette">
                  {types.map((type, index) => {
                    if (type.isVignette) {
                      let contentToShow = type.name;
                      let backgroundColor = "#f2f2f2";
                      let textColor = "#000000";

                      if (
                        selectedAttributeForVignette &&
                        selectedAttributeForVignette.typeIndex === index
                      ) {
                        const attr =
                          type.attributs[
                            selectedAttributeForVignette.attrIndex
                          ];
                        contentToShow = attr.value;
                        backgroundColor = attr.backgroundColor;
                        textColor = attr.textColor;
                      }

                      return (
                        <div
                          key={index}
                          style={{
                            backgroundColor: backgroundColor,
                            color: textColor,
                            padding: "2px 5px",
                            margin: "2px 0",
                          }}
                        >
                          {contentToShow}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
            <div className="bottomCreatePtemplate">
              <button className="cancelButton">Cancel</button>
              <button className="saveButton" onClick={updateTemplate}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTemplateProduct;
