import { useEffect } from "react";
import { useSocket } from "../context/SocketContext";
import { useQueryClient } from "@tanstack/react-query";

const LeadSocketHandler = () => {
  const { socket } = useSocket();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!socket) return;

    const invalidateLeads = () => {
      queryClient.invalidateQueries(["leads"]);
    };

    const handleLeadAssigned = () => {
      invalidateLeads();
    };

    const handleLeadUpdated = () => {
      invalidateLeads();
    };

    const handleLeadAdded = () => {
      invalidateLeads();
    };

    const handleLeadRemoved = () => {
      invalidateLeads();
    };

    const handleLeadCommentAdded = () => {
      invalidateLeads();
    };

    const handleLeadCommentRemoved = () => {
      invalidateLeads();
    };

    socket.on("leadAssigned", handleLeadAssigned);
    socket.on("leadUpdated", handleLeadUpdated);
    socket.on("leadAdded", handleLeadAdded);
    socket.on("leadRemoved", handleLeadRemoved);
    socket.on("leadCommentAdded", handleLeadCommentAdded);
    socket.on("leadCommentRemoved", handleLeadCommentRemoved);

    return () => {
      socket.off("leadAssigned", handleLeadAssigned);
      socket.off("leadUpdated", handleLeadUpdated);
      socket.off("leadAdded", handleLeadAdded);
      socket.off("leadRemoved", handleLeadRemoved);
      socket.off("leadCommentAdded", handleLeadCommentAdded);
      socket.off("leadCommentRemoved", handleLeadCommentRemoved);
    };
  }, [socket, queryClient]);

  return null;
};

export default LeadSocketHandler;
