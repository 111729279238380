import "./DeleteMessage.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";

const DeleteMessage = ({
  setOpen,
  currentData,
  reFetchAllLead,
  selectedData,
}) => {

  const handleDelete = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/leadPlatform/${currentData._id}/declineOffer`,
        { offerId: selectedData._id }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createStatus">
          <div className="closeIconStatus">
            <CloseIcon
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}
            />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>DEL</span>
            </div>
            <div className="middleCreateStatus">
              <span>Are you sure you want to perform this action?</span>
            </div>
            <div className="bottomCreateStatus">
              <button
                className="cancelButton"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(false);
                }}
              >
                Cancel
              </button>
              <button className="saveButton" onClick={handleDelete}>
                <CheckIcon />
                Comfirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteMessage;
