import "./SalesPerfomanceDashboard.css"

const SalesPerfomanceDashboard = () => {
  return (
    <div className="salePerfomanceWrapperDashboard">
          <div className="salePerfomanceWrapperDashboardTop">
            <div className="salePerfomanceWrapperDashboardTopFirst">
              <img src="/images/avatar/avatar20.png" alt="" />
              {/* <div className="salePerfomanceWrapperDashboardTopFirstRanking">
                2
              </div> */}
              <span style={{ color: "#cbcbcb", fontSize: "18px" }}>2</span>
              <span style={{ color: "black" }}>sales2</span>
              <span style={{ fontSize: "10px" }}>3 FTDS</span>
            </div>
            <div className="salePerfomanceWrapperDashboardTopSecond">
              <img src="/images/avatar/avatar16.png" alt="" />
              {/* <MilitaryTechIcon /> */}

              <span style={{ color: "#cbcbcb", fontSize: "18px" }}>1</span>
              <span style={{ color: "black" }}>sales1</span>
              <span style={{ fontSize: "10px" }}>5 FTDS</span>
            </div>
            <div className="salePerfomanceWrapperDashboardTopThird">
              <img src="/images/avatar/avatar19.png" alt="" />

              <span style={{ color: "#cbcbcb", fontSize: "18px" }}>3</span>
              <span style={{ color: "black" }}>sales3</span>
              <span style={{ fontSize: "10px" }}>3 FTDS</span>
            </div>
          </div>
          <div className="salePerfomanceWrapperDashboardBottom">
            <div className="salePerfomanceWrapperDashboardBottomItem">
              <div className="salePerfomanceWrapperDashboardBottomItemLeft">
                <span style={{ color: "#cbcbcb", fontSize: "18px" }}>4</span>
                <img src="/images/avatar/avatar25.png" alt="" />
                <span style={{ color: "black" }}>sales4</span>
              </div>
              <div className="salePerfomanceWrapperDashboardBottomItemRight">
                <span style={{ fontSize: "10px" }}>1 FTDS</span>
              </div>
            </div>
            <div className="salePerfomanceWrapperDashboardBottomItem">
              <div className="salePerfomanceWrapperDashboardBottomItemLeft">
                <span style={{ color: "#cbcbcb", fontSize: "18px" }}>5</span>
                <img src="/images/avatar/avatar22.png" alt="" />
                <span style={{ color: "black" }}>sales5</span>
              </div>
              <div className="salePerfomanceWrapperDashboardBottomItemRight">
                <span style={{ fontSize: "10px" }}>1 FTDS</span>
              </div>
            </div>
            <div className="salePerfomanceWrapperDashboardBottomItem">
              <div className="salePerfomanceWrapperDashboardBottomItemLeft">
                <span style={{ color: "#cbcbcb", fontSize: "18px" }}>6</span>
                <img src="/images/avatar/avatar21.png" alt="" />
                <span style={{ color: "black" }}>sales6</span>
              </div>
              <div className="salePerfomanceWrapperDashboardBottomItemRight">
                <span style={{ fontSize: "10px" }}>1 FTDS</span>
              </div>
            </div>
          </div>
        </div>
  )
}

export default SalesPerfomanceDashboard