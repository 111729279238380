import { useState } from "react";
import ChartPerfomance from "../ChartPerfomance/ChartPerfomance";
import "./GeneralRankingPerf.css";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import SingleUserPerfomance from "../SingleUserPerfomance/SingleUserPerfomance";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";

const GeneralRankingPerf = ({
  userStats,
  rankingCriteria,
  setRankingCriteria,
}) => {
  
  const [openPopupUser, setOpenPopupUser] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);

  const handleRankingCriteriaChange = (e) => {
    setRankingCriteria(e.target.value);
  };


  const sortedUserStats = userStats.sort((a, b) => {
    switch (rankingCriteria) {
      case "Deposits":
        return b.numberOfDeposit - a.numberOfDeposit;
      case "FTDS":
        return b.totalFtds - a.totalFtds;
      case "Leads":
        return b.leadsCreatedInRange.length - a.leadsCreatedInRange.length;
      case "Turnover":
        return b.turnoverPerUser - a.turnoverPerUser;
      case "Conversion":
        return parseFloat(b.conversionRate) - parseFloat(a.conversionRate);
      case "FTDSV":
        return b.ftdsValuePerUser - a.ftdsValuePerUser;
      default:
        return 0;
    }
  });

  const handleOpenUserPerfomance = (data) => {
    setOpenPopupUser(true);
    setSelectedUserData(data);
  };

  return (
    <>
      <div className="salesPerfomancePageBottomLeftTopItemWithSelect">
        <div className="salesPerfomancePageBottomLeftTopItemWithSelectLeft">
          <SignalCellularAltIcon />
          <span>GENERAL RANKING</span>
        </div>
        <div className="salesPerfomancePageBottomLeftTopItemWithSelectRight">
          {/* <select onChange={handleTypeUserChange}>
            <option value="All">All</option>
            <option value="Sales">Sales</option>
            <option value="Admin">Admin</option>
          </select> */}
          <select onChange={handleRankingCriteriaChange}>
            <option value="Leads">Leads</option>
            <option value="FTDS">FTDS</option>
            <option value="Turnover">Turnover</option>
            <option value="Conversion">Conversion</option>
            <option value="Deposits">Deposits</option>
            <option value="FTDSR">FTDS Revenue</option>
            <option value="FTDSV">FTDS Value</option>
          </select>
        </div>
      </div>
      <ChartPerfomance
        userStats={userStats}
        rankingCriteria={rankingCriteria}
      />
      <div className="rankingWrapper">
        {sortedUserStats.map((user, index) => (
          <div
            className="rankingWrapperItem"
            key={user.userId}
            onClick={() => handleOpenUserPerfomance(user)}
          >
            <div className="rankingWrapperItemLeftSide">
              {index === 0 ? (
                <MilitaryTechIcon
                  style={{ color: "#7ec5cf", fontSize: "26px" }}
                />
              ) : (
                <span style={{ fontSize: "12px" }}>{index + 1}</span>
              )}
            </div>
            <div className="rankingWrapperItemImgSide">
              <img src={user.userPicture} alt={user.userPicture} />
            </div>
            <div className="rankingWrapperItemRightSide">
              <div className="rankingWrapperItemRightSideTop">
                {user.userName}
              </div>
              <div className="rankingWrapperItemRightSideBottom">
                <span>
                  Leads: {user.leadsCreatedInRange.length || 0} | Deposits:{" "}
                  {user.numberOfDeposit || 0} | TO : {user.turnoverPerUser || 0}{" "}
                  € | FTDs: {user.totalFtds || 0} | FTDs Revenues : todo € |
                  FTDs Value : {user.ftdsValuePerUser || 0} € | Conversion:{" "}
                  {user.conversionRate || 0}%
                </span>
              </div>
            </div>
          </div>
        ))}
        {openPopupUser && (
          <SingleUserPerfomance
            setOpen={setOpenPopupUser}
            data={selectedUserData}
          />
        )}
      </div>
    </>
  );
};

export default GeneralRankingPerf;
