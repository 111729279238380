import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import { useContext, useState } from "react";
import { AuthContextLead } from "../../../../../context/leadContextAuth";
import { useOpenTab } from "../../../../../context/OpenTabContext";
import PropagateLoader from "react-spinners/PropagateLoader";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

const AcceptOffer = ({ setOpen, selectedData }) => {
  const { lead } = useContext(AuthContextLead);
  const { handleOpenTab } = useOpenTab();
  const [isLoading, setIsLoading] = useState(false);

  const handlePopupClick = (e) => {
    e.stopPropagation();
  };
  const handleAccepterOffer = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const offerInfo = {
      companyName: selectedData.companyName,
      originPrice: selectedData.product.prix,
      salesPrice: selectedData.amount,
      productId: selectedData.product._id,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/leadPlatform/${lead._id}/acceptOffer`,
        offerInfo
      );

      if (response.data) {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/notification`,
          {
            typeNotification: "offre",
            lead: lead._id,
            text: `Offre acceptée pour ${selectedData.product.reference} de la part de ${selectedData.companyName} à `,
            amount: selectedData.amount,
          }
        );
        handleOpenTab("Sold");
        setOpen(false);
      }
    } catch (err) {
      console.error("Error while adding product on hold:", err);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="buyButton" onClick={handlePopupClick}>
          <div className="closeIconBuyButton">
            <CloseIcon
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
          {isLoading ? (
            <div className="loading-container">
              <span>Offre acceptée...</span>
              <div className="loading-spinner">
                <PropagateLoader color="#93a1ca" />
              </div>
            </div>
          ) : (
            <>
              <div className="buyButtonWrapper">
                <div className="topBuyButton">
                  <span>Accepter l'offre</span>
                </div>
                <div className="middleBuyButton">
                  <div className="middleBuyButtonTop">
                    <LocalOfferIcon />
                  </div>
                  <div className="middleBuyButtonMiddle">
                    <span style={{ fontSize: "25px" }}> Accepter l'offre</span>
                    <span>
                      Accepter l'offre pour{" "}
                      <span style={{ color: "#93a1ca" }}>
                        #{selectedData.product.reference}
                      </span>
                      ?
                    </span>
                  </div>
                </div>
                <div className="bottomBuyButton">
                  <button
                    className="cancelButtonBuyButton"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Annuler
                  </button>
                  <button
                    className="saveButtonBuyButton"
                    onClick={handleAccepterOffer}
                  >
                    <CheckIcon />
                    Confirmer
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AcceptOffer;
