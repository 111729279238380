import "./CreateAccount.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import axios from "axios";
import useFetch from "../../../hooks/useFetch";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import PicturesLibrary from "../PicturesLibrary/PicturesLibrary";

const CreateAccount = ({ setOpen, role, reFetchFromTable }) => {
  const { data: users } = useFetch("user");
  const [openPicturesLibrary, setOpenPicturesLibrary] = useState(false);
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState({});
  const [newUserData, setNewUserData] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    pPicture: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const isFormValid = () => {
    let errors = {};
    let formIsValid = true;

    if (!newUserData.name.trim()) {
      errors.name = "First name is required";
      formIsValid = false;
    }
    if (!lastName.trim()) {
      errors.lastName = "Last name is required";
      formIsValid = false;
    }
    if (!newUserData.username.trim()) {
      errors.username = "Username is required";
      formIsValid = false;
    }
    if (!newUserData.email.trim()) {
      errors.email = "Email is required";
      formIsValid = false;
    }

    setError(errors);
    return formIsValid;
  };

  const handleNewUserChange = (e) => {
    const { name, value } = e.target;

    if (name === "lastName") {
      setLastName(value);
      if (error.lastName) {
        setError((prevError) => ({ ...prevError, lastName: "" }));
      }
    } else {
      setNewUserData((prevData) => ({
        ...prevData,
        [name]: value,
      }));

      if (error[name]) {
        setError((prevError) => ({ ...prevError, [name]: "" }));
      }
    }
  };

  const emailExists = (email) => {
    return users.some((user) => user.email === email);
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();

    if (!isFormValid()) return;

    if (emailExists(newUserData.email.trim())) {
      setError((prevError) => ({
        ...prevError,
        email: "Email already exists",
      }));
      return;
    }

    const roleSettings = {
      isAdmin: role === "isAdmin",
      isSales: role === "isSales",
    };

    const combinedUser = {
      ...newUserData,
      name: `${newUserData.name} ${lastName}`,
      pPicture: selectedImage, // Assurez-vous d'inclure l'image sélectionnée ici
      ...roleSettings,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/auth/signup`,
        combinedUser
      );
      // Réinitialiser l'état après la création réussie
      setNewUserData({
        name: "",
        lastName: "",
        email: "",
        password: "",
        username: "",
      });
      setOpen(false);
      reFetchFromTable();
    } catch (err) {
      console.error(
        "Erreur lors de la création de l'utilisateur:",
        err.response?.data || err.message
      );
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createUser" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createUserWrapper">
            <div className="topCreateStatus">
              <span>Create Account</span>
            </div>
            <div className="profilepictureAdd">
              {selectedImage ? (
                <div className="profilePictureContainer">
                  <img src={selectedImage} alt="" />
                  <div
                    className="editImagesContainer"
                    onClick={() => setOpenPicturesLibrary(true)}
                  >
                    <EditIcon />
                  </div>
                </div>
              ) : (
                <button
                  type="file"
                  className="profilepictureAddButton"
                  onClick={() => setOpenPicturesLibrary(true)}
                >
                  <AddIcon />
                  <span>Add profile picture</span>
                </button>
              )}
            </div>
            <div className="middleCreateUser">
              <div className="inputRow">
                <div className="inputGroup">
                  <input
                    type="text"
                    name="name"
                    value={newUserData.name}
                    onChange={handleNewUserChange}
                    placeholder="First Name"
                  />
                  {error.name && (
                    <div className="errormessage">{error.name}</div>
                  )}
                </div>

                <div className="inputGroup">
                  <input
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={handleNewUserChange}
                    placeholder="Last Name"
                  />
                  {error.lastName && (
                    <div className="errormessage">{error.lastName}</div>
                  )}
                </div>
              </div>

              <div className="inputRow">
                <div className="inputGroup">
                  <input
                    type="text"
                    name="username"
                    value={newUserData.username}
                    onChange={handleNewUserChange}
                    placeholder="Username"
                  />
                  {error.username && (
                    <div className="errormessage">{error.username}</div>
                  )}
                </div>

                <div className="inputGroup">
                  <input
                    type="text"
                    name="extension"
                    value={newUserData.extension}
                    onChange={handleNewUserChange}
                    placeholder="Extention"
                  />
                </div>
              </div>

              <div className="fullWidthInput">
                <input
                  type="text"
                  name="password"
                  value={newUserData.password}
                  onChange={handleNewUserChange}
                  placeholder="Password"
                />
              </div>
              <span>
                The password should contain at least 1 uppercase, 1 number, 1
                symbol
              </span>
              <div className="fullWidthInput">
                <input
                  type="text"
                  name="email"
                  value={newUserData.email}
                  onChange={handleNewUserChange}
                  placeholder="Email"
                />
                {error.email && (
                  <div className="errormessage">{error.email}</div>
                )}
              </div>
              {/* <div className="fullWidthInputColor">
                <label>Choose a color</label>
                <input
                  type="color"
                  name="color"
                  value={newUserData.color}
                  onChange={handleNewUserChange}
                />
              </div> */}
            </div>

            <div className="bottomCreateUser">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="saveButton" onClick={handleCreateUser}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
        {openPicturesLibrary && (
          <PicturesLibrary
            setOpen={setOpenPicturesLibrary}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />
        )}
      </div>
    </>
  );
};

export default CreateAccount;
