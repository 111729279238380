import "./CampaignList.css";

const CampaignList = ({ campaignStats, onEditClick, onDeleteClick }) => {
  return (
    <>
      {campaignStats
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .map((campaign, index) => (
          <div
            key={campaign.campaignId}
            className="dashboardCampaignBottomWrapperItem"
          >
            <div className="dashboardCampaignBottomWrapperItemTopAndBottom">
              <div className="dashboardCampaignBottomWrapperItemTop">
                <span>{campaign.campaignTitle}</span>
              </div>
              <div className="dashboardCampaignBottomWrapperItemBottom">
                <span>
                  Deal: {campaign.campaignType}: {campaign.campaignCost}
                  {campaign.type === "RS" ? "%" : "€"} | Leads:
                  {campaign.numberOfLeads} | FTDs:
                  {campaign.numberOfFTDS} | Conversion:{" "}
                  {isNaN((campaign.numberOfFTDS / campaign.numberOfLeads) * 100)
                    ? "0"
                    : (
                        (campaign.numberOfFTDS / campaign.numberOfLeads) *
                        100
                      ).toFixed(2)}
                  % | Deposits: {campaign.numberOfDeposit} | Revenue:{" "}
                  {campaign.revenue.toFixed(2)}€ | Cost:{" "}
                  {campaign.cost.toFixed(2)}€ | Profit:{" "}
                  {campaign.profit.toFixed(2)}€
                </span>
                <div className="editAndDelDashboardCampaign">
                  <span
                    style={{
                      color: "var(--primary-color)",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => onEditClick(campaign.campaignId)}
                  >
                    edit
                  </span>
                  {campaign.numberOfLeads < 1 && (
                    <span
                      style={{
                        color: "var(--primary-color)",
                        cursor: "pointer",
                      }}
                      onClick={() => onDeleteClick(campaign.campaignId)}
                    >
                      del
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default CampaignList;
