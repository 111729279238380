import "./PendingTable.css";
import { handleCheckboxChange } from "../../../../utils/datatable.utils";
import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import SelectOptionsTable from "../../../SelectOptionsTable/SelectOptionsTable";
import CommentPopup from "../../../CRM/Customers/CommentPopup/CommentPopup";
import moment from "moment";
import axios from "axios";
import Countdown from "./Countdown/Countdown";
import PopupCustomerContainer from "../../../CRM/PopupCustomer/PopupClientContainer/PopupCustomerContainer";
import FollowPayment from "../../../CRM/PopupCustomer/RightSide/History/FollowPayment/FollowPayment";
import DeleteMessage from "../../../CRM/PopupCustomer/RightSide/History/Pending/DeleteMessage/DeleteMessage";
import AcceptMessage from "../../../CRM/PopupCustomer/RightSide/History/Pending/AcceptMessage/AcceptMessage";
import { useSocket } from "../../../../context/SocketContext";

const PendingTable = ({
  data,
  reFetch,
  campaigns,
  users,
  reFetchUsers,
  allLeads,
  reFetchLead,
}) => {
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [openCommentPopUp, setOpenCommentPopUp] = useState(null);
  const [selectedAssignee, setSelectedAssignee] = useState({});
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [selectedUserId, setSelectedUserId] = useState("All Sales");
  const [openPopupCustomer, setOpenPopupCustomer] = useState(false);
  const [openFollowPayment, setOpenFollowPayment] = useState(false);
  const [currentData, setCurrentDate] = useState(null);
  const [selectedCampaignId, setSelectedCampaignId] = useState("All Campaign");
  const [openAcceptMessage, setOpenAcceptMessage] = useState(false);
  const { emitRefreshData } = useSocket();

  const tableRef = useRef(null);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleCheckboxChangeWrapper = (event, itemId) => {
    handleCheckboxChange(
      event,
      itemId,
      data,
      checkedItems,
      setIsAllChecked,
      setCheckedItems
    );
    setHighlightedRows((prevRows) => {
      if (event.target.checked) {
        return [...prevRows, itemId];
      } else {
        return prevRows.filter((rowId) => rowId !== itemId);
      }
    });
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setHighlightedRows([]);
    }
  }, [checkedItems]);

  const handleHeaderCheckboxClick = (e) => {
    e.preventDefault();
    if (checkedItems.length > 0 || isAllChecked) {
      setCheckedItems([]);
      setIsAllChecked(false);
    } else {
      setShowSelectAllPopup(true);
    }
  };
  const selectCurrentPage = () => {
    const start = currentPage * perPage;
    const end = start + perPage;
    const currentPageItems = data.slice(start, end).map((item) => item._id);
    setCheckedItems(currentPageItems);
  };

  const selectAllPages = () => {
    const allItems = data.map((item) => item._id);
    setCheckedItems(allItems);
  };

  const handleCampaignChangeFilter = (e) => {
    setSelectedCampaignId(e.target.value);
  };

  const handleReferenceClick = (data) => {
    setSelectedData(data);
    setOpenCommentPopUp(true);
  };

  const handleUserChange = (e) => {
    setSelectedUserId(e.target.value);
  };

  const filteredPending = data.flatMap((lead) => {
    return lead.onHold
      .filter((onHoldItem) => {
        const matchesCampaign =
          selectedCampaignId === "All Campaign" ||
          (lead.campaign && lead.campaign._id === selectedCampaignId);

        const matchesUser =
          selectedUserId === "All Sales" ||
          (lead.assignTo && lead.assignTo._id === selectedUserId);

        return matchesCampaign && matchesUser;
      })
      .map((onHold) => ({
        ...onHold,
        leadReference: lead.reference,
        leadComments: lead.comment,
        leadName: lead.name,
        campaign: lead.campaign ? lead.campaign : null,
        leadId: lead._id,
        leadAssignTo: lead.assignTo,
        lead: lead,
      }));
  });

  const handleOpenPopupCustomer = (data) => {
    setSelectedData(data);
    setOpenPopupCustomer(true);
  };

  const handleOpenFollowPayment = (productData, lead) => {
    setOpenFollowPayment(true);
    setSelectedData(productData);
    setCurrentDate(lead);
  };

  const handleOpenDeleteMessage = (data, lead) => {
    setOpenDeleteMessage(true);
    setSelectedData(data);
    setCurrentDate(lead);
  };

  const handleOpenAcceptMessage = (data, lead) => {
    setOpenAcceptMessage(true);
    setSelectedData(data);
    setCurrentDate(lead);
  };

  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>Pending : {filteredPending.length}</span>

              <select onChange={handleUserChange}>
                <option value="All Sales">All Sales</option>
                {users
                  .filter((user) => user.isSales)
                  .map((user) => (
                    <option key={user._id} value={user._id}>
                      {user.name}
                    </option>
                  ))}
              </select>
              <select onChange={handleCampaignChangeFilter}>
                <option value="All Campaign">All Campaign</option>
                {campaigns.map((campaign) => (
                  <option key={campaign._id} value={campaign._id}>
                    {campaign.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <table className="tableData" ref={tableRef}>
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="placeCheckbox">
                    <label
                      className="container"
                      onClick={handleHeaderCheckboxClick}
                    >
                      <input type="checkbox" checked={isAllChecked} readOnly />

                      <div className="checkmark"></div>
                    </label>
                  </div>
                </th>
                <th>
                  <div className="headerWithIcon">Customer</div>
                </th>
                <th>
                  <div className="headerWithIcon">Reference</div>
                </th>
                <th>
                  <div className="headerWithIcon">Price</div>
                </th>
                <th>
                  <div className="headerWithIcon">Payments</div>
                </th>
                <th>
                  <div className="headerWithIcon">Date</div>
                </th>
                <th>
                  <div className="headerWithIcon">Expiration</div>
                </th>

                <th>
                  <div className="headerWithIcon">Sales Agent</div>
                </th>
                <th>
                  <div className="headerWithIcon">Campaign</div>
                </th>
                <th>
                  <div className="headerWithIcon">Status</div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredPending
                .slice(currentPage * perPage, (currentPage + 1) * perPage)
                .map((pending, index) => (
                  <tr key={index}>
                    <td>
                      <label className="container">
                        <input
                          type="checkbox"
                          checked={checkedItems.includes(pending.leadId)}
                          onChange={(e) =>
                            handleCheckboxChangeWrapper(e, pending.leadId)
                          }
                        />
                        <div className="checkmark"></div>
                      </label>
                    </td>

                    <td onClick={() => handleOpenPopupCustomer(pending.lead)}>
                      <div className="nameAndStatus">
                        <span style={{ color: "#00B161" }}>
                          {pending.leadReference} - {pending.leadName}
                        </span>
                      </div>
                    </td>

                    <td>
                      <span>{pending.product.reference}</span>
                    </td>

                    <td>{<span>{pending.product.prix} €</span>}</td>
                    <td
                      onClick={() =>
                        handleOpenFollowPayment(pending, pending.lead)
                      }
                    >
                      <div className="catalog-payments-progress">
                        <div
                          className="catalog-payments-progress-filled"
                          style={{
                            width: `${
                              (pending.amountPay.reduce(
                                (acc, cur) => acc + cur.amount,
                                0
                              ) /
                                pending.product.prix) *
                              100
                            }%`,
                            backgroundColor:
                              pending.amountPay.reduce(
                                (acc, cur) => acc + cur.amount,
                                0
                              ) /
                                pending.product.prix >
                              0.5
                                ? "#7ec5cf"
                                : "#7ec5cf",
                          }}
                        ></div>
                      </div>
                    </td>
                    <td>
                      <span>
                        {moment(pending.onHoldDate).format("DD/MM/YYYY HH:mm")}
                      </span>
                    </td>
                    <td>
                      <span>
                        <Countdown expirationDate={pending.expirationDate} />
                      </span>
                    </td>

                    <td>
                      <span> {pending.leadAssignTo.name}</span>
                    </td>
                    <td>
                      {campaigns.map((camp) => (
                        <span key={camp._id} value={camp._id}>
                          {camp.title}
                        </span>
                      ))}
                    </td>
                    <td>
                      <span
                        className="hover-effect"
                        onClick={() =>
                          handleOpenDeleteMessage(
                            pending.product._id,
                            pending.lead
                          )
                        }
                      >
                        cancel
                      </span>

                      <span
                        className="hover-effect"
                        onClick={() =>
                          handleOpenAcceptMessage(pending.product, pending.lead)
                        }
                      >
                        confirm
                      </span>
                    </td>
                    <td>
                      <span
                        className="hover-effect"
                        onClick={() => handleReferenceClick(pending.leadData)}
                      >
                        com({pending.leadComments?.length || 0}){" "}
                      </span>

                      {/* <span
                        className="hover-effect"
                        onClick={() =>
                          handleOpenDeleteMessage(pending._id, pending.leadId)
                        }
                      >
                        del
                      </span> */}
                    </td>
                  </tr>
                ))}
              {filteredPending.length === 0 && (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={Math.ceil(filteredPending.length / perPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />

          {showSelectAllPopup && (
            <SelectOptionsTable
              setOpen={setShowSelectAllPopup}
              selectCurrentPage={selectCurrentPage}
              selectAllPages={selectAllPages}
              allPageData={data}
              pageData={data}
              setIsAllChecked={setIsAllChecked}
            />
          )}
          {openCommentPopUp && (
            <CommentPopup
              data={selectedData}
              setOpen={setOpenCommentPopUp}
              reFetchFromTable={reFetch}
            />
          )}
          {openPopupCustomer && (
            <PopupCustomerContainer
              setOpen={setOpenPopupCustomer}
              data={selectedData}
              leads={allLeads}
              reFetchAllLead={reFetchLead}
              reFetchFromTable={reFetch}
            />
          )}
          {openFollowPayment && (
            <FollowPayment
              setOpen={setOpenFollowPayment}
              currentData={currentData}
              selectedData={selectedData}
              reFetchAllLead={reFetchLead}
              type={"onHold"}
              url={"OnHold"}
            />
          )}
          {openDeleteMessage && (
            <DeleteMessage
              setOpen={setOpenDeleteMessage}
              selectedData={selectedData}
              reFetchAllLead={reFetchLead}
              currentData={currentData}
            />
          )}
          {openAcceptMessage && (
            <AcceptMessage
              setOpen={setOpenAcceptMessage}
              selectedData={selectedData}
              reFetchAllLead={reFetchLead}
              currentData={currentData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PendingTable;
