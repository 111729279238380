import "./EventPopup.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import useFetch from "../../../../hooks/useFetch";
import { useState } from "react";
import moment from "moment";
import PersonIcon from "@mui/icons-material/Person";
import PopupCustomerContainer from "../../PopupCustomer/PopupClientContainer/PopupCustomerContainer";
import axios from "axios";
const EventPopup = ({ selectedEvent, onEventDeleted, setOpen }) => {
  const { data: leads, reFetch: reFetchLead } = useFetch("lead");
  const [openPopupCustomer, setOpenPopupCustomer] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const findCorrespondingLead = leads.find(
    (l) => l._id === selectedEvent.leadId
  );

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleOpenPopupCustomer = (data) => {
    setSelectedData(data);
    setOpenPopupCustomer(true);
  };

  const handleDeleteAppointment = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/delete-apt/${selectedEvent.leadId}/${selectedEvent.commentId}`
      );
      onEventDeleted(selectedEvent.commentId);
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createApt" onClick={handleClickInside}>
          <div className="closeIconApt">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createAptWrapper">
            <div className="topCreateApt">
              <span>Appointment</span>
            </div>
            <div className="middleCreateApt">
              <span>{selectedEvent.title}</span>
              <span>
                Date: {moment(selectedEvent.start).format("DD/MM/YYYY HH:mm")}
              </span>
            </div>
            <div className="bottomCreateApt">
              <button
                className="cancelButton"
                onClick={handleDeleteAppointment}
              >
                Delete
              </button>
              <button
                className="saveButton"
                onClick={() => handleOpenPopupCustomer(findCorrespondingLead)}
              >
                <PersonIcon />
                Open Profile
              </button>
            </div>
          </div>
        </div>
      </div>
      {openPopupCustomer && (
        <PopupCustomerContainer
          setOpen={setOpenPopupCustomer}
          data={selectedData}
          reFetchFromTable={reFetchLead}
        />
      )}
    </>
  );
};

export default EventPopup;
