import "./Sales.css";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import CreateAccount from "../CreateAccount/CreateAccount";
import EditAccount from "../EditAccount/EditAccount";

const Sales = ({ data, onDeleteClick, reFetchFromTable }) => {
  const [openCreateSales, setOpenCreateSales] = useState(false);
  const [openEditSales, setOpenEditSales] = useState(false);
  const [selectedSalesId, setSelectedSalesId] = useState(null);

  const handleOpenEditPopup = (userId) => {
    setSelectedSalesId(userId);
    setOpenEditSales(true);
  };
  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>Sales : {data.length}</span>
            </div>

            <div className="rightElements">
              <button onClick={() => setOpenCreateSales(true)}>
                <AddIcon />
                <span>Create</span>
              </button>
            </div>
          </div>
          <table className="tableData">
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="headerWithIcon">Full name</div>
                </th>
                <th></th>
                <th>
                  <div className="headerWithIcon">Username</div>
                </th>
                <th>
                  <div className="headerWithIcon">Email</div>
                </th>

                <th>
                  <div className="headerWithIcon">Extention</div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr>
                  <td>
                    <div className="nameAndStatusAndImg">
                      <img
                        src={item.pPicture || `/images/defaultImg.png`}
                        alt={item.pPicture}
                      />
                      <span>{item.name}</span>
                    </div>
                  </td>
                  <td>
                    <div className="rondConnectStatus"></div>
                  </td>
                  <td>
                    <span>{item.username}</span>
                  </td>
                  <td>
                    <span>{item.email}</span>
                  </td>

                  <td>
                    <span>{item.phone}</span>
                  </td>

                  <td>
                    <span
                      className="hover-effect"
                      onClick={() => handleOpenEditPopup(item._id)}
                    >
                      edit
                    </span>
                    <span
                      className="hover-effect"
                      onClick={() => onDeleteClick(item._id)}
                    >
                      del
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {openCreateSales && (
            <CreateAccount
              setOpen={setOpenCreateSales}
              role={"isSales"}
              reFetchFromTable={reFetchFromTable}
            />
          )}
          {openEditSales && (
            <EditAccount
              setOpen={setOpenEditSales}
              reFetchFromTable={reFetchFromTable}
              id={selectedSalesId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Sales;
