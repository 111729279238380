import "./SidebarRight.css";
import SettingsIcon from "@mui/icons-material/Settings";
import { useEffect, useState } from "react";
import MySafe from "./MySafe/MySafe";
import OnHold from "./OnHold/OnHold";
import Sold from "./Sold/Sold";
import Offers from "./Offers/Offers";
import { useOpenTab } from "../../../context/OpenTabContext";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

const SidebarRight = ({
  selectedTemplate,
  handleOpenProductInfo,
  offers,
  onHold,
  sold,
  onSafe,
  company,
  connectedLead,
}) => {
  const [activeTab, setActiveTab] = useState("Safe");
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [openOffer, setOpenOffer] = useState(false);
  const { openTab } = useOpenTab();
  const [selectedProductOffer, setSelectedProductOffer] = useState(null);

  const handleOpenOffer = (selectedProductOffer) => {
    setSelectedProductOffer(selectedProductOffer);
    setOpenOffer(true);
  };

  useEffect(() => {
    if (openOffer && selectedProductOffer) {
      const offersToDisplay = offers.filter(
        (o) => o.product._id === selectedProductOffer
      );
      setFilteredOffers(offersToDisplay);
    }
  }, [offers, openOffer, selectedProductOffer]);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    if (openTab) {
      setActiveTab(openTab);
    }
  }, [openTab]);

  return (
    <div className="sidebar-right">
      <div className="title-section">
        <span
          className={`titlesongon ${activeTab === "Safe" ? "active" : ""}`}
          onClick={() => handleTabClick("Safe")}
        >
          {company[0]?.nameOne ?? "Name 1 Settings "}
          {onSafe.length < 1 ? (
            ""
          ) : (
            <span className="chipnot">{onSafe.length}</span>
          )}
        </span>
        <span
          className={`titlesongon ${activeTab === "Hold" ? "active" : ""}`}
          onClick={() => handleTabClick("Hold")}
        >
          En attente
          {onHold.length < 1 ? (
            ""
          ) : (
            <span className="chipnot">{onHold.length}</span>
          )}
        </span>
        <span
          className={`titlesongon ${activeTab === "Sold" ? "active" : ""}`}
          onClick={() => handleTabClick("Sold")}
        >
          Vendu
          {sold.length < 1 ? (
            ""
          ) : (
            <span className="chipnot">{sold.length}</span>
          )}
        </span>
      </div>
      {activeTab === "Safe" && (
        <>
          <MySafe
            onSafe={onSafe}
            selectedTemplate={selectedTemplate}
            handleOpenProductInfo={handleOpenProductInfo}
            handleOpenOffer={handleOpenOffer}
            offers={offers}
            connectedLead={connectedLead}
          />
        </>
      )}
      {activeTab === "Hold" && (
        <>
          <OnHold
            onHold={onHold}
            selectedTemplate={selectedTemplate}
            handleOpenProductInfo={handleOpenProductInfo}
          />
        </>
      )}
      {activeTab === "Sold" && (
        <>
          <Sold sold={sold} selectedTemplate={selectedTemplate} />
        </>
      )}

      <div className="title-section">
        <h1 className="titles">
          Offres
          {filteredOffers.length < 1 ? (
            ""
          ) : (
            <span className="chipnot">{filteredOffers.length}</span>
          )}
        </h1>
        <SettingsIcon className="filter" />
      </div>
      {filteredOffers.length === 0 && !openOffer ? (
        <div className="messageNoNothingSidebarRight">
          <LocalOfferIcon />
          <span>Aucune offre disponible</span>
        </div>
      ) : null}

      {openOffer && (
        <Offers offers={filteredOffers} selectedTemplate={selectedTemplate} />
      )}
    </div>
  );
};

export default SidebarRight;
