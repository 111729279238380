import "./PlatformConfig.css";

const PlatformConfig = ({ handleChange, company }) => {
  return (
    <table className="tablePlatformConfiguration">
      <thead>
        <tr>
          <td colspan={2}>PLATFORM CONFIGURATION :</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span>Right Sidebar Name 1 </span>
          </td>
          <td>
            <input
              type="text"
              className="companyName"
              name="nameOne"
              onChange={handleChange}
              value={company.nameOne}
            />
          </td>
        </tr>
        {/* <tr>
          <td>
            <span>Widget 2 name </span>
          </td>
          <td>
            <input
              type="text"
              className="companyName"
              name="widgetTwo"
              onChange={handleChange}
              value={company.widgetTwo}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>Widget 3 name </span>
          </td>
          <td>
            <input
              type="text"
              className="companyName"
              name="widgetThree"
              onChange={handleChange}
              value={company.widgetThree}
            />
          </td>
        </tr> */}
      </tbody>
    </table>
  );
};

export default PlatformConfig;
