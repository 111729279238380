import "./Invoice.css";
import useFetch from "../../../../hooks/useFetch";
import { useEffect, useContext, useState } from "react";
import HexagonIcon from "@mui/icons-material/Hexagon";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import axios from "axios";
import io from "socket.io-client";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
const HistoriquePayment = ({ setOpen }) => {
  const { data: products } = useFetch("product");

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}></div>
      <div className="historiquePayment">
        <div className="closeIconBuyButton">
          <CloseIcon
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
        <div className="topBuyButton">
          <span>Invoice #45458AFEW54</span>
        </div>
        <div className="payAmountHistorique">
          <span>
            {/* {findCorrespondingProduct?.paidSold}€ / {findCorrespondingProduct?.prixVente}€  */}
          </span>
        </div>
        <div className="progressBarContainer">
          <div className="progress_bar_tableHistorique">
            {/* <div
              className="progress_tableHistorique"
              style={{
                width: `${
                  (findCorrespondingProduct?.paidSold /
                    findCorrespondingProduct?.prixVente) *
                    100 || 0
                }%`,
              }}
            ></div> */}
          </div>
        </div>
        <div className="datatableHistorique">
          <div className="searchBarDataTableHistorique"></div>
          <table className="tableDataHistorique">
            <thead className="dtThead">
              <tr>
                <th>Amount</th>
                {/* <th>Mode de Paiement</th> */}
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {/* {findCorrespondingProduct?.paiement.map((payment, index) => (
                <tr key={index}>
                  <td>{payment.montant} €</td>
                  <td>{payment.modePaiement}</td>
                  <td>
                    {payment.date
                      ? moment(payment.date).format("DD/MM/YYYY")
                      : ""}
                  </td>
                </tr>
              ))} */}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default HistoriquePayment;
