import "./Planning.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
// import fr from "@fullcalendar/core/locales/fr";
import { useContext, useEffect, useRef, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import { AuthContext } from "../../../../context/auth.context";
import axios from "axios";
import EventPopup from "../EventPopup/EventPopup";
import AddEvent from "../AddEvent/AddEvent";
import BounceLoader from "react-spinners/BounceLoader";
const Planning = ({ userLeads, isLoading }) => {
  const { data: leads, reFetch } = useFetch("lead");
  const { user } = useContext(AuthContext);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showAddEvent, setShowAddEvent] = useState(false);

  useEffect(() => {
    if (userLeads) {
      const newEvents = extractEventsFromLeads(userLeads);
      setEvents(newEvents);
    }
  }, [userLeads]);

  const extractEventsFromLeads = (leads) => {
    return leads.flatMap((lead) =>
      lead.comment
        .filter((comment) => comment.appointment && comment.appointment.date)
        .map((comment) => {
          const title = user.isSuperAdmin
            ? ` ${comment.author.name} - ${lead.name} LD${lead.reference.slice(
                0,
                5
              )}`
            : `RDV : ${lead.name} LD${lead._id.slice(0, 5)}`;

          return {
            title: title,
            start: comment.appointment.date,
            commentId: comment._id,
            leadId: lead._id,
            authorName: user.isSuperAdmin ? comment.author.name : null,
          };
        })
    );
  };

  const handleDateClick = (arg) => {};
  const calendarRef = useRef(null);

  const changeView = (view) => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(view);
  };

  const handleEventClick = ({ event }) => {
    setSelectedEvent({
      title: event.title,
      start: event.start,
      commentId: event.extendedProps.commentId,
      leadId: event.extendedProps.leadId,
    });
  };

  const handleEventDrop = async (info) => {
    try {
      const eventId = info.event.extendedProps.commentId;
      const newStart = info.event.start;

      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/event/${eventId}`,
        { newStart }
      );
      reFetch();
    } catch (err) {
      console.error(err);
    }
  };

  const handleEventResize = async (info) => {};

  const handleAddEvent = (newEvent) => {
    setEvents((prevEvents) => [...prevEvents, newEvent]);
  };

  const handleDeleteEvent = (commentId) => {
    setEvents((prevEvents) =>
      prevEvents.filter((event) => event.commentId !== commentId)
    );
  };

  return (
    <div className="calendarContainer">
      {isLoading && (
        <div className="loaderOverlay">
          <BounceLoader color="var(--primary-color)" />
        </div>
      )}
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        dateClick={handleDateClick}
        events={events}
        eventClick={handleEventClick}
        slotLabelFormat={{
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }}
        eventTimeFormat={{
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }}
        slotMinTime="06:00:00"
        slotMaxTime="23:00:00"
        allDaySlot={false}
        // locale={fr}
        headerToolbar={{
          start: "title",
          center: "",
          end:
            user && user.isSuperAdmin
              ? "prev today custom1 custom2 custom3 next"
              : "prev add today custom1 custom2 custom3 next",
        }}
        customButtons={{
          add: {
            text: "+ Add",
            click: () => setShowAddEvent(true),
          },
          custom1: {
            text: "Day",
            click: () => changeView("timeGridDay"),
          },
          custom2: {
            text: "Week",
            click: () => changeView("timeGridWeek"),
          },
          custom3: {
            text: "Month",
            click: () => changeView("dayGridMonth"),
          },
        }}
        editable={true}
        droppable={true}
        eventResizableFromStart={true}
        eventDrop={handleEventDrop}
        eventResize={handleEventResize}
      />
      {selectedEvent && (
        <EventPopup
          selectedEvent={selectedEvent}
          setOpen={setSelectedEvent}
          onEventDeleted={handleDeleteEvent}
        />
      )}
      {showAddEvent && (
        <AddEvent setOpen={setShowAddEvent} handleAddEvent={handleAddEvent} />
      )}
    </div>
  );
};

export default Planning;
