import { handleCheckboxChange } from "../../../../utils/datatable.utils";
import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import SelectOptionsTable from "../../../SelectOptionsTable/SelectOptionsTable";
import AddIcon from "@mui/icons-material/Add";
import useFetch from "../../../../hooks/useFetch";
import CommentPopup from "../../../CRM/Customers/CommentPopup/CommentPopup";
import moment from "moment";
import axios from "axios";
import AddRefund from "../AddRefund/AddRefund";
import DeleteRefund from "../DeleteRefund/DeleteRefund";
import PopupCustomerContainer from "../../../CRM/PopupCustomer/PopupClientContainer/PopupCustomerContainer";
import { useSocket } from "../../../../context/SocketContext";

const RefundsTable = ({ data, reFetch, allLeads, reFetchLead, campaigns }) => {
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [openCommentPopUp, setOpenCommentPopUp] = useState(null);
  const [openAddDeposit, setOpenAddDeposit] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [selectedCampaignId, setSelectedCampaignId] = useState("All Campaign");
  const [openPopupCustomer, setOpenPopupCustomer] = useState(false);
  const { emitRefreshData } = useSocket();

  const tableRef = useRef(null);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleCheckboxChangeWrapper = (event, itemId) => {
    handleCheckboxChange(
      event,
      itemId,
      data,
      checkedItems,
      setIsAllChecked,
      setCheckedItems
    );
    setHighlightedRows((prevRows) => {
      if (event.target.checked) {
        return [...prevRows, itemId];
      } else {
        return prevRows.filter((rowId) => rowId !== itemId);
      }
    });
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setHighlightedRows([]);
    }
  }, [checkedItems]);

  const handleHeaderCheckboxClick = (e) => {
    e.preventDefault();
    if (checkedItems.length > 0 || isAllChecked) {
      setCheckedItems([]);
      setIsAllChecked(false);
    } else {
      setShowSelectAllPopup(true);
    }
  };
  const selectCurrentPage = () => {
    const start = currentPage * perPage;
    const end = start + perPage;
    const currentPageItems = data.slice(start, end).map((item) => item._id);
    setCheckedItems(currentPageItems);
  };

  const selectAllPages = () => {
    const allItems = data.map((item) => item._id);
    setCheckedItems(allItems);
  };

  const handleOpenDeleteMessage = (refundId, leadId) => {
    setOpenDeleteMessage(true);
    setSelectedDeletedId({ refundId, leadId });
  };

  const handleReferenceClick = (data) => {
    setSelectedData(data);
    setOpenCommentPopUp(true);
  };

  const handleCampaignChangeFilter = (e) => {
    setSelectedCampaignId(e.target.value);
  };

  const filteredRefund = data.flatMap((lead) => {
    return lead.refund
      .filter((refund) => {
        const matchesCampaign =
          selectedCampaignId === "All Campaign" ||
          (lead.campaign && lead.campaign._id === selectedCampaignId);

        return matchesCampaign;
      })
      .map((refund) => ({
        ...refund,
        leadReference: lead.reference,
        leadName: lead.name,
        campaign: lead.campaign ? lead.campaign : null,
        leadId: lead._id,
        lead: lead,
      }));
  });

  const totalRefund = filteredRefund.reduce((acc, refund) => {
    return acc + refund.amount;
  }, 0);

  const handleOpenPopupCustomer = (data) => {
    setSelectedData(data);
    setOpenPopupCustomer(true);
  };

  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>
                Withdrawals : {filteredRefund.length} | Total Amount :{" "}
                {totalRefund}€
              </span>

              <select onChange={handleCampaignChangeFilter}>
                <option value="All Campaign">All Campaign</option>
                {campaigns.map((campaign) => (
                  <option key={campaign._id} value={campaign._id}>
                    {campaign.title}
                  </option>
                ))}
              </select>
            </div>

            <div className="rightElements">
              <button onClick={() => setOpenAddDeposit(true)}>
                <AddIcon />
                <span>Add</span>
              </button>
            </div>
          </div>
          <table className="tableData" ref={tableRef}>
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="placeCheckbox">
                    <label
                      className="container"
                      onClick={handleHeaderCheckboxClick}
                    >
                      <input type="checkbox" checked={isAllChecked} readOnly />

                      <div className="checkmark"></div>
                    </label>
                  </div>
                </th>
                <th>
                  <div className="headerWithIcon">Customer</div>
                </th>
                <th>
                  <div className="headerWithIcon">Amount</div>
                </th>

                <th>
                  <div className="headerWithIcon">Refund Date</div>
                </th>
                <th>
                  <div className="headerWithIcon">Campaign</div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredRefund
                .sort((a, b) => new Date(b.refundDate) - new Date(a.refundDate))
                .slice(currentPage * perPage, (currentPage + 1) * perPage)
                .map((refund, index) => (
                  <tr key={index}>
                    <td>
                      <label className="container">
                        <input
                          type="checkbox"
                          checked={checkedItems.includes(refund.leadId)}
                          onChange={(e) =>
                            handleCheckboxChangeWrapper(e, refund.leadId)
                          }
                        />
                        <div className="checkmark"></div>
                      </label>
                    </td>

                    <td onClick={() => handleOpenPopupCustomer(refund.lead)}>
                      <div className="nameAndStatus">
                        <span style={{ color: "var(--primary-color)" }}>
                          {refund.leadReference} - {refund.leadName}
                        </span>
                      </div>
                    </td>

                    <td>
                      <span>{refund.amount}€</span>
                    </td>

                    <td>
                      <span>
                        {moment(refund.refundDate).format("DD/MM/YYYY")}
                      </span>
                    </td>

                    <td>
                      <span
                        className="hover-effect"
                        onClick={() => handleReferenceClick(refund.lead)}
                      >
                        com({refund.comments?.length || 0}){" "}
                      </span>

                      <span
                        className="hover-effect"
                        onClick={() =>
                          handleOpenDeleteMessage(refund._id, refund.leadId)
                        }
                      >
                        del
                      </span>
                    </td>
                  </tr>
                ))}
              {filteredRefund.length === 0 && (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={Math.ceil(filteredRefund.length / perPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
          {openAddDeposit && (
            <AddRefund setOpen={setOpenAddDeposit} reFetch={reFetch} />
          )}
          {openDeleteMessage && (
            <DeleteRefund
              setOpen={setOpenDeleteMessage}
              reFetchFromTable={reFetch}
              id={selectedDeletedId}
            />
          )}
          {showSelectAllPopup && (
            <SelectOptionsTable
              setOpen={setShowSelectAllPopup}
              selectCurrentPage={selectCurrentPage}
              selectAllPages={selectAllPages}
              allPageData={data}
              pageData={filteredRefund}
              setIsAllChecked={setIsAllChecked}
            />
          )}
          {openCommentPopUp && (
            <CommentPopup
              data={selectedData}
              setOpen={setOpenCommentPopUp}
              reFetchFromTable={reFetch}
            />
          )}
          {openPopupCustomer && (
            <PopupCustomerContainer
              setOpen={setOpenPopupCustomer}
              data={selectedData}
              leads={allLeads}
              reFetchAllLead={reFetchLead}
              reFetchFromTable={reFetch}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RefundsTable;
