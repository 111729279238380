import axios from "axios";
import Deposit from "../../../components/Banking/Deposit/DepositTable/DepositTable";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { BankingTitles } from "../../../constants/navigationTitles";
import "./RebougthPage.css";
import { useCallback, useEffect, useState } from "react";
import NavigationTabsAndFilterDate from "../../../components/Navigation/NavigationTabsAndFilterDate/NavigationTabsAndFilterDate";
import SalesTable from "../../../components/Banking/Sales/SalesTable/SalesTable";
import RebougthTable from "../../../components/Banking/Rebought/RebougthTable/RebougthTable";
import { useData } from "../../../context/DataContext";

const RebougthPage = () => {
  const { allLeads, reFetchAllLead, campaigns, users, reFetchUsers } =
    useData();
  const [sold, setSold] = useState([]);
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate,
    endDate,
  });
  const fetchAllSold = async () => {
    const queryParams = new URLSearchParams({
      startDate: selectedDateRange.startDate
        ? selectedDateRange.startDate.toISOString()
        : null,
      endDate: selectedDateRange.endDate
        ? selectedDateRange.endDate.toISOString()
        : null,
    }).toString();
    try {
      const storedToken = localStorage.getItem("authToken");

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/banking/rebougth?${queryParams}`,
        { headers: { Authorization: `Bearer ${storedToken}` } }
      );
      setSold(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchAllSold();
  }, [selectedDateRange]);

  const handleFilteredDataChange = useCallback(({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  }, []);

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabsAndFilterDate
            onFilteredDataChange={handleFilteredDataChange}
            tabs={BankingTitles}
          />
        </div>
        <div className="bottomCrmPage">
          <RebougthTable
            data={sold}
            reFetch={fetchAllSold}
            allLeads={allLeads}
            campaigns={campaigns}
            reFetchLead={reFetchAllLead}
            users={users}
            reFetchUsers={reFetchUsers}
          />
        </div>
      </div>
    </div>
  );
};

export default RebougthPage;
