import "./Dashboard.css";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ModeIcon from "@mui/icons-material/Mode";
import Comment from "../Comment/Comment";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import BalanceIcon from "@mui/icons-material/Balance";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { useEffect, useState } from "react";
import { useSocket } from "../../../../../context/SocketContext";
const Dashboard = ({ currentData, reFetchFromTable }) => {
  const { socket } = useSocket();
  const [balance, setBalance] = useState(currentData.balance);
  const [allSalesAmount, setAllSalesAmount] = useState(0);
  const [refund, setRefund] = useState(0);
  const [payments, setPayments] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [turnover, setTurnover] = useState(0);

  useEffect(() => {
    if (
      currentData &&
      currentData.payment &&
      currentData.balance !== undefined
    ) {
      const salesArray = Array.isArray(currentData.sold)
        ? currentData.sold
        : [];
      const refundArray = Array.isArray(currentData.refund)
        ? currentData.refund
        : [];
      const depositArray = Array.isArray(currentData.deposit)
        ? currentData.deposit
        : [];

      const salesAmount = salesArray.reduce(
        (acc, inc) => acc + (inc.salesPrice ?? 0),
        0
      );
      const totalRefunded = refundArray.reduce(
        (acc, curr) => acc + (curr.amount ?? 0),
        0
      );
      const amountPaid = currentData.payment?.amountPaid ?? 0;
      const totalTurnover = depositArray.reduce(
        (acc, curr) => acc + (curr.amount ?? 0),
        0
      );

      const totalPercentage =
        totalTurnover > 0 ? (totalRefunded / totalTurnover) * 100 : 0;

      setTurnover(totalTurnover);
      setPercentage(totalPercentage);
      setPayments(amountPaid);
      setBalance(currentData.balance ?? 0);
      setAllSalesAmount(salesAmount);
      setRefund(totalRefunded);
    } else {
      // Valeurs par défaut si currentData n'est pas prêt
      setBalance(0);
      setAllSalesAmount(0);
      setRefund(0);
      setPayments(0);
      setPercentage(0);
      setTurnover(0);
    }
  }, [currentData]);

  const styleBuyingBack = {
    pathColor: `#81cfbd`,
    textColor: "#7ec5cf",
    trailColor: "#d6d6d6",
    backgroundColor: "#81cfbd",
  };

  const depositArray = Array.isArray(currentData?.deposit)
    ? currentData.deposit
    : [];
  const refundArray = Array.isArray(currentData?.refund)
    ? currentData.refund
    : [];
  const soldArray = Array.isArray(currentData?.sold) ? currentData.sold : [];

  const timelineData = currentData
    ? [
        ...depositArray.map((d) => ({
          ...d,
          action: "Deposit",
          actualDate: new Date(d.depositDate),
          displayAmount: d.amount ?? 0,
        })),
        ...refundArray.map((r) => ({
          ...r,
          action: "Refund",
          actualDate: new Date(r.refundDate),
          displayAmount: r.amount ?? 0,
        })),
        ...soldArray.map((s) => ({
          ...s,
          action: "Sold",
          actualDate: new Date(s.soldDate),
          displayAmount: s.salesPrice ?? 0,
        })),
      ]
    : [];

  timelineData.sort((a, b) => b.actualDate - a.actualDate);

  return (
    <>
      <div className="popupRigthSideBottomLeft">
        <div className="popupRigthSideBottomLeftTop">
          <div className="statsWrapper">
            <div className="statsWrapperPerTwo">
              <div className="userPerfomancePageItem">
                <div className="userPerfomancePageItemTop">
                  <BalanceIcon />
                </div>
                <div className="userPerfomancePageItemBottom">
                  <span>Balance</span>
                  <span>{(balance ?? 0).toLocaleString("en-US")} €</span>
                </div>
              </div>
              <div className="userPerfomancePageItem">
                <div className="userPerfomancePageItemTop">
                  <LocalOfferIcon />
                </div>
                <div className="userPerfomancePageItemBottom">
                  <span>{currentData?.sold?.length ?? 0} Sales</span>
                  <span>{(allSalesAmount ?? 0).toLocaleString("en-US")} €</span>
                </div>
              </div>
            </div>
            <div className="statsWrapperPerTwo">
              <div className="userPerfomancePageItem">
                <div className="userPerfomancePageItemTop">
                  <CreditCardIcon />
                </div>
                <div className="userPerfomancePageItemBottom">
                  <span>
                    {currentData?.payment?.numberOfPayment ?? 0} Payments
                  </span>
                  <span>{(payments ?? 0).toLocaleString("en-US")} €</span>
                </div>
              </div>
              <div className="userPerfomancePageItem">
                <div className="userPerfomancePageItemTop">
                  <CurrencyExchangeIcon />
                </div>
                <div className="userPerfomancePageItemBottom">
                  <span>{currentData?.refund?.length ?? 0} Withdrawals</span>
                  <span>{(refund ?? 0).toLocaleString("en-US")} €</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popupRigthSideBottomLeftBottom">
          <div className="popupRigthSideBottomLeftBottomRight">
            <div className="popupRigthSideBottomLeftBottomRightTop">
              <div className="iconWithTitle">
                <AutorenewIcon />
                <span>TURNOVER</span>
              </div>
              <div className="turnoverWrapper">
                <div className="turnoverWrapperItem">
                  <div className="turnoverWrapperItemLeft">
                    <div className="turnoverWrapperItemLeftItem">
                      <span>
                        <span>
                          {(turnover ?? 0).toLocaleString("en-US")} €{" "}
                        </span>
                        cashed
                      </span>
                    </div>
                  </div>
                  <div className="turnoverWrapperItemRight">
                    <span>{(turnover ?? 0).toLocaleString("en-US")} €</span>
                    <span>Income</span>
                  </div>
                </div>
                <div className="progressionBar"></div>
              </div>
            </div>
            <div className="popupRigthSideBottomLeftBottomRightBottom">
              <div className="iconWithTitle">
                <CurrencyExchangeIcon />
                <span>BUYING BACK</span>
              </div>
              <div className="buyingBackWrapper">
                <div className="buyingBackWrapperLeft">
                  <CircularProgressbar
                    value={percentage ?? 0}
                    text={`${(percentage ?? 0).toFixed(2)}%`}
                    styles={buildStyles(styleBuyingBack)}
                  />
                </div>
                <div className="turnoverWrapperItemRightBuyingBack">
                  <span>{(refund ?? 0).toLocaleString("en-US")}€</span>
                  <span>Buying Back</span>
                </div>
              </div>
            </div>
          </div>
          <div className="popupRigthSideBottomLeftBottomLeft">
            <div className="iconWithTitle">
              <AccessTimeFilledIcon />
              <span>TIMELINE</span>
            </div>
            <div className="timelineWrapper">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Action</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {timelineData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.actualDate?.toLocaleDateString("fr-FR")}</td>
                      <td>{item.action}</td>
                      <td>{`${(item.displayAmount ?? 0).toLocaleString(
                        "en-US"
                      )} €`}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="popupRigthSideBottomRight">
        <div className="iconWithTitle">
          <ModeIcon />
          <span>COMMENTS</span>
        </div>
        <Comment
          currentData={currentData}
          reFetchFromTable={reFetchFromTable}
        />
      </div>
    </>
  );
};

export default Dashboard;
