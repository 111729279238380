import FactureGenerator from "../../../SidebarRight/FactureGenerator/FactureGenerator";
import "./Invoices.css";
import DownloadIcon from "@mui/icons-material/Download";
const Invoices = ({ sold, onSafe, selectedTemplate, connectedLead }) => {
  const timelineData = onSafe
    ? [
        ...(Array.isArray(sold)
          ? sold.map((s) => ({
              ...s,
              actualDate: new Date(s.soldDate),
              productPrice: s.product.prix,
            }))
          : []),
        ...(Array.isArray(onSafe)
          ? onSafe.map((os) => ({
              ...os,
              actualDate: new Date(os.onSafeDate),
              productPrice: os.product.prix,
            }))
          : []),
      ]
    : [];

  timelineData.sort((a, b) => b.actualDate - a.actualDate);
  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <table className="tableDataProfileInfo">
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="headerWithIcon">Infos </div>
                </th>
                <th>
                  <div className="headerWithIcon">Total</div>
                </th>
                <th>
                  <div className="headerWithIcon">Paid amount</div>
                </th>
                <th>
                  <div className="headerWithIcon">Invoice</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {timelineData.map((item, index) => (
                <tr key={index}>
                  <td>
                    <span style={{ color: "#93a1ca" }}>
                      {item.product.reference}
                    </span>
                  </td>
                  <td>{`${(item.productPrice || 0).toLocaleString(
                    "en-US"
                  )} €`}</td>
                  <td>
                    <div className="catalog-payments-progressPlatform" title="">
                      <div
                        className="catalog-payments-progress-filledPlatform"
                        style={{
                          width: `${
                            (item.amountPay.reduce(
                              (acc, cur) => acc + cur.amount,
                              0
                            ) /
                              item.productPrice) *
                            100
                          }%`,
                          backgroundColor:
                            item.amountPay.reduce(
                              (acc, cur) => acc + cur.amount,
                              0
                            ) /
                              item.productPrice >
                            0.5
                              ? "#93a1ca"
                              : "#93a1ca",
                        }}
                      ></div>
                      <span className="hover-info">
                        {`${item.amountPay
                          ?.reduce((acc, cur) => acc + cur.amount, 0)
                          .toLocaleString(
                            "en-US"
                          )} € / ${item.productPrice.toLocaleString(
                          "en-US"
                        )} € Left ${(
                          item.productPrice -
                          item.amountPay.reduce(
                            (acc, cur) => acc + cur.amount,
                            0
                          )
                        ).toLocaleString("en-US")} €`}
                      </span>
                    </div>
                  </td>
                  <td>
                    {/* <DownloadIcon
                      style={{
                        fontSize: "18px",
                        color: "#93a1ca",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                    /> */}
                    <FactureGenerator
                      product={item}
                      connectedLead={connectedLead}
                      selectedTemplate={selectedTemplate}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Invoices;
