import "./DeleteAllMessageCustomers.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import useFetch from "../../../../hooks/useFetch";
import { useState } from "react";

const DeleteAllMessageCustomers = ({
  setOpen,
  reFetchFromTable,
  checkedItems,
  setIsAllChecked,
  setShowLoader,
  setCheckedItems,
  allLeads,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleDelete = async () => {
    // Réinitialiser le message d'erreur
    setErrorMessage("");
    setOpen(false);
    setShowLoader(true);

    // Vérifier si l'un des leads sélectionnés a des dépôts ou des produits non disponibles
    const invalidLead = checkedItems.find((itemId) => {
      const lead = allLeads.find((lead) => lead._id === itemId);
      return (
        lead &&
        ((lead.deposit && lead.deposit.length > 0) ||
          (lead.onSafe && lead.onSafe.length > 0) ||
          (lead.onHold && lead.onHold.length > 0))
      );
    });

    // S'il existe des leads avec des dépôts ou des produits en hold/safe, interdire la suppression
    if (invalidLead) {
      alert(
        "Some leads have deposits or products in safe/hold and cannot be deleted."
      );
      setErrorMessage(
        "Cannot delete leads with deposits or products in safe/hold."
      );
      setShowLoader(false);
      setOpen(false);
      return;
    }

    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/multipleDelete`,
        {
          data: { leadIds: checkedItems },
        }
      );

      // Réinitialiser les états après une suppression réussie
      setIsAllChecked(false);
      setShowLoader(false);
      setCheckedItems([]);
      reFetchFromTable();
    } catch (err) {
      console.error("Error while deleting multiple leads:", err);
      setErrorMessage(
        "An error occurred while deleting the leads. Please try again."
      );
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createStatus">
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>Delete</span>
            </div>
            <div className="middleCreateStatus">
              <span>Are you sure you want to perform this action?</span>
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div className="bottomCreateStatus">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="saveButton" onClick={handleDelete}>
                <CheckIcon />
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteAllMessageCustomers;
