import { useCallback, useContext, useEffect, useState } from "react";
import Planning from "../../../components/CRM/Calendar/Planning/Planning";
import NavigationTabs from "../../../components/Navigation/NavigationTabs/NavigationTabs";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { CRMtitles } from "../../../constants/navigationTitles";
import "./PlanningPage.css";
import { AuthContext } from "../../../context/auth.context";
import AdminPlanning from "../../../components/CRM/Calendar/AdminPlanning/AdminPlanning";
import axios from "axios";
import NavigationTabsAndFilterDate from "../../../components/Navigation/NavigationTabsAndFilterDate/NavigationTabsAndFilterDate";
import {
  format,
  endOfMonth,
  endOfYear,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfYear,
  startOfWeek,
  endOfWeek,
} from "date-fns";

const PlanningPage = () => {
  const { user } = useContext(AuthContext);
  const [userLeads, setUserLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate,
    endDate,
  });

  const fetchAssignedLeads = async () => {
    setIsLoading(true);

    const queryParams = new URLSearchParams({
      startDate: selectedDateRange.startDate
        ? selectedDateRange.startDate.toISOString()
        : null,
      endDate: selectedDateRange.endDate
        ? selectedDateRange.endDate.toISOString()
        : null,
    }).toString();

    if (user && user._id) {
      try {
        const storedToken = localStorage.getItem("authToken");
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/lead/assigned/${user._id}?${queryParams}`,
          { headers: { Authorization: `Bearer ${storedToken}` } }
        );
        setUserLeads(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des leads assignés",
          error
        );
      }
    }
  };

  useEffect(() => {
    fetchAssignedLeads();
  }, [user, selectedDateRange]);

  const handleFilteredDataChange = useCallback(({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  }, []);

  const getSelectedDateRangeTitle = (startDate, endDate) => {
    const today = startOfDay(new Date());
    const thisMonthStart = startOfMonth(new Date());
    const thisMonthEnd = endOfMonth(new Date());
    const thisYearStart = startOfYear(new Date());
    const thisYearEnd = endOfYear(new Date());
    const thisWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
    const thisWeekEnd = endOfWeek(new Date(), { weekStartsOn: 1 });

    if (isSameDay(startDate, today) && isSameDay(endDate, today)) {
      return "Today";
    } else if (
      isSameDay(startDate, thisWeekStart) &&
      isSameDay(endDate, thisWeekEnd)
    ) {
      return "This Week";
    } else if (
      isSameDay(startDate, thisMonthStart) &&
      isSameDay(endDate, thisMonthEnd)
    ) {
      return "This Month";
    } else if (
      isSameDay(startDate, thisYearStart) &&
      isSameDay(endDate, thisYearEnd)
    ) {
      return "This Year";
    } else if (
      startDate.getFullYear() === 1970 &&
      endDate.getFullYear() === 2999
    ) {
      return "All";
    } else {
      // Formattez le range de date pour affichage
      return `${format(startDate, "dd/MM/yyyy")} - ${format(
        endDate,
        "dd/MM/yyyy"
      )}`;
    }
  };

  const title = getSelectedDateRangeTitle(
    selectedDateRange.startDate,
    selectedDateRange.endDate
  );

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          {user && user.isSuperAdmin ? (
            <NavigationTabsAndFilterDate
              tabs={CRMtitles}
              onFilteredDataChange={handleFilteredDataChange}
            />
          ) : (
            <NavigationTabs tabs={CRMtitles} />
          )}
        </div>
        <div className="bottomCrmPage">
          {user && user.isSuperAdmin ? (
            <AdminPlanning userLeads={userLeads} title={title} />
          ) : (
            <Planning userLeads={userLeads} isLoading={isLoading} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanningPage;
