import "./Dashboard.css";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { useEffect, useRef, useState } from "react";
import PublicIcon from "@mui/icons-material/Public";
import Welcome from "./Welcome/Welcome";
import CalendarDashboard from "./CalendarDashboard/CalendarDashboard";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlateformStats from "./PlateformStats/PlateformStats";
import CampaignDashboard from "./CampaignDashboard/CampaignDashboard";

const dummyData = [
  { id: "275", leadCount: 5, leadColor: "#FFD700" }, // Exemple pour la
  { id: "270", leadCount: 10, leadColor: "#C0C0C0" }, // Exemple pour la Gambia
  // Autres données...
];

const Dashboard = ({ allStats, chartData, campaigns }) => {
  const [tooltipContent, setTooltipContent] = useState("");
  if (!allStats) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboardContainer">
      <Welcome allStats={allStats} chartData={chartData} />
      <div className="dashboardContainerBottom">
        <div className="dashboardContainerBottomItemRight">
          <div className="dashboardContainerBottomItemTitle">
            <div className="dashboardContainerBottomItemTitleLeft">
              <PublicIcon />
              <span>Platform Statistics</span>
            </div>
            <div className="dashboardContainerBottomItemTitleRight">
              <select name="" id="">
                <option value="">This week</option>
              </select>
              <MoreVertIcon style={{ fontSize: "18px", color: "grey" }} />
            </div>
          </div>
          <PlateformStats allStats={allStats} />
        </div>
        <div className="dashboardContainerBottomItemRightWrapper">
          <div className="dashboardContainerBottomItemTitle">
            <div className="dashboardContainerBottomItemTitleLeft">
              <PublicIcon />
              <span>Calendar</span>
            </div>

            <div className="dashboardContainerBottomItemTitleRight">
              <div className="legendColorContainerWrapper">
                <div className="legendColorContainer">
                  <div className="legendColor1"></div>
                  <span> ≤ 5 appts </span>
                </div>
                <div className="legendColorContainer">
                  <div className="legendColor2"></div>
                  <span> 6 to 10 appts </span>
                </div>
                <div className="legendColorContainer">
                  <div className="legendColor3"></div>
                  <span> 11 to 20 appts </span>
                </div>
                <div className="legendColorContainer">
                  <div className="legendColor4"></div>
                  <span> &gt; 20 appts </span>
                </div>
              </div>
              <MoreVertIcon style={{ fontSize: "18px", color: "grey" }} />
            </div>
          </div>
          <CalendarDashboard />
        </div>
      </div>
      <div className="dashboardContainerLast">
        <div className="dashboardContainerBottomItemRight">
          <div className="dashboardContainerBottomItemTitle">
            <div className="dashboardContainerBottomItemTitleLeft">
              <PublicIcon />
              <span>Campaign</span>
            </div>
            <MoreVertIcon style={{ fontSize: "18px", color: "grey" }} />
          </div>
          <CampaignDashboard campaigns={campaigns} />
        </div>
        <div className="dashboardContainerBottomItemRight">
          <div className="dashboardContainerBottomItemTitle">
            <div className="dashboardContainerBottomItemTitleLeft">
              <PublicIcon />
              <span>Demographic Data</span>
            </div>
            <div className="dashboardContainerBottomItemTitleRight">
              <select name="" id="">
                <option value="">This week</option>
              </select>
              <MoreVertIcon style={{ fontSize: "18px", color: "grey" }} />
            </div>
          </div>

          <ComposableMap
            className="myCustomMapStyle"
            data-tip=""
            projectionConfig={{ scale: 250, center: [5, 10] }}
            style={{ width: "100%", height: "85%" }}
          >
            <Geographies geography="/geojson/world.json">
              {({ geographies }) =>
                geographies.map((geo) => {
                  // Utilisez l'id pour trouver une correspondance dans dummyData
                  const current = dummyData.find((s) => s.id === geo.id);

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={() => {
                        const { NAME } = geo.properties;
                        setTooltipContent(
                          `${NAME} — ${current ? current.leadCount : "0"} leads`
                        );
                      }}
                      onMouseLeave={() => {
                        setTooltipContent("");
                      }}
                      style={{
                        default: {
                          // Si une correspondance est trouvée, utilisez la couleur spécifiée, sinon gris
                          fill: current ? current.leadColor : "#D6D6DA",
                          outline: "none",
                        },
                        hover: {
                          fill: "#2B8CBE",
                          outline: "none",
                          stroke: "#FFF",
                          strokeWidth: 0.75,
                          transition: "all 250ms",
                        },
                        pressed: {
                          fill: "#E42",
                          outline: "none",
                        },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ComposableMap>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

{
  /* <ComposableMap
className="myCustomMapStyle"
data-tip=""
projectionConfig={{ scale: 250, center: [5, 10] }}
style={{ width: "100%", height: "85%" }}
>
<Geographies geography="/geojson/world.json">
  {({ geographies }) =>
    geographies.map((geo) => {
      // Utilisez l'id pour trouver une correspondance dans dummyData
      const current = dummyData.find((s) => s.id === geo.id);

      return (
        <Geography
          key={geo.rsmKey}
          geography={geo}
          onMouseEnter={() => {
            const { NAME } = geo.properties;
            setTooltipContent(
              `${NAME} — ${current ? current.leadCount : "0"} leads`
            );
          }}
          onMouseLeave={() => {
            setTooltipContent("");
          }}
          style={{
            default: {
              // Si une correspondance est trouvée, utilisez la couleur spécifiée, sinon gris
              fill: current ? current.leadColor : "#D6D6DA",
              outline: "none",
            },
            hover: {
              fill: "#2B8CBE",
              outline: "none",
              stroke: "#FFF",
              strokeWidth: 0.75,
              transition: "all 250ms",
            },
            pressed: {
              fill: "#E42",
              outline: "none",
            },
          }}
        />
      );
    })
  }
</Geographies>
</ComposableMap> */
}
