import "./LoginPagePlateforme.css";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContextLead } from "../../../context/leadContextAuth";
import axios from "axios";
import { useSocket } from "../../../context/SocketContext";
import { useData } from "../../../context/DataContext";

const API_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:5005";

const LoginPagePlateforme = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [logoDataURL, setLogoDataURL] = useState(null);
  const [backgroundLogo, setBackgroundLogo] = useState(null);
  const { company } = useData();

  const navigate = useNavigate();

  const { storeToken, authenticateLead, socket } = useContext(AuthContextLead);

  const convertImageToDataURL = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
      }
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting image to Data URL: ", error);
    }
  };

  useEffect(() => {
    const serverBaseUrl = process.env.REACT_APP_SERVER_URL;
    if (company && company.length > 0 && company[0].loginLogo) {
      const logoUrl = `${serverBaseUrl}/${company[0].loginLogo}`;
      convertImageToDataURL(logoUrl).then(setLogoDataURL);
    }
    if (company && company.length > 0 && company[0].loginBackground) {
      const backgroundUrl = `${serverBaseUrl}/${company[0].loginBackground}`;
      convertImageToDataURL(backgroundUrl).then(setBackgroundLogo);
    }
  }, [company]);

  const handleEmail = (e) => setEmail(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const requestBody = { email, password };

    axios
      .post(`${API_URL}/api/authLead/loginLead`, requestBody)
      .then((response) => {
        storeToken(response.data.authToken);
        socket.emit("leadConnected", { email });
        authenticateLead();
        navigate("/");
      })
      .catch((error) => {
        const errorDescription =
          error.response?.data?.message ||
          "An error occurred. Please try again.";
        setErrorMessage(errorDescription);
      });
  };

  return (
    <div
      className="login-page-Plateforne"
      style={{
        backgroundImage: backgroundLogo ? `url(${backgroundLogo})` : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "mo-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <form className="formLoginPagePlateforme" onSubmit={handleLoginSubmit}>
        <div className="topFormLoginPlateforme">
          {logoDataURL && <img src={logoDataURL} alt="Logo" />}
        </div>
        <div className="middleFormLoginPlateforme">
          <div className="middleFormLoginPlateformeInput">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleEmail}
              required
            />
          </div>
          <div className="middleFormLoginPlateformeInput">
            <label>Mot de passe</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={handlePassword}
              required
            />
          </div>
        </div>
        <div className="bottomFormLoginPlateforme">
          <button type="submit">Connexion</button>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
    </div>
  );
};

export default LoginPagePlateforme;
