import { useState } from "react";
import "./Offers.css";
import Countdown from "../../../../../Banking/Pending/PendingTable/Countdown/Countdown";
import DeleteMessage from "./DeleteMessage/DeleteMessage";

const Offers = ({ currentData, reFetchFromTable }) => {
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const handleOpenDeleteMessage = (data) => {
    setOpenDeleteMessage(true);
    setSelectedData(data);
  };


  return (
    <>
      <div className="containerDatatableHistory">
        <div className="datatableHistory">
          <table className="tableDataHistory">
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="headerWithIcon">Reference</div>
                </th>
                <th>
                  <div className="headerWithIcon">Price</div>
                </th>
                <th>
                  <div className="headerWithIcon">Offer Price</div>
                </th>
                <th>
                  <div className="headerWithIcon">Name</div>
                </th>
                <th>
                  <div className="headerWithIcon">Expiration</div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>

                {/* <th>
                  <div className="headerWithIcon">Add</div>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(currentData.offers) &&
              currentData.offers.length > 0 ? (
                currentData.offers.map((offer) => (
                  <tr key={offer.product._id}>
                    <td style={{ color: "var(--primary-color)" }}>
                      {offer.product.reference}
                    </td>
                    <td>
                      {(offer.product.prix ?? 0).toLocaleString("en-US")} €
                    </td>
                    <td>{(offer.amount ?? 0).toLocaleString("en-US")} €</td>
                    <td>{offer.companyName}</td>
                    <td>
                      <Countdown expirationDate={offer.expirationDate} />
                    </td>
                    <td>
                      <span
                        className="hover-effect"
                        onClick={() => handleOpenDeleteMessage(offer)}
                      >
                        cancel
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {openDeleteMessage && (
        <DeleteMessage
          setOpen={setOpenDeleteMessage}
          currentData={currentData}
          reFetchFromTable={reFetchFromTable}
          selectedData={selectedData}
        />
      )}
    </>
  );
};

export default Offers;
