// src/context/DataContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "./auth.context";

const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [status, setStatus] = useState([]);
  const [allLeads, setAllLeads] = useState([]);
  const [users, setUsers] = useState([]);
  const [pTemplates, setPTemplates] = useState([]);
  const [products, setProducts] = useState([]);
  const [company, setCompany] = useState([]);
  const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    if (isLoggedIn) {
      fetchCampaigns();
      fetchStatus();
      fetchLeads();
      fetchUsers();
      fetchTemplates();
      fetchProducts();
      fetchCompany();
    }
  }, [isLoggedIn]);

  const fetchCampaigns = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/campaign`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setCampaigns(response.data);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  const fetchStatus = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/status`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setStatus(response.data);
    } catch (error) {
      console.error("Error fetching status:", error);
    }
  };

  const fetchLeads = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/lead`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setAllLeads(response.data);
    } catch (error) {
      console.error("Error fetching Leads:", error);
    }
  };

  const fetchUsers = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/user`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching Users:", error);
    }
  };

  const fetchTemplates = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/templateProduct`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setPTemplates(response.data);
    } catch (error) {
      console.error("Error fetching Templates:", error);
    }
  };

  const fetchProducts = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/product`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching Products:", error);
    }
  };
  const fetchCompany = async () => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/company`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setCompany(response.data);
    } catch (error) {
      console.error("Error fetching company:", error);
    }
  };

  useEffect(() => {
    fetchCampaigns();
    fetchStatus();
    fetchLeads();
    fetchUsers();
    fetchTemplates();
    fetchProducts();
    fetchCompany();
  }, []);

  return (
    <DataContext.Provider
      value={{
        campaigns,
        status,
        allLeads,
        users,
        pTemplates,
        products,
        company,
        reFetchCampaigns: fetchCampaigns,
        reFetchStatus: fetchStatus,
        reFetchAllLead: fetchLeads,
        reFetchUsers: fetchUsers,
        reFetchPtemplates: fetchTemplates,
        reFetchProducts: fetchProducts,
        reFetchCompany: fetchCompany,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
