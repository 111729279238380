import "./DashboardCampaignPage.css";
import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import NavigationTabsAndFilterDate from "../../../components/Navigation/NavigationTabsAndFilterDate/NavigationTabsAndFilterDate";
import { CampaignTitles } from "../../../constants/navigationTitles";
import axios from "axios";
import WidgetCampaign from "../../../components/Campaign/NewDashboardCampaign/WidgetCampaign/WidgetCampaign";
import OverviewCampaign from "../../../components/Campaign/NewDashboardCampaign/OverviewCampaign/OverviewCampaign";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import GenerateForm from "../../../components/Campaign/NewDashboardCampaign/GenerateForm/GenerateForm";
import AuthLoader from "../../../components/Loaders/AuthLoader/AuthLoader";

const DashboardCampaignPage = () => {
  const [perCampaignStats, setPerCampaignStats] = useState([]);
  const [totalCampaignStats, setTotalCampaignStats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate,
    endDate,
  });

  const fetchCampaignStats = async () => {
    setIsLoading(true);
    const queryParams = new URLSearchParams({
      startDate: selectedDateRange.startDate
        ? selectedDateRange.startDate.toISOString()
        : null,
      endDate: selectedDateRange.endDate
        ? selectedDateRange.endDate.toISOString()
        : null,
    }).toString();
    try {
      const storedToken = localStorage.getItem("authToken");
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/campaign/statsByDateRange?${queryParams}`,
        { headers: { Authorization: `Bearer ${storedToken}` } }
      );
      setPerCampaignStats(response.data.campaignStats);
      setTotalCampaignStats(response.data.totals);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCampaignStats();
  }, [selectedDateRange]);

  const handleFilteredDataChange = useCallback(({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  }, []);

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabsAndFilterDate
            tabs={CampaignTitles}
            onFilteredDataChange={handleFilteredDataChange}
          />
        </div>
        {isLoading && (
          <div className="loaderOverlayPerfomancePage">
            <AuthLoader color="var(--primary-color)" />
          </div>
        )}
        <div className="bottomCrmPage">
          <div className="dashboardCampaignPageTop">
            <div className="dashboardCampaignPageTopWrapper">
              <WidgetCampaign
                campaignStats={totalCampaignStats}
                perCampaignStats={perCampaignStats}
              />
            </div>
          </div>

          <div className="dashboardCampaignPageBottom">
            <div className="dashboardCampaignPageBottomLeft">
              <div className="dashboardCampaignPageBottomLeftTop">
                <OverviewCampaign
                  campaignStats={perCampaignStats}
                  reFetch={fetchCampaignStats}
                />
              </div>
            </div>
            <div className="dashboardCampaignPageBottomRight">
              <div className="salesPerfomancePageBottomLeftTopItemActivity">
                <SignalCellularAltIcon />
                <span>GENERATE FORM</span>
              </div>
              <GenerateForm  campaignStats={perCampaignStats}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCampaignPage;
