import "./SipPage.css";
import NavigationTabs from "../../../components/Navigation/NavigationTabs/NavigationTabs";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { SettingsTitles } from "../../../constants/navigationTitles";
import Sip from "../../../components/Settings/Platform/Sip/Sip";

const SipPage = () => {
  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <div className="navigationAndFilter">
            <NavigationTabs tabs={SettingsTitles} />
          </div>
        </div>
        <div className="bottomCrmPage">
          <Sip />
        </div>
      </div>
    </div>
  );
};

export default SipPage;
