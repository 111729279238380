import "./PlateformStats.css";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const PlateformStats = ({ allStats }) => {
  if (!allStats) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboardContainerBottomItemRightContainer">
      <div className="dashboardContainerBottomItemRightItem">
        <div className="dashboardContainerBottomItemRightItemLeft">
          <AttachMoneyIcon />
        </div>
        <div className="dashboardContainerBottomItemRightItemRight">
          <span>SALES</span>
          <span>{allStats.totalSales || 0}</span>
        </div>
      </div>
      <div className="dashboardContainerBottomItemRightItem">
        <div className="dashboardContainerBottomItemRightItemLeft">
          <TrendingUpIcon />
        </div>
        <div className="dashboardContainerBottomItemRightItemRight">
          <span>PENDING SALES</span>
          <span>{allStats.totalPendingSales || 0}</span>
        </div>
      </div>
      <div className="dashboardContainerBottomItemRightItem">
        <div className="dashboardContainerBottomItemRightItemLeft">
          <AccountBalanceIcon />
        </div>
        <div className="dashboardContainerBottomItemRightItemRight">
          <span>CUSTOMER BALANCE</span>
          <span>{allStats.totalCustomerBalance || 0} €</span>
        </div>
      </div>
      <div className="dashboardContainerBottomItemRightItem">
        <div className="dashboardContainerBottomItemRightItemLeft">
          <CreditCardIcon />
        </div>
        <div className="dashboardContainerBottomItemRightItemRight">
          <span>BUYING BACK</span>
          <span>{allStats.totalRefunds || 0} €</span>
        </div>
      </div>
    </div>
  );
};

export default PlateformStats;
