import { useEffect, useState } from "react";
import "./Offers.css";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import AcceptOffer from "./AcceptOffer/AcceptOffer";
import DeclineOffer from "./DeclineOffer/DeclineOffer";
import axios from "axios";
import Countdown from "../OnHold/Countdown/Countdown";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
const Offers = ({ offers, selectedTemplate }) => {
  const [openAcceptButton, setOpenAcceptButton] = useState(false);
  const [openDeclineButton, setOpenDeclineButton] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [textOpen, setTextOpen] = useState(false);
  const [expiredOffers, setExpiredOffers] = useState(new Set());


  const handleExpire = (offerId) => {
    setExpiredOffers((prevExpiredOffers) =>
      new Set(prevExpiredOffers).add(offerId)
    );
  };

  const handleMouseEnter = () => setTextOpen(true);
  const handleMouseLeave = () => setTextOpen(false);

  const handleOpenAcceptButton = (data) => {
    setSelectedData(data);
    setOpenAcceptButton(true);
  };

  const handleOpenDeclineButton = (data) => {
    setSelectedData(data);
    setOpenDeclineButton(true);
  };

  const renderProductInfo = (product, index) => {
    const truncateText = (text, limit) => {
      return text.length > limit ? text.substring(0, limit) + "..." : text;
    };
    const sortedTypes = [...selectedTemplate.types].sort(
      (a, b) => a.indexPresentation - b.indexPresentation
    );

    const vignetteAttributes = [];
    const regularAttributes = [];

    sortedTypes.forEach((type) => {
      const productAttribute = product.product.attributs.find(
        (attr) => attr.key === type.name
      );
      let attributeValue = productAttribute ? productAttribute.value : "";

      if (type.abbreviation) {
        attributeValue += ` ${type.abbreviation}`;
      }

      const templateAttribute = type.attributs.find(
        (attr) => attr.value === productAttribute?.value
      );
      const attributeStyle = {
        color: templateAttribute?.textColor || "#000",
        backgroundColor: templateAttribute?.backgroundColor || "#fff",
      };

      if (type.isVignette) {
        vignetteAttributes.push(
          <span
            className="asset-chip3 asset-chip-color5"
            style={attributeStyle}
          >
            {attributeValue}
          </span>
        );
      } else {
        regularAttributes.push(attributeValue);
      }
    });

    const formattedPrice = product.product.prix.toLocaleString("fr-FR");

    // Combine attributes into a single string
    const attributesString = regularAttributes.join(" - ");

    // Truncate the combined string if necessary
    const truncatedAttributesString = truncateText(attributesString, 16); // Assuming a limit of 50 characters

    const fullAttributesString = product.product.attributs
      .map((attr) => ` ${attr.value}`)
      .join(", ");

    const differencePourcentage =
      ((product.amount - product.product.prix) / product.product.prix) * 100;
    const difference = product.amount - product.product.prix;

    const isOfferLower = product.amount < product.product.prix;

    return (
      <>
        <div className="item-offer" key={index}>
          <div className="item-offer-title">
            <span
              className="asset-ti-offer"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {truncatedAttributesString}{" "}
              <span className="question-mark">?</span>
            </span>
            {textOpen && (
              <div
                className="tooltipSidebarRight"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {fullAttributesString}
              </div>
            )}
            {vignetteAttributes}
            <span className="price-asset-offer">€ {formattedPrice}</span>
          </div>

          <div className="item-offer-details">
            <span className="name-offer">{product.companyName}</span>
            <span
              className={`asset-percent2 ${
                isOfferLower ? "asset-price-color-down" : "asset-price-color-up"
              }`}
            >
              {isOfferLower ? (
                <TrendingDownIcon className="trend-size-color-down" />
              ) : (
                <TrendingUpIcon className="trend-size-color-up" />
              )}
              {Math.abs(differencePourcentage).toFixed(2)}%
            </span>
            <span
              className={`offer-profit ${isOfferLower ? "offer-loss" : ""}`}
            >
              {isOfferLower ? "- € " : "+ € "}
              {Math.abs(difference).toLocaleString("en-US")}
            </span>
            <span className="price-offer-offer">
              € {product.amount.toLocaleString("en-US")}
            </span>
          </div>

          <div className="item-actions-details">
            <span className="chrono">
              <Countdown
                expirationDate={product.expirationDate}
                onExpire={() => handleExpire(product._id)}
              />
            </span>
            {!expiredOffers.has(product._id) && (
              <>
                <span
                  className="accept"
                  onClick={() => handleOpenAcceptButton(product)}
                >
                  accepter
                </span>
                <span
                  className="decline"
                  onClick={() => handleOpenDeclineButton(product)}
                >
                  refuser
                </span>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="offer-list">
      {offers.length === 0 ? (
        <div className="messageNoNothing">
          <LocalOfferIcon />
          <span>0 OFFRE</span>
        </div>
      ) : (
        offers.map((product, index) => <>{renderProductInfo(product, index)}</>)
      )}

      {openAcceptButton && (
        <AcceptOffer
          setOpen={setOpenAcceptButton}
          selectedData={selectedData}
        />
      )}
      {openDeclineButton && (
        <DeclineOffer
          setOpen={setOpenDeclineButton}
          selectedData={selectedData}
        />
      )}
    </div>
  );
};

export default Offers;
