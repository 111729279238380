import "./MarketPlacePage.css";
import Navbar from "../../../components/PLATEFORME/Navbar/Navbar";
import SidebarLeft from "../../../components/PLATEFORME/SidebarLeft/SidebarLeft";
import ProductInfo from "../../../components/PLATEFORME/ProductInfo/ProductInfo";
import SidebarRight from "../../../components/PLATEFORME/SidebarRight/SidebarRight";
import BottomBar from "../../../components/PLATEFORME/BottomBar/BottomBar";
import { useContext, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import axios from "axios";
import { AuthContextLead } from "../../../context/leadContextAuth";
import SyncLoader from "react-spinners/SyncLoader";
import Notification from "../../../components/PLATEFORME/Notification/Notification";
import { useData } from "../../../context/DataContext";
import {
  useNotifications,
  usePlatformLeadData,
} from "../../../hooks/usePlatformLeadData";
import { useProducts } from "../../../hooks/usePlatformLeadData";

const MarketPlacePage = () => {
  const [openProductInfo, setOpenProductInfo] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const { pTemplates, company } = useData();

  const [filteredNotifications, setFilteredNotifications] = useState([]);

  const { lead } = useContext(AuthContextLead);
  const { data: platformData } = usePlatformLeadData(lead?._id);
  const { data: productData, isLoading, error, refetch } = useProducts();
  const onHold = platformData?.onHold ?? [];
  const onSafe = platformData?.onSafe ?? [];
  const sold = platformData?.sold ?? [];
  const offers = platformData?.offers ?? [];
  const refund = platformData?.refund ?? [];
  const connectedLead = platformData?.lead ?? null;
  const { data: notifications = [] } = useNotifications(lead?._id);
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState(null);

  const handleOpenProductInfo = (data) => {
    setSelectedData(data);
    setOpenProductInfo(true);
  };

  useEffect(() => {
    if (productData && Array.isArray(productData)) {
      const visibleProducts = productData.filter((product) => !product.isHide);

      if (visibleProducts.length > 0) {
        setSelectedData(visibleProducts[0]);
        setOpenProductInfo(true);
      }
    }
  }, [productData]);

  useEffect(() => {
    if (notifications && lead) {
      const relevantNotifications = notifications.filter(
        (notification) => notification.lead === lead._id
      );
      setFilteredNotifications(relevantNotifications);
    }
  }, [notifications, lead]);

  useEffect(() => {
    const displayNotification = (nextNotification) => {
      setNotification(nextNotification);
      setShowNotification(true);

      // Automatically hide the notification after 10 seconds and mark it as read
      const timer = setTimeout(() => {
        setShowNotification(false);
        markNotificationAsRead(nextNotification._id);
      }, 5000);

      return () => clearTimeout(timer);
    };

    // Filter unread notifications for the connected lead and show the most recent one
    const unreadNotifications = notifications.filter(
      (n) => n.lead === lead._id && n.status === "unread"
    );
    if (unreadNotifications.length > 0) {
      displayNotification(unreadNotifications[0]);
    }
  }, [notifications]);

  const markNotificationAsRead = async (notificationId) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/notification/${notificationId}`,
        {
          status: "read",
        }
      );
    } catch (error) {
      console.error("Error updating notification status:", error);
    }
  };

  if (!pTemplates) {
    return (
      <div className="loaderOverlayAllApp">
        <SyncLoader color="#94a2cb" size="18" />
      </div>
    );
  }

  const selectedTemplate =
    pTemplates.find((template) => template.isSelected) || pTemplates[0];

  if (!selectedTemplate || pTemplates.length === 0) {
    return (
      <div className="loaderOverlayAllApp">
        <SyncLoader color="#94a2cb" size="18" />
      </div>
    );
  }

  return (
    <div className="marketPlacePage">
      {showNotification && notification && (
        <Notification
          notification={notification}
          setShowNotification={setShowNotification}
        />
      )}

      <Navbar
        connectedLead={connectedLead}
        sold={sold}
        onSafe={onSafe}
        selectedTemplate={selectedTemplate}
        refund={refund}
        filteredNotifications={filteredNotifications}
        company={company}
      />

      <SidebarLeft
        pTemplates={pTemplates}
        handleOpenProductInfo={setOpenProductInfo}
        products={productData}
      />
      {openProductInfo && (
        <ProductInfo
          productData={selectedData}
          selectedTemplate={selectedTemplate}
          products={productData}
          handleOpenProductInfo={handleOpenProductInfo}
          company={company}
        />
      )}
      <SidebarRight
        selectedTemplate={selectedTemplate}
        handleOpenProductInfo={handleOpenProductInfo}
        offers={offers}
        onHold={onHold}
        sold={sold}
        onSafe={onSafe}
        company={company}
        connectedLead={connectedLead}
      />
      <BottomBar
        onSafe={onSafe}
        onHold={onHold}
        selectedTemplate={selectedTemplate}
        handleOpenProductInfo={handleOpenProductInfo}
        offers={offers}
        sold={sold}
      />
    </div>
  );
};

export default MarketPlacePage;
