import "./History.css";
import { useState } from "react";
import Funds from "./Funds/Funds";
import Refunds from "./Refunds/Refunds";
import Pending from "./Pending/Pending";
import Sales from "./Sales/Sales";
import Rebougths from "./Rebougths/Rebougths";
import Offers from "./Offers/Offers";

const History = ({ currentData, reFetchFromTable, refetch }) => {
  const [activeTab, setActiveTab] = useState("Funds");

  const renderContent = () => {
    switch (activeTab) {
      case "Funds":
        return (
          <Funds
            currentData={currentData}
            reFetchFromTable={reFetchFromTable}
            refetch={refetch}
          />
        );
      case "Refunds":
        return <Refunds currentData={currentData} />;
      case "Pending":
        return (
          <Pending
            currentData={currentData}
            reFetchFromTable={reFetchFromTable}
          />
        );
      case "Sales":
        return (
          <Sales
            currentData={currentData}
            reFetchFromTable={reFetchFromTable}
          />
        );
      case "Offers":
        return (
          <Offers
            currentData={currentData}
            reFetchFromTable={reFetchFromTable}
          />
        );
      case "Rebougths":
        return (
          <Rebougths
            currentData={currentData}
            reFetchFromTable={reFetchFromTable}
          />
        );
      default:
        return <div>Contenu de l'onglet 1</div>;
    }
  };
  return (
    <div className="history">
      <div className="history-tabs">
        <button
          className={`tab ${activeTab === "Funds" ? "active" : ""}`}
          onClick={() => setActiveTab("Funds")}
        >
          Funds
        </button>
        <button
          className={`tab ${activeTab === "Refunds" ? "active" : ""}`}
          onClick={() => setActiveTab("Refunds")}
        >
          Refunds
        </button>
        <button
          className={`tab ${activeTab === "Pending" ? "active" : ""}`}
          onClick={() => setActiveTab("Pending")}
        >
          Pending
        </button>
        <button
          className={`tab ${activeTab === "Sales" ? "active" : ""}`}
          onClick={() => setActiveTab("Sales")}
        >
          Sales
        </button>
        <button
          className={`tab ${activeTab === "Offers" ? "active" : ""}`}
          onClick={() => setActiveTab("Offers")}
        >
          Offers
        </button>
        <button
          className={`tab ${activeTab === "Rebougths" ? "active" : ""}`}
          onClick={() => setActiveTab("Rebougths")}
        >
          Rebougths
        </button>
        <button
          className={`tab ${activeTab === "Withdrawals" ? "active" : ""}`}
          onClick={() => setActiveTab("Withdrawals")}
        >
          Withdrawals
        </button>
      </div>
      <div className="history-content">{renderContent()}</div>
    </div>
  );
};

export default History;
