import "./MySafeQuery.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DownloadIcon from "@mui/icons-material/Download";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useState } from "react";

const MySafeQuery = ({
  selectedTemplate,
  handleOpenProductInfo,
  offers,
  onSafe,
  handleShowOffers,
}) => {
  const [openInvoice, setOpenInvoice] = useState(false);
  const numberOfOffersPerProduct = (productId) => {
    const numberOfOffer = offers.filter((o) => o.product._id === productId);

    return numberOfOffer.length;
  };
  const renderProductInfo = (product) => {
    const truncateText = (text, limit) => {
      return text.length > limit ? text.substring(0, limit) + "..." : text;
    };
    const sortedTypes = [...selectedTemplate.types].sort(
      (a, b) => a.indexPresentation - b.indexPresentation
    );

    const vignetteAttributes = [];
    const regularAttributes = [];

    sortedTypes.forEach((type) => {
      const productAttribute = product.product.attributs.find(
        (attr) => attr.key === type.name
      );
      let attributeValue = productAttribute ? productAttribute.value : "";

      if (type.abbreviation) {
        attributeValue += ` ${type.abbreviation}`;
      }

      const templateAttribute = type.attributs.find(
        (attr) => attr.value === productAttribute?.value
      );
      const attributeStyle = {
        color: templateAttribute?.textColor || "#000",
        backgroundColor: templateAttribute?.backgroundColor || "#fff",
      };

      if (type.isVignette) {
        vignetteAttributes.push(
          <span
            className="asset-chip3 asset-chip-color5"
            style={attributeStyle}
          >
            {attributeValue}
          </span>
        );
      } else {
        regularAttributes.push(attributeValue);
      }
    });

    // Combine attributes into a single string
    const attributesString = regularAttributes.join(" - ");

    // Truncate the combined string if necessary
    const truncatedAttributesString = truncateText(attributesString, 16); // Assuming a limit of 50 characters

    const formattedPrice = product.product.prix.toLocaleString("fr-FR");

    return (
      <>
        <div className="item-safe-titleQuery">
          <span className="asset-ti-safeQuery">
            {attributesString}
            <span className="question-mark">?</span>
          </span>

          {vignetteAttributes}
          <span className="price-asset-safeQuery">€ {formattedPrice}</span>
        </div>
        <div className="item-safe-detailsQuery">
          <div className="item-actions-detailsQuery">
            <span
              className="actionQuery"
              onClick={() => handleShowOffers(product.product._id)}
            >
              <CheckBoxIcon className="icoresizeQuery" />
              Show Offers
            </span>
            <span className="offerssubQuery">
              {numberOfOffersPerProduct(product.product._id)}
            </span>
            <span className="actionQuery" onClick={() => setOpenInvoice(true)}>
              <DownloadIcon className="icoresizeQuery" />
              Invoice
            </span>
            <span className="actionQuery">
              <LocalShippingIcon className="icoresizeQuery" />
              Ship It
            </span>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <span className="titleQueryMobile">My Safe</span>
      <div className="safe-listQuery">
        {onSafe.map((product, index) => (
          <div
            key={index}
            className="item-safeQuery"
            onClick={() => handleOpenProductInfo(product.product)}
          >
            {renderProductInfo(product)}
          </div>
        ))}
        {/* {openInvoice && <Invoice setOpen={setOpenInvoice} />} */}
      </div>
    </>
  );
};

export default MySafeQuery;
