import React, { useState, useEffect } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./PasswordChange.css";
import axios from "axios";

const PasswordChange = ({ connectedLead }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordValidations, setPasswordValidations] = useState({
    minLength: false,
    uppercase: false,
    number: false,
    symbol: false,
    match: false,
  });

  useEffect(() => {
    const validatePassword = () => {
      const minLength = password.length >= 8;
      const uppercase = /[A-Z]/.test(password);
      const number = /[0-9]/.test(password);
      const symbol = /[^A-Za-z0-9]/.test(password);
      const match = password && confirmPassword && password === confirmPassword;

      setPasswordValidations({
        minLength,
        uppercase,
        number,
        symbol,
        match,
      });
    };

    validatePassword();
  }, [password, confirmPassword]);

  const criteriaMessages = {
    minLength: "Must be at least 8 characters long",
    uppercase: "Must contain at least one uppercase letter",
    number: "Must contain at least one number",
    symbol: "Must contain at least one special character",
    match: "Passwords must match",
  };

  const totalValid = Object.values(passwordValidations).filter(Boolean).length;
  const passwordStrength = (totalValid / 5) * 100;

  const handleUpdatedLead = async (e) => {
    e.preventDefault();

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/${connectedLead._id}`,
        { password: password }
      );
      setPassword("");
      setConfirmPassword("");
      setPasswordValidations({
        minLength: false,
        uppercase: false,
        number: false,
        symbol: false,
        match: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="middleFormLoginPlateformeInputProfile">
        <label>New password</label>
        <input
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="middleFormLoginPlateformeInputProfile">
        <label>Repeat password</label>
        <input
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
      <div className="passwordStrength">
        <div
          className="strengthBar"
          style={{ width: `${passwordStrength}%` }}
        ></div>
      </div>
      <ul className="passwordCriteria">
        {Object.entries(passwordValidations).map(([key, isValid]) => (
          <li key={key} className={isValid ? "valid" : ""}>
            {isValid ? (
              <CheckCircleIcon color="success" />
            ) : (
              <CheckCircleOutlineIcon />
            )}
            {criteriaMessages[key]}
          </li>
        ))}
      </ul>
      <div className="editButtonPlatformPopUpInfoLeadPassword">
        <button disabled={totalValid !== 5} onClick={handleUpdatedLead}>
          Save
        </button>
      </div>
    </>
  );
};

export default PasswordChange;
