import "./Welcome.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PublicIcon from "@mui/icons-material/Public";
import NewLeadsDashboardChat from "./NewLeadsDashboardChat/NewLeadsDashboardChat";
import LeadDashboardChart from "./LeadDashboardChart/LeadDashboardChart";
import FTDSDashboardChart from "./FTDSDashboardChart/FTDSDashboardChart";
import DepositDashboardChart from "./DepositDashboardChart/DepositDashboardChart";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/autoplay";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TurnoverDashboardChart from "./TurnoverDashboardChart/TurnoverDashboardChart";
import Chart6 from "./chart6/chart6";
import SalesPerfomanceDashboard from "./SalesPerfomanceDashboard/SalesPerfomanceDashboard";
const Welcome = ({ allStats, chartData }) => {
  return (
    <div className="dashboardContainerTop">
      <div className="dashboardContainerTopLeft">
        <div className="dashboardContainerTopLeftWrapperTop">
          <div className="dashboardContainerTopLeftWrapperTopLeft">
            <img src="/images/defaultImg.png" alt="" />
            <div className="dashboardContainerTopLeftWrapperTopLeftTitle">
              <span>
                Welcome, <strong>Super !</strong>
              </span>
              <span>
                Navigate the depths of strategic insights with our advanced CRM
                analytics.
              </span>
            </div>
          </div>
          <div className="dashboardContainerTopLeftWrapperTopRight">
            <select name="" id="">
              <option value="">This week</option>
            </select>
            <button>
              <FileUploadIcon />
              Export
            </button>
          </div>
        </div>
        <div className="dashboardContainerTopLeftWrapperBottom">
          <Swiper
            modules={[FreeMode, Autoplay]} // Utiliser FreeMode et Autoplay
            spaceBetween={50}
            slidesPerView={3}
            loop={true}
            freeMode={true}
            autoplay={{
              delay: 0, // Mettre delay à 0
              disableOnInteraction: false,
              pauseOnMouseEnter: true, // Optionnel: pause lors du survol
            }}
            speed={10000} // Ajuster la vitesse pour un défilement fluide
            // Note: La vitesse doit être ajustée en fonction du nombre de diapositives et du delay pour obtenir un défilement fluide
          >
            <SwiperSlide>
              <NewLeadsDashboardChat
                allStats={allStats}
                chartData={chartData}
              />
            </SwiperSlide>
            <SwiperSlide>
              <LeadDashboardChart allStats={allStats} chartData={chartData} />
            </SwiperSlide>
            <SwiperSlide>
              <FTDSDashboardChart allStats={allStats} chartData={chartData} />
            </SwiperSlide>
            <SwiperSlide>
              <DepositDashboardChart
                allStats={allStats}
                chartData={chartData}
              />
            </SwiperSlide>
            <SwiperSlide>
              <TurnoverDashboardChart
                allStats={allStats}
                chartData={chartData}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Chart6 />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="dashboardContainerTopRight">
        <div className="dashboardContainerBottomItemTitle">
          <div className="dashboardContainerBottomItemTitleLeft">
            <PublicIcon />
            <span>Sales Perfomance</span>
          </div>
          <div className="dashboardContainerBottomItemTitleRight">
            <select name="" id="">
              <option value="">FTDS</option>
              <option value="">Deposits</option>
            </select>
            <select name="" id="">
              <option value="">This week</option>
              <option value="">This month</option>
              <option value="">Last 3 months</option>
              <option value="">Last 6 months</option>
              <option value="">This year</option>
              <option value="">All</option>
            </select>
            <MoreVertIcon style={{ fontSize: "18px", color: "grey" }} />
          </div>
        </div>
        <SalesPerfomanceDashboard />
      </div>
    </div>
  );
};

export default Welcome;
