import "./Notifications.css";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CloseIcon from "@mui/icons-material/Close";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
const Notifications = ({ setOpen, filteredNotifications }) => {
  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="notificationUser" onClick={handleClickInside}>
      <span className="notificationUserTitle">Notifications</span>
      <CloseIcon
        style={{
          position: "absolute",
          top: "8px",
          right: "10px",
          fontSize: "16px",
          color: "grey",
          cursor: "pointer",
        }}
        onClick={() => setOpen(false)}
      />
      {filteredNotifications.length === 0 ? (
        <div className="userNoNotification">
          <NotificationsNoneIcon />
          <span>0 Notification</span>
        </div>
      ) : (
        filteredNotifications.map((notification) => (
          <div className="notificationUserItem">
            {notification.typeNotification === "depot" && (
              <AccountBalanceIcon
                style={{ borderRight: "1px solid rgb(223, 223, 223)" }}
              />
            )}
            {notification.typeNotification === "remboursement" && (
              <CreditCardIcon
                style={{ borderRight: "1px solid rgb(223, 223, 223)" }}
              />
            )}
            {notification.typeNotification === "attente" && (
              <AccessTimeIcon
                style={{ borderRight: "1px solid rgb(223, 223, 223)" }}
              />
            )}
            {notification.typeNotification === "offre" && (
              <LocalOfferIcon
                style={{ borderRight: "1px solid rgb(223, 223, 223)" }}
              />
            )}
            {notification.typeNotification === "vendu" && (
              <AccountBalanceIcon
                style={{ borderRight: "1px solid rgb(223, 223, 223)" }}
              />
            )}
            {notification.typeNotification === "coffre" && (
              <AccountBalanceWalletIcon
                style={{ borderRight: "1px solid rgb(223, 223, 223)" }}
              />
            )}
            <div className="notificationUserItemBottomTop">
              <span
                style={{
                  color: "#93a1ca",
                  fontWeight: "400",
                }}
                className="capitalize-first"
              >
                {notification.typeNotification}
              </span>
              <div className="notificationUserItemBottom">
                <span style={{ fontWeight: "300" }}>{notification.text}</span>
                <span style={{ color: "#93a1ca" }}>
                  {notification.typeNotification === "remboursement" ? "-" : ""}
                  {notification.typeNotification === "depot" ? "+" : ""}
                  {notification.typeNotification === "offre" ? "" : ""}{" "}
                  {["remboursement", "depot", "vendu", "offre"].includes(
                    notification.typeNotification
                  ) && notification.amount
                    ? `${notification.amount.toLocaleString()} €`
                    : ""}
                </span>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Notifications;
