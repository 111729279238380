import "./EditCampaign.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import useFetch from "../../../../hooks/useFetch";

const EditCampaign = ({ setOpen, reFetchFromTable, id }) => {
  const { data: campaign } = useFetch("campaign");
  const [editCampaign, setEditCampaign] = useState({ title: "" });

  useEffect(() => {
    if (campaign) {
      const findCorrespondingCampaign = campaign.find((s) => s._id === id);
      if (findCorrespondingCampaign) {
        setEditCampaign({ title: findCorrespondingCampaign.title, typePrice: findCorrespondingCampaign.typePrice, type: findCorrespondingCampaign.type });
      }
    }
  }, [campaign, id]);

  console.log(editCampaign)

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleChange = (e) => {
    setEditCampaign({ ...editCampaign, [e.target.name]: e.target.value });
  };

  const handleEditCampaign = async (e) => {
    e.preventDefault();

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/campaign/${id}`,
        editCampaign
      );
      reFetchFromTable();
      setEditCampaign({ title: "" });
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createStatus" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>Edit Campaign</span>
            </div>
            <div className="middleCreateDeposit">
              <label>Name</label>
              <input
                type="text"
                name="title"
                value={editCampaign.title}
                onChange={handleChange}
              />
               <label>Type</label>
              <select
                className="depositSelect"
                name="type"
                onChange={handleChange}
                value={editCampaign.type}
              >
                <option value="CPA">CPA</option>
                <option value="CPL">CPL</option>
                <option value="RS">Revenue Sharing</option>
                <option value="FLAT">Flat</option>
              </select>
              <label>Cost</label>
              <input
                type="text"
                name="typePrice"
                onChange={handleChange}
                value={editCampaign.typePrice}
              />
            </div>
            <div className="bottomCreateStatus">
              <button className="cancelButton">Cancel</button>
              <button className="saveButton" onClick={handleEditCampaign}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCampaign;
