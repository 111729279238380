import "./CreateProducts.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import useFetch from "../../../../hooks/useFetch";
// Recharts
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function generateGraphData({
  startYear,
  endYear,
  startValue,
  endValue,
  amplitude, // "low", "medium", "high"
  frequency, // "rare", "moderate", "often"
  trend, // "up" ou "down" (pour info, si vous voulez imposer un endValue < startValue quand "down")
}) {
  const sYear = parseInt(startYear, 10);
  const eYear = parseInt(endYear, 10);
  const sValue = parseFloat(startValue);
  const eValue = parseFloat(endValue);

  // Si jamais l'année de départ >= année de fin, on renvoie un tableau vide
  if (sYear >= eYear) {
    return [];
  }

  // Différence globale
  let globalDiff = eValue - sValue;

  // Nombre total d'années (inclusif)
  const totalYears = eYear - sYear;

  // Amplitude => facteur de fluctuation
  let fluctFactor;
  switch (amplitude) {
    case "low":
      fluctFactor = 0.03; // ±3%
      break;
    case "medium":
      fluctFactor = 0.08; // ±8%
      break;
    case "high":
      fluctFactor = 0.15; // ±15%
      break;
    default:
      fluctFactor = 0.05;
      break;
  }

  // Fréquence => tous les X ans, on applique un "random bump"
  let freqValue;
  switch (frequency) {
    case "rare":
      freqValue = 4;
      break;
    case "moderate":
      freqValue = 2;
      break;
    case "often":
      freqValue = 1;
      break;
    default:
      freqValue = 2;
      break;
  }

  // Construction du tableau final
  const data = [];
  for (let i = 0; i <= totalYears; i++) {
    const currentYear = sYear + i;
    // Progression linéaire t entre 0 et 1
    const t = i / totalYears;
    // Valeur linéaire
    const baseValue = sValue + globalDiff * t;

    // Appliquer fluctuation seulement certaines années
    let finalValue = baseValue;
    // On évite la fluctuation sur la toute première et la toute dernière année
    if (i !== 0 && i !== totalYears && i % freqValue === 0) {
      const rand = Math.random() * 2 - 1; // dans [-1, 1]
      finalValue = baseValue * (1 + rand * fluctFactor);
    }

    data.push({
      year: currentYear,
      value: Math.round(finalValue),
    });
  }

  return data;
}

const CreateProducts = ({ setOpen, reFetchFromTable }) => {
  const { data: pTemplates } = useFetch("templateProduct");
  const { data: suppliers } = useFetch("supplier");

  const [startYear, setStartYear] = useState("");
  const [startValue, setStartValue] = useState("");
  const [endValue, setEndValue] = useState("");
  const [amplitude, setAmplitude] = useState("medium");
  const [frequency, setFrequency] = useState("moderate");
  const [trend, setTrend] = useState("up");
  const [yearInputs, setYearInputs] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [productDetails, setProductDetails] = useState({
    reference: "",
    description: "",
    supplier: "",
    prix: "",
    attributs: [],
    graphData: [],
    assetRarity: 0,
    assetCondition: 0,
    certificat: {
      name: "",
      number: "",
    },
  });

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleGenerateData = () => {
    const currentYear = new Date().getFullYear();

    // Générer le tableau complet (automatique)
    const autoData = generateGraphData({
      startYear,
      endYear: currentYear,
      startValue,
      endValue: productDetails.prix, 
      amplitude,
      frequency,
      trend,
    });

    const years = [];
    for (let year = parseInt(startYear, 10); year <= currentYear; year++) {
      years.push(year);
    }
    setYearInputs(years);

    setProductDetails((prev) => ({
      ...prev,
      graphData: autoData,
    }));
  };

  const handleGraphDataChange = (year) => (e) => {
    const value = Number(e.target.value);

    setProductDetails((prevDetails) => {
      // On retire l'année si déjà présente
      const updatedGraphData = prevDetails.graphData.filter(
        (d) => d.year !== year
      );

      // On ajoute la nouvelle entrée
      updatedGraphData.push({ year, value });

      // Pour garder l'ordre chronologique, on peut trier
      updatedGraphData.sort((a, b) => a.year - b.year);

      return {
        ...prevDetails,
        graphData: updatedGraphData,
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericValue = parseFloat(value);

    // Champs de certificat
    if (name === "certificat.name" || name === "certificat.number") {
      setProductDetails((prevDetails) => ({
        ...prevDetails,
        certificat: {
          ...prevDetails.certificat,
          [name.split(".")[1]]: value,
        },
      }));
    }
    // Validation pour assetRarity et assetCondition
    else if (name === "assetRarity" || name === "assetCondition") {
      if (numericValue >= 1 && numericValue <= 5) {
        setProductDetails((prev) => ({
          ...prev,
          [name]: numericValue,
        }));
      }
    }
    // Champs classiques
    else if (
      name === "description" ||
      name === "prix" ||
      name === "reference" ||
      name === "supplier" ||
      name === "assetRarity" ||
      name === "assetCondition"
    ) {
      setProductDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    // Sinon, attributs
    else {
      const attributs = productDetails.attributs.filter(
        (attr) => attr.key !== name
      );
      attributs.push({ key: name, value });
      setProductDetails((prev) => ({
        ...prev,
        attributs,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!productDetails.supplier) {
      setErrorMessage("Please select a supplier.");
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/product/`,
        productDetails
      );
      reFetchFromTable();
      setOpen(false);
      setErrorMessage("");
    } catch (error) {
      console.error("Error creating product:", error);
      setErrorMessage("Error creating product.");
    }
  };

  const selectedTemplate =
    (pTemplates && pTemplates.find((template) => template.isSelected)) ||
    (pTemplates && pTemplates[0]);

  useEffect(() => {
    if (selectedTemplate) {
      const attributs = selectedTemplate.types.map((type) => ({
        key: type.name,
        value: "",
      }));
      setProductDetails((prev) => ({
        ...prev,
        attributs,
      }));
    }
  }, [selectedTemplate]);


  const ChartPreview = ({ data }) => {
    if (!data || data.length === 0) {
      return <p style={{ fontSize: "0.8rem" }}>Aucune donnée à afficher</p>;
    }

    const startY = data[0].year;
    const endY = data[data.length - 1].year;
    const totalYears = endY - startY;
    const step = Math.max(1, Math.round(totalYears / 4));
    const ticks = [];
    for (let y = startY; y <= endY; y += step) {
      ticks.push(y);
    }
    if (ticks[ticks.length - 1] !== endY) {
      ticks[ticks.length - 1] = endY;
    }

    return (
      <div style={{ width: 300, height: 200, background: "#fff" }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <Line
              type="monotone"
              dataKey="value"
              stroke="#8884d8"
              strokeWidth={2}
            />
            <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
            <XAxis
              dataKey="year"
              type="number"
              domain={[startY, endY]}
              ticks={ticks}
              tick={{ fontSize: 10 }}
            />
            <YAxis tick={{ fontSize: 10 }} />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  };

  // Si pas encore chargé
  if (!pTemplates || pTemplates.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createProduct" onClick={handleClickInside}>
          <div className="closeIconProduct">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <form className="createProductWrapper" onSubmit={handleSubmit}>
            <div className="topCreateProduct">
              <span>Create Product</span>
            </div>

            <div className="middleCreateProduct">
              <div className="createProductPrensentation">
                <select name="supplier" onChange={handleChange}>
                  <option value="">Select Supplier</option>
                  {suppliers.map((supplier) => (
                    <option key={supplier._id} value={supplier._id}>
                      {supplier.name}
                    </option>
                  ))}
                </select>
                {errorMessage && (
                  <div
                    style={{ color: "red", marginTop: "10px", fontSize: "9px" }}
                  >
                    {errorMessage}
                  </div>
                )}
              </div>
              <input
                type="text"
                placeholder="Reference (ex : REF7587748)"
                name="reference"
                value={productDetails.reference}
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="Description"
                name="description"
                value={productDetails.description}
                onChange={handleChange}
              />
              <input
                type="number"
                name="assetRarity"
                min="1"
                max="5"
                step="0.1"
                value={
                  productDetails.assetRarity === 0
                    ? ""
                    : productDetails.assetRarity
                }
                onChange={handleChange}
                placeholder="Asset Rarity (1 to 5)"
              />
              <input
                type="number"
                name="assetCondition"
                min="1"
                max="5"
                step="0.1"
                value={
                  productDetails.assetCondition === 0
                    ? ""
                    : productDetails.assetCondition
                }
                onChange={handleChange}
                placeholder="Asset Condition (1 to 5)"
              />
              <input
                type="text"
                name="certificat.name"
                value={productDetails.certificat.name}
                onChange={handleChange}
                placeholder="Certificat Name (ex : CER)"
              />
              <input
                type="text"
                name="certificat.number"
                value={productDetails.certificat.number}
                onChange={handleChange}
                placeholder="Certificat Number (ex : 45877555)"
              />
              <input
                type="number"
                placeholder="Prix"
                name="prix"
                value={productDetails.prix}
                onChange={handleChange}
              />

              {selectedTemplate.types.map((type, index) => {
                if (type.attributs.length > 0) {
                  return (
                    <div key={index} className="createProductPrensentation">
                      <label>{type.name}</label>
                      <select name={type.name} onChange={handleChange}>
                        <option value="">Select {type.name}</option>
                        {type.attributs.map((attribut, attributIndex) => (
                          <option key={attributIndex} value={attribut.value}>
                            {attribut.value}
                          </option>
                        ))}
                      </select>
                    </div>
                  );
                } else {
                  const inputType =
                    type.typeCategory === "numeric"
                      ? "number"
                      : type.typeCategory === "UM"
                      ? "number"
                      : "text";
                  return (
                    <div key={index} className="createProductPrensentation">
                      <label>{type.name}</label>
                      <input
                        type={inputType}
                        name={type.name}
                        placeholder={`Enter ${type.name}`}
                        onChange={handleChange}
                        step={
                          type.typeCategory === "numeric" ||
                          type.typeCategory === "UM"
                            ? "any"
                            : undefined
                        }
                      />
                    </div>
                  );
                }
              })}
              {/* ==================== PARTIE GENERATION ==================== */}
              <div
                style={{ border: "1px solid #ccc", marginTop: 15, padding: 10 }}
              >
                <h3>Chart</h3>
                <div style={{ display: "flex", gap: 20 }}>
                  <div>
                    <label>Année de départ</label>
                    <input
                      type="number"
                      placeholder="ex: 2015"
                      value={startYear}
                      onChange={(e) => setStartYear(e.target.value)}
                    />
                  </div>

                  <div>
                    <label>Valeur de départ</label>
                    <input
                      type="number"
                      placeholder="ex: 1000"
                      value={startValue}
                      onChange={(e) => setStartValue(e.target.value)}
                    />
                  </div>

                  <div>
                    <label>Valeur cible (année actuelle)</label>
                    <input
                      type="number"
                      placeholder="ex: 3000"
                      value={productDetails.prix || ""}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="prix"
                    />
                  </div>
                </div>

                <div style={{ display: "flex", gap: 20, marginTop: 10 }}>
                  <div>
                    <label>Tendance</label>
                    <select
                      value={trend}
                      onChange={(e) => setTrend(e.target.value)}
                    >
                      <option value="up">À la hausse</option>
                      <option value="down">À la baisse</option>
                    </select>
                  </div>

                  <div>
                    <label>Amplitude</label>
                    <select
                      value={amplitude}
                      onChange={(e) => setAmplitude(e.target.value)}
                    >
                      <option value="low">Faible</option>
                      <option value="medium">Modérée</option>
                      <option value="high">Forte</option>
                    </select>
                  </div>

                  <div>
                    <label>Fréquence</label>
                    <select
                      value={frequency}
                      onChange={(e) => setFrequency(e.target.value)}
                    >
                      <option value="rare">Rare</option>
                      <option value="moderate">Modérée</option>
                      <option value="often">Élevée</option>
                    </select>
                  </div>
                </div>

                <button
                  type="button"
                  style={{ marginTop: 10 }}
                  onClick={handleGenerateData}
                >
                  Générer
                </button>
              </div>

              {/* ==================== PARTIE MANUELLE (avec résultats auto) ==================== */}
              <div
                style={{ border: "1px solid #ccc", marginTop: 15, padding: 10 }}
              >
                <h3>Valeurs par année (modifiables)</h3>
                {yearInputs.length === 0 && (
                  <p style={{ fontSize: "0.9rem" }}>
                    (Cliquez d'abord sur "Générer" pour créer la liste d'années)
                  </p>
                )}
                {yearInputs.map((year) => {
                  // Trouver la valeur déjà calculée
                  const existing = productDetails.graphData.find(
                    (d) => d.year === year
                  );
                  const val = existing ? existing.value : 0;

                  return (
                    <div
                      key={year}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                        marginBottom: 8,
                      }}
                    >
                      <label style={{ minWidth: "70px" }}>{year}:</label>
                      <input
                        type="number"
                        value={val}
                        onChange={handleGraphDataChange(year)}
                      />
                    </div>
                  );
                })}
              </div>

              {/* ==================== APERÇU GRAPHIQUE ==================== */}
              <div
                style={{ margin: "10px 0" }}
                onMouseEnter={() => setShowPreview(true)}
                onMouseLeave={() => setShowPreview(false)}
              >
                <strong>Survoler ici pour voir l'aperçu du graphique</strong>
                {showPreview && (
                  <div
                    style={{
                      position: "absolute",
                      background: "white",
                      border: "1px solid #ccc",
                      padding: 10,
                      top: 112, // Ajustez
                      left: 400, // Ajustez
                      zIndex: 999,
                    }}
                  >
                    <ChartPreview data={productDetails.graphData} />
                  </div>
                )}
              </div>
            </div>

            <div className="bottomCreateProduct">
              <button
                type="button"
                className="cancelButton"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button type="submit" className="saveButton">
                <CheckIcon />
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateProducts;
