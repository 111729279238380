import "./AdminPlanning.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import moment from "moment";
import { useEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import SnowshoeingIcon from "@mui/icons-material/Snowshoeing";
const AdminPlanning = ({ userLeads, title }) => {
  const { data: users } = useFetch("user");
  const [selectedSales, setSelectedSales] = useState("All");

  const handleSelectedSales = (e) => {
    setSelectedSales(e.target.value);
  };

  // Extraire les IDs des auteurs des commentaires qui ont un rendez-vous
  const userWithAppointment = userLeads.flatMap((lead) =>
    lead.comment
      .filter((comment) => comment.appointment && comment.appointment.date)
      .map((comment) => comment.author._id)
  );

  // Créer un Set pour filtrer les IDs uniques
  const uniqueUserIds = new Set(userWithAppointment);

  // Filtrer les utilisateurs pour trouver ceux qui correspondent aux IDs uniques
  const findAllUsers = users.filter((user) =>
    uniqueUserIds.has(user._id.toString())
  );

  const filteredAppointment = userLeads.flatMap((lead) => {
    return lead.comment
      .filter((comment) => {
        if (comment.appointment && comment.appointment.date) {
          const matchesUser =
            selectedSales === "All" ||
            (comment.author && comment.author._id === selectedSales);

          return matchesUser;
        }
      })
      .map((comment) => ({
        ...comment,
        leadName: lead.name,
      }));
  });

  return (
    <div className="adminPlanning">
      <div className="adminPlanningWrapper">
        <div className="topAdminPlanning">
          <div className="topAdminPlanningLeft">
            <CalendarMonthIcon />
            <span>PLANNING</span>
          </div>
          <div className="topAdminPlanningRight">
            <select onChange={handleSelectedSales}>
              <option value="All">Select Sales</option>
              {findAllUsers.map((u) => (
                <option key={u._id} value={u._id}>
                  {u.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="centerAdminPlanning">
          <div className="thisMonthContainer">
            <div className="thisMonthContainerTitle">
              <span>{title}</span>
            </div>
            {filteredAppointment.length < 1 ? (
              <div className="noAppointment">
                <SnowshoeingIcon />
                <span>0 APPOINTMENT</span>
                {/* <span>COME BACK LATER !</span> */}
              </div>
            ) : (
              <div className="appointmentContainer">
                <div className="appointmentWrapper">
                  {filteredAppointment.map((comment, index) => (
                    <div key={index} className="appointmentContainerItem">
                      {/* <div className="delContainer">
                          <span>del</span>
                        </div> */}
                      {comment.appointment && (
                        <div className="rdvContainer">
                          <QueryBuilderIcon />
                          <span>
                            {moment(comment.appointment.date).format(
                              "DD/MM/YY HH:mm"
                            )}
                          </span>
                        </div>
                      )}
                      <div className="appointmentContainerItemName">
                        <div className="appointmentContainerItemNameWrapper">
                          <div className="appointmentContainerItemNameWrapperNameImg">
                            <img
                              src={comment.author.pPicture}
                              alt={comment.author.pPicture}
                            />
                            <div className="appointmentContainerItemNameWrapperNameImgDate">
                              <span>{comment.author.name}</span>
                              <span className="dateColor">
                                {new Date(comment.createdAt).toLocaleString()}
                              </span>
                              <span
                                style={{
                                  color: "#e76790",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                }}
                              >
                                {comment.leadName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="appointmentContainerItemComentaire">
                        <span>{comment.content}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPlanning;
