import { useEffect, useState } from "react";
import "./LeadInfo.css";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";

const LeadInfo = ({ connectedLead }) => {
  const [leadData, setLeadData] = useState(connectedLead);
  const [updateLead, setUpdateLead] = useState({
    email: leadData.email,
    phone: leadData.phone,
    password: leadData.password,
    country: leadData.country,
    city: leadData.city,
    address: leadData.address,
    postalCode: leadData.postalCode,
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (connectedLead.name) {
      const names = connectedLead.name.split(" ");
      if (names.length > 1) {
        setLastName(names.pop());
        setFirstName(names.join(" "));
      } else {
        setFirstName(names[0]);
        setLastName("");
      }
    }
  }, [connectedLead]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else {
      setUpdateLead((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleUpdatedLead = async (e) => {
    e.preventDefault();
    const updatedName = fullName.trim();
    const dataToSend = { ...updateLead, name: updatedName };

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/${connectedLead._id}`,
        dataToSend
      );
      setLeadData(dataToSend);
      setIsEditing(false);
    } catch (err) {
      console.log(err);
    }
  };

  const fullName = `${firstName} ${lastName}`;

  return (
    <>
      <div className="middleFormLoginPlateformeInputProfile">
        <label>First Name</label>
        <input
          type="text"
          name="firstName"
          value={firstName}
          onChange={handleChange}
          readOnly={!isEditing}
        />
      </div>
      <div className="middleFormLoginPlateformeInputProfile">
        <label>Last name</label>
        <input
          type="text"
          name="lastName"
          value={lastName}
          onChange={handleChange}
          readOnly={!isEditing}
        />
      </div>
      <div className="middleFormLoginPlateformeInputProfile">
        <label>Phone</label>
        <input
          type="text"
          name="phone"
          value={isEditing ? updateLead.phone : leadData.phone}
          onChange={handleChange}
          readOnly={!isEditing}
        />
      </div>
      <div className="middleFormLoginPlateformeInputProfile">
        <label>Email</label>
        <input
          type="text"
          name="email"
          value={isEditing ? updateLead.email : leadData.email}
          onChange={handleChange}
          readOnly={!isEditing}
        />
      </div>
      <div className="middleFormLoginPlateformeInputProfile">
        <label>Address</label>
        <input
          type="text"
          name="address"
          value={isEditing ? updateLead.address : leadData.address}
          onChange={handleChange}
          readOnly={!isEditing}
        />
      </div>
      <div className="middleFormLoginPlateformeInputProfile">
        <label>Postal code</label>
        <input
          type="text"
          name="postalCode"
          value={isEditing ? updateLead.postalCode : leadData.postalCode}
          onChange={handleChange}
          readOnly={!isEditing}
        />
      </div>
      <div className="middleFormLoginPlateformeInputProfile">
        <label>City</label>
        <input
          type="text"
          name="city"
          value={isEditing ? updateLead.city : leadData.city}
          onChange={handleChange}
          readOnly={!isEditing}
        />
      </div>
      <div className="middleFormLoginPlateformeInputProfile">
        <label>Country</label>
        <input
          type="text"
          name="country"
          value={isEditing ? updateLead.country : leadData.country}
          onChange={handleChange}
          readOnly={!isEditing}
        />
      </div>
      <div className="editButtonPlatformPopUpInfoLead">
        {isEditing ? (
          <div className="isEditingPopupPlateform">
            <button onClick={() => setIsEditing(false)}>Cancel</button>
            <button onClick={handleUpdatedLead}>Save</button>
          </div>
        ) : (
          <button onClick={() => setIsEditing(true)}>Edit</button>
        )}
      </div>
    </>
  );
};

export default LeadInfo;
