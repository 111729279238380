import "./HomePage.css";
import Sidebar from "../../components/Navigation/Sidebar/Sidebar";
import Dashboard from "../../components/Dashboard/Dashboard";
import NavigationTabsAndFilterDate from "../../components/Navigation/NavigationTabsAndFilterDate/NavigationTabsAndFilterDate";
import { DashboardTitles } from "../../constants/navigationTitles";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useData } from "../../context/DataContext";
function HomePage() {
  const [allStats, setAllStats] = useState([]);
  const [chartData, setChartData] = useState([]);
  const { campaigns } = useData();
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate,
    endDate,
  });

  const handleFilteredDataChange = useCallback(({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  }, []);

  const fetchAllStats = async () => {
    const queryParams = new URLSearchParams({
      startDate: selectedDateRange.startDate
        ? selectedDateRange.startDate.toISOString()
        : null,
      endDate: selectedDateRange.endDate
        ? selectedDateRange.endDate.toISOString()
        : null,
    }).toString();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/user/allStats?${queryParams}`
      );

      setAllStats(response.data.totals);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchChartData = async () => {
    // Ici vous récupérez vos données pour le graphique
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/user/chartStats`
      );
      setChartData(response.data);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, []);

  useEffect(() => {
    fetchAllStats();
  }, [selectedDateRange]);

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabsAndFilterDate
            tabs={DashboardTitles}
            onFilteredDataChange={handleFilteredDataChange}
          />
        </div>
        <div className="bottomCrmPage">
          <Dashboard allStats={allStats} chartData={chartData} campaigns={campaigns}/>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
