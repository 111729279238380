export const handleExportButtonClick = (data, checkedItems) => {
  if (checkedItems.length > 0) {
    const selectedData = data.filter((item) => checkedItems.includes(item._id));
    const headers = Object.keys(selectedData[0]);
    const csvContent = [
      headers.join(","),
      ...selectedData.map((item) =>
        headers.map((header) => item[header]).join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "data.csv";
    link.click();
  } else {
    alert("Please select at least one checkbox to export.");
  }
};

// Function for handling the change event of the "Select All" checkbox
export const handleAllCheckboxChange = (isChecked, data, setIsAllChecked, setCheckedItems) => {
  // Update the state variable isAllChecked to reflect the new value of isChecked
  setIsAllChecked(isChecked);
  
  if (isChecked) {
    // If the checkbox is checked, create an array of all checkbox ids
    const allCheckboxIds = data.map((item) => item._id);
    // Set the state variable checkedItems to contain all checkbox ids
    setCheckedItems(allCheckboxIds);
  } else {
    // If the checkbox is unchecked, reset the state variable checkedItems to an empty array
    setCheckedItems([]);
  }
};


// Function for handling the change event of individual checkboxes
export const handleCheckboxChange = (event, itemId, data, checkedItems, setIsAllChecked, setCheckedItems) => {
  // Get the checkbox element and its checked status
  const checkbox = event.target;
  const checked = checkbox.checked;

  // Update the state variable checkedItems based on the checked status of the checkbox
  setCheckedItems((prevItems) => {
    if (checked) {
      // If the checkbox is checked, add the itemId to the checkedItems array
      const updatedItems = [...prevItems, itemId];
      return updatedItems;
    } else {
      // If the checkbox is unchecked, remove the itemId from the checkedItems array
      const updatedItems = prevItems.filter((id) => id !== itemId);
      return updatedItems;
    }
  });

  // Update the state variable isAllChecked based on the number of checkedItems and the total number of items
  setIsAllChecked(checkedItems.length + 1 === data.length);
};
