import "./LegalInfo.css";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

const LegalInfo = ({ handleLegalChange, company }) => {
  return (
    <table className="tablePlatformConfiguration">
      <thead>
        <tr>
          <td colspan={2}>LEGAL INFORMATIONS :</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <CustomToolbarLEGAL />
            <ReactQuill
              modules={{ toolbar: { container: "#toolbarLEGAL" } }}
              value={company.legal}
              onChange={handleLegalChange}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default LegalInfo;

const CustomToolbarLEGAL = () => (
  <div id="toolbarLEGAL">
    {/* Ajouter les contrôles de mise en forme souhaités */}
    <select className="ql-header" defaultValue="">
      <option value="1">Heading 1</option>
      <option value="2">Heading 2</option>
      <option value="">Normal</option>
    </select>
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    <button className="ql-strike"></button>
    <button className="ql-align" value=""></button>
    <button className="ql-align" value="center"></button>
    <button className="ql-align" value="right"></button>
    <button className="ql-align" value="justify"></button>
  </div>
);
