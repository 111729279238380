import "./Activity.css";
import { useRef, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const Activity = ({ userStats }) => {
  const [isAllChecked, setIsAllChecked] = useState(false);

  const tableRef = useRef(null);

  return (
    <>
      <div className="salesPerfomancePageBottomLeftTopItemActivity">
        <AccessTimeIcon />
        <span>ACTIVITY</span>
      </div>
      <div className="activityTableWrapper">
        <table className="tableDataSales" ref={tableRef}>
          <thead className="dtThead">
            <tr>
              {/* <th>
              <div className="placeCheckbox">
                <label className="container">
                  <input type="checkbox" checked={isAllChecked} readOnly />

                  <div className="checkmark"></div>
                </label>
              </div>
            </th> */}
              <th>
                <div className="headerWithIcon">Sales Agent</div>
              </th>
              {/* <th></th> */}
              <th>
                <div className="headerWithIcon">Connection</div>
              </th>
              <th>
                <div className="headerWithIcon">Call</div>
              </th>
              <th>
                <div className="headerWithIcon">Comments</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {userStats.map((item, index) => (
              <tr>
                {/* <td>
                <label className="container">
                  <input type="checkbox" />
                  <div className="checkmark"></div>
                </label>
              </td> */}

                <td>
                  <div className="salesAgentImg">
                    <img src={item.userPicture} alt={item.userPicture} />
                    <span>{item.userName}</span>
                  </div>
                </td>
                {/* <td>
                  <div className="rondConnectStatus"></div>
                </td> */}
                <td>
                  <span>38</span>
                </td>
                <td>
                  <span>38</span>
                </td>
                <td>
                  <span>38</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Activity;
