import "./Navbar.css";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import LanguageIcon from "@mui/icons-material/Language";
import LogoutIcon from "@mui/icons-material/Logout";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContextLead } from "../../../context/leadContextAuth";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Notifications from "./Notifications/Notifications";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Withdrawal from "./Withdrawal/Withdrawal";
import ProfileInfo from "./ProfileInfo/ProfileInfo";
import axios from "axios";
import { useSocket } from "../../../context/SocketContext";

const Navbar = ({
  connectedLead,
  sold,
  onSafe,
  selectedTemplate,
  refund,
  refetchRefund,
  reFetchNotifications,
  filteredNotifications,
  company,
}) => {
  const { logOutLead, lead } = useContext(AuthContextLead);
  const [logoDataURL, setLogoDataURL] = useState(null);
  const [openNotifications, setOpenNotifications] = useState(null);
  const [openScrollDown, setOpenScrollDown] = useState(false);
  const [openWithdrawal, setOpenWithdrawal] = useState(false);
  const [openProfileInfo, setOpenProfileInfo] = useState(false);
  const [balance, setBalance] = useState(0);
  const [lastFetchedBalance, setLastFetchedBalance] = useState(0);
  const { socket } = useSocket();
  
  const animateBalance = (start, end) => {
    let startTimestamp = null;
    const duration = 3500;

    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const newBalance = start + progress * (end - start);
      setBalance(Number(newBalance.toFixed(0)));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  };

  useEffect(() => {
    if (
      connectedLead &&
      connectedLead.balance !== undefined &&
      lastFetchedBalance !== connectedLead.balance
    ) {
      if (lastFetchedBalance !== 0) {
        animateBalance(balance, connectedLead.balance);
      }
      setLastFetchedBalance(connectedLead.balance);
    }
  }, [connectedLead]);

  useEffect(() => {
    if (connectedLead && connectedLead.balance !== undefined) {
      setBalance(connectedLead.balance);
    }
  }, [connectedLead]);

  const getInitials = (fullName) => {
    const names = fullName.split(" ");
    if (names.length > 1) {
      return (
        names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase()
      );
    } else {
      return fullName.charAt(0).toUpperCase();
    }
  };

  const leadInitials = connectedLead ? getInitials(connectedLead.name) : "NA";

  const convertImageToDataURL = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
      }
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting image to Data URL: ", error);
    }
  };

  useEffect(() => {
    const serverBaseUrl = process.env.REACT_APP_SERVER_URL;
    if (company && company.length > 0 && company[0].storeLogo) {
      const logoUrl = `${serverBaseUrl}/${company[0].storeLogo}`;
      convertImageToDataURL(logoUrl).then(setLogoDataURL);
    }
  }, [company]);

  const profilRef = useRef(null);
  const notificationsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profilRef.current &&
        !profilRef.current.contains(event.target) &&
        !event.target.classList.contains("menu-item")
      ) {
        setOpenScrollDown(false);
      }
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target) &&
        !event.target.classList.contains("menu-item")
      ) {
        setOpenNotifications(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openScrollDown, openNotifications]);

  const toggleProfileMenu = (e) => {
    e.stopPropagation();
    setOpenScrollDown(!openScrollDown);
  };

  

  const toggleNotifications = async () => {
    const wasClosed = !openNotifications;
    setOpenNotifications(!openNotifications);

    // Si on ouvre les notifications et qu'il y a des notifications non ouvertes
    if (wasClosed && filteredNotifications.some((n) => !n.hasBeenOpen)) {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_SERVER_URL}/api/notification/${connectedLead._id}/mark-all-open`
        );
        if (response.data) {
          reFetchNotifications(); // Assurez-vous que cette fonction recharge les données pour refléter les changements
        }
      } catch (error) {
        console.error("Error marking notifications as open:", error);
      }
    }
  };

  return (
    <div className="header">
      <div className="logo">
        {logoDataURL && <img src={logoDataURL} alt="Logo" />}
      </div>
      <MenuIcon className="menumob" />
      <div
        className="clocheContainer"
        ref={notificationsRef}
        onClick={toggleNotifications}
      >
        <NotificationsNoneIcon className="cloche" />
        {filteredNotifications.some((n) => !n.hasBeenOpen) && (
          <div className="notificationDotCloche"></div>
        )}
      </div>

      <LanguageIcon className="language" />
      <div className="profil" ref={profilRef} onClick={toggleProfileMenu}>
        {leadInitials}
      </div>
      {openScrollDown && (
        <div className="userMenuNavbar">
          <div className="userMenuNavbarWrapper">
            <div
              className="userMenuNavbarItem menu-item"
              onClick={(e) => {
                e.stopPropagation();
                setOpenScrollDown(false);
                setOpenProfileInfo(true);
              }}
            >
              <AccountCircleIcon />
              Profile
            </div>
            <div
              className="userMenuNavbarItem menu-item"
              onClick={(e) => {
                e.stopPropagation();
                setOpenScrollDown(false);
                setOpenWithdrawal(true);
              }}
            >
              <CreditCardIcon />
              Withdrawal
            </div>

            <div
              className="userMenuNavbarItem menu-item"
              onClick={(e) => {
                e.stopPropagation();
                setOpenScrollDown(false);
                logOutLead();
              }}
            >
              <LogoutIcon />
              Log Out
            </div>
          </div>
        </div>
      )}
      <div className="idendity">
        <span>Balance : </span>€ {balance.toLocaleString("fr-FR")}
      </div>
      <div className="menu">
        {/* <a className="aoff">Dashboard</a> */}
        <a className="aoff">Market Place</a>
        {/* <a className="aoff">Trading</a> */}
      </div>
      {openNotifications && (
        <Notifications
          setOpen={setOpenNotifications}
          filteredNotifications={filteredNotifications}
        />
      )}
      {openWithdrawal && (
        <Withdrawal setOpen={setOpenWithdrawal} connectedLead={connectedLead} />
      )}
      {openProfileInfo && (
        <ProfileInfo
          setOpen={setOpenProfileInfo}
          connectedLead={connectedLead}
          sold={sold}
          onSafe={onSafe}
          selectedTemplate={selectedTemplate}
          refund={refund}
        />
      )}
    </div>
  );
};

export default Navbar;
