import "./AddRefund.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import useFetch from "../../../../hooks/useFetch";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const AddRefund = ({ setOpen, reFetch }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { data: leads } = useFetch("lead");
  const { data: users } = useFetch("user");
  const { data: payments } = useFetch("payment");
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [showDropdown, setShowDropdown] = useState(true);
  const [refund, setRefund] = useState([]);
  const [selectedSalesId, setSelectedSalesId] = useState("Select");
  const [newRefund, setNewRefund] = useState({
    amount: 0,
    refundDate: new Date().toISOString(),
  });

  useEffect(() => {
    const findCorrespondingLead = leads.find((l) => l._id === selectedLeadId);

    if (findCorrespondingLead) {
      setRefund(findCorrespondingLead.refund);
      setSelectedSalesId(findCorrespondingLead.assignTo._id);
    } else {
      setSelectedSalesId("Select");
    }
  }, [selectedLeadId, leads]);

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query.length === 0) {
      setSelectedLeadId(null);
      setSelectedSalesId("Select");
      setFilteredLeads([]);
      setShowDropdown(true);
    } else {
      const filtered = leads.filter(
        (l) =>
          l?.reference?.toString().toLowerCase().includes(query) ||
          l?.email?.toLowerCase().includes(query) ||
          l?.name?.toLowerCase().includes(query)
      );
      setFilteredLeads(filtered);
      setShowDropdown(filtered.length > 0);
    }
  };
  const handleLeadSelect = (leadId, leadName) => {
    setSelectedLeadId(leadId);
    setSearchQuery(leadName);
    setShowDropdown(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "assignTo") {
      setSelectedSalesId(value);
    }

    setNewRefund({ ...newRefund, [name]: value });
  };

  const handleAddNewRefund = async () => {
    if (!selectedLeadId) {
      alert("Please select a lead.");
      return;
    }

    const updatedRefund = {
      ...newRefund,
    };

    const findCorrespondingLead = leads.find((l) => l._id === selectedLeadId);
    const updateLeadBalance =
      findCorrespondingLead.balance - Number(updatedRefund.amount);
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/${selectedLeadId}`,
        {
          refund: [...refund, updatedRefund],
          balance: updateLeadBalance,
        }
      );
      setOpen(false);
      reFetch();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createDeposit" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createDepositWrapper">
            <div className="topCreateStatus">
              <span>Add Refund</span>
            </div>
            <div className="middleCreateDeposit">
              <div className="searchContainer">
                <label>Select Customer</label>
                <input
                  type="text"
                  className="searchInput"
                  placeholder="Search..."
                  autoFocus
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                {showDropdown && filteredLeads.length > 0 && (
                  <ul className="searchResults">
                    {filteredLeads
                      .filter((lead) => lead.isAttribued)
                      .map((lead) => (
                        <li
                          key={lead._id}
                          onClick={() => handleLeadSelect(lead._id, lead.name)}
                        >
                          <AccountCircleIcon className="searchResultIcon" />
                          <div className="searchResultDetails">
                            <span className="searchResultName">
                              {lead.name}
                            </span>
                            <span
                              className="searchResultId"
                              style={{ color: "#757575" }}
                            >
                              ID: {lead.reference}
                            </span>
                          </div>
                        </li>
                      ))}
                  </ul>
                )}
              </div>
              <label>Amount</label>
              <input type="number" name="amount" onChange={handleChange} />
            </div>
            <div className="bottomCreateDeposit">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button
                className="saveButton"
                type="submit"
                onClick={handleAddNewRefund}
              >
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRefund;
