import { useState } from "react";
import "./CreateSupplier.css";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import CountrySelect from "../../../CountrySelect/CountrySelect";

const CreateSupplier = ({ setOpen, reFetchFromTable }) => {
  const [newSupplier, setNewSupplier] = useState({
    immatriculation: "",
    name: "",
    address: "",
    rate: 0,
    country: "",
  });

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewSupplier((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreateSupplier = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/supplier`,
        newSupplier
      );
      setNewSupplier({
        immatriculation: "",
        name: "",
        address: "",
        rate: 0,
        country: "",
      });
      setOpen(false);
      reFetchFromTable();
    } catch (err) {
      console.error(
        "Erreur lors de la création de l'utilisateur:",
        err.response?.data || err.message
      );
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createSupplier" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createSupplierWrapper">
            <div className="topCreateStatus">
              <span>Create Supplier</span>
            </div>

            <div className="middleCreateSupplier">
              <div className="inputRow">
                <div className="inputGroup">
                  <label>Immatriculation</label>
                  <input
                    type="text"
                    name="immatriculation"
                    value={newSupplier.immatriculation}
                    onChange={handleChange}
                    placeholder="PG 453 656 "
                  />
                </div>
              </div>

              <div className="inputRow">
                <div className="inputGroup">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={newSupplier.name}
                    onChange={handleChange}
                  />
                </div>

                <div className="inputGroup">
                  <label>Address</label>
                  <input
                    type="text"
                    name="address"
                    value={newSupplier.address}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="fullWidthInput">
                <label>Rate</label>
                <input
                  type="number"
                  name="rate"
                  min="1"
                  max="5"
                  step="0.1"
                  value={newSupplier.rate === 0 ? "" : newSupplier.rate}
                  onChange={handleChange}
                  placeholder="Rate (1 to 5)"
                />
              </div>

              <div className="fullWidthInput">
                {" "}
                <CountrySelect
                  label="Country"
                  className="countrySelectCreateLead"
                  value={newSupplier.country}
                  onChange={handleChange}
                  name="country"
                />
              </div>
            </div>

            <div className="bottomCreateSupplier">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="saveButton" onClick={handleCreateSupplier}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSupplier;
