import "./AssignSingleLead.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import axios from "axios";
import useFetch from "../../../../../hooks/useFetch";

const AssignSingleLead = ({ setOpen, reFetchFromTable, id }) => {
  const { data: leads } = useFetch("lead");
  const { data: campaigns } = useFetch("campaign");
  const { data: users, loading, reFetch: reFetchUsers } = useFetch("user");
  const [selectedUserId, setSelectedUserId] = useState("");

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleAssignmentChange = (event) => {
    setSelectedUserId(event.target.value);
  };

  const handleSaveClick = async () => {
    if (!selectedUserId) {
      alert("Veuillez sélectionner un agent commercial.");
      return;
    }

    try {
      // Find the new assign user
      const newAssignee = users.find((user) => user._id === selectedUserId);

      // Find the corresponding lead
      const correspondingLead = leads.find((lead) => lead._id === id);

      //Add the lead on the leadAssigna array of the choosen user
      const updatedNewAssigneeLeads = [...newAssignee.leadAssign, id];

      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/user/${selectedUserId}`,
        { leadAssign: updatedNewAssigneeLeads }
      );

      //And i update the assignTo of the Lead with the new assign User
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/${correspondingLead._id}`,
        {
          assignTo: newAssignee._id,
          attributionDate: new Date(),
          isAttribued: true,
        }
      );
      reFetchUsers();
      reFetchFromTable();
      setOpen(false)
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'assignation :", error);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="assignSingleLead" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="assignSingleLeadWrapper">
            <div className="topCreateStatus">
              <span>Assign Customer</span>
            </div>
            <div className="middleAssignSingleLead">
              <div className="wrapperAssignSingleLead">
                <label>Sales Agent</label>
                <select onChange={handleAssignmentChange}>
                  <option value="">All Sales</option>
                  {users.map(
                    (user) =>
                      user.isSales && (
                        <option key={user._id} value={user._id}>
                          {user.name}
                        </option>
                      )
                  )}
                </select>
              </div>
            </div>
            <div className="bottomAssignSingleLead">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="saveButton" onClick={handleSaveClick}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignSingleLead;
