import "./PlatformPage.css";
import NavigationTabs from "../../../components/Navigation/NavigationTabs/NavigationTabs";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { SettingsTitles } from "../../../constants/navigationTitles";
import Platform from "../../../components/Settings/Platform/PlatformContainer/PlatformContainer";

const PlatformPage = () => {
  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <div className="navigationAndFilter">
            <NavigationTabs tabs={SettingsTitles} />
          </div>
        </div>
        <div className="bottomCrmPage">
          <Platform />
        </div>
      </div>
    </div>
  );
};

export default PlatformPage;
