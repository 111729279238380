import { useState } from "react";
import "./OnHold.css";
import Countdown from "./Countdown/Countdown";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
const OnHold = ({
  pTemplates,
  onHold,
  handleOpenProductInfo,
  selectedTemplate,
}) => {
  const [textOpen, setTextOpen] = useState(false);

  const handleMouseEnter = () => setTextOpen(true);
  const handleMouseLeave = () => setTextOpen(false);

  const renderProductInfo = (product) => {
    const truncateText = (text, limit) => {
      return text.length > limit ? text.substring(0, limit) + "..." : text;
    };
    const sortedTypes = [...selectedTemplate.types].sort(
      (a, b) => a.indexPresentation - b.indexPresentation
    );

    const vignetteAttributes = [];
    const regularAttributes = [];

    sortedTypes.forEach((type) => {
      const productAttribute = product.product.attributs.find(
        (attr) => attr.key === type.name
      );
      let attributeValue = productAttribute ? productAttribute.value : "";

      if (type.abbreviation) {
        attributeValue += ` ${type.abbreviation}`;
      }

      const templateAttribute = type.attributs.find(
        (attr) => attr.value === productAttribute?.value
      );
      const attributeStyle = {
        color: templateAttribute?.textColor || "#000",
        backgroundColor: templateAttribute?.backgroundColor || "#fff",
      };

      if (type.isVignette) {
        vignetteAttributes.push(
          <span
            className="asset-chip3 asset-chip-color5"
            style={attributeStyle}
          >
            {attributeValue}
          </span>
        );
      } else {
        regularAttributes.push(attributeValue);
      }
    });

    const formattedPrice = product.product.prix.toLocaleString("fr-FR");

    // Combine attributes into a single string
    const attributesString = regularAttributes.join(" - ");

    // Truncate the combined string if necessary
    const truncatedAttributesString = truncateText(attributesString, 35); // Assuming a limit of 50 characters

    const fullAttributesString = product.product.attributs
      .map((attr) => ` ${attr.value}`)
      .join(", ");

    return (
      <>
        <div className="item-safe-title">
          <span
            className="asset-ti-safe"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {truncatedAttributesString}
            <span className="question-mark">?</span>
          </span>
          {textOpen && (
            <div
              className="tooltipSidebarRight"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {fullAttributesString}
            </div>
          )}
          {vignetteAttributes}
        </div>
        <div className="item-safe-details">
          <div className="item-actions-details">
            <span className="chrono">
              <Countdown expirationDate={product.expirationDate} />
            </span>
            <span className="price-asset-safe">€ {formattedPrice}</span>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="safe-list">
      {onHold.length === 0 ? (
        <div className="messageNoNothing">
          <HistoryToggleOffIcon />
          <span>0 PRODUIT</span>
        </div>
      ) : (
        onHold.map((product, index) => (
          <div
            key={index}
            className="item-safe"
            onClick={() => handleOpenProductInfo(product.product)}
          >
            {renderProductInfo(product)}
          </div>
        ))
      )}
    </div>
  );
};

export default OnHold;
