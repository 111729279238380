import "./CommentDeleteMessage.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import axios from "axios";

const CommentDeleteMessage = ({
  setOpen,
  id,
  data,
  setComments,
  comments,
  user,
}) => {
  const storedToken = localStorage.getItem("authToken");
  
  const handleDeleteComment = async () => {
    const findComment = comments.find((c) => c._id === id);
    if (!findComment) {
      console.error("Comment not found");
      return;
    }

    const deleteCorrespondingComment = comments.filter((c) => c._id !== id);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/delete-comment/${data._id}`,
        {
          comment: { _id: id },
          user: {
            _id: user._id,
            isSuperAdmin: user.isSuperAdmin
          }
        },
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );

      if (response.data) {
        setComments(deleteCorrespondingComment);
      }

      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createStatus">
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>DEL</span>
            </div>
            <div className="middleCreateStatus">
              <span>Are you sure you want to perform this action?</span>
            </div>
            <div className="bottomCreateStatus">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="saveButton" onClick={handleDeleteComment}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentDeleteMessage;
