import "./GenerateForm.css";
import { useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import DownloadIcon from "@mui/icons-material/Download";
import NorthIcon from "@mui/icons-material/North";
import { countries } from "countries-list";
import Select from "react-select";

const GenerateForm = ({ campaignStats }) => {
  const { data: campaigns } = useFetch("campaign");
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [showScript, setShowScript] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState({ id: "" }); // Initialisez selectedCampaign avec une valeur par défaut
  const [selectedCountries, setSelectedCountries] = useState([]);

  const scriptContent = `
    <style>
    @import url('https://fonts.googleapis.com/css?family=Cinzel');

#api-register .header {
        height: 45px;
        padding: 18px 15px;
        background: #000;
        display: flex;
        flex-direction: column;
        justify-content: center;
}
#api-register .header * {
        font-family: "cinzel" ;
        color: #fff ;
}

#api-register a {
    color: #b0976d;
    text-decoration: none;
}
#api-register .header h3 {
        margin: 0;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        font-weight: 500;
}
#api-register .header h6 {
        font-size: 12px !important;
        font-weight: 300 !important;
        position: relative;
        margin: 0 !important;
        font-style: initial !important;
}
#api-register .header h6::before, #api-register .header h6::after{
        content:"";
        width: 13px;
        height: 7px;
        display: block;
        top: 4px;
        position: absolute;
        background-size: 100% !important;
}
#api-register .header h6::before {
        left: 10px;
}
#api-register .header h6::after{
        right: 10px;
}

#api-register {
        background: rgba(220, 220, 220, 0.50);
        padding: 5px;
        max-width: 314px;
        text-align: center;
        box-shadow: 0px 1px 6px #333;
}

#api-form {
        padding: 25px 15px;
 position:relative;
}
#api-register br {
        display: none;
}
#api-form p {
        margin: 0 !important;
        font-size: 12px !important;
}
#api-form p.tleft {
        text-align: left;
        margin: 3px 0 15px !important;
}
#api-form label {
        color: #000;
}

#api-form #errors-placeholder {
    padding: 10px;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgba(221,221,221,0.7);
    display:none;
}

#api-form #errors-placeholder.show {
    display: flex;
}

#api-form #errors-placeholder .close-icon {
    position: absolute;
    display: inline-block;
    top: 5px;
    right: 0px;
    height: 25px;
    width: 25px;
    background-color: #000000;
    color: var(--white-text);
    cursor: pointer;
    opacity: 0.65;
    font-size: 25px !important;
    line-height: 22px !important;
    text-align: center;
}

#api-form #errors-placeholder .close-icon:hover {
    opacity: 1;
}


#errors-placeholder p, #errors-placeholder span {
    font-size: 20px !important;
    line-height: 29px !important;
    color: red !important;
    font-style: initial;
}

#api-register input {
    width: 100%;
    height: 38px;
    font-size: 13px;
    padding: 2px 15px;
    border: none;
    margin-bottom: 6px;
    background: #fff;
}
#api-register input:hover {
    background: #f9f9f9;
}
#api-register input:focus {
    background: #f6f6f6;
}

#api-form input.prenom, #api-form input.nom {
    width: calc(50% - 2.5px);
    float: left;
    margin-right: 5px;
}
#api-form input.nom {
    margin-right: 0;
}
#api-form input[type="checkbox"] {
    width: auto;
    height: auto;
    margin: 0 5px 0 0;
    cursor: pointer;
}

#api-register input[type="submit"] {
    font-family: "cizel";
    max-width: 214px;
    cursor: pointer;
    font-size: 22px !important;
    font-weight: 400;
    height: 39px !important;
    margin: 0 0;
    text-transform: uppercase;
    background: #b0976d !important;
    margin-top:20px;
}
#api-register input[type="submit"]:hover,
#api-register input[type="submit"]:focus {
    background: #856e47 !important;
}
#api-form input.disable,
#api-form input.disable:hover,
#api-form input.disable:focus {
    opacity:0.5;
    cursor:not-allowed;
    background: #b0976d !important;
}

#api-success {
    height: 260px;
    display: flex;
    padding: 15px;
}
#api-success * {
    font-size: 21px !important;
    line-height: 26px;
    font-weight: 600;
    color: #b0976d;
    margin: auto;
}
</style>

<script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>
<div id="api-register">
    <div class="header">
        <h3>Inscrivez-vous</h3>
        <h6>Accédez à la plateforme de vente</h6>
    </div>
    <form id="api-form">
        <input class="prenom" name="firstname" type="text" placeholder="Prénom"/>
        <input class="nom" name="lastname" type="text" placeholder="Nom"/>
        <input name="email" type="email" placeholder="Email"/>
        <input name="phone" type="text" placeholder="Téléphone" />
        ${
          selectedCountries.length > 1
            ? `
        <select name="country">
          ${selectedCountries
            .map(
              (country) =>
                `<option value="${country.value}">${country.label}</option>`
            )
            .join("")}
        </select>`
            : `<input name="country" type="hidden" value="${selectedCountries[0]?.value}" />`
        }
        <input name="campaign" type="hidden" value="${
          selectedCampaign && selectedCampaign.id
        }"/>
        <br>
        <input type="submit" value="Inscription" name="submit">
        <div id="errors-placeholder">
            <div class="close-icon">×</div>
            <div class="errors-msg"></div>
        </div>
    </form>
</div>

<script type="text/javascript">
    const SUCCESS_MSG = {
        fr: "<span class='fr'>Votre inscription a été enregistrée avec succès. Nous vous contacterons dès qu'elle sera validée pas nos services.</span>",
        en: '<span class="en">Your registration has been registered with success. We will contact you as soon as it is validated by us.</span>',
        ch: '<span class="ch">您的注册已成功注册。 我们会在验证后立即与您联系。</span>',
        ru: '<span class="ru">Ваша регистрация зарегистрирована с успехом. Мы свяжемся с вами, как только он будет подтвержден нами.</span>'
    }

    const EMAIL_ERROR = {
        fr: "<span class='fr'>L'adresse email est déjà enregistrée</span>",
        en: '<span class="en">The email address is already registered.</span>',
        ch: '<span class="ch">电子邮件地址已经注册。</span>',
        ru: '<span class="ru">Адрес электронной почты уже зарегистрирован.</span>'
    }

    const FIELDS_ERROR = {
        fr: '<span class="fr">Les informations suivantes sont manquantes ou invalides</span>',
        en: '<span class="en">The following information is missing or invalid.</span>',
        ch: '<span class="ch">以下信息缺失或无效。</span>',
        ru: '<span class="ru">Следующая информация отсутствует или недействительна.</span>'
    }

    const FIELDS_NAMES = {
        lastname : {
            fr: '<span class="fr">Prénom</span>',
            en: '<span class="en">Last name</span>',
            ch: '<span class="ch">名字</span>',
            ru: '<span class="ru">имя</span>'
        },
        firstname : {
            fr: '<span class="fr">Nom</span>',
            en: '<span class="en">First name</span>',
            ch: '<span class="ch">名</span>',
            ru: '<span class="ru">фамилия</span>'
        },
        email : {
            fr: '<span class="fr">Email</span>',
            en: '<span class="en">Email</span>',
            ch: '<span class="ch">电子邮件</span>',
            ru: '<span class="ru">электронная почта</span>'
        },
        phone : {
            fr: '<span class="fr">Téléphone</span> ',
            en: '<span class="en">Phone</span>',
            ch: '<span class="ch">电话</span>',
            ru: '<span class="ru">телефон</span>'
        }
    }

    document.addEventListener("DOMContentLoaded", () => {
      document.getElementById("api-form").addEventListener("submit", (e) => {
          e.preventDefault();
          let locale = navigator.language || navigator.userLanguage;
          let lang = locale.split('-')[0];

          let data = {
            firstname: document.querySelector('#api-form input[name="firstname"]').value,
            lastname: document.querySelector('#api-form input[name="lastname"]').value,
            email: document.querySelector('#api-form input[name="email"]').value,
            phone: document.querySelector('#api-form input[name="phone"]').value,
            country: document.querySelector('#api-form select[name="country"]') ? document.querySelector('#api-form select[name="country"]').value : document.querySelector('#api-form input[name="country"]').value,
            campaign: document.querySelector('#api-form input[name="campaign"]').value
        };

          axios.post('${
            process.env.REACT_APP_SERVER_URL
          }/api/lead/external', data)
              .then((response) => {
                  const results = response.data;

                  if(results.status == 'ok'){
                      document.getElementById('api-form').innerHTML = '<div id="api-success">' + SUCCESS_MSG[lang] + '</div>';
                  } else if(results.status == 'error'){
                      let errorMessage = '<p>' + FIELDS_ERROR[lang] + ': ';
                      if(results.hasOwnProperty('missingFields')){
                          const missingTrad = results.missingFields.map(field => FIELDS_NAMES[field][lang]);
                          errorMessage += missingTrad.join(', ') + '</p>';
                      }

                      if(results.hasOwnProperty('emailExists') && results.emailExists){
                          errorMessage += '<p>' + EMAIL_ERROR[lang] + '</p>';
                      }

                      document.getElementById('errors-placeholder').innerHTML = errorMessage;
                      document.getElementById('errors-placeholder').classList.add('show');
                  }
              })
              .catch((error) => {
                  console.error('Error submitting form:', error);
                  alert('Internal error, please retry later');
              });

          document.querySelector('#api-form #errors-placeholder .close-icon').addEventListener('click', () => {
              document.getElementById('errors-placeholder').classList.remove('show');
          });
      });
  });
</script>

      `;

  const handleCopy = () => {
    // Créez une référence au textarea
    const textarea = document.querySelector(".scriptTextarea");

    // Sélectionnez le texte du textarea
    textarea.select();
    textarea.setSelectionRange(0, 99999); // Pour les appareils mobiles

    // Exécutez la commande de copie
    try {
      document.execCommand("copy");
      // Vous pouvez ajouter ici une notification discrète si nécessaire
    } catch (err) {
      // Gérez l'erreur silencieusement ou loggez-la si nécessaire
    }
    setShowCopyMessage(true);
    setTimeout(() => setShowCopyMessage(false), 2000); // Cache la notification après 5 secondes
    // Désélectionnez le texte et retirez le focus
    textarea.blur();
    window.getSelection().removeAllRanges(); // Enlève la sélection visuelle
  };
  const handleCopyCampaignNumber = () => {
    const campaignId = selectedCampaign && selectedCampaign.id;

    navigator.clipboard
      .writeText(campaignId)
      .then(() => {
        setShowCopyMessage(true);
        setTimeout(() => setShowCopyMessage(false), 2000);
      })
      .catch((error) => {
        console.error("Error copying campaign ID to clipboard: ", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedCampaign((prev) => {
      return { ...prev, [name]: value };
    });
    if (name === "id" && value === "") {
      setShowScript(false);
    }
  };

  const openScript = () => {
    if (!selectedCampaign.id) {
      setShowScript(false);
    } else {
      setShowScript(true);
    }
  };

  const countryOptions = Object.keys(countries).map((code) => ({
    value: code,
    label: countries[code].name,
  }));

  return (
    <>
      <div className="leftElementsTableGenericForm">
        <select onChange={handleChange} name="id" value={selectedCampaign.id}>
          <option value="">Select Campaign</option>
          {Array.isArray(campaignStats) &&
            campaignStats.map((c) => (
              <option key={c.campaignId} value={c.campaignId}>
                {c.campaignTitle}
              </option>
            ))}
        </select>
        <Select
          classNamePrefix="react-select"
          options={countryOptions}
          onChange={setSelectedCountries}
          isMulti
          placeholder="Select countries"
        />

        <button
          onClick={openScript}
          className={
            selectedCampaign && selectedCampaign.id
              ? "buttonCampaign"
              : "buttonCampaignNotSelected"
          }
        >
          <DownloadIcon />
          <span>Generate Form</span>
        </button>
      </div>
      <div className="textareaContainer">
        {showScript ? (
          <div className="textareaGenericForm">
            <button
              className="copyButton copyButton2"
              onClick={handleCopyCampaignNumber}
            >
              Copy only the campaign number
            </button>
            <button className="copyButton copyButton1" onClick={handleCopy}>
              Copy
            </button>
            <textarea
              className="scriptTextarea"
              value={scriptContent}
              readOnly
            />
          </div>
        ) : (
          <div className="noScriptMessage">
            <NorthIcon />
            SELECT A CAMPAIGN <br />
            TO GENERATE A FORM
          </div>
        )}
        {showCopyMessage && (
          <div className="copyNotification">Copy to Clipboard</div>
        )}
      </div>
    </>
  );
};

export default GenerateForm;
