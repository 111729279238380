import { useState } from "react";
import "./Rebougths.css";
import moment from "moment";
import FollowPayment from "../FollowPayment/FollowPayment";
import DeleteMessage from "./DeleteMessage/DeleteMessage";
import AcceptMessage from "./AcceptMessage/AcceptMessage";

const Rebougths = ({ currentData, reFetchFromTable }) => {
  const [openFollowPayment, setOpenFollowPayment] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [openAcceptMessage, setOpenAcceptMessage] = useState(false);

  const handleOpenFollowPayment = (data) => {
    setOpenFollowPayment(true);
    setSelectedData(data);
  };

  const handleOpenDeleteMessage = (data) => {
    setOpenDeleteMessage(true);
    setSelectedData(data);
  };

  const handleOpenAcceptMessage = (data) => {
    setOpenAcceptMessage(true);
    setSelectedData(data);
  };

  return (
    <>
      <div className="containerDatatableHistory">
        <div className="datatableHistory">
          <table className="tableDataHistory">
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="headerWithIcon">Reference</div>
                </th>
                <th>
                  <div className="headerWithIcon">Price</div>
                </th>
                <th>
                  <div className="headerWithIcon">Offer</div>
                </th>
                <th>
                  <div className="headerWithIcon">Name</div>
                </th>
                <th>
                  <div className="headerWithIcon">Payments</div>
                </th>
                <th>
                  <div className="headerWithIcon">Date</div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(currentData.sold) &&
              currentData.sold.length > 0 ? (
                currentData.sold.map((product) => {
                  const totalPaid = product.amountPay.reduce(
                    (acc, cur) => acc + cur.amount,
                    0
                  );
                  const progress = (totalPaid / product.product.prix) * 100;

                  return (
                    <tr key={product.product._id}>
                      <td style={{ color: "var(--primary-color)" }}>
                        {product.product.reference}
                      </td>
                      <td>
                        {(product.product.prix ?? 0).toLocaleString("en-US")}
                      </td>
                      <td>
                        {(product.salesPrice ?? 0).toLocaleString("en-US")}
                      </td>
                      <td>{product.companyName}</td>
                      <td onClick={() => handleOpenFollowPayment(product)}>
                        <div className="catalog-payments-progress">
                          <div
                            className="catalog-payments-progress-filled"
                            style={{
                              width: `${progress}%`,
                              backgroundColor: "#7ec5cf",
                            }}
                          ></div>
                        </div>
                      </td>
                      <td>
                        {moment(product.onHoldDate).format("DD/MM/YYYY HH:mm")}
                      </td>
                      {product.isAccepted ? (
                        <td>
                          <span>
                            {moment(product.confirmationDate).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </span>
                          <span
                            className="hover-effect"
                            onClick={() =>
                              handleOpenDeleteMessage(product.product._id)
                            }
                          >
                            cancel
                          </span>
                        </td>
                      ) : (
                        <td>
                          <span
                            className="hover-effect"
                            onClick={() =>
                              handleOpenDeleteMessage(product.product._id)
                            }
                          >
                            cancel
                          </span>
                          <span
                            className="hover-effect"
                            onClick={() => handleOpenAcceptMessage(product)}
                          >
                            confirm
                          </span>
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {openFollowPayment && (
        <FollowPayment
          setOpen={setOpenFollowPayment}
          currentData={currentData}
          selectedData={selectedData}
          reFetchFromTable={reFetchFromTable}
          type={"sold"}
          url={"Sold"}
        />
      )}
      {openDeleteMessage && (
        <DeleteMessage
          setOpen={setOpenDeleteMessage}
          selectedData={selectedData}
          reFetchFromTable={reFetchFromTable}
          currentData={currentData}
        />
      )}
      {openAcceptMessage && (
        <AcceptMessage
          setOpen={setOpenAcceptMessage}
          selectedData={selectedData}
          reFetchFromTable={reFetchFromTable}
          currentData={currentData}
        />
      )}
    </>
  );
};

export default Rebougths;
