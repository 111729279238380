import "./ProfileInfo.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PasswordIcon from "@mui/icons-material/Password";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Invoices from "./Invoices/Invoices";
import BalanceHistory from "./BalanceHistory/BalanceHistory";
import LeadInfo from "./LeadInfo/LeadInfo";
import PasswordChange from "./PasswordChange/PasswordChange";
const ProfileInfo = ({
  setOpen,
  connectedLead,
  sold,
  onSafe,
  selectedTemplate,
  refund,
}) => {
  const [activeTab, setActiveTab] = useState("Account");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handlePopupClick = (e) => {
    e.stopPropagation();
  };

  const getInitials = (fullName) => {
    const names = fullName.split(" ");
    if (names.length > 1) {
      return (
        names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase()
      );
    } else {
      return fullName.charAt(0).toUpperCase();
    }
  };

  const leadInitials = connectedLead ? getInitials(connectedLead.name) : "NA";

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="profileInfo" onClick={handlePopupClick}>
          <div className="closeIconProfileInfo">
            <CloseIcon
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
          <div className="profileInfoWrapper">
            <div className="topProfileInfo">
              <span>Profile</span>
            </div>

            <div className="middleProfileInfo">
              <div className="middleProfileInfoWithPP">
                <div className="profilPopUp">{leadInitials}</div>
                <div className="middleProfileInfoLeft">
                  <div
                    className="middleProfileInfoLeftItem"
                    onClick={() => handleTabClick("Account")}
                  >
                    <AccountCircleIcon />
                    <span>My account</span>
                  </div>
                  <div
                    className="middleProfileInfoLeftItem"
                    onClick={() => handleTabClick("Invoice")}
                  >
                    <ReceiptIcon />
                    <span>Invoices</span>
                  </div>
                  <div
                    className="middleProfileInfoLeftItem"
                    onClick={() => handleTabClick("Balance")}
                  >
                    <AccountBalanceIcon />
                    <span> History</span>
                  </div>
                  <div
                    className="middleProfileInfoLeftItem"
                    onClick={() => handleTabClick("Password")}
                  >
                    <PasswordIcon />
                    <span>Password</span>
                  </div>
                </div>
              </div>
              <div className="middleProfileInfoRight">
                {activeTab === "Account" && (
                  <>
                    <span className="titleProfileinfoPopup">My Account -</span>
                    <LeadInfo connectedLead={connectedLead} />
                  </>
                )}
                {activeTab === "Invoice" && (
                  <>
                    <span className="titleProfileinfoPopup">Invoices -</span>
                    <Invoices
                      sold={sold}
                      onSafe={onSafe}
                      selectedTemplate={selectedTemplate}
                      connectedLead={connectedLead}
                    />
                  </>
                )}
                {activeTab === "Balance" && (
                  <>
                    <span className="titleProfileinfoPopup">
                      Balance History -
                    </span>
                    <BalanceHistory
                      connectedLead={connectedLead}
                      sold={sold}
                      onSafe={onSafe}
                      refund={refund}
                    />
                  </>
                )}
                {activeTab === "Password" && (
                  <>
                    <span className="titleProfileinfoPopup">Password -</span>
                    <PasswordChange
                      connectedLead={connectedLead}
                    />
                  </>
                )}
              </div>
            </div>
            {/* <div className="bottomProfileInfo">
              <button
                className="cancelButtonProfileInfo"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </button>
              <button className="saveButtonProfileInfo">
                <CheckIcon />
                Comfirm
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileInfo;
