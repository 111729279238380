import "./EditProduct.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import useFetch from "../../../../hooks/useFetch";

const EditProduct = ({ setOpen, reFetchFromTable, selectedData }) => {
  const { data: pTemplates } = useFetch("templateProduct");
  const { data: suppliers } = useFetch("supplier");

  const [startYear, setStartYear] = useState("");
  const [yearInputs, setYearInputs] = useState([]);
  const [productDetails, setProductDetails] = useState(() => ({
    reference: selectedData.reference || "",
    description: selectedData.description || "",
    prix: selectedData.prix || "",
    attributs: selectedData.attributs || [],
    graphData: selectedData.graphData || [],
    supplier: selectedData.supplier._id || "",
    assetRarity: selectedData.assetRarity || 0,
    assetCondition: selectedData.assetCondition || 0,
    certificat: {
      name: selectedData.certificat?.name || "",
      number: selectedData.certificat?.number || "",
    },
  }));

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    setProductDetails({
      ...productDetails,
      graphData: selectedData.graphData || [],
    });
  }, [selectedData]);

  const handleYearChange = (e) => {
    setStartYear(e.target.value);
  };

  const handleNext = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = parseInt(startYear); year <= currentYear; year++) {
      years.push(year);
    }
    setYearInputs(years);

    // Ajoute des inputs vides uniquement si une nouvelle année de départ est saisie
    if (years.length > 0) {
      const newGraphData = years.map((year) => ({
        year,
        value:
          productDetails.graphData.find((data) => data.year === year)?.value ||
          0,
      }));
      setProductDetails((prevDetails) => ({
        ...prevDetails,
        graphData: newGraphData,
      }));
    }
  };

  const handleAttributeChange = (index, e) => {
    const updatedAttributes = [...productDetails.attributs];
    updatedAttributes[index].value = e.target.value;
    setProductDetails((prev) => ({ ...prev, attributs: updatedAttributes }));
  };

  const handleGraphDataChange = (year, e) => {
    const value = e.target.value;
    const updatedGraphData = productDetails.graphData.map((data) =>
      data.year === year ? { ...data, value: Number(value) } : data
    );
    setProductDetails((prev) => ({ ...prev, graphData: updatedGraphData }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("certificat.")) {
      const key = name.split(".")[1];
      setProductDetails((prev) => ({
        ...prev,
        certificat: {
          ...prev.certificat,
          [key]: value,
        },
      }));
    } else {
      setProductDetails((prev) => ({ ...prev, [name]: value }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/product/${selectedData._id}`,
        productDetails
      );
      if (response.status === 200) {
        reFetchFromTable();
        setOpen(false);
      } else {
        throw new Error("Failed to update the product");
      }
    } catch (error) {
      console.error("Error editing product:", error);
    }
  };

  if (!pTemplates.length) {
    return <div>Loading...</div>;
  }

  const template =
    pTemplates.find((t) => t._id === selectedData.templateId) || pTemplates[0];

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createProduct" onClick={handleClickInside}>
          <div className="closeIconProduct">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <form className="createProductWrapper" onSubmit={handleSubmit}>
            <div className="topCreateProduct">
              <span>Edit Product</span>
            </div>

            <div className="middleCreateProduct">
              <div className="createProductPrensentation">
                <select
                  name="supplier"
                  onChange={handleChange}
                  value={productDetails.supplier}
                >
                  <option value="">Select Supplier</option>
                  {suppliers.map((supplier) => (
                    <option key={supplier._id} value={supplier._id}>
                      {supplier.name}
                    </option>
                  ))}
                </select>
              </div>
              <input
                type="text"
                placeholder="Reference"
                name="reference"
                value={productDetails.reference}
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="Description"
                name="description"
                value={productDetails.description}
                onChange={handleChange}
              />
              <input
                type="number"
                name="assetRarity"
                min="1"
                max="5"
                step="0.1"
                value={
                  productDetails.assetRarity === 0
                    ? ""
                    : productDetails.assetRarity
                }
                onChange={handleChange}
                placeholder="Asset Rarity (1 to 5)"
              />
              <input
                type="number"
                name="assetCondition"
                min="1"
                max="5"
                step="0.1"
                value={
                  productDetails.assetCondition === 0
                    ? ""
                    : productDetails.assetCondition
                }
                onChange={handleChange}
                placeholder="Asset Condition (1 to 5)"
              />
              <input
                type="text"
                name="certificat.name"
                value={productDetails.certificat.name}
                onChange={handleChange}
                placeholder="Certificate Name"
              />
              <input
                type="text"
                name="certificat.number"
                value={productDetails.certificat.number}
                onChange={handleChange}
                placeholder="Certificate Number"
              />
              <input
                type="number"
                placeholder="Prix"
                name="prix"
                value={productDetails.prix}
                onChange={handleChange}
              />

              {template.types.map((type, index) => {
                // Trouver l'attribut correspondant dans le produit actuel
                const attribute =
                  productDetails.attributs.find(
                    (attr) => attr.key === type.name
                  ) || {};

                // Déterminer si le type a des attributs pré-définis pour utiliser un sélecteur
                if (type.attributs && type.attributs.length > 0) {
                  return (
                    <div key={index} className="createProductPrensentation">
                      <label>{type.name}</label>
                      <select
                        name={type.name}
                        value={attribute.value || ""}
                        onChange={(e) => handleAttributeChange(index, e)}
                      >
                        <option value="">Select {type.name}</option>
                        {type.attributs.map((attribut, attributIndex) => (
                          <option key={attributIndex} value={attribut.value}>
                            {attribut.value}
                          </option>
                        ))}
                      </select>
                    </div>
                  );
                } else {
                  // Choisir le type d'input basé sur la catégorie du type
                  const inputType =
                    type.typeCategory === "numeric"
                      ? "number"
                      : type.typeCategory === "UM"
                      ? "number"
                      : "text";

                  return (
                    <div key={index} className="createProductPrensentation">
                      <label>{type.name}</label>
                      <input
                        type={inputType}
                        name={type.name}
                        value={attribute.value || ""}
                        onChange={(e) => handleAttributeChange(index, e)}
                        step={inputType === "number" ? "any" : undefined}
                      />
                    </div>
                  );
                }
              })}
              <label>Graph</label>
              <input
                type="number"
                placeholder="Année de départ"
                name="startYear"
                value={startYear}
                onChange={handleYearChange}
              />
              <button type="button" onClick={handleNext}>
                Next
              </button>
              {productDetails.graphData.map((data, index) => (
                <div key={index}>
                  <label>{data.year}:</label>
                  <input
                    type="number"
                    placeholder={`Montant pour ${data.year}`}
                    value={data.value || 0}
                    onChange={(e) => handleGraphDataChange(data.year, e)}
                  />
                </div>
              ))}
            </div>

            <div className="bottomCreateProduct">
              <button
                type="button"
                className="cancelButton"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button type="submit" className="saveButton">
                <CheckIcon />
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditProduct;
