import "./BalanceHistory.css";
import moment from "moment";

const BalanceHistory = ({ sold, onSafe, refund, connectedLead }) => {
  const timelineData = [
    ...(Array.isArray(sold)
      ? sold
          .filter((s) => s.isAccepted)
          .map((s) => ({
            ...s,
            action: "Sale",
            actualDate: new Date(s.soldDate),
            displayAmount: s.salesPrice,
            reference: s.product.reference,
            type: "credit",
          }))
      : []),
    ...(Array.isArray(connectedLead.deposit)
      ? connectedLead.deposit.map((d) => ({
          ...d,
          action: "Deposit",
          actualDate: new Date(d.depositDate),
          displayAmount: d.amount,
          type: "credit",
        }))
      : []),
    ...(Array.isArray(refund)
      ? refund.map((r) => ({
          ...r,
          action: "Refund",
          actualDate: new Date(r.refundDate),
          displayAmount: r.amount,
          type: "debit",
        }))
      : []),
  ];

  // Sort transactions by date in descending order
  timelineData.sort((a, b) => b.actualDate - a.actualDate);

  // Reverse to start balance calculation from the oldest
  timelineData.reverse();

  let runningBalance = 0;
  const processedData = timelineData.map((item) => {
    const amount =
      item.type === "credit" ? item.displayAmount : -item.displayAmount;
    runningBalance += amount;
    return {
      ...item,
      displayAmount: `${
        item.type === "credit" ? "+ " : "- "
      }${item.displayAmount.toLocaleString("en-US")} €`,
      balance: runningBalance.toLocaleString("en-US") + " €",
    };
  });

  // Reverse again to display the most recent transaction at the top
  processedData.reverse();

  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <table className="tableDataProfileInfo">
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="headerWithIcon">Date </div>
                </th>
                <th>
                  <div className="headerWithIcon">Type</div>
                </th>
                <th>
                  <div className="headerWithIcon">Info</div>
                </th>
                <th>
                  <div className="headerWithIcon">Amount</div>
                </th>
                <th>
                  <div className="headerWithIcon">Balance</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {processedData.map((item, index) => (
                <tr key={index}>
                  <td>{moment(item.actualDate).format("DD/MM/YYYY")}</td>
                  <td>{item.action}</td>
                  <td>{item.reference}</td>
                  <td>{item.displayAmount}</td>
                  <td>{item.balance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BalanceHistory;
