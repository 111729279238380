//PopupCustomerContainer.js
import "./PopupCustomerContainer.css";
import CloseIcon from "@mui/icons-material/Close";
import LeftSideInfo from "../LeftSideInfo/LeftSideInfo";
import TopTab from "../RightSide/TopTab/TopTab";
import { useLeadDetails } from '../../../../hooks/useLeadDetails';

const PopupCustomerContainer = ({
  setOpen,
  reFetchFromTable,
  leadId,
}) => {
  const { data: fullLead, isLoading, isError, refetch } = useLeadDetails(leadId);

  if (isLoading) return <div>Loading details...</div>;
  if (isError) return <div>Error loading lead details</div>;
  if (!fullLead) return <div>No lead found</div>;
  return (
    <>
      <div className="overlayPopupCustomer" onClick={() => setOpen(false)}></div>
      <div className="popupContainer">
        <div className="closeIconPopupCustomer" onClick={() => setOpen(false)}>
          <CloseIcon />
        </div>
        <div className="popupWrapper">
          <div className="popupLeftSide">
            <LeftSideInfo currentData={fullLead} reFetchFromTable={reFetchFromTable} />
          </div>
          <div className="popupRigthSide">
            <TopTab currentData={fullLead} reFetchFromTable={reFetchFromTable} refetch={refetch} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupCustomerContainer;
