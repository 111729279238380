import "./PaymentPage.css";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import NavigationTabs from "../../../components/Navigation/NavigationTabs/NavigationTabs";
import { SettingsTitles } from "../../../constants/navigationTitles";
import SettingsTable from "../../../components/Settings/SettingsTable/SettingsTable";
import useFetch from "../../../hooks/useFetch";
import { useState } from "react";
import CreatePayment from "../../../components/Settings/Payment/CreatePayment/CreatePayment";
import DeleteMessage from "../../../components/DeleteMessage/DeleteMessage";
import EditPayment from "../../../components/Settings/Payment/EditPayment/EditPayment";

const PaymentPage = () => {
  const { data: payments, reFetch } = useFetch("payment");
  const [showPaymentCreatePopup, setShowPaymentCreatePopup] = useState(false);
  const [showDeletePaymentMessage, setShowDeletePaymentMessage] =
    useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const [showPaymentEditPopup, setShowPaymentEditPopup] = useState(false);

  const openCreatePaymentPopup = () => setShowPaymentCreatePopup(true);
  const openDeletePaymentPopup = (paymentId) => {
    setSelectedPaymentId(paymentId);
    setShowDeletePaymentMessage(true);
  };

  const openEditPaymentPopup = (paymentId) => {
    setSelectedPaymentId(paymentId);
    setShowPaymentEditPopup(true);
  };

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <div className="navigationAndFilter">
            <NavigationTabs tabs={SettingsTitles} />
          </div>
        </div>
        <div className="bottomCrmPage">
          <SettingsTable
            data={payments}
            reFetch={reFetch}
            onAddClick={openCreatePaymentPopup}
            onDeleteClick={openDeletePaymentPopup}
            onEditClick={openEditPaymentPopup}
          />

          {showPaymentCreatePopup && (
            <CreatePayment
              setOpen={setShowPaymentCreatePopup}
              reFetchFromTable={reFetch}
            />
          )}
          {showPaymentEditPopup && (
            <EditPayment
              setOpen={setShowPaymentEditPopup}
              reFetchFromTable={reFetch}
              id={selectedPaymentId}
            />
          )}
          {showDeletePaymentMessage && (
            <DeleteMessage
              setOpen={setShowDeletePaymentMessage}
              id={selectedPaymentId}
              reFetchFromTable={reFetch}
              endPoint={"payment"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
