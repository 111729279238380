import "./SuppliersPage.css";
import NavigationTabs from "../../../components/Navigation/NavigationTabs/NavigationTabs";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { CatalogueTitles } from "../../../constants/navigationTitles";
import SuppliersTable from "../../../components/Catalogue/Suppliers/SuppliersTable/SuppliersTable";

const SuppliersPage = () => {
  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabs tabs={CatalogueTitles} />
        </div>
        <div className="bottomCrmPage">
          <SuppliersTable />
        </div>
      </div>
    </div>
  );
};

export default SuppliersPage;
