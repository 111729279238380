import "./DeleteMessage.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import { useOpenTab } from "../../../../../../../context/OpenTabContext";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useState } from "react";

const DeleteMessage = ({ setOpen, currentData, selectedData }) => {
  const { handleOpenTab } = useOpenTab();
  const [isLoading, setIsLoading] = useState(false);

  const handlePopupClick = (e) => {
    e.stopPropagation();
  };

  const handleRemoveFromSold = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const productId = selectedData;

      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/leadPlatform/${currentData._id}/deleteFromSold`,
        { productId }
      );

      if (response.data) {
        setOpen(false);
        handleOpenTab("Safe");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="overlay" onClick={() => setOpen(false)}>
      <div className="createStatus" onClick={handlePopupClick}>
        <div className="closeIconStatus">
          <CloseIcon
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
            }}
          />
        </div>
        {isLoading ? (
          <div className="loading-container">
            <span>Cancel...</span>
            <div className="loading-spinner">
              <PropagateLoader color="var(--primary-color)" />
            </div>
          </div>
        ) : (
          <>
            <div className="createStatusWrapper">
              <div className="topCreateStatus">
                <span>DEL</span>
              </div>
              <div className="middleCreateStatus">
                <span>Are you sure you want to perform this action?</span>
              </div>
              <div className="bottomCreateStatus">
                <button
                  className="cancelButton"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(false);
                  }}
                >
                  Cancel
                </button>
                <button className="saveButton" onClick={handleRemoveFromSold}>
                  <CheckIcon />
                  Confirm
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DeleteMessage;
