import axios from "axios";
import PendingTable from "../../../components/Banking/Pending/PendingTable/PendingTable";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { BankingTitles } from "../../../constants/navigationTitles";
import "./PendingPage.css";
import { useCallback, useContext, useEffect, useState } from "react";
import NavigationTabsAndFilterDate from "../../../components/Navigation/NavigationTabsAndFilterDate/NavigationTabsAndFilterDate";
import { useSocket } from "../../../context/SocketContext";
import { useData } from "../../../context/DataContext";

const PendingPage = () => {
  const [pending, setPending] = useState([]);
  const { allLeads, reFetchAllLead, campaigns, users, reFetchUsers } =
    useData();
  const { socket } = useSocket();
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate,
    endDate,
  });

  const fetchAllPending = async () => {
    const queryParams = new URLSearchParams({
      startDate: selectedDateRange.startDate
        ? selectedDateRange.startDate.toISOString()
        : null,
      endDate: selectedDateRange.endDate
        ? selectedDateRange.endDate.toISOString()
        : null,
    }).toString();
    try {
      const storedToken = localStorage.getItem("authToken");

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/banking/pending?${queryParams}`,
        { headers: { Authorization: `Bearer ${storedToken}` } }
      );
      setPending(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchAllPending();
  }, [selectedDateRange]);

  useEffect(() => {
    if (socket == null) return;

    const handleRefresh = () => {
      fetchAllPending();
    };
    socket.on("refreshDataPlatform", handleRefresh);

    return () => {
      socket.off("refreshDataPlatform", handleRefresh);
    };
  }, [socket]);

  useEffect(() => {
    if (socket == null) return;

    const handleRefresh = () => {
      fetchAllPending();
    };
    socket.on("refreshData", handleRefresh);

    return () => {
      socket.off("refreshData", handleRefresh);
    };
  }, [socket]);

  const handleFilteredDataChange = useCallback(({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  }, []);

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabsAndFilterDate
            onFilteredDataChange={handleFilteredDataChange}
            tabs={BankingTitles}
          />
        </div>
        <div className="bottomCrmPage">
          <PendingTable
            data={pending}
            reFetch={fetchAllPending}
            allLeads={allLeads}
            reFetchLead={reFetchAllLead}
            users={users}
            reFetchUsers={reFetchUsers}
            campaigns={campaigns}
          />
        </div>
      </div>
    </div>
  );
};

export default PendingPage;
