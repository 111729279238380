import React, { useState } from "react";
import ChartCampaign from "../ChartCampaign/ChartCampaign";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import CampaignList from "../CampaignList/CampaignList"
import EditCampaign from "../EditCampaign/EditCampaign";
import DeleteMessage from "../../../DeleteMessage/DeleteMessage";
import AddIcon from "@mui/icons-material/Add";
import "./OverviewCampaign.css"
import CreateCampaign from "../CreateCampaign/CreateCampaign";
const OverviewCampaign = ({ campaignStats, reFetch }) => {
  const [selectedDataType, setSelectedDataType] = useState("Leads");
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [showCampaignEditPopup, setShowCampaignEditPopup] = useState(false);
  const [showCampaignCreatePopup, setShowCampaignCreatePopup] = useState(false);
  const [showDeleteCampaignMessage, setShowDeleteCampaignMessage] =
    useState(false);
  const handleDataTypeChange = (e) => {
    setSelectedDataType(e.target.value);
  };

  const getGraphDataValue = (campaign, dataType) => {
    switch (dataType) {
      case "Leads":
        return campaign.numberOfLeads;
      case "Conversions":
        return (campaign.numberOfFTDS / campaign.numberOfLeads) * 100;
      case "Deposits":
        return campaign.numberOfDeposit;
      case "FTDS":
        return campaign.numberOfFTDS;
      case "Profits":
        return campaign.profit;
      default:
        return 0;
    }
  };

  const graphData = campaignStats.map((campaign) => ({
    name: campaign.campaignTitle,
    value: getGraphDataValue(campaign, selectedDataType),
  }));

  const openEditCampaignPopup = (campaignId) => {
    setSelectedCampaignId(campaignId);
    setShowCampaignEditPopup(true);
  };

  const onDeleteClick = (id) => {
    setSelectedCampaignId(id);
    setShowDeleteCampaignMessage(true);
  };

  return (
    <>
      <div className="salesPerfomancePageBottomLeftTopItemWithSelect">
        <div className="salesPerfomancePageBottomLeftTopItemWithSelectLeft">
          <SignalCellularAltIcon />
          <span>Overview</span>
        </div>
        <div className="overviewCampaignSelect">
          <select onChange={handleDataTypeChange}>
            <option value="Leads">Leads</option>
            <option value="Profits">Profit</option>
            <option value="">TO</option>
            <option value="FTDS">FTDS</option>
            <option value="Conversions">Conversion</option>
            <option value="Deposits">Deposits</option>
          </select>
          <button onClick={() => setShowCampaignCreatePopup(true)}>
            <AddIcon />
            <span>Add</span>
          </button>
        </div>
      </div>
      <ChartCampaign campaignStats={graphData} />
      <div className="rankingWrapper">
        <CampaignList
          campaignStats={campaignStats}
          onEditClick={openEditCampaignPopup}
          onDeleteClick={onDeleteClick}
        />
      </div>
      {showDeleteCampaignMessage && (
        <DeleteMessage
          setOpen={setShowDeleteCampaignMessage}
          id={selectedCampaignId}
          reFetchFromTable={reFetch}
          endPoint={"campaign"}
        />
      )}

      {showCampaignEditPopup && (
        <EditCampaign
          setOpen={setShowCampaignEditPopup}
          reFetchFromTable={reFetch}
          id={selectedCampaignId}
        />
      )}
      {showCampaignCreatePopup && (
        <CreateCampaign
          setOpen={setShowCampaignCreatePopup}
          reFetchFromTable={reFetch}
        />
      )}
    </>
  );
};

export default OverviewCampaign;
