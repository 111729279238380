import "./AcceptMessage.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import { useOpenTab } from "../../../../../../../context/OpenTabContext";
import { useState } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";

const AcceptMessage = ({
  setOpen,
  currentData,
  reFetchFromTable,
  selectedData,
}) => {
  const { handleOpenTab } = useOpenTab();
  const [isLoading, setIsLoading] = useState(false);

  const handlePopupClick = (e) => {
    e.stopPropagation();
  };

  const acceptSoldProduct = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const soldId = selectedData._id;
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/leadPlatform/${currentData._id}/acceptSoldProduct`,
        { soldId }
      );

      if (response.data) {
        const message = `Produit vendu : ${selectedData.salesPrice?.toLocaleString(
          "fr-FR"
        )} € ont été ajoutés à votre solde.`;
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/notification`,
          {
            typeNotification: "vendu",
            lead: currentData._id,
            text: message,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );

        handleOpenTab("Sold");
        setOpen(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="overlay" onClick={() => setOpen(false)}>
      <div className="createStatus" onClick={handlePopupClick}>
        <div className="closeIconStatus">
          <CloseIcon
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
            }}
          />
        </div>
        {isLoading ? (
          <div className="loading-container">
            <span>Confirmation...</span>
            <div className="loading-spinner">
              <PropagateLoader color="var(--primary-color)" />
            </div>
          </div>
        ) : (
          <>
            <div className="createStatusWrapper">
              <div className="topCreateStatus">
                <span>ACCEPT</span>
              </div>
              <div className="middleCreateStatus">
                <span>Are you sure you want to perform this action?</span>
              </div>
              <div className="bottomCreateStatus">
                <button
                  className="cancelButton"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(false);
                  }}
                >
                  Cancel
                </button>
                <button className="saveButton" onClick={acceptSoldProduct}>
                  <CheckIcon />
                  Confirm
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AcceptMessage;
