import React, { useState, useEffect } from "react";
import authServiceLead from "../services/auth.LeadService";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";

const socket = io(process.env.REACT_APP_SERVER_URL);

const AuthContextLead = React.createContext();

function AuthLeadProviderWrapper(props) {
  const [isLoggedInPlateforme, setIsLoggedInPlateforme] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lead, setLead] = useState(null);
  const navigate = useNavigate();
  const storeToken = (token) => {
    localStorage.setItem("authTokenLead", token);
  };

  const authenticateLead = async () => {
    // 1. Récupérer le token dans le localStorage
    const storedToken = localStorage.getItem("authTokenLead");

    if (storedToken) {
      try {
        const response = await authServiceLead.verify();
        const leadData = response.data;

        setIsLoggedInPlateforme(true);
        setIsLoading(false);
        setLead(leadData);
      } catch (error) {
        // 4. Si erreur (token invalide / 401 / etc.), on repasse tout à false
        console.error("Error during lead authentication:", error);
        setIsLoggedInPlateforme(false);
        setIsLoading(false);
        setLead(null);
      }
    } else {
      // 4 bis. Si aucun token stocké
      setIsLoggedInPlateforme(false);
      setIsLoading(false);
      setLead(null);
    }
  };

  const removeToken = () => {
    localStorage.removeItem("authTokenLead");
  };

  const logOutLead = () => {
    // Émettre l'événement de déconnexion via socket
    if (lead && lead.email) {
      socket.emit("leadDisconnected", { email: lead.email });
    }

    // Supprimer le token et mettre à jour l'état
    removeToken();
    authenticateLead();
    navigate("/login");
  };

  const logOutLeadNoRedirect = () => {
    // Émettre l'événement de déconnexion via socket
    if (lead && lead.email) {
      socket.emit("leadDisconnected", { email: lead.email });
    }

    // Supprimer le token et mettre à jour l'état
    removeToken();
    authenticateLead();
  };

  useEffect(() => {
    authenticateLead();
  }, []);

  return (
    <AuthContextLead.Provider
      value={{
        isLoggedInPlateforme,
        isLoading,
        lead,
        socket,
        storeToken,
        authenticateLead,
        logOutLead,
        logOutLeadNoRedirect,
      }}
    >
      {props.children}
    </AuthContextLead.Provider>
  );
}

export { AuthLeadProviderWrapper, AuthContextLead };
