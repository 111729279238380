//useLeadDetails.js
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchLeadDetails = async (id) => {
  const storedToken = localStorage.getItem("authToken");
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/api/lead/${id}/details`,
    { headers: { Authorization: `Bearer ${storedToken}` } }
  );
  return response.data;
};

export const useLeadDetails = (id) => {
  return useQuery({
    queryKey: ["leadDetails", id],
    queryFn: () => fetchLeadDetails(id),
    enabled: !!id,
    refetchOnWindowFocus: false,
    staleTime: 10 * 1000,
  });
};
