import "./LeadPage.css";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { CRMtitles } from "../../../constants/navigationTitles";
import { useState, useCallback, useContext, useEffect } from "react";
import NavigationTabsAndFilterDate from "../../../components/Navigation/NavigationTabsAndFilterDate/NavigationTabsAndFilterDate";
import { AuthContext } from "../../../context/auth.context";
import axios from "axios";
import CustomerTable from "../../../components/CRM/Customers/CustomerTable/CustomerTable";
import { useSocket } from "../../../context/SocketContext";
import useFetch from "../../../hooks/useFetch";
import { useData } from "../../../context/DataContext";
import LeadSocketHandler from "../../../components/LeadSocketHandler";
import { useLeads } from "../../../hooks/useLeads";

const LeadPage = () => {
  const { campaigns, status, users, reFetchUsers, allLeads, reFetchAllLead } =
    useData();
  const { user } = useContext(AuthContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState({
    user: "All Sales",
    campaign: "All Campaign",
    status: "All Status",
    country: "All Countries",
  });

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const { data, isLoading, isError, refetch } = useLeads(
    currentPage,
    selectedFilters,
    selectedDateRange,
    user
  );

  const handleFilteredDataChange = useCallback(({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  }, []);

  // if (isLoading) return <div>Chargement...</div>;
  if (isError) return <div>Erreur lors du chargement</div>;

  const leads = data?.leads || [];
  const totalLeads = data?.total || 0;

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabsAndFilterDate
            tabs={CRMtitles}
            onFilteredDataChange={handleFilteredDataChange}
          />
        </div>
        <div className="bottomCrmPage">
          <CustomerTable
            loading={isLoading && !data} 
            isFetching={isLoading && data} 
            data={leads}
            reFetch={refetch}
            currentPage={currentPage}
            setPage={setCurrentPage}
            totalLeads={totalLeads}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            userId={user._id}
            campaigns={campaigns}
            status={status}
            users={users}
            reFetchUsers={reFetchUsers}
            allLeads={allLeads}
            reFetchFromTable={reFetchAllLead}
          />
        </div>
      </div>
    </div>
  );
};

export default LeadPage;
