// FactureUtils.js
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import useFetch from "./hooks/useFetch";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generateInvoicePDF = (
  product,
  company,
  formattedDate,
  invoiceNumber,
  selectedTemplate,
  connectedLead
) => {
  const currentYear = new Date().getFullYear();

  const renderProductInfo = (product) => {
    if (!selectedTemplate) return null;

    const sortedTypes = [...selectedTemplate.types].sort(
      (a, b) => a.indexPresentation - b.indexPresentation
    );

    const regularAttributes = sortedTypes
      .map((type) => {
        const attribute = product.attributs.find(
          (attr) => attr.key === type.name
        );
        return attribute
          ? `${attribute.value}${
              type.abbreviation ? ` ${type.abbreviation}` : ""
            }`
          : "";
      })
      .filter(Boolean)
      .join(" - ");

    return regularAttributes;
  };

  const documentDefinition = {
    pageSize: "A4",
    pageMargins: [40, 60, 40, 60],
    content: [
      {
        columns: [
          {
            stack: [
              {
                text: `${product.product.supplier.name}`,
                fontSize: 16,
                bold: true,
              },
              {
                text: `${product.product.supplier.address}`,
                fontSize: 11,
                color: "grey",
              },
            ],
            margin: [0, 0, 0, 20],
          },
          {
            // We use a stack to layer the rectangle and the text
            stack: [
              {
                // This is the rectangle with the light blue background
                canvas: [
                  {
                    type: "rect",
                    x: 0,
                    y: 0,
                    w: 230, // Adjust the width as needed
                    h: 62, // Adjust the height as needed
                    color: "#93a1ca", // This is light blue
                  },
                ],
                margin: [0, 0, 0, 20],
              },
              {
                // Now we put the text on top of the rectangle
                text: "FACTURE",
                fontSize: 13,
                bold: true,
                color: "white",
                alignment: "left",
                absolutePosition: { x: 340, y: 71 },
                margin: [10, 5, 0, 5], // Add padding as needed
              },
              {
                text: `n°${invoiceNumber}`,
                fontSize: 11,
                alignment: "left",
                color: "white",
                absolutePosition: { x: 420, y: 75 },
                margin: [10, 0, 0, 5], // Add padding as needed
              },
              {
                text: `Date : ${formattedDate}`,
                alignment: "left",
                color: "white",
                fontSize: 11,
                absolutePosition: { x: 340, y: 100 },
                margin: [10, 0, 0, 5], // Add padding as needed
              },
            ],
            width: "auto",
            alignment: "right", // Align the stack to the right
          },
        ],
      },
      { text: `${connectedLead.name}`, bold: true, margin: [0, 0, 0, 20] },
      {
        text: "Votre Achat :",
        fontSize: 23,
        bold: true,
        margin: [0, 20, 0, 10],
      },
      {
        style: "tableExample",
        table: {
          widths: ["*", "auto", 100, "*"],
          body: [
            [
              { text: "REFERENCES", style: "tableHeader" },
              { text: "DESIGNATIONS", style: "tableHeader" },
              { text: "PU HT", style: "tableHeader" },
              { text: "TTC", style: "tableHeader" },
            ],
            [
              { text: ` ${product.product.reference}`, style: "tableBody" },
              {
                text: ` ${renderProductInfo(product.product)}`,
                style: "tableBody",
              },
              { text: `${product.product.prix} €`, style: "tableBody" },
              { text: `${product.product.prix} €`, style: "tableBody" },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0.5;
          },
          vLineWidth: function (i, node) {
            return 0.5;
          },

          paddingLeft: function (i, node) {
            return i < 1 ? 20 : 10;
          },
          paddingRight: function (i, node) {
            return i < 1 ? 20 : 10;
          },
          paddingTop: function (i, node) {
            return i < 1 ? 10 : 5;
          },
          paddingBottom: function (i, node) {
            return i < 1 ? 10 : 5;
          },
        },
      },
      {
        columns: [
          {
            text: "Facture en Euro",
            alignment: "right",
            margin: [0, 20, 0, 0],
          },

          {
            text: `Total TTC :          ${product.product.prix} €`,
            alignment: "right",
            margin: [0, 20, 0, 0],
            bold: true,
          },
        ],
      },
      {
        text: [
          {
            text: "Condition de règlement : réception de facture après paiement\n",
            italics: true,
            fontSize: 8,
          },
          {
            text: "Aucun escompte consenti pour règlement anticipé",
            italics: true,
            fontSize: 8,
          },
        ],
        margin: [0, 20, 0, 20],
      },
    ],
    footer: function (currentPage, pageCount) {
      return {
        stack: [
          {
            text: `Copyright © ${currentYear} ${product.product.supplier.name} ${product.product.supplier.immatriculation} All rights reserved`,
            alignment: "center",
            margin: [0, 2], // Marge intérieure du footer
            fontSize: 10,
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 5,
                x2: 515 - 40 * 2, // La longueur est ajustée pour les marges de la page
                y2: 2,
                lineWidth: 0.5,
                lineColor: "#4d4d4d",
              },
            ],
            width: "*",
            alignment: "center",
          },
          {
            text: "Adhérant à un centre de gestion agréé par l'Administration Fiscale, acceptant à ce titre le règlement des honoraires",
            alignment: "center",
            fontSize: 8,
            color: "#aaaaaa", // Couleur du texte en gris clair
          },
        ],
        margin: [0, 20, 0, 0], // Marge extérieure du footer
      };
    },
    styles: {
      tableExample: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        fontSize: 11,
        bold: true,
        color: "white", // Définir la couleur de la police à blanc
        fillColor: "#93a1ca", // Appliquer la couleur de fond à l'aide de la valeur hexadécimale
      },
      tableBody: {
        fontSize: 9,
      },
    },
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
  pdfDocGenerator.download(`${product.product.reference}.pdf`);
};
