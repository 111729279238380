import "./Refunds.css";
import moment from "moment";
const Refunds = ({ currentData }) => {
  return (
    <>
      <div className="containerDatatableHistory">
        <div className="datatableHistory">
          <table className="tableDataHistory">
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="headerWithIcon">Amount</div>
                </th>
                <th>
                  <div className="headerWithIcon">Refund Date</div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>

                {/* <th>
                  <div className="headerWithIcon">Add</div>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(currentData?.refund) &&
              currentData.refund.length > 0 ? (
                currentData.refund.map((refund) => (
                  <tr key={refund._id}>
                    <td>{(refund.amount ?? 0).toLocaleString("en-US")} €</td>
                    <td>{moment(refund.refundDate).format("DD/MM/YYYY")}</td>
                    <td>
                      <span className="hover-effect">del</span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Refunds;
