import "./DepositTable.css";
import { handleCheckboxChange } from "../../../../utils/datatable.utils";
import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import SelectOptionsTable from "../../../SelectOptionsTable/SelectOptionsTable";
import AddIcon from "@mui/icons-material/Add";
import useFetch from "../../../../hooks/useFetch";
import AddDeposit from "../AddDeposit/AddDeposit";
import CommentPopup from "../../../CRM/Customers/CommentPopup/CommentPopup";
import moment from "moment";
import axios from "axios";
import DeleteDeposit from "../DeleteDeposit/DeleteDeposit";
import PopupCustomerContainer from "../../../CRM/PopupCustomer/PopupClientContainer/PopupCustomerContainer";

const DepositTable = ({
  data,
  reFetch,
  allLeads,
  reFetchLead,
  users,
  reFetchUsers,
  campaigns,
}) => {
  const { data: payments } = useFetch("payment");
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [openCommentPopUp, setOpenCommentPopUp] = useState(null);
  const [openAddDeposit, setOpenAddDeposit] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState({});
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [selectedUserId, setSelectedUserId] = useState("All Sales");
  const [selectedCampaignId, setSelectedCampaignId] = useState("All Campaign");
  const [selectedTypeId, setSelectedTypeId] = useState("All Types");
  const [selectedFTDS, setselectedFTDS] = useState("All Deposits");
  const [openPopupCustomer, setOpenPopupCustomer] = useState(false);

  const tableRef = useRef(null);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setHighlightedRows([]);
    }
  }, [checkedItems]);

  const selectCurrentPage = () => {
    const start = currentPage * perPage;
    const end = start + perPage;
    const currentPageItems = data.slice(start, end).map((item) => item._id);
    setCheckedItems(currentPageItems);
  };

  const selectAllPages = () => {
    const allItems = data.map((item) => item._id);
    setCheckedItems(allItems);
  };

  const handleOpenDeleteMessage = (depositId, leadId) => {
    setOpenDeleteMessage(true);
    setSelectedDeletedId({ depositId, leadId });
  };

  const handleReferenceClick = (data) => {
    setSelectedData(data);
    setOpenCommentPopUp(true);
  };

  const handleAssignmentDepositChange = async (event, depositId) => {
    const newAssigneeId = event.target.value;
    setSelectedAssignee((prev) => ({ ...prev, [depositId]: newAssigneeId }));

    try {
      // Trouver le lead correspondant qui contient ce dépôt
      const leadWithDeposit = data.find((lead) =>
        lead.deposit.some((dep) => dep._id === depositId)
      );

      if (!leadWithDeposit) {
        console.error("Lead not found");
        return;
      }

      // Trouver et mettre à jour le dépôt spécifique
      const updatedDeposit = leadWithDeposit.deposit.find(
        (dep) => dep._id === depositId
      );
      if (updatedDeposit) {
        updatedDeposit.assignTo = newAssigneeId;
      }

      // Préparer les données pour la mise à jour
      const updatedData = {
        deposit: leadWithDeposit.deposit,
      };

      // Envoyer la requête de mise à jour
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/${leadWithDeposit._id}`,
        updatedData
      );

      reFetch();
    } catch (error) {
      console.error("Error updating deposit assignment:", error);
    }
  };

  const handleAssignmentChange = async (event, leadId) => {
    const newAssigneeId = event.target.value;
    setSelectedAssignee((prev) => ({ ...prev, [leadId]: newAssigneeId }));

    try {
      // Find the new assign user
      const newAssignee = users.find((user) => user._id === newAssigneeId);

      // Find the corresponding lead
      const correspondingLead = data.find((lead) => lead._id === leadId);

      // Delete the old user of the assignTo of the lead
      if (correspondingLead.assignTo) {
        //I find the old user
        const oldAssignee = users.find(
          (u) => u._id === correspondingLead.assignTo._id
        );

        //I find the lead to delete from the user leadAssign array
        const deleteLeadFromArray = oldAssignee.leadAssign.filter(
          (l) => l !== leadId
        );
        //I delete from his array
        const response = await axios.put(
          `${process.env.REACT_APP_SERVER_URL}/api/user/${oldAssignee._id}`,
          { leadAssign: deleteLeadFromArray }
        );

        reFetchUsers();
        reFetch();
      }

      if (event.target.value === "Not assign") {
        const response = await axios.put(
          `${process.env.REACT_APP_SERVER_URL}/api/lead/${correspondingLead._id}`,
          { assignTo: null }
        );
      }

      //Add the lead on the leadAssigna array of the choosen user
      const updatedNewAssigneeLeads = [...newAssignee.leadAssign, leadId];

      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/user/${newAssigneeId}`,
        { leadAssign: updatedNewAssigneeLeads }
      );

      //And i update the assignTo of the Lead with the new assign User
      const responses = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/${correspondingLead._id}`,
        { assignTo: newAssignee._id, attributionDate: new Date() }
      );

      reFetch();
      reFetchUsers();
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'assignation :", error);
    }
  };

  const handleCampaignChange = async (event, leadId) => {
    const newCampaignId = event.target.value;
    setSelectedCampaign((prev) => ({ ...prev, [leadId]: newCampaignId }));

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/${leadId}`,
        { campaign: newCampaignId }
      );

      reFetch();
    } catch (err) {
      console.log(err);
    }
  };

  const handleUserChange = (e) => {
    setSelectedUserId(e.target.value);
  };

  const handleCampaignChangeFilter = (e) => {
    setSelectedCampaignId(e.target.value);
  };

  const handleTypePaymentChangeFilter = (e) => {
    setSelectedTypeId(e.target.value);
  };

  const handleDepositsChangeFilter = (e) => {
    setselectedFTDS(e.target.value);
  };
  const filteredDeposits = data.flatMap((lead) => {
    const hasOnlyOneDeposit = lead.deposit.length === 1;

    return lead.deposit
      .filter((deposit) => {
        const matchesUser =
          selectedUserId === "All Sales" ||
          (deposit.assignTo && deposit.assignTo._id === selectedUserId);

        const matchesCampaign =
          selectedCampaignId === "All Campaign" ||
          (lead.campaign && lead.campaign._id === selectedCampaignId);

        const matchesTypePayment =
          selectedTypeId === "All Types" ||
          (deposit.typePayment && deposit.typePayment._id === selectedTypeId);

        const matchesFTDS =
          selectedFTDS === "All Deposits" ||
          (selectedFTDS === "Only FTDS" && hasOnlyOneDeposit);

        return (
          matchesUser && matchesCampaign && matchesTypePayment && matchesFTDS
        );
      })
      .map((deposit) => ({
        ...deposit,
        leadReference: lead.reference,
        leadComments: lead.comment,
        leadName: lead.name,
        campaign: lead.campaign ? lead.campaign : null,
        leadId: lead._id,
        leadAssignTo: lead.assignTo,
        lead: lead,
      }));
  });

  const totalDeposit = filteredDeposits.reduce((acc, deposit) => {
    return acc + deposit.amount;
  }, 0);

  const handleOpenPopupCustomer = (data) => {
    setSelectedData(data._id);
    setOpenPopupCustomer(true);
  };

  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>
                Deposit : {filteredDeposits.length} | Total Amount :{" "}
                {totalDeposit}€
              </span>

              <select onChange={handleUserChange}>
                <option value="All Sales">All Sales</option>
                {users
                  .filter((user) => user.isSales)
                  .map((user) => (
                    <option key={user._id} value={user._id}>
                      {user.name}
                    </option>
                  ))}
              </select>
              <select onChange={handleCampaignChangeFilter}>
                <option value="All Campaign">All Campaign</option>
                {campaigns.map((campaign) => (
                  <option key={campaign._id} value={campaign._id}>
                    {campaign.title}
                  </option>
                ))}
              </select>
              <select onChange={handleTypePaymentChangeFilter}>
                <option value="All Types">All Types</option>
                {payments.map((payment) => (
                  <option key={payment._id} value={payment._id}>
                    {payment.title}
                  </option>
                ))}
              </select>
              <select onChange={handleDepositsChangeFilter}>
                <option value="All Deposits">All Deposits</option>
                <option value="Only FTDS">Only FTDS</option>
              </select>
            </div>

            <div className="rightElements">
              <button onClick={() => setOpenAddDeposit(true)}>
                <AddIcon />
                <span>Add</span>
              </button>
            </div>
          </div>
          <table className="tableData" ref={tableRef}>
            <thead className="dtThead">
              <tr>
                {/* <th>
                  <div className="placeCheckbox">
                    <label
                      className="container"
                      onClick={handleHeaderCheckboxClick}
                    >
                      <input type="checkbox" checked={isAllChecked} readOnly />

                      <div className="checkmark"></div>
                    </label>
                  </div>
                </th> */}
                <th>
                  <div className="headerWithIcon">Customer</div>
                </th>
                <th>
                  <div className="headerWithIcon">Amount</div>
                </th>
                <th>
                  <div className="headerWithIcon">Type</div>
                </th>
                <th>
                  <div className="headerWithIcon">Deposit Date</div>
                </th>

                <th>
                  <div className="headerWithIcon">Assign To</div>
                </th>
                <th>
                  <div className="headerWithIcon">Sales Agent</div>
                </th>

                <th>
                  <div className="headerWithIcon">Campaign</div>
                </th>
                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredDeposits
                .sort(
                  (a, b) => new Date(b.depositDate) - new Date(a.depositDate)
                )
                .slice(currentPage * perPage, (currentPage + 1) * perPage)
                .map((deposit, index) => (
                  <tr key={index}>
                    {/* <td>
                      <label className="container">
                        <input
                          type="checkbox"
                          checked={checkedItems.includes(deposit._id)}
                          onChange={(e) =>
                            handleCheckboxChangeWrapper(e, deposit._id)
                          }
                        />
                        <div className="checkmark"></div>
                      </label>
                    </td> */}

                    <td onClick={() => handleOpenPopupCustomer(deposit.lead)}>
                      <div className="nameAndStatus">
                        <span style={{ color: "#00B161" }}>
                          {deposit.leadReference} - {deposit.leadName}
                        </span>
                      </div>
                    </td>

                    <td>
                      <span>{deposit.amount}€</span>
                    </td>

                    <td>
                      <span>{deposit.typePayment.title}</span>
                    </td>

                    <td>
                      <span>
                        {moment(deposit.depositDate).format("DD/MM/YYYY")}
                      </span>
                    </td>

                    <td>
                      <select
                        className="statusLead"
                        value={
                          selectedAssignee[deposit._id] ||
                          (deposit.assignTo
                            ? deposit.assignTo._id
                            : "Not assign")
                        }
                        onChange={(e) =>
                          handleAssignmentDepositChange(e, deposit._id)
                        }
                      >
                        {deposit.assignTo ? (
                          <option value={deposit.assignTo._id}>
                            {deposit.assignTo.name}
                          </option>
                        ) : (
                          <option value="Not assign">Not assign</option>
                        )}
                        {users
                          .filter(
                            (user) =>
                              (user.isSales || user.isAdmin) &&
                              (!deposit.assignTo ||
                                user._id !== deposit.assignTo._id)
                          )
                          .map((user) => (
                            <option key={user._id} value={user._id}>
                              {user.name}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td>
                      <select
                        className="statusLead"
                        value={
                          selectedAssignee[deposit.leadId] ||
                          (deposit.leadAssignTo
                            ? deposit.leadAssignTo._id
                            : "Not assign")
                        }
                        onChange={(e) =>
                          handleAssignmentChange(e, deposit.leadId)
                        }
                      >
                        {deposit.leadAssignTo ? (
                          <option value={deposit.leadAssignTo._id}>
                            {deposit.leadAssignTo.name}
                          </option>
                        ) : (
                          <option value="Not assign">Not assign</option>
                        )}
                        {users
                          .filter(
                            (user) =>
                              (user.isSales || user.isAdmin) &&
                              (!deposit.leadAssignTo ||
                                user._id !== deposit.leadAssignTo._id)
                          )
                          .map((user) => (
                            <option key={user._id} value={user._id}>
                              {user.name}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td>
                      <select
                        className="statusLead"
                        value={
                          deposit.campaign ? deposit.campaign._id : "default"
                        }
                        onChange={(e) =>
                          handleCampaignChange(e, deposit.leadId)
                        }
                      >
                        <option value="default">No Campaign</option>
                        {campaigns.map((camp) => (
                          <option key={camp._id} value={camp._id}>
                            {camp.title}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td>
                      <span
                        className="hover-effect"
                        onClick={() => handleReferenceClick(deposit.lead)}
                      >
                        com({deposit.leadComments?.length || 0}){" "}
                      </span>

                      <span
                        className="hover-effect"
                        onClick={() =>
                          handleOpenDeleteMessage(deposit._id, deposit.leadId)
                        }
                      >
                        del
                      </span>
                    </td>
                  </tr>
                ))}
              {filteredDeposits.length === 0 && (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={Math.ceil(filteredDeposits.length / perPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
          {openAddDeposit && (
            <AddDeposit
              setOpen={setOpenAddDeposit}
              reFetch={reFetch}
              reFetchLead={reFetchLead}
            />
          )}
          {openDeleteMessage && (
            <DeleteDeposit
              setOpen={setOpenDeleteMessage}
              reFetchFromTable={reFetch}
              id={selectedDeletedId}
            />
          )}
          {showSelectAllPopup && (
            <SelectOptionsTable
              setOpen={setShowSelectAllPopup}
              selectCurrentPage={selectCurrentPage}
              selectAllPages={selectAllPages}
              allPageData={data}
              pageData={filteredDeposits}
              setIsAllChecked={setIsAllChecked}
            />
          )}
          {openCommentPopUp && (
            <CommentPopup
              data={selectedData}
              setOpen={setOpenCommentPopUp}
              reFetchFromTable={reFetch}
            />
          )}
          {openPopupCustomer && (
            <PopupCustomerContainer
              setOpen={setOpenPopupCustomer}
              data={selectedData}
              leads={allLeads}
              reFetchAllLead={reFetchLead}
              reFetchFromTable={reFetch}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DepositTable;
