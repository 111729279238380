import "./AddDeposit.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import useFetch from "../../../../../../../hooks/useFetch";

const AddDeposit = ({ setOpen, currentData, reFetchFromTable }) => {
  const { data: users } = useFetch("user");
  const { data: payments } = useFetch("payment");
  const [deposit, setDeposit] = useState([]);
  const [selectedSalesId, setSelectedSalesId] = useState("Select");
  const [newDeposit, setNewDeposit] = useState({
    amount: 0,
    depositDate: new Date().toISOString(),
    typePayment: "",
  });

  useEffect(() => {
    setDeposit(currentData?.deposit || []);
    setSelectedSalesId(currentData?.assignTo?._id || "Select");
  }, [currentData]);

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "assignTo") {
      setSelectedSalesId(value);
    }

    setNewDeposit({ ...newDeposit, [name]: value });
  };

  const handleAddNewDeposit = async () => {
    let depositDateTime = new Date(newDeposit.depositDate);
    const now = new Date();
    depositDateTime.setHours(
      now.getHours(),
      now.getMinutes(),
      now.getSeconds()
    );

    const updatedDeposit = {
      ...newDeposit,
      depositDate: depositDateTime.toISOString(),
      assignTo: selectedSalesId !== "Select" ? selectedSalesId : null,
    };

    const updateLeadBalance =
      currentData.balance + Number(updatedDeposit.amount);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/${currentData._id}`,
        {
          deposit: [...deposit, updatedDeposit],
          isFTDS: true,
          balance: updateLeadBalance,
        }
      );
      if (response.data) {
        reFetchFromTable();
        // Create a notification for the deposit
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/notification`,
          {
            typeNotification: "depot",
            lead: currentData._id,
            text: "Un dépôt a été effectué sur votre compte.",
            amount: Number(updatedDeposit.amount),
          }
        );
      }
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createDeposit" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createDepositWrapper">
            <div className="topCreateStatus">
              <span>Add Deposit</span>
            </div>
            <div className="middleCreateDeposit">
              <label>Select Sales</label>
              <select
                className="depositSelect"
                onChange={handleChange}
                name="assignTo"
                value={selectedSalesId}
              >
                <option value="Select">Select</option>
                {users &&
                  users.map((u) => (
                    <option key={u._id} value={u._id}>
                      {u.name}
                    </option>
                  ))}
              </select>
              <label>Type Payment</label>
              <select
                className="depositSelect"
                onChange={handleChange}
                name="typePayment"
              >
                <option value="Select">Select</option>
                {payments &&
                  payments.map((payment) => (
                    <option key={payment._id} value={payment._id}>
                      {payment.title}
                    </option>
                  ))}
              </select>
              <label>Amount</label>
              <input type="number" name="amount" onChange={handleChange} />
              <label>Date</label>
              <input
                type="date"
                name="depositDate"
                value={newDeposit.depositDate}
                onChange={handleChange}
              />
            </div>
            <div className="bottomCreateDeposit">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button
                className="saveButton"
                type="submit"
                onClick={handleAddNewDeposit}
              >
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDeposit;
