import "./MySafe.css";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DownloadIcon from "@mui/icons-material/Download";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useContext, useState } from "react";
import Invoice from "../Invoice/Invoice";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import FactureGenerator from "../FactureGenerator/FactureGenerator";
import { AuthContextLead } from "../../../../context/leadContextAuth";

const MySafe = ({
  onSafe,
  handleOpenProductInfo,
  handleOpenOffer,
  offers,
  selectedTemplate,
  connectedLead,
}) => {
  const [textOpen, setTextOpen] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const handleMouseEnter = () => setTextOpen(true);
  const handleMouseLeave = () => setTextOpen(false);

  const numberOfOffersPerProduct = (productId) => {
    const numberOfOffer = offers.filter((o) => o.product._id === productId);

    return numberOfOffer.length;
  };

  const renderProductInfo = (product) => {
    const truncateText = (text, limit) => {
      return text.length > limit ? text.substring(0, limit) + "..." : text;
    };
    const sortedTypes = [...selectedTemplate.types].sort(
      (a, b) => a.indexPresentation - b.indexPresentation
    );

    const vignetteAttributes = [];
    const regularAttributes = [];

    sortedTypes.forEach((type) => {
      const productAttribute = product.product.attributs.find(
        (attr) => attr.key === type.name
      );
      let attributeValue = productAttribute ? productAttribute.value : "";

      if (type.abbreviation) {
        attributeValue += ` ${type.abbreviation}`;
      }

      const templateAttribute = type.attributs.find(
        (attr) => attr.value === productAttribute?.value
      );
      const attributeStyle = {
        color: templateAttribute?.textColor || "#000",
        backgroundColor: templateAttribute?.backgroundColor || "#fff",
      };

      if (type.isVignette) {
        vignetteAttributes.push(
          <span
            className="asset-chip3 asset-chip-color5"
            style={attributeStyle}
          >
            {attributeValue}
          </span>
        );
      } else {
        regularAttributes.push(attributeValue);
      }
    });

    // Combine attributes into a single string
    const attributesString = regularAttributes.join(" - ");

    // Truncate the combined string if necessary
    const truncatedAttributesString = truncateText(attributesString, 35); 

    const formattedPrice = product.product.prix.toLocaleString("fr-FR");

    const fullAttributesString = product.product.attributs
      .map((attr) => ` ${attr.value}`)
      .join(", ");

    return (
      <>
        <div className="item-safe-title">
          <span
            className="asset-ti-safe"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {truncatedAttributesString}
            <span className="question-mark">?</span>
          </span>
          {textOpen && (
            <div
              className="tooltipSidebarRight"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {fullAttributesString}
            </div>
          )}
          {vignetteAttributes}
         
        </div>
        <div className="item-safe-details">
          <div className="item-actions-details">
            <span
              className="action"
              onClick={() => handleOpenOffer(product.product._id)}
            >
              <CheckBoxIcon className="icoresize" />
               Offres
            </span>
            <span className="offerssub">
              {numberOfOffersPerProduct(product.product._id)}
            </span>
            <FactureGenerator
              product={product}
              connectedLead={connectedLead}
              selectedTemplate={selectedTemplate}
            />
            <span className="action">
              <LocalShippingIcon className="icoresize" />
              Retrait
            </span>
            <span className="price-asset-safe">€ {formattedPrice}</span>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="safe-list">
      {onSafe.length === 0 ? (
        <div className="messageNoNothing">
          <HistoryToggleOffIcon />
          <span>0 PRODUIT</span>
        </div>
      ) : (
        onSafe.map((product, index) => (
          <div
            key={index}
            className="item-safe"
            onClick={() => handleOpenProductInfo(product.product)}
          >
            {renderProductInfo(product)}
          </div>
        ))
      )}
      {openInvoice && <Invoice setOpen={setOpenInvoice} />}
    </div>
  );
};

export default MySafe;
