import { useCallback, useEffect, useState } from "react";
import RefundsTable from "../../../components/Banking/Refunds/RefundsTable/RefundsTable";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { BankingTitles } from "../../../constants/navigationTitles";
import "./RefundsPage.css";
import axios from "axios";
import NavigationTabsAndFilterDate from "../../../components/Navigation/NavigationTabsAndFilterDate/NavigationTabsAndFilterDate";
import { useData } from "../../../context/DataContext";

const RefundsPage = () => {
  const [leadRefunds, setLeadRefunds] = useState([]);
  const { allLeads, reFetchAllLead, campaigns } = useData();
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const fetchAllRefunds = async () => {
    const queryParams = new URLSearchParams({
      startDate: selectedDateRange.startDate
        ? selectedDateRange.startDate.toISOString()
        : null,
      endDate: selectedDateRange.endDate
        ? selectedDateRange.endDate.toISOString()
        : null,
    }).toString();
    try {
      const storedToken = localStorage.getItem("authToken");

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/banking/refund?${queryParams}`,
        { headers: { Authorization: `Bearer ${storedToken}` } }
      );
      setLeadRefunds(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchAllRefunds();
  }, [selectedDateRange]);

  const handleFilteredDataChange = useCallback(({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  }, []);
  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabsAndFilterDate
            onFilteredDataChange={handleFilteredDataChange}
            tabs={BankingTitles}
          />
        </div>
        <div className="bottomCrmPage">
          <RefundsTable
            data={leadRefunds}
            reFetch={fetchAllRefunds}
            allLeads={allLeads}
            reFetchLead={reFetchAllLead}
            campaigns={campaigns}
          />
        </div>
      </div>
    </div>
  );
};

export default RefundsPage;
