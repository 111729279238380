import "./CompanyInfo.css";

const CompanyInfo = ({ handleChange, company }) => {
  return (
    <table className="tablePlatformConfiguration">
      <thead>
        <tr>
          <td colspan={2}>COMPANY INFORMATIONS :</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span>Company Name</span>
          </td>
          <td>
            <input
              type="text"
              className="companyName"
              name="companyName"
              onChange={handleChange}
              value={company.companyName}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>Company Number</span>
          </td>
          <td>
            <input
              type="text"
              className="companyName"
              name="companyNumber"
              onChange={handleChange}
              value={company.companyNumber}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>Company Address</span>
          </td>
          <td>
            <textarea
              type="text"
              name="companyAddress"
              className="textAreaAddress"
              rows="4"
              onChange={handleChange}
              value={company.companyAddress}
            ></textarea>
          </td>
        </tr>
        <tr>
          <td>
            <span>Company Country</span>
          </td>
          <td>
            <input
              type="text"
              className="companyName"
              name="companyCountry"
              onChange={handleChange}
              value={company.companyCountry}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>Company Mail</span>
          </td>
          <td>
            <input
              type="text"
              className="companyName"
              name="companyMail"
              onChange={handleChange}
              value={company.companyMail}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>Company Phone</span>
          </td>
          <td>
            <input
              type="text"
              className="companyName"
              name="companyPhone"
              onChange={handleChange}
              value={company.companyPhone}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>Company Extra Informations</span>
          </td>
          <td>
            <input
              type="text"
              className="companyName"
              name="companyExtraInfo"
              onChange={handleChange}
              value={company.companyExtraInfo}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>Invoice Extra Informations</span>
          </td>
          <td>
            <input
              type="text"
              className="companyName"
              name="invoiceExtraInfo"
              onChange={handleChange}
              value={company.invoiceExtraInfo}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CompanyInfo;
