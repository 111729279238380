import { useState, useContext } from "react";
import "./Funds.css";
import moment from "moment";
import AddDeposit from "./AddDeposit/AddDeposit";
import DeleteDeposit from "../../../../../Banking/Deposit/DeleteDeposit/DeleteDeposit";
import { AuthContext } from "../../../../../../context/auth.context.jsx";
const Funds = ({ currentData, reFetchFromTable, refetch }) => {
  const [openAddDeposit, setOpenAddDeposit] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const { user } = useContext(AuthContext);
  
  const handleOpenDeleteMessage = (depositId, leadId) => {
    setOpenDeleteMessage(true);
    setSelectedDeletedId({ depositId, leadId });
  };


  return (
    <>
      <div className="containerDatatableHistory">
        <div className="datatableHistory">
          {user.isSuperAdmin && (
            <span
              className="addFundPopup"
              onClick={() => setOpenAddDeposit(true)}
            >
              + Add Fund
            </span>
          )}
          <table className="tableDataHistory">
            <thead className="dtThead">
              <tr>
                <th>Amount</th>
                <th>Deposit Date</th>
                <th>Payment Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(currentData?.deposit) &&
              currentData.deposit.length > 0 ? (
                currentData.deposit.map((deposit) => (
                  <tr key={deposit._id}>
                    <td>{(deposit.amount ?? 0).toLocaleString("en-US")} €</td>
                    <td>{moment(deposit.depositDate).format("DD/MM/YYYY")}</td>
                    <td>{deposit.typePayment?.title ?? "N/A"}</td>
                    <td>
                      <span
                        className="hover-effect"
                        onClick={() =>
                          handleOpenDeleteMessage(deposit._id, currentData._id)
                        }
                      >
                        del
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {openAddDeposit && (
        <AddDeposit
          setOpen={setOpenAddDeposit}
          currentData={currentData}
          reFetchFromTable={reFetchFromTable}
        />
      )}
      {openDeleteMessage && (
        <DeleteDeposit
          setOpen={setOpenDeleteMessage}
          reFetchFromTable={reFetchFromTable}
          refetch={refetch}
          id={selectedDeletedId}
        />
      )}
    </>
  );
};

export default Funds;
