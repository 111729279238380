//usePlatformLeadData.js
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchPlatformLeadData = async (leadId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/api/leadPlatform/${leadId}/data`,
    {}
  );
  return response.data;
};

const fetchProducts = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/api/product`,
    {}
  );
  return response.data;
};

const fetchNotifications = async (leadId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/api/notification?lead=${leadId}`
  );
  return response.data;
};

export const usePlatformLeadData = (leadId) => {
  return useQuery({
    queryKey: ["platformLeadData", leadId],
    queryFn: () => fetchPlatformLeadData(leadId),
    enabled: !!leadId,
    refetchOnWindowFocus: false,
    staleTime: 10 * 1000,
  });
};

export const useProducts = () => {
  return useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
    refetchOnWindowFocus: false,
    staleTime: 10 * 1000,
  });
};

export const useNotifications = (leadId) => {
  return useQuery({
    queryKey: ["notifications", leadId],
    queryFn: () => fetchNotifications(leadId),
    enabled: !!leadId,
    refetchOnWindowFocus: false,
    staleTime: 10 * 1000,
  });
};