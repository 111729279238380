import "./CountrySelect.css";
import { countries } from "countries-list";

const CountrySelect = ({ label, className, ...props }) => {
  const countriesArray = Object.values(countries);

  // Trier les pays par nom, mais s'assurer que France n'est pas inclus
  const sortedCountries = countriesArray
    .filter((country) => country.code !== "FR")
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="country">
      <label>
        {label}
        <select className={className} {...props}>
          <option value="default">Select</option>
          {/* France en tant que première option */}
          <option value="France">France</option>
          {sortedCountries.map((country) => (
            <option key={country.code} value={country.name}>
              {country.name}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};

export default CountrySelect;
