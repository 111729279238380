import "./Notification.css";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CloseIcon from "@mui/icons-material/Close";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useEffect, useState } from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
const Notification = ({ notification, setShowNotification }) => {
  const [visibleState, setVisibleState] = useState(""); // Initialement neutre

  useEffect(() => {
    // Déclencher l'animation d'entrée dès que le composant est monté
    setVisibleState("show");

    // Planifier la fermeture et le nettoyage
    const showTimer = setTimeout(() => {
      setVisibleState("hide");
    }, 4500); // Commencer à cacher après 4500ms

    const hideTimer = setTimeout(() => {
      setShowNotification(false);
    }, 5000); // Retirer complètement après 5000ms

    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
    };
  }, []);

  return (
    <div className={`notification-popupTest ${visibleState}`}>
      {/* <CloseIcon
        style={{
          position: "absolute",
          top: "8px",
          right: "0px",
          fontSize: "16px",
          cursor: "pointer",
        }}
      /> */}
      {notification.typeNotification === "depot" && (
        <AccountBalanceIcon
          style={{ borderRight: "1px solid rgb(223, 223, 223)" }}
        />
      )}
      {notification.typeNotification === "remboursement" && (
        <CreditCardIcon
          style={{ borderRight: "1px solid rgb(223, 223, 223)" }}
        />
      )}
      {notification.typeNotification === "attente" && (
        <AccessTimeIcon
          style={{ borderRight: "1px solid rgb(223, 223, 223)" }}
        />
      )}
      {notification.typeNotification === "offre" && (
        <LocalOfferIcon
          style={{ borderRight: "1px solid rgb(223, 223, 223)" }}
        />
      )}
      {notification.typeNotification === "vendu" && (
        <AccountBalanceIcon
          style={{ borderRight: "1px solid rgb(223, 223, 223)" }}
        />
      )}
      {notification.typeNotification === "coffre" && (
        <AccountBalanceWalletIcon
          style={{ borderRight: "1px solid rgb(223, 223, 223)" }}
        />
      )}
      <div className="notification-popupTestBottomTop">
        <span
          style={{
            color: "#93a1ca",
            fontWeight: "400",
          }}
          className="capitalize-first"
        >
          {notification.typeNotification}
        </span>
        <div className="notification-popupTestBottom">
          <span style={{ fontWeight: "300" }}>{notification.text}</span>
          <span style={{ color: "#93a1ca" }}>
            {notification.typeNotification === "remboursement" ? "-" : ""}
            {notification.typeNotification === "depot" ? "+" : ""}
            {notification.typeNotification === "offre" ? "" : ""}{" "}
            {["remboursement", "depot", "vendu", "offre"].includes(
              notification.typeNotification
            ) && notification.amount
              ? `${notification.amount.toLocaleString()} €`
              : ""}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Notification;
