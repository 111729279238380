import "./CGU.css";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

const CGU = ({ handleCGUChange, company }) => {
  return (
    <table className="tablePlatformConfiguration">
      <thead>
        <tr>
          <td colspan={2}>CGU :</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <CustomToolbarCGU />
            <ReactQuill
              modules={{ toolbar: { container: "#toolbarCGU" } }}
              value={company.cgu}
              onChange={handleCGUChange}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CGU;

const CustomToolbarCGU = () => (
  <div id="toolbarCGU">
    {/* Ajouter les contrôles de mise en forme souhaités */}
    <select className="ql-header" defaultValue="">
      <option value="1">Heading 1</option>
      <option value="2">Heading 2</option>
      <option value="">Normal</option>
    </select>
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    <button className="ql-strike"></button>
    <button className="ql-align" value=""></button>
    <button className="ql-align" value="center"></button>
    <button className="ql-align" value="right"></button>
    <button className="ql-align" value="justify"></button>
    {/* ... autres options ... */}
  </div>
);
