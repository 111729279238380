import "./NavigationTabs.css";
import { Link, useLocation } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { useContext } from "react";
import { AuthContext } from "../../../context/auth.context";


const NavigationTabs = ({ tabs, onFilteredDataChange }) => {
  const location = useLocation();
  const { user } = useContext(AuthContext);

  const isActive = (path) => {
    return location.pathname === path;
  };

    // Filtrer les onglets en fonction du rôle de l'utilisateur
    const filteredTabs = tabs.filter((tab) => {
      if (tab.name === "Attribution" && !user.isSuperAdmin) {
        return false;
      } else if (tab.name ===  "Sales Performance" && !user.isSuperAdmin) {
        return false;
      }
      return true;
    });

  return (
    <>
      <div className="navigationAndFilterLeft">
        <nav className="topTabNavigation">
          <ul>
            {filteredTabs.map(({ name, path }) => (
              <li key={name}>
                <Link
                  to={path}
                  className={`tabLink ${isActive(path) ? "tabSelected" : ""}`}
                >
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="navigationAndFilterRightNoNeed">
        <ul>
          {[<AllInclusiveIcon />, "Y", "M", "W", "D"].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <div className="champDateContainer">
          <div className="champDate">
            <span></span>
            <CalendarMonthIcon />
          </div>
          <div className="champDate">
            <span></span>
            <CalendarMonthIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationTabs;
