import BankInfo from "../BankInfo/BankInfo";
import "./PlatformContainer.css";
import CompanyInfo from "../CompanyInfo/CompanyInfo";
import ImagesTable from "../ImagesTable/ImagesTable";
import LegalInfo from "../LegalInfo/LegalInfo";
import CGU from "../CGU/CGU";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import useFetch from "../../../../hooks/useFetch";
import PlatformConfig from "../PlatformConfig/PlatformConfig";
const PlatformContainer = () => {
  const { data: companies } = useFetch("company");
  const [company, setCompany] = useState(companies || {});
  const [imagePreviews, setImagePreviews] = useState({
    loginLogo: null,
    storeLogo: null,
    invoiceLogo: null,
    loginBackground: null,
    mobileStoreLogo: null,
    favicon: null,
  });

  useEffect(() => {
    if (companies && companies.length > 0) {
      const {
        loginLogo,
        storeLogo,
        invoiceLogo,
        loginBackground,
        mobileStoreLogo,
        favicon,
        ...rest
      } = companies[0];
      setImagePreviews({
        loginLogo,
        storeLogo,
        invoiceLogo,
        loginBackground,
        mobileStoreLogo,
        favicon,
      });
      setCompany(rest);
    }
  }, [companies]);

  const handleUpdateCompany = async (e) => {
    const formData = new FormData();

    // Ajouter toutes les propriétés de l'entreprise à formData, excluant les images
    Object.keys(company).forEach((key) => {
      // Exclure les champs d'image puisqu'ils sont déjà traités
      if (
        ![
          "favicon",
          "storeLogo",
          "invoiceLogo",
          "loginLogo",
          "loginBackground",
          "mobileStoreLogo",
        ].includes(key)
      ) {
        formData.append(key, company[key]);
      }
    });

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let response;
      if (companies.length < 1) {
        response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/company/`,
          formData,
          config
        );
      } else {
        response = await axios.put(
          `${process.env.REACT_APP_SERVER_URL}/api/company/${companies[0]._id}`,
          formData,
          config
        );
      }

      // Vous pouvez choisir de mettre à jour l'objet company avec les données fraîchement mises à jour du serveur
      if (response && response.data) {
        setCompany(response.data);
      }
    } catch (err) {
      console.error("Error updating company:", err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompany((prev) => ({ ...prev, [name]: value }));
  };

  const handleLegalChange = (content) => {
    setCompany({ ...company, legal: content });
  };
  const handleCGUChange = (content) => {
    setCompany({ ...company, cgu: content });
  };

  const handleImageChange = (imageType) => async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviews((prev) => ({ ...prev, [imageType]: reader.result }));
      };
      reader.readAsDataURL(file);

      // Préparation de formData pour le téléchargement du fichier
      const formData = new FormData();
      formData.append(imageType, file);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        let response;
        if (company._id) {
          // Mise à jour de l'entreprise existante
          response = await axios.put(
            `${process.env.REACT_APP_SERVER_URL}/api/company/${company._id}`,
            formData,
            config
          );
        } else {
          // Création d'une nouvelle entreprise
          response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/company`,
            formData,
            config
          );
        }

        // Mise à jour de l'état de l'entreprise avec la réponse du serveur
        if (response && response.data) {
          setCompany(response.data);
        }
      } catch (error) {
        console.error("Erreur lors du téléchargement de l'image : ", error);
      }
    }
  };

  const imagesHandleChange = {
    handleFaviconChange: handleImageChange("favicon"),
    handleStoreLogoChange: handleImageChange("storeLogo"),
    handleInvoiceLogoChange: handleImageChange("invoiceLogo"),
    handleLoginLogoChange: handleImageChange("loginLogo"),
    handleLoginBackgroundChange: handleImageChange("loginBackground"),
    handleMobileStoreLogoChange: handleImageChange("mobileStoreLogo"),
  };

  const convertImageToDataURL = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
      }
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting image to Data URL: ", error);
    }
  };

  const updateImagePreview = (imageType, imageUrl) => {
    if (imageUrl) {
      convertImageToDataURL(
        `${process.env.REACT_APP_SERVER_URL}/${imageUrl}`
      ).then((dataUrl) => {
        setImagePreviews((prev) => ({ ...prev, [imageType]: dataUrl }));
      });
    }
  };

  useEffect(() => {
    updateImagePreview("favicon", company.favicon);
    updateImagePreview("loginLogo", company.loginLogo);
    updateImagePreview("storeLogo", company.storeLogo);
    updateImagePreview("invoiceLogo", company.invoiceLogo);
    updateImagePreview("loginBackground", company.loginBackground);
    updateImagePreview("mobileStoreLogo", company.mobileStoreLogo);
  }, [company]);

  const handleDeleteImage = async (imageType) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/api/company/${company._id}/images`,
        {
          data: { imagesToDelete: [imageType] },
        }
      );

      setCompany((prev) => ({ ...prev, [imageType]: "" }));
      setImagePreviews((prev) => ({ ...prev, [imageType]: null }));
    } catch (error) {
      console.error("Erreur lors de la suppression de l'image : ", error);
    }
  };

  return (
    <div>
      <div className="containerPlatform">
        <div className="platformWrapper">
          <div className="searchBarPlatform">
            <div className="leftElementsTablePlatform">
              <span>Platform Configuration</span>
            </div>
          </div>
          <ImagesTable
            handleChangeImages={imagesHandleChange}
            imagePreviews={imagePreviews}
            handleDeleteImage={handleDeleteImage}
          />
          <br />
          <PlatformConfig handleChange={handleChange} company={company} />
          <br />
          <CompanyInfo handleChange={handleChange} company={company} />
          <br />
          <BankInfo handleChange={handleChange} company={company} />
          <br />
          <LegalInfo handleLegalChange={handleLegalChange} company={company} />
          <br />
          <CGU handleCGUChange={handleCGUChange} company={company} />

          <div className="buttonConfirmPlatform">
            <button type="submit" onClick={handleUpdateCompany}>
              <CheckIcon />
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatformContainer;
