import "./TemplateProduct.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import axios from "axios";
import useFetch from "../../../../hooks/useFetch";
import CreateTemplateProduct from "../CreateTemplateProduct/CreateTemplateProduct";
import DeleteMessage from "../../../DeleteMessage/DeleteMessage";
import EditTemplateProduct from "../EditTemplateProduct/EditTemplateProduct";
const TemplateProduct = ({
  setOpen,
  openCreateTemplateProduct,
  data,
  reFetch,
  products,
}) => {
  const { data: allLeads } = useFetch("lead");
  const [openCreateTemplate, setOpenCreateTemplate] = useState(false);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(null);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [openEditTemplate, setOpenEditTemplate] = useState(false);
  const [selectedData, setSelectedData] = useState(false);

  const handleEditTemplate = (template) => {
    setOpenEditTemplate(true);
    setSelectedData(template);
  };

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleDeleteTemplate = (deletedId) => {
    setSelectedDeletedId(deletedId);
    setOpenDeleteMessage(true);
  };

  const validateSelectedTemplate = async () => {
    if (selectedTemplateIndex === null) return;

    const selectedTemplateId = data[selectedTemplateIndex]._id;

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/templateProduct/select/${selectedTemplateId}`
      );
      reFetch();
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createTemplateProduct" onClick={handleClickInside}>
          <div className="closeIconTemplateProduct">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createTemplateProductWrapper">
            <div className="topCreateTemplateProduct">
              <span>Product Template</span>
            </div>
            <div className="middleCreateTemplateProduct">
              {data?.length === 0 && (
                <div className="middleCreateTemplateProductItem">
                  <div
                    className="addIconWrapper"
                    onClick={(e) => {
                      e.stopPropagation();
                      openCreateTemplateProduct();
                    }}
                  >
                    <AddIcon style={{ color: "var(--primary-color)" }} />
                  </div>
                </div>
              )}

              {data.map((template, index) => (
                <div
                  key={index}
                  className={`middleCreateTemplateProductItem ${
                    index === selectedTemplateIndex ? "selected" : ""
                  }`}
                  onClick={() => setSelectedTemplateIndex(index)}
                >
                  {template.isSelected && (
                    <div className="isSelectedIndicator">
                      <CheckIcon />
                    </div>
                  )}
                  <div className="iconDeleteAndEditTemplate">
                    <EditIcon onClick={() => handleEditTemplate(template)} />
                    <CloseIcon
                      style={{ color: "red" }}
                      onClick={() => handleDeleteTemplate(template._id)}
                    />
                  </div>
                  <div className="templatePreview">
                    <div className="templateNameVignette">{template.name}</div>
                    <div className="templatePreviewTypes">
                      {template.types
                        .slice()
                        .sort(
                          (a, b) => a.indexPresentation - b.indexPresentation
                        )
                        .filter((type) => !type.isVignette)
                        .map((type, typeIndex) => (
                          <span key={typeIndex}>{type.name}-</span>
                        ))}
                    </div>
                    <span style={{ color: "#ccc" }}>
                      Lorem ipsum dolor sit amet...
                    </span>
                    <div className="templateVignettes">
                      {template.types
                        .filter((type) => type.isVignette)
                        .map((vignette, vignetteIndex) => (
                          <div key={vignetteIndex} className="vignetteItem">
                            {vignette.name}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="bottomCreateTemplateProduct">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="saveButton" onClick={validateSelectedTemplate}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
        {openCreateTemplate && (
          <CreateTemplateProduct setOpen={setOpenCreateTemplate} />
        )}
        {openDeleteMessage && (
          <DeleteMessage
            setOpen={setOpenDeleteMessage}
            reFetchFromTable={reFetch}
            endPoint={"templateProduct"}
            id={selectedDeletedId}
            allLeads={allLeads}
          />
        )}
        {openEditTemplate && (
          <EditTemplateProduct
            setOpen={setOpenEditTemplate}
            data={selectedData}
          />
        )}
      </div>
    </>
  );
};

export default TemplateProduct;
