import "./WidgetPerfomance.css";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
const WidgetPerfomance = ({ userStatsTotal }) => {

  return (
    <>
      <div className="salesPerfomancePageItem">
        <div className="salesPerfomancePageItemTop">
          <AccountBoxIcon />
        </div>
        <div className="salesPerfomancePageItemBottom">
          <span>LEADS</span>
          <span>{userStatsTotal.totalLeads}</span>
        </div>
      </div>
      <div className="salesPerfomancePageItem">
        <div className="salesPerfomancePageItemTop">
          <AccountBalanceIcon />
        </div>
        <div className="salesPerfomancePageItemBottom">
          <span>DEPOSITS</span>
          <span>{userStatsTotal.totalDeposits}</span>
        </div>
      </div>
      <div className="salesPerfomancePageItem">
        <div className="salesPerfomancePageItemTop">
          <StarHalfIcon />
        </div>
        <div className="salesPerfomancePageItemBottom">
          <span>FTDS</span>
          <span>{userStatsTotal.totalFTDS}</span>
        </div>
      </div>
      <div className="salesPerfomancePageItem">
        <div className="salesPerfomancePageItemTop">
          <CreditCardIcon />
        </div>
        <div className="salesPerfomancePageItemBottom">
          <span>TURNOVER</span>
          <span>{userStatsTotal.totalTurnover}€ </span>
        </div>
      </div>
      <div className="salesPerfomancePageItem">
        <div className="salesPerfomancePageItemTop">
          <TrendingUpIcon />
        </div>
        <div className="salesPerfomancePageItemBottom">
          <span>CONVERSION</span>
          <span>
            {userStatsTotal.totalConversion}
            %
          </span>
        </div>
      </div>
    </>
  );
};

export default WidgetPerfomance;
