import React from 'react';
import "./ConnectionHistory.css";
import moment from 'moment'; 

const ConnectionHistory = ({ data, setOpen }) => {
  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}></div>
      <div className="connectionHistory">
        <h3>Historique de Connexion</h3>
        <table>
          <thead>
            <tr>
              <th>Connected at</th>
              <th>Disconnected at</th>
            </tr>
          </thead>
          <tbody>
            {data.connectionHistory.map((history, index) => (
              <tr key={index}>
                <td>{moment(history.connectedAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td>{history.disconnectedAt ? moment(history.disconnectedAt).format('DD/MM/YYYY HH:mm:ss') : '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ConnectionHistory;
