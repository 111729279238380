import "./Sip.css";
import CheckIcon from "@mui/icons-material/Check";

const Sip = () => {
  return (
    <div>
      <div className="containerPlatform">
        <div className="platformWrapper">
          <div className="searchBarPlatform">
            <div className="leftElementsTablePlatform">
              <span>SIP Configuration</span>
            </div>
          </div>
          <table className="tablePlatformConfiguration">
            <thead>
              <tr>
                <td colspan={2}>COMPANY INFORMATIONS :</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h3>Sip Domain</h3>
                </td>
                <td>
                  <input
                    type="text"
                    className="companyName"
                    name="companyName"
                    value=""
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <h3>Pathname</h3>
                </td>
                <td>
                  <input
                    type="text"
                    className="companyName"
                    name="companyName"
                    value=""
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <h3>Port</h3>
                </td>
                <td>
                  <textarea
                    type="text"
                    name="companyAddress"
                    className="textAreaAddress"
                    rows="4"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td>
                  <h3>Extensions Common Password</h3>
                </td>
                <td>
                  <input
                    type="text"
                    className="companyName"
                    name="companyName"
                    value=""
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <div className="buttonConfirmPlatform">
            <button>
              <CheckIcon />
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sip;
