import "./ChartCampaign.css";
import {
  BarChart,
  Bar,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell
} from "recharts";
import BarChartIcon from "@mui/icons-material/BarChart";

const ChartCampaign = ({ campaignStats }) => {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>{`${payload[0].payload.name}: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  const CustomBarShape = (props) => {
    const { fill, x, y, width, height, payload } = props;

    // Calcul pour centrer l'image dans la barre
    const imageWidth = Math.min(25, width); // Limite la largeur de l'image pour qu'elle ne dépasse pas la largeur de la barre
    const imageHeight = imageWidth; // Garde l'image carrée pour maintenir le cercle
    const imageX = x + (width - imageWidth) / 2; // Centre l'image horizontalement dans la barre
    const imageY = y + (height - imageHeight) / 2; // Centre l'image verticalement dans la barre

    return (
      <g>
        <rect x={x} y={y} width={width} height={height} fill={fill} />
        {/* <image
          href={payload.pPicture} // URL de l'image de l'utilisateur
          x={imageX}
          y={imageY}
          width={imageWidth}
          height={imageHeight}
          clipPath="circle(50%)" // Rend l'image ronde
        /> */}
      </g>
    );
  };
  

  const max_value_index = campaignStats.reduce(
    (maxIndex, item, index, array) =>
      item.value > array[maxIndex].value ? index : maxIndex,
    0
  );
  const hasData = campaignStats.some((item) => item.value > 0);


  return (
    <div className="chartContainer" style={{ marginBottom: "10px" }}>
      {hasData ? (
        <ResponsiveContainer width="100%" height={200}>
          <BarChart
            data={campaignStats}
            margin={{ top: 0, right: 0, left: -25, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#f9f9f9" />

            <YAxis
              axisLine={{ stroke: "#e3e3e3", strokeWidth: 0.5 }}
              tickLine={false}
              tick={{ fill: "#c0c0c0", fontSize: 10, dx: 0 }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="value"
              fill="#8884d8" // Couleur par défaut pour toutes les barres
              shape={<CustomBarShape />}
              barSize={45}
            >
              {campaignStats.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={index === max_value_index ? "#009fb09c" : "#00b0939c"} // Utilisez la couleur spéciale pour la barre avec la valeur la plus élevée
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div className="noDataMessageChart">
          <BarChartIcon />
          <span>No Data</span>
        </div>
      )}
    </div>
  );
};

export default ChartCampaign;
