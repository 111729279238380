import "./SalesPerfomancePage.css";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import NavigationTabsAndFilterDate from "../../../components/Navigation/NavigationTabsAndFilterDate/NavigationTabsAndFilterDate";
import { CRMtitles } from "../../../constants/navigationTitles";
import WidgetPerfomance from "../../../components/CRM/SalesPerfomance/WidgetPerfomance/WidgetPerfomance";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import Activity from "../../../components/CRM/SalesPerfomance/Activity/Activity";
import GeneralRankingPerf from "../../../components/CRM/SalesPerfomance/GeneralRankingPerf/GeneralRankingPerf";
import AuthLoader from "../../../components/Loaders/AuthLoader/AuthLoader";

const SalesPerfomancePage = () => {
  const [userStats, setUserStats] = useState([]);
  const [userStatsTotal, setUserStatsTotal] = useState([]);
  const [rankingCriteria, setRankingCriteria] = useState("Leads");
  const [isLoading, setIsLoading] = useState(false);
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate,
    endDate,
  });

  const fetchUserStats = async () => {
    setIsLoading(true);
    const queryParams = new URLSearchParams({
      startDate: selectedDateRange.startDate
        ? selectedDateRange.startDate.toISOString()
        : null,
      endDate: selectedDateRange.endDate
        ? selectedDateRange.endDate.toISOString()
        : null,
    }).toString();
    try {
      const storedToken = localStorage.getItem("authToken");
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/user/userStats?${queryParams}`,
        { headers: { Authorization: `Bearer ${storedToken}` } }
      );
      setUserStats(response.data.userStats);
      setUserStatsTotal(response.data.totals);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUserStats();
  }, [selectedDateRange]);

  const handleFilteredDataChange = useCallback(({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  }, []);

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabsAndFilterDate
            tabs={CRMtitles}
            onFilteredDataChange={handleFilteredDataChange}
          />
        </div>
        {isLoading && (
          <div className="loaderOverlayPerfomancePage">
            <AuthLoader color="var(--primary-color)" />
          </div>
        )}
        <div className="bottomCrmPage">
          <div className="salesPerfomancePageTop">
            <div className="salesPerfomancePageTopWrapper">
              <WidgetPerfomance userStatsTotal={userStatsTotal} />
            </div>
          </div>

          <div className="salesPerfomancePageBottom">
            <div className="salesPerfomancePageBottomLeft">
              <div className="salesPerfomancePageBottomLeftTop">
                <GeneralRankingPerf
                  userStats={userStats}
                  rankingCriteria={rankingCriteria}
                  setRankingCriteria={setRankingCriteria}
                />
              </div>
            </div>
            <div className="salesPerfomancePageBottomRight">
              <Activity userStats={userStats} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesPerfomancePage;
