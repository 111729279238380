import "./DeleteMessage.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import { useSocket } from "../../../../../../../context/SocketContext";
import { useState } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";

const DeleteMessage = ({
  setOpen,
  currentData,
  reFetchAllLead,
  selectedData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handlePopupClick = (e) => {
    e.stopPropagation();
  };
  const deleteFromOnHold = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/leadPlatform/${currentData._id}/delete-onhold`,
        { productId: selectedData }
      );

      if (response.data) {
        setOpen(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createStatus" onClick={handlePopupClick}>
          <div className="closeIconStatus">
            <CloseIcon
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}
            />
          </div>
          {isLoading ? (
            <div className="loading-container">
              <span>Removing product...</span>
              <div className="loading-spinner">
                <PropagateLoader color="var(--primary-color)" />
              </div>
            </div>
          ) : (
            <>
              <div className="createStatusWrapper">
                <div className="topCreateStatus">
                  <span>DEL</span>
                </div>
                <div className="middleCreateStatus">
                  <span>Are you sure you want to perform this action?</span>
                </div>
                <div className="bottomCreateStatus">
                  <button
                    className="cancelButton"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button className="saveButton" onClick={deleteFromOnHold}>
                    <CheckIcon />
                    Comfirm
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DeleteMessage;
