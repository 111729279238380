import "./BankInfo.css";

const BankInfo = ({ handleChange, company }) => {
  return (
    <table className="tablePlatformConfiguration">
      <thead>
        <tr>
          <td colspan={2}>COMPANY IBAN :</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span>Account Name</span>
          </td>
          <td>
            <input
              type="text"
              className="companyName"
              name="accountName"
              value={company.accountName}
              onChange={handleChange}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>IBAN</span>
          </td>
          <td>
            <input
              type="text"
              className="companyName"
              name="iban"
              value={company.iban}
              onChange={handleChange}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>BIC (SWIFT)</span>
          </td>
          <td>
            <input
              type="text"
              className="companyName"
              name="bic"
              value={company.bic}
              onChange={handleChange}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>BANK</span>
          </td>
          <td>
            <textarea
              type="text"
              name="bank"
              className="textAreaAddress"
              rows="4"
              value={company.bank}
              onChange={handleChange}
            ></textarea>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default BankInfo;
