import "./Withdrawal.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSocket } from "../../../../context/SocketContext";

const Withdrawal = ({ setOpen, connectedLead }) => {
  const { emitWithdrawal, emitRefreshDataPlatform } = useSocket();
  const [amount, setAmount] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");

  const handlePopupClick = (e) => {
    e.stopPropagation();
  };

  const handleAddNewRefund = async (e) => {
    e.preventDefault();
    if (Number(amount) > connectedLead.balance) {
      setErrorMessage("Insufficient balance for withdrawal request.");
      return;
    }

    const newRefund = {
      amount,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/leadPlatform/${connectedLead._id}/add-refund`,

        newRefund
      );
      if (response.data) {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/notification`,
          {
            typeNotification: "remoboursement",
            lead: connectedLead._id,
            text: "Un remboursement a été effectué sur votre compte.",
            amount: amount,
          }
        );
        emitRefreshDataPlatform();
      }
      emitWithdrawal();
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (amount === "") {
      setErrorMessage("");
    }
  }, [amount]);
  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="withdrawal" onClick={handlePopupClick}>
          <div className="closeIconBuyButton">
            <CloseIcon
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
          <div className="buyButtonWrapper">
            <div className="topBuyButton">
              <span>Withdrawal</span>
            </div>

            <div className="middleWithdrawal">
              <label>
                Account balance : €{" "}
                {connectedLead && connectedLead.balance.toLocaleString("fr-FR")}
              </label>
              {errorMessage && (
                <>
                  <span style={{ fontSize: "10px", color: "red" }}>
                    {errorMessage}
                  </span>
                </>
              )}
              <input
                type="number"
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                placeholder="Enter amount"
              />
            </div>
            <div className="bottomBuyButton">
              <button
                className="cancelButtonBuyButton"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className="saveButtonBuyButton"
                onClick={handleAddNewRefund}
              >
                <CheckIcon />
                Comfirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdrawal;
