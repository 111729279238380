import React from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { useEffect, useRef, useState } from "react";
import PublicIcon from "@mui/icons-material/Public";

const allData = [
  { name: "Jan", uv: 500 },
  { name: "Feb", uv: 1000 },
  { name: "Mar", uv: 300 },
  { name: "Apr", uv: 780 },
  { name: "May", uv: 2500 },
  // ... ajoutez d'autres données si nécessaire
];

// Filtrez pour obtenir les trois derniers mois
const dataForChart = allData.slice(-3);

const renderCustomBarShape = (props) => {
  const { fill, x, y, width, height } = props;
  const lineWidth = 6; // Largeur de la ligne colorée à la fin de la barre

  return (
    <g>
      {/* Partie grise de la barre */}
      <rect
        x={x}
        y={y}
        width={width - lineWidth}
        height={height}
        fill="#38495424"
      />
      {/* Partie colorée de la barre */}
      <rect
        x={x + width - lineWidth}
        y={y}
        width={lineWidth}
        height={height}
        fill={fill}
      />
    </g>
  );
};

const dummyData = [
  { id: "275", leadCount: 5, leadColor: "#FFD700" }, // Exemple pour la Palestine
  { id: "270", leadCount: 10, leadColor: "#C0C0C0" }, // Exemple pour la Gambia
  // Autres données...
];

const NewLeadsDashboardChat = ({ allStats, chartData }) => {
  if (!allStats) {
    return <div>Loading...</div>;
  }
  return (
    <div className="dashboardContainerTopLeftWrapperBottomItem">
      <div className="dashboardContainerTopLeftWrapperBottomItemLeft">
        <span>New Leads</span>
        <span>{allStats?.totalNotAttribuedLeads || 0} </span>
        {/* <span>+7%(+104)</span> */}
      </div>
      <div className="dashboardContainerTopLeftWrapperBottomItemRight">
        <ResponsiveContainer width="100%" height={113}>
          <BarChart
            width={730}
            height={250}
            data={chartData}
            margin={{
              top: 10,
              right: 0,
              left: -28,
              bottom: -9,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#f9f9f9" />
            <XAxis
              dataKey="month"
              tickLine={false}
              tick={{ fill: "#c0c0c0", fontSize: 10, dx: 0, dy: 5 }}
            />
            <YAxis
              axisLine={{ stroke: "#e3e3e3", strokeWidth: 0.5 }}
              tickLine={false}
              tick={{ fill: "#c0c0c0", fontSize: 10, dx: -5 }}
              tickFormatter={(value) => {
                if (value >= 1000) {
                  return `${value / 1000}k`;
                }
                return value;
              }}
            />
            <Tooltip />
            <Bar
              dataKey="attribution"
              fill="#7ec5cf"
              barSize={20}
              shape={(props) => renderCustomBarShape(props)}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default NewLeadsDashboardChat;
