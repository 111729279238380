import "./Widgets.css";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarIcon from "@mui/icons-material/Star";
const Widgets = ({ productData, company }) => {
  let rarityText, conditionText;
  let rarityWidth, conditionWidth;

  const assetRarity = productData.assetRarity;
  const assetCondition = productData.assetCondition;
  const supplierRate = productData.supplier.rate;

  // Gestion de la notation par étoiles
  const fullStars = Math.floor(supplierRate);
  const halfStar = supplierRate % 1 >= 0.5 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStar;

  // Définir le texte de la rareté en fonction de l'échelle de rareté
  if (assetRarity <= 2.5) {
    rarityText = "Pas rare";
  } else if (assetRarity <= 4) {
    rarityText = "Rare";
  } else {
    rarityText = "Très rare";
  }

  // Définir le texte de la condition en fonction de l'échelle de condition
  if (assetCondition <= 2.5) {
    conditionText = "Pas en bon état";
  } else if (assetCondition <= 4) {
    conditionText = "En bon état";
  } else {
    conditionText = "En très bon état";
  }

  // Calcul de la largeur en fonction de l'échelle de rareté de 0 à 5, inverse
  rarityWidth = `${(1 - assetRarity / 5) * 100}%`;

  // Calcul de la largeur en fonction de l'échelle de condition de 0 à 5, inverse
  conditionWidth = `${(1 - assetCondition / 5) * 100}%`;

  const stars = [];
  for (let i = 0; i < fullStars; i++) {
    stars.push(<StarIcon className="space-star" key={`full-${i}`} />);
  }
  if (halfStar) {
    stars.push(<StarHalfIcon className="space-star" key="half" />);
  }
  for (let i = 0; i < emptyStars; i++) {
    stars.push(<StarBorderIcon className="space-star" key={`empty-${i}`} />);
  }

  // Extraction de graphData
  const graphData = productData.graphData;

  // Tri des données par année (au cas où elles ne seraient pas triées)
  const sortedGraphData = graphData.sort((a, b) => a.year - b.year);

  // Calcul du pourcentage de variation pour les 5 dernières années
  const firstValue = sortedGraphData[0].value;
  const lastValue = sortedGraphData[sortedGraphData.length - 1].value;
  const percentageChange5Years = ((lastValue - firstValue) / firstValue) * 100;

  // Calcul du pourcentage de variation pour l'année dernière
  const lastYearValue = sortedGraphData[sortedGraphData.length - 2].value;
  const percentageChangeLastYear =
    ((lastValue - lastYearValue) / lastYearValue) * 100;

  return (
    <>
      <div className="widget-tiers wid-margin-right  wid-margin-bottom">
        <span className="widgets-title">Certificat</span>
        <div className="tit1wid">
          {productData.certificat.name},{" "}
          <span className="blck-color">{productData.certificat.number}</span>
        </div>
        {/* <span className="minfo">More Informations</span> */}
      </div>
      <div className="widget-tiers wid-margin-right wid-margin-bottom">
        <span className="widgets-title">L'année dernière</span>
        <div
          className={`widtrend ${
            percentageChangeLastYear < 0
              ? "asset-price-color-down"
              : "asset-price-color-up"
          }`}
        >
          {percentageChangeLastYear < 0 ? (
            <TrendingDownIcon className="trend-size-color-down2" />
          ) : (
            <TrendingUpIcon className="trend-size-color-up2" />
          )}
          {percentageChangeLastYear.toFixed(1)}%
        </div>
      </div>
      <div className="widget-tiers wid-margin-bottom">
        <span className="widgets-title">Les 5 dernières années</span>
        <div
          className={`widtrend ${
            percentageChange5Years < 0
              ? "asset-price-color-down"
              : "asset-price-color-up"
          }`}
        >
          {percentageChange5Years < 0 ? (
            <TrendingDownIcon className="trend-size-color-down2" />
          ) : (
            <TrendingUpIcon className="trend-size-color-up2" />
          )}
          {percentageChange5Years.toFixed(1)}%
        </div>
      </div>
      <div className="widget-tiers wid-margin-right">
        <span className="widgets-title"> Informations du propriétaire</span>
        <div className="tit1wid">
          {productData.supplier.name},{" "}
          <span className="blck-color">{productData.supplier.country}</span>
        </div>
        <div className="star">
          {stars}
          <span className="notetext">{productData.supplier.rate}</span>
        </div>
      </div>
      <div className="widget-tiers wid-margin-right">
        <span className="widgets-title">Rareté de l'actif</span>
        <div className="tit1wid">{rarityText}</div>
        <div className="barwid">
          <div className="barwinprogress" style={{ width: rarityWidth }}></div>
        </div>
        <span className="notetext2">{productData.assetRarity}</span>
      </div>
      <div className="widget-tiers">
        <span className="widgets-title">Conditions de l'actif</span>
        <div className="tit1wid">{conditionText}</div>
        <div className="barwid">
          <div
            className="barwinprogress"
            style={{ width: conditionWidth }}
          ></div>
        </div>
        <span className="notetext2">{productData.assetCondition}</span>
      </div>
    </>
  );
};

export default Widgets;
