import "./WidgetCampaign.css";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
const WidgetCampaign = ({ campaignStats, perCampaignStats }) => {
  const totalConversion = (
    (campaignStats.totalFTDS / campaignStats.totalLeads) *
    100
  ).toFixed(2);

  const campaignCost = perCampaignStats.reduce(
    (acc, curr) => acc + curr.cost,
    0
  );

  const totalProfit = (campaignStats.totalRevenue - campaignCost).toFixed(2);
  return (
    <>
      <div className="widgetCampaignItem">
        <div className="widgetCampaignItemTop">
          <AccountBoxIcon />
        </div>
        <div className="widgetCampaignItemBottom">
          <span>LEADS</span>
          <span>{campaignStats.totalLeads}</span>
        </div>
      </div>
      <div className="widgetCampaignItem">
        <div className="widgetCampaignItemTop">
          <AccountBalanceIcon />
        </div>
        <div className="widgetCampaignItemBottom">
          <span>DEPOSITS</span>
          <span>{campaignStats.totalDeposits}</span>
        </div>
      </div>
      <div className="widgetCampaignItem">
        <div className="widgetCampaignItemTop">
          <StarHalfIcon />
        </div>
        <div className="widgetCampaignItemBottom">
          <span>FTDS</span>
          <span>{campaignStats.totalFTDS}</span>
        </div>
      </div>
      <div className="widgetCampaignItem">
        <div className="widgetCampaignItemTop">
          <CreditCardIcon />
        </div>
        <div className="widgetCampaignItemBottom">
          <span>REVENUE</span>
          <span
            className={`${
              campaignStats.totalRevenue < 0 ? "negativeStatsNumber" : ""
            } `}
          >
            {campaignStats.totalRevenue}€
          </span>
        </div>
      </div>
      <div className="widgetCampaignItem">
        <div className="widgetCampaignItemTop">
          <KeyboardDoubleArrowUpIcon />
        </div>
        <div className="widgetCampaignItemBottom">
          <span>PROFIT</span>

          <span className={`${totalProfit < 0 ? "negativeStatsNumber" : ""} `}>
            {totalProfit}€
          </span>
        </div>
      </div>
      <div className="widgetCampaignItem">
        <div className="widgetCampaignItemTop">
          <TrendingUpIcon />
        </div>
        <div className="widgetCampaignItemBottom">
          <span>CONVERSION</span>
          <span>{isNaN(totalConversion) ? "0 %" : `${totalConversion} %`}</span>
        </div>
      </div>
      <div className="widgetCampaignItem">
        <div className="widgetCampaignItemTop">
          <AttachMoneyIcon />
        </div>
        <div className="widgetCampaignItemBottom">
          <span>COST</span>
          <span>{campaignCost}€</span>
        </div>
      </div>
    </>
  );
};

export default WidgetCampaign;
