import "./FollowPayment.css";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import axios from "axios";
import { useEffect, useState } from "react";
import useFetch from "../../../../../../hooks/useFetch";
import moment from "moment";

const FollowPayment = ({
  setOpen,
  currentData,
  selectedData,
  reFetchFromTable,
  type,
  url,
}) => {
  const { data: payments = [] } = useFetch("payment");
  const [animatedPaidAmount, setAnimatedPaidAmount] = useState(0);
  const [animatedBalance, setAnimatedBalance] = useState(0);
  const [animatedPercentage, setAnimatedPercentage] = useState(0);
  const [allPayments, setAllPayments] = useState([]);
  const [newDeposit, setNewDeposit] = useState({
    amount: 0,
    depositDate: new Date().toISOString(),
    typePayment: "",
  });

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewDeposit((prevDeposit) => ({
      ...prevDeposit,
      [name]: value,
    }));
  };

  const handleAddNewDeposit = async () => {
    let depositDateTime = new Date(newDeposit.depositDate);
    const now = new Date();
    depositDateTime.setHours(
      now.getHours(),
      now.getMinutes(),
      now.getSeconds()
    );

    const updatedDeposit = {
      ...newDeposit,
      depositDate: depositDateTime.toISOString(),
      assignTo: currentData._id,
    };

    const updateLeadBalance =
      (currentData.balance ?? 0) + Number(updatedDeposit.amount);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/${currentData._id}`,
        {
          deposit: [...currentData.deposit, updatedDeposit],
          isFTDS: true,
          balance: updateLeadBalance,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      if (response.data) {
      }

      setNewDeposit({
        amount: 0,
        depositDate: new Date().toISOString(),
        typePayment: "",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const styleBuyingBack = {
    pathColor: `#81cfbd`,
    textColor: "#7ec5cf",
    trailColor: "#d6d6d6",
    backgroundColor: "#81cfbd",
  };

  const handleAddBalance = async (e) => {
    e.preventDefault();
    const leadId = currentData._id;
    const productId = selectedData.product._id;

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/leadPlatform/addBalanceToPayment${url}`,
        { leadId, productId }
      );
    } catch (err) {
      console.error("Error adding balance to payment:", err);
    }
  };

  const animateValue = (start, end, setter, duration = 1000) => {
    const frameDuration = 1000 / 60;
    const totalFrames = Math.round(duration / frameDuration);
    let frame = 0;

    const counter = setInterval(() => {
      frame++;
      const progress = frame / totalFrames;
      const current = start + (end - start) * progress;
      setter(current);

      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);
  };

  const calculateTotalPaid = (onHoldEntry) => {
    return onHoldEntry.amountPay.reduce(
      (total, payment) => total + payment.amount,
      0
    );
  };

  let dataArray;
  switch (type) {
    case "onSafe":
      dataArray = currentData.onSafe ?? [];
      break;
    case "onHold":
      dataArray = currentData.onHold ?? [];
      break;
    case "sold":
      dataArray = currentData.sold ?? [];
      break;
    default:
      dataArray = [];
  }

  const correspondingObject = dataArray.find(
    (entry) => entry._id === selectedData._id
  );

  useEffect(() => {
    // Animation de la balance
    animateValue(0, currentData.balance ?? 0, setAnimatedBalance);

    if (correspondingObject) {
      const totalPaid = calculateTotalPaid(correspondingObject);
      const percentage = (totalPaid / correspondingObject.product.prix) * 100;

      animateValue(0, totalPaid, setAnimatedPaidAmount);
      animateValue(0, percentage, setAnimatedPercentage);
      setAllPayments(correspondingObject.amountPay ?? []);
    }
  }, [currentData, selectedData, type]);

  const handleDeletePayment = async (e, paymentId) => {
    e.preventDefault();

    const deleteObject = {
      paymentId: paymentId,
      onHoldId: correspondingObject._id,
    };
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/leadPlatform/${currentData._id}/deletePayment${url}`,
        deleteObject
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createFollowPayment" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>
                Sales of {currentData.assignTo?.name || "User"} for{" "}
                {selectedData.product.reference}
              </span>
            </div>
            <div className="middleCreateFollowPayment">
              <div className="middleCreateFollowPaymentTop">
                <div className="buyingBackWrapperFollowPayment">
                  <CircularProgressbar
                    value={animatedPercentage}
                    text={`${animatedPercentage.toFixed(2)}%`}
                    styles={buildStyles(styleBuyingBack)}
                  />
                </div>
                <div className="middleCreateFollowPaymentTopItem">
                  <span className="followPaymentNumber">
                    {animatedPaidAmount.toFixed(2)} €
                  </span>
                  <span className="followPaymentText">Already Paid</span>
                </div>
                <div className="middleCreateFollowPaymentTopItem">
                  <span className="followPaymentNumber">
                    {(selectedData.product.prix ?? 0).toLocaleString("en-US")} €
                  </span>
                  <span className="followPaymentText">Total Price</span>
                </div>
                <div className="middleCreateFollowPaymentTopItem">
                  <span className="followPaymentNumber">
                    {animatedBalance.toFixed(2)} €
                  </span>
                  <span className="followPaymentText">Customer Balance</span>
                  {currentData.balance === 0 ||
                  animatedPaidAmount === selectedData.product.prix ? (
                    ""
                  ) : (
                    <button onClick={handleAddBalance}>Buy</button>
                  )}
                </div>
              </div>
              {animatedPercentage === 100 ? (
                " "
              ) : (
                <div className="middleCreateFollowPaymentMiddle">
                  <span className="titleFollowPayment">Add a payment</span>
                  <div className="middleCreateFollowPaymentMiddleWrapper">
                    <div className="middleCreateFollowPaymentMiddleItem">
                      <label>Amount</label>
                      <input
                        type="number"
                        name="amount"
                        value={newDeposit.amount}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="middleCreateFollowPaymentMiddleItem">
                      <label>Type</label>
                      <select
                        onChange={handleChange}
                        value={newDeposit.typePayment}
                        name="typePayment"
                      >
                        <option value="Select">Select</option>
                        {payments.map((payment) => (
                          <option key={payment._id} value={payment._id}>
                            {payment.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="middleCreateFollowPaymentMiddleItem">
                      <label>Date</label>
                      <input
                        type="date"
                        name="depositDate"
                        value={newDeposit.depositDate.slice(0, 10)}
                        onChange={handleChange}
                      />
                    </div>
                    <button
                      className="addFollowPaymentDeposit"
                      onClick={handleAddNewDeposit}
                    >
                      Add
                    </button>
                  </div>
                </div>
              )}
              <div className="middleCreateFollowPaymentBottom">
                <span className="titleFollowPayment">List of payments</span>
                <div className="containerDatatableHistory">
                  <div className="datatableHistoryFollowPayment">
                    <table className="tableDataHistory">
                      <thead className="dtThead">
                        <tr>
                          <th>Amount</th>
                          <th>Type</th>
                          <th>Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allPayments.map((payment) => (
                          <tr key={payment._id}>
                            <td>{payment.amount} €</td>
                            <td>{payment.typePayment}</td>
                            <td>
                              {moment(payment.paymentDate).format("DD/MM/YYYY")}
                            </td>
                            <td>
                              <span
                                className="hover-effect"
                                onClick={(e) =>
                                  handleDeletePayment(e, payment._id)
                                }
                              >
                                cancel
                              </span>
                            </td>
                          </tr>
                        ))}
                        {allPayments.length === 0 && (
                          <tr>
                            <td colSpan="4" style={{ textAlign: "center" }}>
                              No results found...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FollowPayment;
