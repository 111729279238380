import "./ImportLeadCSV.css";
import React, { useState, useCallback, useRef } from "react";
import Papa from "papaparse";
import useFetch from "../../../../../hooks/useFetch";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import { countries } from "countries-list";

const ImportLeadCSV = ({
  setOpen,
  setShowLoader,
  reFetchFromTable,
  reFetchAllLead,
  setIsAllChecked,
}) => {
  const { data: campagne } = useFetch("campaign");
  const { data: statut } = useFetch("statut");
  const { data: leads, reFetch } = useFetch("lead");
  const [campagneDropdownOpen, setCampagneDropdownOpen] = useState(false);
  const [statutDropdownOpen, setStatutDropdownOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [fileName, setFileName] = useState("");
  const [numberOfLead, setNumberOfLeads] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedHeaders, setSelectedHeaders] = useState({
    Name: { firstName: "", lastName: "" },
    Mail: "",
    Phone: "",
    Country: "",
  });

  const [selectedCampagne, setSelectedCampagne] = useState(
    "Selectionner une campagne"
  );

  function convertCountryNameToISO(name) {
    const customMapping = {
      France: "FR",
      Belgique: "BE",
      Suisse: "CH",
      Allemagne: "DE",
    };
  
    if (customMapping[name]) {
      return customMapping[name];
    }
  
    let isoCode = null;
    Object.entries(countries).forEach(([code, country]) => {
      if (country.name === name) {
        isoCode = code;
      }
    });
  
    return isoCode || name; 
  }

  const formatLeads = () => {
    return csvData.map((row) => {
      return {
        name: `${row[selectedHeaders.Name.firstName] || ""} ${
          row[selectedHeaders.Name.lastName] || ""
        }`.trim(),
        email: row[selectedHeaders.Mail],
        phone: row[selectedHeaders.Phone],
        country: convertCountryNameToISO(row[selectedHeaders.Country]),
        campagne: selectedCampagne.id,
      };
    });
  };

  const checkDoublons = () => {
    const importedLeads = formatLeads();

    let doublonsDansImport = 0;
    let emailsVus = new Set();

    importedLeads.forEach((lead) => {
      const email = lead.email;
      if (email && emailsVus.has(email)) {
        doublonsDansImport++;
      } else if (email) {
        emailsVus.add(email);
      }
    });
    const doublonsAvecDB = Array.isArray(leads)
      ? leads.filter((existingLead) => emailsVus.has(existingLead.email))
      : [];

    const nombreDeDoublonsAvecDB = doublonsAvecDB.length;

    return { doublonsDansImport, nombreDeDoublonsAvecDB };
  };

  const fileInputRef = useRef(null);

  const toggleCampagneDropdown = () => {
    setCampagneDropdownOpen(!campagneDropdownOpen);
  };

  const handleSelectCampagne = (campagneId, campagneTitle) => {
    setSelectedCampagne({ id: campagneId, title: campagneTitle });
    setCampagneDropdownOpen(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsFileLoaded(true);
    setFileName(file.name);
    setNumberOfLeads(file.length);

    const fileType = file.type || file.name.split(".").pop().toLowerCase();
    switch (fileType) {
      case "text/csv":
      case "csv":
        parseCSV(file);
        break;
      case "text/tab-separated-values":
      case "tsv":
        parseTSV(file);
        break;
      default:
        console.error("Type de fichier non pris en charge");
      // Gérer les types de fichiers non pris en charge
    }
  };

  const parseCSV = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (result) => {
        setCsvHeaders(result.meta.fields);
        setCsvData(result.data);
      },
    });
  };

  const parseTSV = (file) => {
    Papa.parse(file, {
      header: true,
      delimiter: "\t",
      complete: (result) => {
        setCsvHeaders(result.meta.fields);
        setCsvData(result.data);
      },
    });
  };

  const handleHeaderMappingChange = (header, csvHeader, subfield = null) => {
    if (subfield) {
      setSelectedHeaders((prev) => ({
        ...prev,
        [header]: { ...prev[header], [subfield]: csvHeader },
      }));
    } else {
      setSelectedHeaders({ ...selectedHeaders, [header]: csvHeader });
    }
  };

  // Vérifie les doublons dans la liste CSV
  const checkForDuplicatesInCSV = (email, rowIndex) => {
    if (!email) return false; // S'assurer que l'email est valide

    return csvData.some((row, index) => {
      return index !== rowIndex && row[selectedHeaders.Mail] === email;
    });
  };

  // Vérifie les doublons dans les leads existants
  const checkForDuplicatesInLeads = (email) => {
    if (!email) return false; // S'assurer que l'email est valide

    return Array.isArray(leads) && leads.some((lead) => lead.email === email);
  };

  const createTableRows = () => {
    return csvData.map((row, rowIndex) => {
      const email = row[selectedHeaders.Mail];
      const isDuplicateInCSV = email
        ? checkForDuplicatesInCSV(email, rowIndex)
        : false;
      const isDuplicateInLeads = email
        ? checkForDuplicatesInLeads(email)
        : false;
      const isDuplicate = isDuplicateInCSV || isDuplicateInLeads;

      return (
        <tr key={rowIndex} className={isDuplicate ? "duplicate" : ""}>
          {Object.entries(selectedHeaders).map(
            ([header, mapping], colIndex) => {
              let cellValue = "";
              if (header === "Name") {
                cellValue = `${row[mapping.firstName] || ""} ${
                  row[mapping.lastName] || ""
                }`.trim();
              } else if (mapping) {
                cellValue = row[mapping];
              }
              return <td key={colIndex}>{cellValue}</td>;
            }
          )}
        </tr>
      );
    });
  };

  const handleCreateListOfLead = async () => {
    const formattedLeads = formatLeads();
    let emailsTraites = new Set();
    let leadsToSend = [];

    formattedLeads.forEach((lead) => {
      if (!emailsTraites.has(lead.email)) {
        emailsTraites.add(lead.email);
        leadsToSend.push({ ...lead, campaign: selectedCampagne.id });
      }
    });

    setOpen(false);
    setShowLoader(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/batchCreate`,
        { leads: leadsToSend }
      );
      setShowLoader(false);
      reFetchFromTable();
      reFetchAllLead();
      setIsAllChecked(false);
    } catch (err) {
      console.error("Error while sending leads for processing:", err);
    }
  };

  const { doublonsDansImport, nombreDeDoublonsAvecDB } = checkDoublons();

  return (
    <>
      <div className="overlay"></div>
      <div className="popUpImportLeadCsv">
        <div className="closeIconContainer">
          <CancelIcon onClick={() => setOpen(false)} />
        </div>
        <div
          className={`importContainer ${isFileLoaded ? "afterFileLoad" : ""}`}
        >
          {!isFileLoaded && (
            <div className="fileUploadContainer">
              <button
                className="fileUploadButton"
                onClick={() => fileInputRef.current.click()}
              >
                Importer un fichier (.csv, .tsv)
              </button>
              <input
                ref={fileInputRef}
                type="file"
                accept=".csv, .tsv"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>
          )}
          {isFileLoaded && (
            <div className="topImportContainer">
              <span>
                Fichier Importé :
                <span style={{ color: "var(--primary-color)" }}>
                  {fileName}
                </span>
              </span>
            </div>
          )}

          <div
            className={`bottomImportContainer ${
              isFileLoaded ? "afterFileLoad" : ""
            }`}
          >
            {isFileLoaded && (
              <div className="headerMappingContainer">
                {Object.keys(selectedHeaders).map((header) => (
                  <div key={header} className="headerMapping">
                    <span>{header}: </span>
                    {header === "Name" ? (
                      <>
                        <select
                          onChange={(e) =>
                            handleHeaderMappingChange(
                              header,
                              e.target.value,
                              "firstName"
                            )
                          }
                        >
                          <option value="Select">Select</option>
                          {csvHeaders.map((h) => (
                            <option key={h} value={h}>
                              {h}
                            </option>
                          ))}
                        </select>
                        <select
                          onChange={(e) =>
                            handleHeaderMappingChange(
                              header,
                              e.target.value,
                              "lastName"
                            )
                          }
                        >
                          <option value="Select">Select</option>
                          {csvHeaders.map((h) => (
                            <option key={h} value={h}>
                              {h}
                            </option>
                          ))}
                        </select>
                      </>
                    ) : (
                      <select
                        onChange={(e) =>
                          handleHeaderMappingChange(header, e.target.value)
                        }
                      >
                        <option value="Select">Select</option>
                        {csvHeaders.map((h) => (
                          <option key={h} value={h}>
                            {h}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                ))}
              </div>
            )}

            {isFileLoaded && (
              <div className="tableContainer">
                <table className="previewTable">
                  <thead>
                    <tr>
                      {Object.keys(selectedHeaders).map((header) => (
                        <th key={header}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>{createTableRows()}</tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <div className="separatordiv">
          <div className="linediv"></div>
        </div>
        <div className="rightDivImportCsbLead">
          <div className="numberOfleadDisplay">
            <span>Nombre de leads</span>
            <span> {csvData.length}</span>
            <span> Nombre de doublons</span>
            <span
              style={{ color: "#e76161", fontWeight: "bold", fontSize: 18 }}
            >
              {nombreDeDoublonsAvecDB}
            </span>
            <span> Nombre de doublons dans l'import</span>
            <span
              style={{ color: "#e76161", fontWeight: "bold", fontSize: 18 }}
            >
              {doublonsDansImport}
            </span>
          </div>

          <div className="customSelect" onClick={toggleCampagneDropdown}>
            <div className="selectValue">
              {selectedCampagne.title || "Select a campaign"}
            </div>
            <div
              className={`optionsContainer ${
                campagneDropdownOpen ? "show" : ""
              }`}
            >
              {Array.isArray(campagne) &&
                campagne.map((c, index) => (
                  <div
                    key={index}
                    className="option"
                    onClick={() => handleSelectCampagne(c._id, c.title)}
                  >
                    {c.title}
                  </div>
                ))}
            </div>
            {errorMessage && (
              <div className="error-messageImport">{errorMessage}</div>
            )}
          </div>

          <button
            onClick={handleCreateListOfLead}
            disabled={!selectedCampagne.id}
          >
            Enregistrer les Leads
          </button>
        </div>
      </div>
    </>
  );
};

export default ImportLeadCSV;
