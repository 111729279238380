import "./CrmPage.css";
import NavigationTabs from "../../../components/Navigation/NavigationTabs/NavigationTabs";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { SettingsTitles } from "../../../constants/navigationTitles";
import CRMContainer from "../../../components/Settings/CRM/CRMContainer/CRMContainer";

const CrmPage = () => {
  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <div className="navigationAndFilter">
            <NavigationTabs tabs={SettingsTitles} />
          </div>
        </div>
        <div className="bottomCrmPage">
          <CRMContainer />
        </div>
      </div>
    </div>
  );
};

export default CrmPage;
