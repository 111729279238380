import "./OfferQuery.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DownloadIcon from "@mui/icons-material/Download";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useState } from "react";
import Countdown from "../../SidebarRight/OnHold/Countdown/Countdown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import AcceptOffer from "../../SidebarRight/Offers/AcceptOffer/AcceptOffer";
import DeclineOffer from "../../SidebarRight/Offers/DeclineOffer/DeclineOffer";
const OfferQuery = ({ selectedTemplate, handleOpenProductInfo, offers }) => {
  const [openInvoice, setOpenInvoice] = useState(false);
  const [expiredOffers, setExpiredOffers] = useState(new Set());
  const [openAcceptButton, setOpenAcceptButton] = useState(false);
  const [openDeclineButton, setOpenDeclineButton] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const handleExpire = (offerId) => {
    setExpiredOffers((prevExpiredOffers) =>
      new Set(prevExpiredOffers).add(offerId)
    );
  };

  const handleOpenAcceptButton = (data) => {
    setSelectedData(data);
    setOpenAcceptButton(true);
  };

  const handleOpenDeclineButton = (data) => {
    setSelectedData(data);
    setOpenDeclineButton(true);
  };

  const renderProductInfo = (product) => {
    const truncateText = (text, limit) => {
      return text.length > limit ? text.substring(0, limit) + "..." : text;
    };
    const sortedTypes = [...selectedTemplate.types].sort(
      (a, b) => a.indexPresentation - b.indexPresentation
    );

    const vignetteAttributes = [];
    const regularAttributes = [];

    sortedTypes.forEach((type) => {
      const productAttribute = product.product.attributs.find(
        (attr) => attr.key === type.name
      );
      let attributeValue = productAttribute ? productAttribute.value : "";

      if (type.abbreviation) {
        attributeValue += ` ${type.abbreviation}`;
      }

      const templateAttribute = type.attributs.find(
        (attr) => attr.value === productAttribute?.value
      );
      const attributeStyle = {
        color: templateAttribute?.textColor || "#000",
        backgroundColor: templateAttribute?.backgroundColor || "#fff",
      };

      if (type.isVignette) {
        vignetteAttributes.push(
          <span
            className="asset-chip3 asset-chip-color5"
            style={attributeStyle}
          >
            {attributeValue}
          </span>
        );
      } else {
        regularAttributes.push(attributeValue);
      }
    });

    const formattedPrice = product.product.prix.toLocaleString("fr-FR");

    // Combine attributes into a single string
    const attributesString = regularAttributes.join(" - ");

    // Truncate the combined string if necessary
    const truncatedAttributesString = truncateText(attributesString, 16); // Assuming a limit of 50 characters

    const fullAttributesString = product.product.attributs
      .map((attr) => ` ${attr.value}`)
      .join(", ");

    const differencePourcentage =
      ((product.amount - product.product.prix) / product.product.prix) * 100;
    const difference = product.amount - product.product.prix;

    const isOfferLower = product.amount < product.product.prix;

    return (
      <>
        <div className="item-offer">
          <div className="item-offer-title">
            <span className="asset-ti-offer">
              {attributesString}
              <span className="question-mark">?</span>
            </span>
            {vignetteAttributes}
            <span className="price-asset-offer">€ {formattedPrice}</span>
          </div>

          <div className="item-offer-details">
            <span className="name-offer">{product.companyName}</span>
            <span
              className={`asset-percent2 ${
                isOfferLower ? "asset-price-color-down" : "asset-price-color-up"
              }`}
            >
              {isOfferLower ? (
                <TrendingDownIcon className="trend-size-color-down" />
              ) : (
                <TrendingUpIcon className="trend-size-color-up" />
              )}
              {Math.abs(differencePourcentage).toFixed(2)}%
            </span>
            <span
              className={`offer-profit ${isOfferLower ? "offer-loss" : ""}`}
            >
              {isOfferLower ? "- € " : "+ € "}
              {Math.abs(difference).toLocaleString("en-US")}
            </span>
            <span className="price-offer-offer">
              € {product.amount.toLocaleString("en-US")}
            </span>
          </div>

          <div className="item-actions-details">
            <span className="chrono">
              <Countdown
                expirationDate={product.expirationDate}
                onExpire={() => handleExpire(product._id)}
              />
            </span>
            {!expiredOffers.has(product._id) && (
              <>
                <span
                  className="accept"
                  onClick={() => handleOpenAcceptButton(product)}
                >
                  accept
                </span>
                <span
                  className="decline"
                  onClick={() => handleOpenDeclineButton(product)}
                >
                  decline
                </span>
              </>
            )}
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <span className="titleQueryMobile">Offers</span>
      <div className="item-safeQueryOffer">
        {offers.map((product, index) => (
          <div
            key={index}
            className="safeQueryOffer"
            onClick={() => handleOpenProductInfo(product.product)}
          >
            {renderProductInfo(product)}
          </div>
        ))}
      </div>
      {openAcceptButton && (
        <AcceptOffer
          setOpen={setOpenAcceptButton}
          selectedData={selectedData}
        />
      )}
      {openDeclineButton && (
        <DeclineOffer
          setOpen={setOpenDeclineButton}
          selectedData={selectedData}
        />
      )}
    </>
  );
};

export default OfferQuery;
