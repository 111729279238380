import React from "react";
import { generateInvoicePDF } from "../../../../FactureUtils";
import "./FactureGenerator.css";
import useFetch from "../../../../hooks/useFetch";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useData } from "../../../../context/DataContext";

dayjs.extend(utc);
dayjs.extend(timezone);

const FactureGenerator = ({ product, connectedLead, selectedTemplate }) => {
  const { company } = useData();

  const generateInvoice = () => {
    // Directly format the Paris time using Day.js
    const formattedDate = dayjs().tz("Europe/Paris").format("DD/MM/YYYY HH:mm");

    // Generate a random five-digit invoice number
    const invoiceNumber = Math.floor(10000 + Math.random() * 90000);

    // Call the function to generate the invoice PDF with the formatted date and invoice number
    generateInvoicePDF(
      product,
      company,
      formattedDate,
      invoiceNumber,
      selectedTemplate,
      connectedLead
    );
  };

  return (
    <span className="action" onClick={generateInvoice}>
      <DownloadIcon className="icoresize" />
      Facture
    </span>
  );
};

export default FactureGenerator;
