import "./CreateCampaign.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import axios from "axios";

const CreateCampaign = ({ setOpen, reFetchFromTable }) => {
  const [newCampaign, setNewCampaign] = useState({
    title: "",
  });

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCampaign((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleCreateCampaign = async (e) => {
    e.preventDefault();

    try {
      const storedToken = localStorage.getItem("authToken");

      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/campaign/`,
        newCampaign,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      reFetchFromTable();
      setNewCampaign({ title: "" });
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createStatus" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>Add Campaign</span>
            </div>
            <div className="middleCreateDeposit">
              <label>Name</label>
              <input
                type="text"
                name="title"
                value={newCampaign.title}
                onChange={handleChange}
              />
              <label>Type</label>
              <select
                className="depositSelect"
                name="type"
                onChange={handleChange}
                defaultValue="default"
              >
                <option value="default">Select</option>
                <option value="CPA">CPA</option>
                <option value="CPL">CPL</option>
                <option value="RS">Revenue Sharing</option>
                <option value="FLAT">Flat</option>
              </select>
              <label>Cost</label>
              <input type="text" name="typePrice" onChange={handleChange} />
            </div>
            <div className="bottomCreateStatus">
              <button className="cancelButton">Cancel</button>
              <button className="saveButton" onClick={handleCreateCampaign}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCampaign;
