import "./LeftSideInfo.css";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContextLead } from "../../../../context/leadContextAuth";
import ConnectionHistory from "../ConnectionHistory/ConnectionHistory";
import { useData } from "../../../../context/DataContext";
import { useSocket } from "../../../../context/SocketContext";

const LeftSideInfo = ({ currentData, reFetchFromTable }) => {
  const { storeToken, authenticateLead } = useContext(AuthContextLead);
  const [openDropdown, setOpenDropdown] = useState(false);
  const { campaigns, status, users } = useData();
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [selectedStatus, setSelectedStatus] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState(
    currentData.assignTo ? currentData.assignTo._id : "Not assign"
  );
  const { socket } = useSocket();
  const [leadsOnlineStatus, setLeadsOnlineStatus] = useState({});

  const [openHistoryConnection, setOpenHistoryConnection] = useState(false);

  const [leadData, setLeadData] = useState(currentData);
  const [updateLead, setUpdateLead] = useState({
    email: leadData.email,
    phone: leadData.phone,
    password: leadData.password,
    country: leadData.country,
    city: leadData.city,
    address: leadData.address,
    postalCode: leadData.postalCode,
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    if (currentData.name) {
      const names = currentData.name.split(" ");
      if (names.length > 1) {
        setLastName(names.pop());
        setFirstName(names.join(" "));
      } else {
        setFirstName(names[0]);
        setLastName("");
      }
    }
  }, [currentData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const pickerElement = document.querySelector(".dropdownSelectSales");
      if (pickerElement && !pickerElement.contains(event.target)) {
        setOpenDropdown(false);
      }
    };

    if (openDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdown]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else {
      setUpdateLead((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleCampaignChange = async (event, leadId) => {
    const newCampaignId = event.target.value;
    setSelectedCampaign((prev) => ({ ...prev, [leadId]: newCampaignId }));

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/${leadId}`,
        { campaign: newCampaignId }
      );

      reFetchFromTable();
    } catch (err) {
      console.log(err);
    }
  };

  const handleStatusChange = async (event, leadId) => {
    const newStatutId = event.target.value;
    setSelectedStatus((prev) => ({ ...prev, [leadId]: newStatutId }));

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/${leadId}`,
        { status: newStatutId }
      );

      reFetchFromTable();
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdatedLead = async (e) => {
    e.preventDefault();
    const updatedName = `${firstName} ${lastName}`.trim();
    const dataToSend = { ...updateLead, name: updatedName };

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/${currentData._id}`,
        dataToSend
      );
      setLeadData(dataToSend);
      setIsEditing(false);
      reFetchFromTable();
    } catch (err) {
      console.log(err);
    }
  };

  const handleImpersonateLead = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/authLead/impersonateLead`,
        { leadId: currentData._id }
      );
      const { authToken } = response.data;

      // Mettre à jour le contexte avec le nouveau token
      storeToken(authToken);
      await authenticateLead();

      // Ouvrir le tableau de bord du lead dans un nouvel onglet
      window.open("/", "_blank");
    } catch (error) {
      console.error("Error during impersonation:", error);
    }
  };

  const assignedUser =
    users.find((user) => user._id === selectedAssignee) || {};

  const getInitials = (name) => {
    const parts = name.split(" ");
    const initials = parts.map((part) => part.charAt(0).toUpperCase()).join("");
    return initials.slice(0, 2);
  };

  const getColorClass = (name) => {
    const colorClasses = [
      "red",
      "blue",
      "purple",
      "green",
      "pink",
      "orange",
      "teal",
      "yellow",
      "brown",
      "gray",
    ];
    const index = name.length % colorClasses.length;
    return colorClasses[index];
  };

  const fullName = currentData.name || "Unknown";

  useEffect(() => {
    if (!socket) return;

    // Quand on reçoit "updateLeadsStatus", on met à jour leadsOnlineStatus
    const handleUpdateLeadsStatus = (connectedLeads) => {
      // connectedLeads est un objet { email1: socketId, email2: socketId, ... }
      // On transforme ça en { email1: true, email2: true, ... } si on veut
      let statusMap = {};
      Object.keys(connectedLeads).forEach((email) => {
        statusMap[email] = true;
      });
      setLeadsOnlineStatus(statusMap);
    };

    // S'abonner
    socket.on("updateLeadsStatus", handleUpdateLeadsStatus);

    // Au montage, on peut demander l’état actuel
    socket.emit("requestCurrentLeadsStatus");

    // Quand on reçoit la liste courante (db: isOnline)
    const handleCurrentLeadsStatus = (leads) => {
      const initialStatus = {};
      leads.forEach((lead) => {
        initialStatus[lead.email] = lead.isOnline;
      });
      setLeadsOnlineStatus(initialStatus);
    };
    socket.on("currentLeadsStatus", handleCurrentLeadsStatus);

    // Cleanup
    return () => {
      socket.off("updateLeadsStatus", handleUpdateLeadsStatus);
      socket.off("currentLeadsStatus", handleCurrentLeadsStatus);
    };
  }, [socket]);

  return (
    <>
      <div className="popupLeftSideTop">
        <div className="editIconPopUp">
          <EditIcon onClick={() => setIsEditing(true)} />
        </div>
        <div className="ppCustomer">
          <div
            className={`connectedDot ${
              leadsOnlineStatus[currentData.email] ? "online" : ""
            }`}
            onClick={() => setOpenHistoryConnection(!openHistoryConnection)}
          ></div>
          <div
            className={`circleAgence ${getColorClass(fullName)}`}
            style={{
              width: "90px",
              height: "90px",
              fontSize: "35px",
              fontWeight: "bold",
            }}
          >
            {getInitials(fullName)}
          </div>
        </div>
        <div className="popupLeftSideTopName">
          <span>{fullName}</span>
          <RemoveRedEyeIcon onClick={handleImpersonateLead} />
        </div>
        <span style={{ color: "#00B161" }}>{currentData.reference}</span>
      </div>
      <div className="popupLeftSideBottom">
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">First Name</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="firstName"
            value={firstName}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Last Name</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="lastName"
            value={lastName}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Civility</label>
          <select className="popupLeftSideBottomFormSelect">
            <option value="mr">Mr</option>
            <option value="ms">Ms</option>
          </select>
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Email</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="email"
            name="email"
            value={isEditing ? updateLead.email : leadData.email}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Phone</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="phone"
            value={isEditing ? updateLead.phone : leadData.phone}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Password</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="password"
            value={isEditing ? updateLead.password : leadData.password}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Country</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="country"
            value={isEditing ? updateLead.country : leadData.country}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Campaign</label>
          <select
            className="popupLeftSideBottomFormSelect"
            value={
              selectedCampaign[currentData._id] ||
              (currentData.campaign ? currentData.campaign._id : "default")
            }
            onChange={(e) => handleCampaignChange(e, currentData._id)}
          >
            {currentData.campaign && (
              <option value={currentData.campaign._id}>
                {currentData.campaign.title}
              </option>
            )}
            {campaigns.map((camp) => {
              if (
                !currentData.campaign ||
                camp._id !== currentData.campaign._id
              ) {
                return (
                  <option key={camp._id} value={camp._id}>
                    {camp.title}
                  </option>
                );
              }
              return null;
            })}
          </select>
        </div>

        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Status</label>
          <select
            className="popupLeftSideBottomFormSelect"
            value={
              selectedStatus[currentData._id] ||
              (currentData.status ? currentData.status._id : "default")
            }
            onChange={(e) => handleStatusChange(e, currentData._id)}
          >
            {currentData.status && (
              <option value={currentData.status._id}>
                {currentData.status.title}
              </option>
            )}
            {status.map((stat) => {
              if (!currentData.status || stat._id !== currentData.status._id) {
                return (
                  <option key={stat._id} value={stat._id}>
                    {stat.title}
                  </option>
                );
              }
              return null;
            })}
          </select>
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Address</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="address"
            value={isEditing ? updateLead.address : leadData.address}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">Postal Code</label>
          <input
            className="popupLeftSideBottomFormInput"
            name="postalCode"
            value={isEditing ? updateLead.postalCode : leadData.postalCode}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
        <div className="popupLeftSideBottomFormRow">
          <label className="popupLeftSideBottomFormLabel">City</label>
          <input
            className="popupLeftSideBottomFormInput"
            type="text"
            name="city"
            value={isEditing ? updateLead.city : leadData.city}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        </div>
      </div>
      <div className="containerNameAssign">
        {isEditing ? (
          <div className="isEditingButton">
            <button
              className="isEditingCancel"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </button>
            <button className="isEditingValidate" onClick={handleUpdatedLead}>
              Save
            </button>
          </div>
        ) : (
          <div className="popupLeftSideBottomFormRowImgAndName">
            <label className="popupLeftSideBottomFormLabel">Assign to</label>

            <div
              className="imgAndNameAssignTo"
              onClick={() => setOpenDropdown(true)}
            >
              {assignedUser && (
                <>
                  {assignedUser.pPicture && (
                    <img
                      src={assignedUser.pPicture}
                      alt="Assigned person's profile"
                    />
                  )}
                  <span>{assignedUser.name || "Unassigned"}</span>
                  {/* {openDropdown ? (
                    <KeyboardArrowDownIcon style={{ fontSize: "16px" }} />
                  ) : (
                    <KeyboardArrowUpIcon style={{ fontSize: "16px" }} />
                  )} */}
                </>
              )}
              {!assignedUser && <span>Not Assign</span>}
            </div>
          </div>
        )}
      </div>
      {/* {openDropdown && (
        <div
          className="dropdownSelectSales"
          onClick={() => setOpenDropdown(false)}
        >
          {users
            .filter(
              (u) => u.isSales && (!assignedUser || u._id !== assignedUser._id)
            )
            .map((u) => (
              <div
                className="imgAndNameAssignToDropdowm"
                key={u._id}
                onClick={() => handleAssignmentChange(u._id, currentData._id)}
              >
                <img src={u.pPicture} alt={u.name} />
                <span>{u.name}</span>
              </div>
            ))}
        </div>
      )} */}
      {openHistoryConnection && (
        <ConnectionHistory
          data={currentData}
          setOpen={setOpenHistoryConnection}
        />
      )}
    </>
  );
};

export default LeftSideInfo;
