import "./AttributionTable.css";
import {
  handleExportButtonClick,
  handleCheckboxChange,
} from "../../../../utils/datatable.utils";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DownloadIcon from "@mui/icons-material/Download";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ImportLeadCSV from "../Import-CreateLead/ImportLeadCSV/ImportLeadCSV";
import AddIcon from "@mui/icons-material/Add";
import SelectOptionsTable from "../../../SelectOptionsTable/SelectOptionsTable";
import CreateSingleLead from "../Import-CreateLead/CreateSingleLead/CreateSingleLead";
import moment from "moment";
import DeleteMessage from "../../../DeleteMessage/DeleteMessage";
import axios from "axios";
import AssignSingleLead from "../AssignLead/AssignSingleLead/AssignSingleLead";
import AssignAllLead from "../AssignLead/AssignAllLead/AssignAllLead";
import { countries } from "countries-list";
import PacmanLoaderPopUp from "../../../Loaders/PacmanLoaderPopUp/PacmanLoaderPopUp";
import DeleteAllMessageCustomers from "../../Customers/DeleteAllMessageCustomers/DeleteAllMessageCustomers";
import ClimbingBoxLoaderPopup from "../../../Loaders/ClimbingBoxLoaderPopup/ClimbingBoxLoaderPopup";
import PulseLoaderPopup from "../../../Loaders/PulseLoaderPopup/PulseLoaderPopup";
import PopupCustomerContainer from "../../PopupCustomer/PopupClientContainer/PopupCustomerContainer";

const Attribution = ({
  data,
  reFetch,
  currentPage,
  setPage,
  totalLeads,
  selectedFilters,
  setSelectedFilters,
  allLeadIds,
  campaigns,
  allLeads,
  reFetchAllLead,
}) => {
  const [perPage, setPerPage] = useState(50);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [isAllRowsHighlighted, setIsAllRowsHighlighted] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openImportLeadCsv, setOpenImportLeadCsv] = useState(false);
  const [showPacmanLoader, setShowPacmanLoader] = useState(false);
  const [showClimbingLoader, setShowClimbingLoader] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  const [openCreateLeadPopUp, setOpenCreateLeadPopUp] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [openDeleteAllMessages, setOpenDeleteAllMessage] = useState(false);
  const [openAssignSingleLead, setOpenAssignSingleLead] = useState(false);
  const [openAssignAllLead, setOpenAssignAllLead] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [showPulseLoader, setShowPulseLoader] = useState(false);
  const [openPopupCustomer, setOpenPopupCustomer] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [searchContainerRef, setSearchContainerRef] = useState(null);

  const tableRef = useRef(null);

  useEffect(() => {
    reFetch();
  }, [currentPage]);

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage.selected + 1);
  };

  const handleExportCsv = () => {
    if (checkedItems.length > 0) {
      handleExportButtonClick(data, checkedItems);
    } else {
      alert("Please select at least one checkbox to export.");
    }
  };

  const handleCheckboxChangeWrapper = (event, itemId) => {
    handleCheckboxChange(
      event,
      itemId,
      data,
      checkedItems,
      setIsAllChecked,
      setCheckedItems
    );
    setHighlightedRows((prevRows) => {
      if (event.target.checked) {
        return [...prevRows, itemId];
      } else {
        return prevRows.filter((rowId) => rowId !== itemId);
      }
    });
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setHighlightedRows([]);
    }
  }, [checkedItems]);

  const handleHeaderCheckboxClick = (e) => {
    e.preventDefault();
    if (checkedItems.length > 0 || isAllChecked) {
      setCheckedItems([]);
      setIsAllChecked(false);
    } else {
      setShowSelectAllPopup(true);
    }
  };

  const selectCurrentPage = () => {
    const currentPageItems = data.map((item) => item._id);
    setCheckedItems(currentPageItems);
  };

  const selectAllPages = async () => {
    await reFetch(true);
  };

  useEffect(() => {
    if (allLeadIds && allLeadIds.length > 0) {
      setCheckedItems(allLeadIds);
    }
  }, [allLeadIds]);

  const handleOpenDeleteMessage = (leadId) => {
    setOpenDeleteMessage(true);
    setSelectedDeletedId(leadId);
  };
  const handleOpenAssignSinglelead = (leadId) => {
    setOpenAssignSingleLead(true);
    setSelectedId(leadId);
  };

  const handleCampaignChange = async (event, leadId) => {
    const newCampaignId = event.target.value;
    setSelectedCampaign((prev) => ({ ...prev, [leadId]: newCampaignId }));

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/${leadId}`,
        { campaign: newCampaignId }
      );
      reFetch();
    } catch (err) {
      console.log(err);
    }
  };
  const handleCampagneFilterChange = (e) => {
    setSelectedFilters((filters) => ({ ...filters, campaign: e.target.value }));
  };
  const handleCountryFilterChange = (e) => {
    setSelectedFilters((filters) => ({ ...filters, country: e.target.value }));
  };

  function getCountryNameByISO(code) {
    return countries[code]?.name || code;
  }

  const countriesArray = Object.keys(countries).map((code) => ({
    code: code,
    name: countries[code].name,
  }));

  const sortedCountries = countriesArray.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const handleOpenPopupCustomer = (data) => {
    setSelectedData(data);
    setOpenPopupCustomer(true);
  };

  // Filtrer pour obtenir uniquement les leads non attribués
  const unassignedLeads = Array.isArray(allLeads)
    ? allLeads.filter((lead) => !lead.isAttribued)
    : [];

  // Filter no attribued lead
  const filteredLeads =
    searchValue.length > 0
      ? unassignedLeads.filter(
          (lead) =>
            lead.reference.toLowerCase().includes(searchValue.toLowerCase()) ||
            lead.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            lead.email.toLowerCase().includes(searchValue.toLowerCase())
        )
      : unassignedLeads;

  const handleClickOutside = (event) => {
    if (searchContainerRef && !searchContainerRef.contains(event.target)) {
      setSearchActive(false);
      setSearchValue("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchContainerRef]);

  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>Customers to assign : {totalLeads} result(s)</span>
              <select
                value={selectedFilters.campaign}
                onChange={handleCampagneFilterChange}
                className={
                  selectedFilters.campaign !== "All Campaign"
                    ? "greenBackgroundAfterSelect"
                    : ""
                }
              >
                <option value="All Campaign">All Campaign</option>
                {Array.isArray(campaigns) &&
                  campaigns.map((camp) => (
                    <option key={camp._id} value={camp._id}>
                      {camp.title}
                    </option>
                  ))}
              </select>
              <select
                value={selectedFilters.country}
                onChange={handleCountryFilterChange}
                className={
                  selectedFilters.country !== "All Countries"
                    ? "greenBackgroundAfterSelect"
                    : ""
                }
              >
                <option value="All Countries">All Countries</option>
                {sortedCountries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="rightElements">
              <div className="searchContainer" ref={setSearchContainerRef}>
                {!searchActive && (
                  <button onClick={() => setSearchActive(true)}>
                    <SearchIcon />
                    <span>Search</span>
                  </button>
                )}
                {searchActive && (
                  <div>
                    <input
                      type="text"
                      className="searchInput"
                      onChange={(e) => setSearchValue(e.target.value)}
                      autoFocus
                    />
                    <div className="searchResults">
                      {searchValue.length > 0 &&
                        filteredLeads.map((lead, index) => (
                          <div
                            key={index}
                            className="searchResultItem"
                            onClick={() => handleOpenPopupCustomer(lead, index)}
                          >
                            {lead.reference} - {lead.name} - {lead.email}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              {checkedItems.length > 0 && (
                <>
                  <button onClick={() => setOpenAssignAllLead(true)}>
                    <KeyboardArrowRightIcon />
                    <span>Assign To</span>
                  </button>
                  <button onClick={() => setOpenDeleteAllMessage(true)}>
                    <KeyboardArrowRightIcon />
                    <span>Delete All</span>
                  </button>
                </>
              )}
              <button onClick={() => setOpenCreateLeadPopUp(true)}>
                <AddIcon />
                <span>Create</span>
              </button>
              <button onClick={() => setOpenImportLeadCsv(true)}>
                <DownloadIcon />
                <span>Import Data</span>
              </button>
              <button onClick={handleExportCsv}>
                <ImportExportIcon />
                <span>Export</span>
              </button>
            </div>
          </div>
          <table className="tableData" ref={tableRef}>
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="placeCheckbox">
                    <label
                      className="container"
                      onClick={handleHeaderCheckboxClick}
                    >
                      <input type="checkbox" checked={isAllChecked} readOnly />

                      <div className="checkmark"></div>
                    </label>
                  </div>
                </th>
                <th>
                  <div className="headerWithIcon">Full name</div>
                </th>
                <th></th>
                <th>
                  <div className="headerWithIcon">Country</div>
                </th>
                <th>
                  {/* Autres colonnes qui ne sont pas triées */}
                  <div className="headerWithIcon">Phone</div>
                </th>
                <th>
                  <div className="headerWithIcon">Register</div>
                </th>

                <th>
                  <div className="headerWithIcon">Campaign</div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={index}
                  className={
                    isAllRowsHighlighted || highlightedRows.includes(item._id)
                      ? "highlighted"
                      : ""
                  }
                >
                  <td>
                    <label className="container">
                      <input
                        type="checkbox"
                        disabled={item.name === "Protected Lead"}
                        checked={checkedItems.includes(item._id)}
                        onChange={(e) =>
                          handleCheckboxChangeWrapper(e, item._id)
                        }
                      />
                      <div className="checkmark"></div>
                    </label>
                  </td>

                  <td
                    onClick={() => {
                      if (item.name !== "Protected Lead") {
                        handleOpenPopupCustomer(item, index);
                      }
                    }}
                  >
                    <div className="nameAndStatus">
                      <span style={{ color: "var(--primary-color)" }}>
                        {item.reference} - {item.name}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="rondConnectStatus"></div>
                  </td>
                  <td>
                    <span>{getCountryNameByISO(item.country)}</span>
                  </td>

                  <td>
                    <span>{item.phone}</span>
                  </td>
                  <td>
                    <span>{moment(item.createdAt).format("DD/MM/YYYY")}</span>
                  </td>
                  <td>
                    <span> {item.campaign.title}</span>
                  </td>

                  <td>
                    {/* <span
                        className="hover-effect"
                        onClick={() => handleOpenAssignSinglelead(item._id)}
                      >
                        assign
                      </span> */}
                    <span
                      className="hover-effect"
                      onClick={() => handleOpenDeleteMessage(item._id)}
                    >
                      del
                    </span>
                  </td>
                </tr>
              ))}
              {data.length === 0 && (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={Math.ceil(totalLeads / perPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            forcePage={currentPage - 1}
          />
          {openImportLeadCsv && (
            <ImportLeadCSV
              setOpen={setOpenImportLeadCsv}
              setShowLoader={setShowPacmanLoader}
              reFetchFromTable={reFetch}
              reFetchAllLead={reFetchAllLead}
              setIsAllChecked={setIsAllChecked}
            />
          )}
          {showPacmanLoader && <PacmanLoaderPopUp />}

          {openCreateLeadPopUp && (
            <CreateSingleLead
              setOpen={setOpenCreateLeadPopUp}
              reFetchFromTable={reFetch}
            />
          )}
          {openAssignSingleLead && (
            <AssignSingleLead
              setOpen={setOpenAssignSingleLead}
              reFetchFromTable={reFetch}
              id={selectedId}
            />
          )}
          {openPopupCustomer && (
            <PopupCustomerContainer
              setOpen={setOpenPopupCustomer}
              data={selectedData}
              leads={allLeads}
              reFetchAllLead={reFetchAllLead}
              reFetchFromTable={reFetch}
            />
          )}
          {openAssignAllLead && (
            <AssignAllLead
              setOpen={setOpenAssignAllLead}
              reFetchFromTable={reFetch}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              setIsAllChecked={setIsAllChecked}
              setShowLoader={setShowClimbingLoader}
            />
          )}
          {showClimbingLoader && <ClimbingBoxLoaderPopup />}
          {openDeleteMessage && (
            <DeleteMessage
              setOpen={setOpenDeleteMessage}
              reFetchFromTable={reFetch}
              endPoint={"lead"}
              id={selectedDeletedId}
              allLeads={allLeads}
            />
          )}
          {openDeleteAllMessages && (
            <DeleteAllMessageCustomers
              setOpen={setOpenDeleteAllMessage}
              reFetchFromTable={reFetch}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              setShowLoader={setShowPulseLoader}
              setIsAllChecked={setIsAllChecked}
              allLeads={allLeads}
            />
          )}
          {showPulseLoader && <PulseLoaderPopup />}
          {showSelectAllPopup && (
            <SelectOptionsTable
              setOpen={setShowSelectAllPopup}
              selectCurrentPage={selectCurrentPage}
              selectAllPages={selectAllPages}
              allPageData={totalLeads}
              pageData={data.length}
              setIsAllChecked={setIsAllChecked}
              name="customer"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Attribution;
