import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

const fetchLeads = async ({
  page,
  filters,
  dateRange,
  userId,
  isSuperAdmin,
}) => {
  const storedToken = localStorage.getItem("authToken");
  const queryParams = new URLSearchParams({
    ...filters,
    page: page.toString(),
    userId: userId,
    limit: "50",
    startDate: dateRange.startDate ? dateRange.startDate.toISOString() : "",
    endDate: dateRange.endDate ? dateRange.endDate.toISOString() : "",
  }).toString();

  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/api/lead/pagination?${queryParams}`,
    { headers: { Authorization: `Bearer ${storedToken}` } }
  );

  return response.data;
};

export const useLeads = (page, filters, dateRange, user) => {
  return useQuery({
    queryKey: [
      "leads",
      {
        page,
        filters,
        dateRange,
        userId: user._id,
        isSuperAdmin: user.isSuperAdmin,
      },
    ],
    queryFn: () =>
      fetchLeads({
        page,
        filters,
        dateRange,
        userId: user._id,
        isSuperAdmin: user.isSuperAdmin,
      }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 10 * 1000, // 10 secondes
  });
};

// Fonctions pour mettre à jour le cache localement
export const updateLeadInCache = (queryClient, leadId, updates) => {
  queryClient.setQueryData(["leads"], (oldData) => {
    if (!oldData) return oldData;
    const { leads, total } = oldData;
    const newLeads = leads.map((l) =>
      l._id === leadId ? { ...l, ...updates } : l
    );
    return { leads: newLeads, total };
  });
};

export const addLeadToCache = (queryClient, newLead) => {
  queryClient.setQueryData(["leads"], (oldData) => {
    if (!oldData) return oldData;
    const { leads, total } = oldData;
    return { leads: [newLead, ...leads], total: total + 1 };
  });
};

export const removeLeadFromCache = (queryClient, leadId) => {
  queryClient.setQueryData(["leads"], (oldData) => {
    if (!oldData) return oldData;
    const { leads, total } = oldData;
    const filtered = leads.filter((l) => l._id !== leadId);
    return { leads: filtered, total: total - 1 };
  });
};
