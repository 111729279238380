import { useEffect, useState } from "react";
import "./Pending.css";
import moment from "moment";
import Countdown from "../../../../../Banking/Pending/PendingTable/Countdown/Countdown";
import FollowPayment from "../FollowPayment/FollowPayment";
import DeleteMessage from "./DeleteMessage/DeleteMessage";
import AcceptMessage from "./AcceptMessage/AcceptMessage";
import { useSocket } from "../../../../../../context/SocketContext";
import axios from "axios";

const Pending = ({ currentData, reFetchFromTable }) => {
  const [pending, setPending] = useState([]);
  const [openFollowPayment, setOpenFollowPayment] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [openAcceptMessage, setOpenAcceptMessage] = useState(false);
  const [selectedData, setSelectedData] = useState(null);



  const handleOpenFollowPayment = (data) => {
    setOpenFollowPayment(true);
    setSelectedData(data);
  };

  const handleOpenDeleteMessage = (data) => {
    setOpenDeleteMessage(true);
    setSelectedData(data);
  };

  const handleOpenAcceptMessage = (data) => {
    setOpenAcceptMessage(true);
    setSelectedData(data);
  };

  return (
    <>
      <div className="containerDatatableHistory">
        <div className="datatableHistory">
          <table className="tableDataHistory">
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="headerWithIcon">Reference</div>
                </th>
                <th>
                  <div className="headerWithIcon">Price</div>
                </th>
                <th>
                  <div className="headerWithIcon">Payments</div>
                </th>
                <th>
                  <div className="headerWithIcon">Date</div>
                </th>
                <th>
                  <div className="headerWithIcon">Expiration</div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>

                {/* <th>
                  <div className="headerWithIcon">Add</div>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(currentData.onHold) && currentData.onHold.length > 0 ? (
                currentData.onHold.map((product) => {
                  const totalPaid = product.amountPay.reduce(
                    (acc, cur) => acc + cur.amount,
                    0
                  );
                  const progress = (totalPaid / product.product.prix) * 100;

                  return (
                    <tr key={product.product._id}>
                      <td style={{ color: "var(--primary-color)" }}>
                        {product.product.reference}
                      </td>
                      <td>
                        {(product.product.prix ?? 0).toLocaleString("en-US")}
                      </td>
                      <td onClick={() => handleOpenFollowPayment(product)}>
                        <div className="catalog-payments-progress">
                          <div
                            className="catalog-payments-progress-filled"
                            style={{
                              width: `${progress}%`,
                              backgroundColor: "#7ec5cf",
                            }}
                          ></div>
                        </div>
                      </td>
                      <td>
                        {moment(product.onHoldDate).format("DD/MM/YYYY HH:mm")}
                      </td>
                      <td>
                        <Countdown expirationDate={product.expirationDate} />
                      </td>
                      <td>
                        <span
                          className="hover-effect"
                          onClick={() =>
                            handleOpenDeleteMessage(product.product._id)
                          }
                        >
                          cancel
                        </span>
                        <span
                          className="hover-effect"
                          onClick={() =>
                            handleOpenAcceptMessage(product.product)
                          }
                        >
                          confirm
                        </span>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {openFollowPayment && (
        <FollowPayment
          setOpen={setOpenFollowPayment}
          currentData={currentData}
          selectedData={selectedData}
          reFetchFromTable={reFetchFromTable}
          type={"onHold"}
          url={"OnHold"}
        />
      )}
      {openDeleteMessage && (
        <DeleteMessage
          setOpen={setOpenDeleteMessage}
          selectedData={selectedData}
          reFetchFromTable={reFetchFromTable}
          currentData={currentData}
        />
      )}
      {openAcceptMessage && (
        <AcceptMessage
          setOpen={setOpenAcceptMessage}
          selectedData={selectedData}
          reFetchFromTable={reFetchFromTable}
          currentData={currentData}
        />
      )}
    </>
  );
};

export default Pending;
