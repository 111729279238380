import "./DeleteMessage.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import { useSocket } from "../../context/SocketContext";
import { useData } from "../../context/DataContext";
import { useProducts } from "../../hooks/usePlatformLeadData";

const DeleteMessage = ({
  setOpen,
  reFetchFromTable,
  endPoint,
  id,
  allLeads,
}) => {
  const { products } = useData();
    const { data: productData, isLoading, error, refetch } = useProducts();

  const handleDelete = async () => {
    if (endPoint === "product") {
      const leadsWithProduct = allLeads.filter(
        (lead) =>
          (lead.onSafe &&
            lead.onSafe.some((item) => item.product._id.toString() === id)) ||
          (lead.onHold &&
            lead.onHold.some((item) => item.product._id.toString() === id))
      );

      if (leadsWithProduct.length > 0) {
        alert("This product is on hold or on safe and cannot be deleted.");
        setOpen(false);
        return; // Arrêter le processus de suppression
      }
    }

    // Si l'opération concerne un lead
    if (endPoint === "lead") {
      const lead = allLeads.find((lead) => lead._id === id);

      if (
        lead &&
        ((lead.deposit && lead.deposit.length > 0) ||
          (lead.onSafe && lead.onSafe.length > 0) ||
          (lead.onHold && lead.onHold.length > 0))
      ) {
        alert(
          "This lead has deposits or products in safe/hold and cannot be deleted."
        );
        setOpen(false);
        return; // Stop the deletion process
      }
    }

    // 3) Cas où l'on supprime un template
    if (endPoint === "templateProduct") {
      // Vérifier si on a au moins un produit
      if (products.length > 0) {
        alert(
          "Impossible de supprimer ce template : il existe encore des produits. Veuillez d'abord supprimer tous les produits."
        );
        setOpen(false);
        return; // Arrêter le processus de suppression
      }
    }

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/api/${endPoint}/${id}`
      );

      if (response.status === 200) {
        reFetchFromTable();
      }
    } catch (err) {
      if (err.response) {
        alert(err.response.data);
      } else {
        console.error("Error", err.message);
        alert("An error occurred while deleting.");
      }
    } finally {
      setOpen(false);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createStatus">
          <div className="closeIconStatus">
            <CloseIcon
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}
            />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>DEL</span>
            </div>
            <div className="middleCreateStatus">
              <span>Are you sure you want to perform this action?</span>
            </div>
            <div className="bottomCreateStatus">
              <button
                className="cancelButton"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(false);
                }}
              >
                Cancel
              </button>
              <button className="saveButton" onClick={handleDelete}>
                <CheckIcon />
                Comfirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteMessage;
