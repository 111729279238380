import Dashboard from "../Dashboard/Dashboard";
import "./TopTab.css";
import { useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import History from "../History/History";

const TopTab = ({ currentData, reFetchFromTable, refetch }) => {
  const [activeTab, setActiveTab] = useState("Dashboard");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="popupRigthSideWrapper">
      <div className="popupTopNav">
        <div className="popupRigthSideTop">
          <div
            className={`popupRigthSideTopItem ${
              activeTab === "Dashboard" ? "active" : ""
            }`}
            onClick={() => handleTabClick("Dashboard")}
          >
            <span>Dashboard</span>
          </div>
          <div
            className={`popupRigthSideTopItem ${
              activeTab === "History" ? "active" : ""
            }`}
            onClick={() => handleTabClick("History")}
          >
            <span>History</span>
          </div>
        </div>
        <div className="popupRigthSideTop"></div>
      </div>
      <div className="popupRigthSideBottom">
        {activeTab === "Dashboard" && (
          <Dashboard
            currentData={currentData}
            reFetchFromTable={reFetchFromTable}
          />
        )}
        {activeTab === "History" && (
          <History
            currentData={currentData}
            reFetchFromTable={reFetchFromTable}
            refetch={refetch}
          />
        )}
      </div>
    </div>
  );
};

export default TopTab;
