import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
const DeleteRefund = ({ setOpen, reFetchFromTable, id }) => {
  const handleDeleteRefund = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/delete-refund/${id.leadId}`,
        { refund: { _id: id.refundId } }
      );
      reFetchFromTable();
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createStatus">
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createStatusWrapper">
            <div className="topCreateStatus">
              <span>DEL</span>
            </div>
            <div className="middleCreateStatus">
              <span>Are you sure you want to perform this action?</span>
            </div>
            <div className="bottomCreateStatus">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="saveButton" onClick={handleDeleteRefund}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteRefund;
