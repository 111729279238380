import { useState } from "react";
import "./Sales.css";
import moment from "moment";
import FollowPayment from "../FollowPayment/FollowPayment";
import CreateOffers from "./CreateOffers/CreateOffers";
import DeleteMessage from "./DeleteMessage/DeleteMessage";

const Sales = ({ currentData, reFetchFromTable }) => {
  const [openFollowPayment, setOpenFollowPayment] = useState(false);
  const [openOffers, setOpenOffers] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);

  const handleOpenFollowPayment = (data) => {
    setOpenFollowPayment(true);
    setSelectedData(data);
  };
  const handleOpenCreateOffer = (data) => {
    setOpenOffers(true);
    setSelectedData(data);
  };

  const handleOpenDeleteMessage = (data) => {
    setOpenDeleteMessage(true);
    setSelectedData(data);
  };

  return (
    <>
      <div className="containerDatatableHistory">
        <div className="datatableHistory">
          <table className="tableDataHistory">
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="headerWithIcon">Reference</div>
                </th>
                <th>
                  <div className="headerWithIcon">Price</div>
                </th>
                <th>
                  <div className="headerWithIcon">Payments</div>
                </th>
                <th>
                  <div className="headerWithIcon">Date</div>
                </th>

                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData.onSafe && currentData.onSafe.length > 0 ? (
                currentData.onSafe.map((product) => (
                  <tr>
                    <td>
                      <span style={{ color: "var(--primary-color)" }}>
                        {product.product.reference}
                      </span>
                    </td>
                    <td>
                      <span>{product.product.prix}</span>
                    </td>
                    <td onClick={() => handleOpenFollowPayment(product)}>
                      <div className="catalog-payments-progress">
                        <div
                          className="catalog-payments-progress-filled"
                          style={{
                            width: `${
                              (product.amountPay.reduce(
                                (acc, cur) => acc + cur.amount,
                                0
                              ) /
                                product.product.prix) *
                              100
                            }%`,
                            backgroundColor:
                              product.amountPay.reduce(
                                (acc, cur) => acc + cur.amount,
                                0
                              ) /
                                product.product.prix >
                              0.5
                                ? "#7ec5cf"
                                : "#7ec5cf",
                          }}
                        ></div>
                      </div>
                    </td>
                    <td>
                      <span>
                        {moment(product.onHoldDate).format("DD/MM/YYYY HH:mm")}
                      </span>
                    </td>

                    <td>
                      <span
                        className="hover-effect"
                        onClick={() =>
                          handleOpenDeleteMessage(product.product._id)
                        }
                      >
                        cancel
                      </span>
                      <span
                        className="hover-effect"
                        onClick={() => handleOpenCreateOffer(product)}
                      >
                        rebought
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    No results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {openFollowPayment && (
        <FollowPayment
          setOpen={setOpenFollowPayment}
          currentData={currentData}
          selectedData={selectedData}
          reFetchFromTable={reFetchFromTable}
          type={"onSafe"}
          url={"OnSafe"}
        />
      )}
      {openOffers && (
        <CreateOffers
          setOpen={setOpenOffers}
          currentData={currentData}
          selectedData={selectedData}
          reFetchFromTable={reFetchFromTable}
        />
      )}
      {openDeleteMessage && (
        <DeleteMessage
          setOpen={setOpenDeleteMessage}
          selectedData={selectedData}
          reFetchFromTable={reFetchFromTable}
          currentData={currentData}
        />
      )}
    </>
  );
};

export default Sales;
