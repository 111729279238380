import { useEffect, useRef, useState } from "react";
import "./ProductsTable.css";
import useFetch from "../../../../hooks/useFetch";
import {
  handleCheckboxChange,
  handleExportButtonClick,
} from "../../../../utils/datatable.utils";
import ReactPaginate from "react-paginate";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import DeleteMessage from "../../../DeleteMessage/DeleteMessage";
import SelectOptionsTable from "../../../SelectOptionsTable/SelectOptionsTable";
import CreateProducts from "../../Products/CreateProducts/CreateProducts";
import CreateTemplateProduct from "../CreateTemplateProduct/CreateTemplateProduct";
import TemplateProduct from "../TemplateProduct/TemplateProduct";
import TuneIcon from "@mui/icons-material/Tune";
import EditProduct from "../EditProduct/EditProduct";

const ProductsTable = ({
  allLeads,
  products,
  reFetchFromTable,
  pTemplates,
  reFetchTemplate,
}) => {
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);
  const [openDeleteAllMessage, setOpenDeleteAllMessage] = useState(false);
  const [selectedDeletedId, setSelectedDeletedId] = useState(null);
  const [openCreateProduct, setopenCreateProduct] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [openEditProduct, setOpenEditProduct] = useState(false);
  const [tempSelectedFilters, setTempSelectedFilters] = useState({});
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 100]);
  const [maxPrice, setMaxPrice] = useState(100);

  const openTemplateProduct = () => {
    setActiveComponent("templateProduct");
  };

  const openCreateTemplateProduct = () => {
    setActiveComponent("CreateTemplateProduct");
  };

  const handleTemplateCreated = () => {
    reFetchTemplate();
    setActiveComponent("templateProduct");
  };

  const handleClose = () => {
    setActiveComponent(null);
  };
  const data = {};
  const tableRef = useRef(null);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleExportCsv = () => {
    if (checkedItems.length > 0) {
      handleExportButtonClick(data, checkedItems);
    } else {
      alert("Please select at least one checkbox to export.");
    }
  };

  const handleCheckboxChangeWrapper = (event, itemId) => {
    handleCheckboxChange(
      event,
      itemId,
      data,
      checkedItems,
      setIsAllChecked,
      setCheckedItems
    );
    setHighlightedRows((prevRows) => {
      if (event.target.checked) {
        return [...prevRows, itemId];
      } else {
        return prevRows.filter((rowId) => rowId !== itemId);
      }
    });
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setHighlightedRows([]);
    }
  }, [checkedItems]);

  const handleHeaderCheckboxClick = (e) => {
    e.preventDefault();
    if (checkedItems.length > 0 || isAllChecked) {
      setCheckedItems([]);
      setIsAllChecked(false);
    } else {
      setShowSelectAllPopup(true);
    }
  };
  const selectCurrentPage = () => {
    const start = currentPage * perPage;
    const end = start + perPage;
    const currentPageItems = data.slice(start, end).map((item) => item._id);
    setCheckedItems(currentPageItems);
  };

  const selectAllPages = () => {
    const allItems = data.map((item) => item._id);
    setCheckedItems(allItems);
  };

  const handleOpenDeleteMessage = (leadId) => {
    setOpenDeleteMessage(true);
    setSelectedDeletedId(leadId);
  };

  const isTemplateSelected = pTemplates?.some(
    (template) => template.isSelected
  );

  const handleCreateButtonClick = () => {
    if (isTemplateSelected) {
      setopenCreateProduct(true);
      setShowErrorMessage(false);
    } else {
      setShowErrorMessage(true);
    }
  };

  const handleFilterChange = (type, value, isRange = false) => {
    setTempSelectedFilters((prev) => {
      if (isRange) {
        return { ...prev, [type]: { ...prev[type], ...value } };
      }
      return { ...prev, [type]: value };
    });
  };

  const applyFilters = () => {
    setSelectedFilters(tempSelectedFilters);

    const newFilteredProducts = products.filter((product) => {
      const matchesPrice =
        product.prix >= priceRange[0] && product.prix <= priceRange[1];
      return (
        matchesPrice &&
        Object.entries(tempSelectedFilters).every(([key, value]) => {
          return product.attributs.some(
            (attr) => attr.key === key && attr.value === value
          );
        })
      );
    });
    setFilteredProducts(newFilteredProducts);
    setOpenFilter(false);
  };

  const handlePriceChange = (event) => {
    const value = event.target.value.split(",").map(Number);
    setPriceRange(value);
  };

  const selectedTemplate = pTemplates?.find((template) => template.isSelected);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const pickerElement = document.querySelector(
        ".filterSectionProductTable"
      );
      if (pickerElement && !pickerElement.contains(event.target)) {
        setOpenFilter(false);
      }
    };

    if (openFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const renderProductInfo = (product) => {
    if (!selectedTemplate) return null; // Early return si aucune template sélectionnée

    // Continuer avec la logique existante si la template est définie
    const sortedTypes = [...selectedTemplate.types].sort(
      (a, b) => a.indexPresentation - b.indexPresentation
    );

    const regularAttributes = sortedTypes
      .map((type) => {
        const attribute = product.attributs.find(
          (attr) => attr.key === type.name
        );
        return attribute
          ? `${attribute.value}${
              type.abbreviation ? ` ${type.abbreviation}` : ""
            }`
          : "";
      })
      .filter(Boolean)
      .join(" - ");

    return (
      <td>
        <span>{regularAttributes}</span>
      </td>
    );
  };

  const getTableHeaders = (pTemplates) => {
    const allTypes = new Set(); // Set to avoid duplicate
    pTemplates
      .filter((pt) => pt.isSelected)
      .forEach((template) => {
        template.types.forEach((type) => {
          allTypes.add(type.name); //Only add the tpye name
        });
      });

    return Array.from(allTypes); // Convert to array to iterate
  };

  const renderTableRows = (products, headers) => {
    if (!selectedTemplate) return null;

    return products.map((product, index) => {
      const row = headers.map((header) => {
        const attribute = product.attributs.find((attr) => attr.key === header);
        return attribute ? attribute.value : "";
      });

      return (
        <>
          <tr key={index}>
            <td>
              <label className="container">
                <input
                  type="checkbox"
                  checked={checkedItems.includes(product._id)}
                  onChange={(e) => handleCheckboxChangeWrapper(e, product._id)}
                />
                <div className="checkmark"></div>
              </label>
            </td>
            <td>
              <span style={{ color: "var(--primary-color)" }}>
                {product.reference}
              </span>
            </td>
            {renderProductInfo(product)}
            {row.map((cell, index) => (
              <td key={index}>{cell}</td>
            ))}
            <td>
              <span>{product.prix} €</span>
            </td>
            <td>
              <span>{product.isHide ? "Unavailable" : "Available"} </span>
            </td>
            <td>
              <span
                className="hover-effect"
                onClick={() => handleOpenEditProduct(product)}
              >
                edit
              </span>
              <span
                className="hover-effect"
                onClick={() => handleOpenDeleteMessage(product._id)}
              >
                del
              </span>
            </td>
          </tr>
          {products.length === 0 && (
            <tr>
              <td colSpan="8" style={{ textAlign: "center" }}>
                No results found...
              </td>
            </tr>
          )}
        </>
      );
    });
  };

  const headers = selectedTemplate ? getTableHeaders([selectedTemplate]) : [];

  const handleOpenEditProduct = (data) => {
    setOpenEditProduct(true);
    setSelectedData(data);
  };

  if (pTemplates < 0 && !selectedTemplate.types) {
    return <div>Loading Template...</div>;
  }

  if (!pTemplates) {
    return <div>Loading Template...</div>;
  }

  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>Products : {products.length} result(s)</span>
              {selectedTemplate && (
                <button onClick={() => setOpenFilter(!openFilter)}>
                  Filters
                </button>
              )}
              {openFilter && (
                <div className="filterSectionProductTable">
                  {selectedTemplate.types.map((type) => {
                    switch (type.typeCategory) {
                      case "Type":
                        return (
                          <div key={type.name}>
                            <span>{type.name}</span>
                            <select
                              onChange={(e) =>
                                handleFilterChange(type.name, e.target.value)
                              }
                            >
                              <option value="">Select</option>
                              {type.attributs.map((attribut, index) => (
                                <option key={index} value={attribut.value}>
                                  {attribut.value}
                                </option>
                              ))}
                            </select>
                          </div>
                        );
                      case "UM":
                      case "numeric":
                        return (
                          <div
                            key={type.name}
                            className="filterTestContainerWrapperItem"
                          >
                            <span>{`${type.name} Range`}</span>
                            <input
                              type="number"
                              placeholder="Min"
                              onChange={(e) =>
                                handleFilterChange(
                                  type.name,
                                  { min: e.target.value },
                                  true
                                )
                              }
                            />
                            <input
                              type="number"
                              placeholder="Max"
                              onChange={(e) =>
                                handleFilterChange(
                                  type.name,
                                  { max: e.target.value },
                                  true
                                )
                              }
                            />
                          </div>
                        );
                      case "freeValue":
                        return (
                          <div
                            key={type.name}
                            className="filterTestContainerWrapperItem"
                          >
                            <span>{type.name}</span>
                            <input
                              type="text"
                              placeholder={`Enter ${type.name}`}
                              onChange={(e) =>
                                handleFilterChange(type.name, e.target.value)
                              }
                            />
                          </div>
                        );
                      default:
                        return null;
                    }
                  })}
                  <div className="priceRangeFilter">
                    <label>
                      Price Range: {`${priceRange[0]} - ${priceRange[1]}`}
                    </label>
                    <input
                      type="range"
                      min="0"
                      max={maxPrice}
                      value={priceRange.join(",")}
                      onChange={handlePriceChange}
                      multiple
                    />
                  </div>
                  <button onClick={applyFilters}>Valider</button>
                </div>
              )}
            </div>

            <div className="rightElements">
              {checkedItems.length > 0 && (
                <>
                  <button onClick={() => setOpenDeleteAllMessage(true)}>
                    <KeyboardArrowRightIcon />
                    <span>Delete All</span>
                  </button>
                </>
              )}
              <button onClick={openTemplateProduct}>
                <AddIcon />
                <span>Template</span>
              </button>

              {selectedTemplate && (
                <button
                  className={`create-btn ${
                    !isTemplateSelected ? "disabled" : ""
                  }`}
                  onClick={handleCreateButtonClick}
                >
                  <AddIcon />
                  <span>Create</span>
                </button>
              )}

              {/* <button>
              <ImportExportIcon />
              <span>Export</span>
            </button> */}
            </div>
          </div>
          {pTemplates && selectedTemplate ? (
            <table className="tableData" ref={tableRef}>
              <thead className="dtThead">
                <tr>
                  <th>
                    <div className="placeCheckbox">
                      <label
                        className="container"
                        onClick={handleHeaderCheckboxClick}
                      >
                        <input
                          type="checkbox"
                          checked={isAllChecked}
                          readOnly
                        />

                        <div className="checkmark"></div>
                      </label>
                    </div>
                  </th>
                  <th>
                    <div className="headerWithIcon">Reference</div>
                  </th>
                  <th>
                    <div className="headerWithIcon">Name</div>
                  </th>
                  {headers.map((header) => (
                    <th key={header}>
                      <div className="headerWithIcon">{header}</div>
                    </th>
                  ))}
                  <th>
                    <div className="headerWithIcon">Prix</div>
                  </th>
                  <th>
                    <div className="headerWithIcon">Status</div>
                  </th>
                  <th>
                    <div className="headerWithIcon">Actions</div>
                  </th>
                </tr>
              </thead>
              <tbody>{renderTableRows(products, headers)}</tbody>
            </table>
          ) : (
            <div>Create a template to start</div>
          )}
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={Math.ceil(data.length / perPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
          {openDeleteMessage && (
            <DeleteMessage
              setOpen={setOpenDeleteMessage}
              reFetchFromTable={reFetchFromTable}
              endPoint={"product"}
              id={selectedDeletedId}
              allLeads={allLeads}
            />
          )}
          {showSelectAllPopup && (
            <SelectOptionsTable
              setOpen={setShowSelectAllPopup}
              selectCurrentPage={selectCurrentPage}
              selectAllPages={selectAllPages}
              allPageData={data}
              //   pageData={data}
              setIsAllChecked={setIsAllChecked}
            />
          )}
          {activeComponent === "templateProduct" && (
            <TemplateProduct
              setOpen={handleClose}
              openCreateTemplateProduct={openCreateTemplateProduct}
              data={pTemplates}
              reFetch={reFetchTemplate}
              products={products}
            />
          )}

          {activeComponent === "CreateTemplateProduct" && (
            <CreateTemplateProduct
              setOpen={handleClose}
              onTemplateCreated={handleTemplateCreated}
            />
          )}

          {openCreateProduct && (
            <CreateProducts
              setOpen={setopenCreateProduct}
              reFetchFromTable={reFetchFromTable}
            />
          )}
          {openEditProduct && (
            <EditProduct
              setOpen={setOpenEditProduct}
              reFetchFromTable={reFetchFromTable}
              selectedData={selectedData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductsTable;
