import React from "react";
import FadeLoader from "react-spinners/FadeLoader"
const AuthLoader = () => {
  return (
    <>
      <FadeLoader color="#36d7b7" />
    </>
  );
};

export default AuthLoader;
