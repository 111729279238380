import "./App.css";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import LoginPage from "./pages/LoginPage/LoginPage";
import AttributionPage from "./pages/CRM/AttributionPage/AttributionPage";
import LeadPage from "./pages/CRM/LeadPage/LeadPage";
import SalesPage from "./pages/Accounts/SalesPage/SalesPage";
import PaymentPage from "./pages/Settings/PaymentPage/PaymentPage";
import StatusPage from "./pages/Settings/StatusPage/StatusPage";
import { AuthContext } from "./context/auth.context";
import { useContext } from "react";
import PlanningPage from "./pages/CRM/PlanningPage/PlanningPage";
import DepositPage from "./pages/Banking/DepositPage/DepositPage";
import DashboardCampaignPage from "./pages/Campaign/DashboardCampaignPage/DashboardCampaignPage";
import RefundsPage from "./pages/Banking/RefundsPage/RefundsPage";
import SalesLeadPage from "./pages/Banking/SalesLeadPage/SalesLeadPage";
import RebougthPage from "./pages/Banking/RebougthPage/RebougthPage";
import PlatformPage from "./pages/Settings/PlatformPage/PlatformPage";
import SipPage from "./pages/Settings/SipPage/SipPage";
import SalesPerfomancePage from "./pages/CRM/SalesPerfomancePage/SalesPerfomancePage";
import ProductsPage from "./pages/Catalogue/ProductsPage/ProductsPage";
import MarketPlacePage from "./pages/PLATEFORME/MarketPlacePage/MarketPlacePage";
import LoginPagePlateforme from "./pages/PLATEFORME/LoginPagePlateforme/LoginPagePlateforme";
import { AuthContextLead } from "./context/leadContextAuth";
import Pending from "./pages/Banking/PendingPage/PendingPage";
import SuppliersPage from "./pages/Catalogue/SuppliersPage/SuppliersPage";
import SyncLoader from "react-spinners/SyncLoader";
import CrmPage from "./pages/Settings/CrmPage/CrmPage";

function App() {
  const { isLoggedIn, isLoading: isLoadingCRM, user } = useContext(AuthContext);
  const { isLoggedInPlateforme, isLoading: isLoadingPlateforme } =
    useContext(AuthContextLead);

  let isLoading = window.location.pathname.startsWith("/management")
    ? isLoadingCRM
    : isLoadingPlateforme;

  let loaderColor = window.location.pathname.startsWith("/management")
    ? "#00B161"
    : "#94a2cb";

  if (isLoading) {
    return (
      <div className="loaderOverlayAllApp">
        <SyncLoader color={loaderColor} size="18" />
      </div>
    );
  }

  return (
    <div className="App">
      <Routes>
        <Route
          path="/management/"
          element={isLoggedIn ? <HomePage /> : <LoginPage />}
        />

        <Route
          path="/management/crm/attribution"
          element={
            isLoggedIn && user.isSuperAdmin ? (
              <AttributionPage />
            ) : (
              <LoginPage />
            )
          }
        />
        <Route
          path="/management/crm/customers"
          element={isLoggedIn ? <LeadPage /> : <LoginPage />}
        />
        <Route
          path="/management/crm/perform"
          element={
            isLoggedIn && user.isSuperAdmin ? (
              <SalesPerfomancePage />
            ) : (
              <LoginPage />
            )
          }
        />
        <Route
          path="/management/crm/planning"
          element={isLoggedIn ? <PlanningPage /> : <LoginPage />}
        />

        <Route
          path="/management/catalogue/products"
          element={
            isLoggedIn && user.isSuperAdmin ? <ProductsPage /> : <LoginPage />
          }
        />

        <Route
          path="/management/catalogue/suppliers"
          element={
            isLoggedIn && user.isSuperAdmin ? <SuppliersPage /> : <LoginPage />
          }
        />

        <Route
          path="/management/banking/deposit"
          element={
            isLoggedIn && user.isSuperAdmin ? <DepositPage /> : <LoginPage />
          }
        />
        <Route
          path="/management/banking/refund"
          element={
            isLoggedIn && user.isSuperAdmin ? <RefundsPage /> : <LoginPage />
          }
        />

        <Route
          path="/management/banking/pending"
          element={
            isLoggedIn && user.isSuperAdmin ? <Pending /> : <LoginPage />
          }
        />

        <Route
          path="/management/banking/salesLead"
          element={
            isLoggedIn && user.isSuperAdmin ? <SalesLeadPage /> : <LoginPage />
          }
        />
        <Route
          path="/management/banking/rebought"
          element={
            isLoggedIn && user.isSuperAdmin ? <RebougthPage /> : <LoginPage />
          }
        />

        <Route
          path="/management/campaign/dashboard"
          element={
            isLoggedIn && user.isSuperAdmin ? (
              <DashboardCampaignPage />
            ) : (
              <LoginPage />
            )
          }
        />

        <Route
          path="/management/accounts/sales"
          element={
            isLoggedIn && user.isSuperAdmin ? <SalesPage /> : <LoginPage />
          }
        />

        <Route
          path="/management/settings/platform"
          element={
            isLoggedIn && user.isSuperAdmin ? <PlatformPage /> : <LoginPage />
          }
        />
        <Route
          path="/management/settings/payments"
          element={
            isLoggedIn && user.isSuperAdmin ? <PaymentPage /> : <LoginPage />
          }
        />
        <Route
          path="/management/settings/status"
          element={
            isLoggedIn && user.isSuperAdmin ? <StatusPage /> : <LoginPage />
          }
        />
        <Route
          path="/management/settings/sip"
          element={
            isLoggedIn && user.isSuperAdmin ? <SipPage /> : <LoginPage />
          }
        />
        <Route
          path="/management/settings/crm"
          element={
            isLoggedIn && user.isSuperAdmin ? <CrmPage /> : <LoginPage />
          }
        />

        <Route path="/management/login" element={<LoginPage />} />

        {/* PLATEFORME */}
        <Route path="/login" element={<LoginPagePlateforme />} />
        <Route
          path="/"
          element={
            isLoggedInPlateforme ? <MarketPlacePage /> : <LoginPagePlateforme />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
