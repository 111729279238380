import { useState } from "react";
import "./CalendarDashboard.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Importez les styles de base
import { format, isSameMonth, addMonths, subMonths } from "date-fns";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { fr } from "date-fns/locale"; // Importez le locale français

const CalendarDashboard = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const today = new Date();
  const events = ["2024-03-12", "2024-03-16", "2024-03-23", "2024-02-26"];

  const tileContent = ({ date, view }) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    if (events.includes(formattedDate)) {
      return (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
              height: "5px",
              width: "5px",
              borderRadius: "50%",
              backgroundColor: "#7ec5cf",
            }}
          ></div>
        </div>
      );
    }
  };

  const nextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  const prevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  const onDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setSelectedDate(date);
    if (events.includes(formattedDate)) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  };

  const displayDate = isSameMonth(currentDate, today)
    ? format(currentDate, "MMM d, EEEE, yyyy")
    : format(currentDate, "MMMM yyyy")

  return (
    <div className="calendarContainerDashboard">
     {showPopup && (
      <div className="overlayCalendar"></div>
    )}
        <div className={showPopup ? "blurEffect" : ""}>
          <div className="calendar-navigation">
            <span>{displayDate}</span>
            <div className="calendar-navigationRight">
              <KeyboardArrowLeftIcon onClick={prevMonth} />
              <KeyboardArrowRightIcon onClick={nextMonth} />
            </div>
          </div>
          <Calendar
            onChange={onDateChange}
            value={selectedDate}
            activeStartDate={currentDate}
            locale="en-US"
            tileContent={tileContent}
            navigationLabel={null}
            showNavigation={false}
          />
   
      </div>
      {showPopup && (
        <div className="popupEventCalendar">
          <div className="popupInnerEventCalendar">
            <div className="popupInnerEventCalendarTop">
              <span>Mar 7, Thursday, 2024</span>
            </div>
            <div className="popupInnerEventCalendarMiddle">
              <div className="eventItem">
                <img src="/images/avatar/avatar1.png" alt="" />
                <div className="eventItemInfo">
                  <p className="eventItemName">
                    Nom du Vendeur - Nom du Client
                  </p>
                  <p className="eventItemDetails">10:00 AM</p>
                </div>
              </div>
            </div>
            <div className="popupInnerEventCalendarBottom">
              <button onClick={() => setShowPopup(false)}>Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarDashboard;
