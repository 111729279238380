import "./AddEvent.css";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import useFetch from "../../../../hooks/useFetch";
import { useContext, useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { AuthContext } from "../../../../context/auth.context";
import axios from "axios";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const AddEvent = ({ setOpen, handleAddEvent }) => {
  const { user } = useContext(AuthContext);
  const { data: leads } = useFetch("lead");
  const [showPicker, setShowPicker] = useState(false);
  const [time, setTime] = useState("12:00");
  const [comments, setComments] = useState([]);
  const [appointmentDate, setAppointmentDate] = useState(new Date());
  const [isDateTimeChanged, setIsDateTimeChanged] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [showDropdown, setShowDropdown] = useState(true);
  const [dateRange, setDateRange] = useState([
    { startDate: new Date(), endDate: new Date(), key: "selection" },
  ]);
  const [newComment, setNewComment] = useState({
    author: "",
    content: "",
  });
  const pickerRef = useRef();

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleSelectDate = (ranges) => {
    const newDate = ranges.selection.startDate;
    setDateRange([ranges.selection]);
    setAppointmentDate(newDate);
    setShowPicker(false);
    setIsDateTimeChanged(true);
  };

  const handleTimeChange = (e) => {
    setTime(e.target.value);
    setIsDateTimeChanged(true);
  };

  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewComment((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateComment = async (e) => {
    e.preventDefault();

    const selectedLead = leads.find((lead) => lead._id === selectedLeadId);
    if (!selectedLead) {
      console.error("Selected lead not found");
      return;
    }

    const authorInfo = {
      _id: user._id,
      name: user.name,
    };

    const appointmentDateTime = new Date(appointmentDate);
    appointmentDateTime.setHours(parseInt(time.split(":")[0]));
    appointmentDateTime.setMinutes(parseInt(time.split(":")[1]));

    // Préparation de l'objet à envoyer
    const eventData = {
      commentContent: newComment.content,
      appointmentDateTime: appointmentDateTime.toISOString(),
      authorInfo: authorInfo,
    };

    try {
      // Utilisation de la nouvelle route API pour ajouter un événement
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/lead/${selectedLeadId}/add-event`,
        eventData
      );
      const addedComment = response.data;
     
      const newEvent = {
        title: user.isSuperAdmin
          ? `${authorInfo.name} - ${
              selectedLead.name
            } LD${selectedLead.reference.slice(0, 5)}`
          : `RDV : ${selectedLead.name} LD${selectedLead._id.slice(0, 5)}`,
        start: appointmentDateTime,
        end: new Date(appointmentDateTime.getTime() + 30 * 60000),
        commentId: addedComment._id,
        leadId: selectedLead._id,
        authorName: authorInfo.name,
      };

      handleAddEvent(newEvent);
      setOpen(false);
      setNewComment({ content: "" });
      setAppointmentDate(new Date());
    } catch (err) {
      console.error("Error while adding event:", err);
    }
  };

  const userLeads = leads.filter(
    (l) => l.assignTo && l.assignTo._id === user._id
  );

  const handleLeadSelect = (leadId, leadName) => {
    setSelectedLeadId(leadId);
    setSearchQuery(leadName);
    setShowDropdown(false);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query.length > 0 && Array.isArray(userLeads)) {
      const filtered = userLeads.filter(
        (l) =>
          l?.reference?.toString().toLowerCase().includes(query) ||
          l?.email?.toLowerCase().includes(query) ||
          l?.name?.toLowerCase().includes(query)
      );
      setFilteredLeads(filtered);
    } else {
      setFilteredLeads([]);
    }
  };
  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createNewApt" onClick={handleClickInside}>
          <div className="closeIconNewApt">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createNewAptWrapper">
            <div className="topCreateNewApt">
              <span>Add Appointment</span>
            </div>
            <div className="middleCreateNewApt">
              <div className="searchContainer">
                <label>Select Customer</label>
                <input
                  type="text"
                  className="searchInput"
                  placeholder="Search..."
                  autoFocus
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                {showDropdown && filteredLeads.length > 0 && (
                  <ul className="searchResults">
                    {filteredLeads.map((lead) => (
                      <li
                        key={lead._id}
                        onClick={() => handleLeadSelect(lead._id, lead.name)}
                      >
                        <AccountCircleIcon className="searchResultIcon" />
                        <div className="searchResultDetails">
                          <span className="searchResultName">{lead.name}</span>
                          <span
                            className="searchResultId"
                            style={{ color: "#757575" }}
                          >
                            ID: {lead.reference}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <label>Date & Hour</label>
              <div className="dateAndHour">
                <div
                  onClick={() => setShowPicker(true)}
                  className="datePickerTriggerNewEvent"
                >
                  {formatDate(dateRange[0].startDate)} {time}
                </div>
                {showPicker && (
                  <div className="datePickerContainerNewEvent" ref={pickerRef}>
                    <DateRangePicker
                      onChange={handleSelectDate}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={1}
                      ranges={dateRange}
                      direction="horizontal"
                    />
                  </div>
                )}
                <input type="time" value={time} onChange={handleTimeChange} />
              </div>
              <div className="middleCommentPopupNewEvent">
                <label>Add a comment</label>
                <textarea
                  name="content"
                  cols="40"
                  rows="5"
                  placeholder="Comments"
                  value={newComment.content}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
            <div className="bottomCreateNewApt">
              <button className="cancelButton">Delete</button>
              <button className="saveButton" onClick={handleCreateComment}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEvent;
