

export const CRMtitles = [
  { name: "Attribution", path: "/management/crm/attribution" },
  { name: "Customers", path: "/management/crm/customers" },
  { name: "Sales Performance", path: "/management/crm/perform" },
  { name: "Planning", path: "/management/crm/planning" },
];

export const CatalogueTitles = [
  { name: "Products", path: "/management/catalogue/products" },
  { name: "Suppliers", path: "/management/catalogue/suppliers" },
];

export const DashboardTitles = [{ name: "Dashboard", path: "/management/" }];

export const BankingTitles = [
  { name: "Deposit", path: "/management/banking/deposit" },
  { name: "Refunds", path: "/management/banking/refund" },
  { name: "Pending", path: "/management/banking/pending" },
  { name: "Sales", path: "/management/banking/salesLead" },
  { name: "Rebought", path: "/management/banking/rebought" },
  { name: "Withdrawals", path: "/management/banking/withdrawals" },
];

export const AccountTitles = [
  { name: "Sales", path: "/management/accounts/sales" },
];

export const SettingsTitles = [
  { name: "Platform", path: "/management/settings/platform" },
  { name: "Payments", path: "/management/settings/payments" },
  { name: "Status", path: "/management/settings/status" },
  { name: "SIP", path: "/management/settings/sip" },
  { name: "CRM", path: "/management/settings/crm" },
];

export const CampaignTitles = [
  { name: "Campaign", path: "/management/campaign/dashboard" },
];
