import "./SuppliersTable.css";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
// import CreateAccount from "../CreateAccount/CreateAccount";
// import EditAccount from "../EditAccount/EditAccount";
import useFetch from "../../../../hooks/useFetch";
import CreateSupplier from "../CreateSupplier/CreateSupplier";
import DeleteMessage from "../../../DeleteMessage/DeleteMessage";
import EditSupplier from "../EditSupplier/EditSupplier";
const SuppliersTable = () => {
  const { data: suppliers, reFetch: reFetchFromTable } = useFetch("supplier");
  const [openCreateSupplier, setOpenCreateSupplier] = useState(false);
  const [showDeleteSalesMessage, setShowdeleteSalesMessage] = useState(false);

  const [openEditSupplier, setOpenEditSupplier] = useState(false);
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);

  const handleOpenEditPopup = (supplier) => {
    setSelectedSupplierId(supplier);
    setOpenEditSupplier(true);
  };

  const handleOpenDeleteSupplier = (supplierId) => {
    setSelectedSupplierId(supplierId);
    setShowdeleteSalesMessage(true);
  };

  return (
    <div>
      <div className="containerDatatable">
        <div className="datatable">
          <div className="searchBarDataTable">
            <div className="leftElementsTable">
              <span>Suppliers : {suppliers.length}</span>
            </div>

            <div className="rightElements">
              <button onClick={() => setOpenCreateSupplier(true)}>
                <AddIcon />
                <span>Create</span>
              </button>
            </div>
          </div>
          <table className="tableData">
            <thead className="dtThead">
              <tr>
                <th>
                  <div className="headerWithIcon">Name</div>
                </th>
                <th>
                  <div className="headerWithIcon">Immatriculation</div>
                </th>
                <th>
                  <div className="headerWithIcon">Address</div>
                </th>
                <th>
                  <div className="headerWithIcon">Rate</div>
                </th>
                <th>
                  <div className="headerWithIcon">Country</div>
                </th>
                <th>
                  <div className="headerWithIcon">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {suppliers.map((item, index) => (
                <tr>
                  <td>
                    <span>{item.name}</span>
                  </td>

                  <td>
                    <span>{item.immatriculation}</span>
                  </td>
                  <td>
                    <span>{item.address}</span>
                  </td>
                  <td>
                    <span>{item.rate}</span>
                  </td>
                  <td>
                    <span>{item.country}</span>
                  </td>

                  <td>
                    <span
                      className="hover-effect"
                      onClick={() => handleOpenEditPopup(item)}
                    >
                      edit
                    </span>
                    <span
                      className="hover-effect"
                      onClick={() => handleOpenDeleteSupplier(item._id)}
                    >
                      del
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* {openCreateSupplier && (
            <CreateAccount
              setOpen={setOpenCreateSupplier}
              role={"isAdmin"}
              reFetchFromTable={reFetchFromTable}
            />
          )}
          {openEditSupplier && (
            <EditAccount
              setOpen={setOpenEditSupplier}
              reFetchFromTable={reFetchFromTable}
              id={selectedSupplierId}
            />
          )} */}
        </div>
      </div>
      {openCreateSupplier && (
        <CreateSupplier
          setOpen={setOpenCreateSupplier}
          reFetchFromTable={reFetchFromTable}
        />
      )}
      {showDeleteSalesMessage && (
        <DeleteMessage
          setOpen={setShowdeleteSalesMessage}
          id={selectedSupplierId}
          reFetchFromTable={reFetchFromTable}
          endPoint={"supplier"}
        />
      )}
      {openEditSupplier && (
        <EditSupplier
          setOpen={setOpenEditSupplier}
          selectedSupplier={selectedSupplierId}
          reFetchFromTable={reFetchFromTable}
        />
      )}
    </div>
  );
};

export default SuppliersTable;
