import "./CampaignDashboard.css";

const CampaignDashboard = ({ campaigns }) => {
  return (
    <div className="homepageCampaignDashboardWrapper">
      {campaigns
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .map((campaign, index) => (
          <div
            key={campaign._id}
            className="dashboardCampaignBottomWrapperItem"
          >
            <div className="dashboardCampaignBottomWrapperItemTopAndBottom">
              <div className="dashboardCampaignBottomWrapperItemTop">
                <span>{campaign.title}</span>
              </div>
              <div className="dashboardCampaignBottomWrapperItemBottom">
                <span>
                  Deal: {campaign.type}: {campaign.typePrice}
                  {campaign.type === "RS" ? "%" : "€"} | Leads:
                  {/* {campaign.numberOfLeads} */}| FTDs:
                  {campaign.numberOfFTDS} | Conversion:{" "}
                  {/* {isNaN((campaign.numberOfFTDS / campaign.numberOfLeads) * 100)
                    ? "0"
                    : (
                        (campaign.numberOfFTDS / campaign.numberOfLeads) *
                        100
                      ).toFixed(2)} */}
                  {/* % | Deposits: {campaign.numberOfDeposit} | Revenue:{" "} */}
                  {/* {campaign.revenue.toFixed(2)}€ | Cost:{" "}
                  {campaign.cost.toFixed(2)}€ | Profit:{" "}
                  {campaign.profit.toFixed(2)}€ */}
                </span>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CampaignDashboard;
