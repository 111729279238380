import NavigationTabs from "../../../components/Navigation/NavigationTabs/NavigationTabs";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import { CatalogueTitles } from "../../../constants/navigationTitles";
import "./ProductsPage.css";
import ProductsTable from "../../../components/Catalogue/Products/ProductsTable/ProductsTable";
import { useData } from "../../../context/DataContext";
import { useProducts } from "../../../hooks/usePlatformLeadData";

const ProductsPage = () => {
  const { allLeads, products, pTemplates, reFetchProducts, reFetchPtemplates } =
    useData();

    const { data: productData, isLoading, error, refetch } = useProducts();

  if (isLoading) return <div>Loading products...</div>;

  return (
    <div className="crmPage">
      <Sidebar />
      <div className="crmPageContainer">
        <div className="navigationAndFilter">
          <NavigationTabs tabs={CatalogueTitles} />
        </div>
        <div className="bottomCrmPage">
          <ProductsTable
            allLeads={allLeads}
            products={productData}
            reFetchFromTable={refetch}
            pTemplates={pTemplates}
            reFetchTemplate={reFetchPtemplates}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductsPage;
