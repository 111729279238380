import "./ProductInfo.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useFetch from "../../../hooks/useFetch";
import { useEffect, useState } from "react";
import Chart from "./Chart/Chart";
import Widgets from "./Widgets/Widgets";
import BuyButton from "./BuyButton/BuyButton";

const ProductInfo = ({
  productData,
  selectedTemplate,
  products,
  handleOpenProductInfo,
  company,
}) => {
  const [openConfirmationMessage, setOpenConfirmationMessage] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const pickerElement = document.querySelector(".dropDownElements");
      if (pickerElement && !pickerElement.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!productData || !productData.graphData) {
    return <div>Loading...</div>;
  }

  const toggleDropdown = () => {
    if (windowWidth <= 1250) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const renderDropdownOptions = () => {
    return (
      <div className="dropDownElements">
        {products
          .filter((p) => !p.isHide)
          .map((product, index) => (
            <div
              key={index}
              className="asset"
              onClick={() => handleOpenProductInfo(product)}
            >
              {renderProductInfo(product)}
            </div>
          ))}
      </div>
    );
  };

  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const renderProductInfo = (product) => {
    const sortedTypes = [...selectedTemplate.types].sort(
      (a, b) => a.indexPresentation - b.indexPresentation
    );
    const vignetteAttributes = [];
    const regularAttributes = [];

    sortedTypes.forEach((type) => {
      const productAttribute = product.attributs.find(
        (attr) => attr.key === type.name
      );
      let attributeValue = productAttribute ? productAttribute.value : "";

      if (type.abbreviation) {
        attributeValue += ` ${type.abbreviation}`;
      }

      const templateAttribute = type.attributs.find(
        (attr) => attr.value === productAttribute?.value
      );
      const attributeStyle = {
        color: templateAttribute?.textColor || "#000",
        backgroundColor: templateAttribute?.backgroundColor || "#fff",
      };

      if (type.isVignette) {
        vignetteAttributes.push(
          <span className="asset-chip asset-chip-color5" style={attributeStyle}>
            {attributeValue}
          </span>
        );
      } else {
        regularAttributes.push(attributeValue);
      }
    });

    const priceChangePercentage = "12,8%";

    const formattedPrice = product.prix.toLocaleString("fr-FR");

    return (
      <>
        <span className="asset-ti2">{regularAttributes.join(" - ")}</span>
        <span className="asset-de2">{product.description}</span>
        <div className="asset-cnt-price2">
          {vignetteAttributes}
          {priceChangePercentage && (
            <span className="asset-percent asset-price-color-up">
              <span>{priceChangePercentage}</span>
            </span>
          )}
          <span className="asset-price asset-price-color-up">
            € {formattedPrice}
          </span>
        </div>
      </>
    );
  };

  const renderProductDetails = () => {
    const sortedTypes = [...selectedTemplate.types].sort(
      (a, b) => a.indexPresentation - b.indexPresentation
    );

    let detailsForTitle = sortedTypes
      .filter((type) => !type.isVignette)
      .map((type) => {
        const attribute = productData.attributs.find(
          (attr) => attr.key === type.name
        );
        const value = attribute ? attribute.value : "N/A";
        return `${value}${type.abbreviation ? " " + type.abbreviation : ""}`;
      })
      .join(" - ");

    return (
      <>
        <h1 className="h1-select-title">
          {detailsForTitle} <span>€ {formatPrice(productData.prix)}</span>
        </h1>
        <span className="description-select-title">
          {productData.description}
        </span>
        {/* Rendu des éléments de la vignette avec styles */}
        {sortedTypes
          .filter((type) => type.isVignette)
          .map((type) => {
            const attribute = productData.attributs.find(
              (attr) => attr.key === type.name
            );
            // Trouver l'attribut correspondant dans la template pour appliquer les styles
            const templateAttribute = type.attributs.find(
              (attrTemplate) => attrTemplate.value === attribute?.value
            );
            const attributeStyle = {
              color: templateAttribute?.textColor || "#000",
              backgroundColor: templateAttribute?.backgroundColor || "#fff",
            };
            const value = attribute ? attribute.value : "N/A";
            return (
              <span
                key={type.name}
                className="asset-chip2"
                style={attributeStyle}
              >
                {value}
              </span>
            );
          })}
        <ExpandMoreIcon className="flepl" />
        {isDropdownOpen && renderDropdownOptions()}
      </>
    );
  };

  return (
    <div className="main">
      <a class="select-asset" onClick={toggleDropdown}>
        {renderProductDetails()}
      </a>

      {!productData.isHide && (
        <span className="buy" onClick={() => setOpenConfirmationMessage(true)}>
          achat
        </span>
      )}
      <span className={`mainprice ${productData.isHide ? "price-right" : ""}`}>
         {formatPrice(productData.prix)} €
      </span>
      <div className="chart">
        <div className="graph-container">
          <Chart productData={productData} />
        </div>
      </div>
      <div className="widgets">
        <Widgets productData={productData} company={company} />
      </div>
      {openConfirmationMessage && (
        <BuyButton
          setOpen={setOpenConfirmationMessage}
          productData={productData}
        />
      )}
    </div>
  );
};

export default ProductInfo;
