import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProviderWrapper } from "./context/auth.context";
import { AuthLeadProviderWrapper } from "./context/leadContextAuth";
import { OpenTabProvider } from "./context/OpenTabContext";
import { SocketProvider } from "./context/SocketContext";
import { DataProvider } from "./context/DataContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import CrmSocketHandler from "./components/CrmSocketHandler";
import LeadSocketHandler from "./components/LeadSocketHandler";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

root.render(
  <Router>
    <QueryClientProvider client={queryClient}>
      <SocketProvider>
      <LeadSocketHandler />
      <CrmSocketHandler />
        <AuthProviderWrapper>
          <DataProvider>
            <AuthLeadProviderWrapper>
              <OpenTabProvider>
                <App />
              </OpenTabProvider>
            </AuthLeadProviderWrapper>
          </DataProvider>
        </AuthProviderWrapper>
      </SocketProvider>
    </QueryClientProvider>
  </Router>
);
