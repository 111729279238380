import { useEffect, useState } from "react";
import "./EditSupplier.css";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import CountrySelect from "../../../CountrySelect/CountrySelect";

const EditSupplier = ({ setOpen, reFetchFromTable, selectedSupplier }) => {
  const [editSupplier, setEditSupplier] = useState({
    immatriculation: "",
    name: "",
    address: "",
    rate: 0,
    country: "",
  });

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    setEditSupplier({
      immatriculation: selectedSupplier.immatriculation,
      name: selectedSupplier.name,
      address: selectedSupplier.address,
      rate: selectedSupplier.rate,
      country: selectedSupplier.country,
    });
  }, [selectedSupplier]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditSupplier((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditSupplier = async (e) => {
    e.preventDefault();

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/supplier/${selectedSupplier._id}`,
        editSupplier
      );

      setOpen(false);
      reFetchFromTable();
    } catch (err) {
      console.error(
        "Erreur lors de la création de l'utilisateur:",
        err.response?.data || err.message
      );
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setOpen(false)}>
        <div className="createSupplier" onClick={handleClickInside}>
          <div className="closeIconStatus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
          <div className="createSupplierWrapper">
            <div className="topCreateStatus">
              <span>Create Supplier</span>
            </div>

            <div className="middleCreateSupplier">
              <div className="inputRow">
                <div className="inputGroup">
                  <label>Immatriculation</label>
                  <input
                    type="text"
                    name="immatriculation"
                    value={editSupplier.immatriculation}
                    onChange={handleChange}
                    placeholder="PG 453 656 "
                  />
                </div>
              </div>

              <div className="inputRow">
                <div className="inputGroup">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={editSupplier.name}
                    onChange={handleChange}
                  />
                </div>

                <div className="inputGroup">
                  <label>Address</label>
                  <input
                    type="text"
                    name="address"
                    value={editSupplier.address}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="fullWidthInput">
                <label>Rate</label>
                <input
                  type="number"
                  name="rate"
                  min="1"
                  max="5"
                  step="0.1"
                  value={editSupplier.rate === 0 ? "" : editSupplier.rate}
                  onChange={handleChange}
                  placeholder="Rate (1 to 5)"
                />
              </div>

              <div className="fullWidthInput">
                <CountrySelect
                  label="Country"
                  className="countrySelectCreateLead"
                  value={editSupplier.country}
                  onChange={handleChange}
                  name="country"
                />
              </div>
            </div>

            <div className="bottomCreateSupplier">
              <button className="cancelButton" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="saveButton" onClick={handleEditSupplier}>
                <CheckIcon />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSupplier;
