// src/components/CrmSocketHandler.jsx
import { useEffect } from "react";
import { useSocket } from "../context/SocketContext";
import { useQueryClient } from "@tanstack/react-query";

const CrmSocketHandler = () => {
  const { socket } = useSocket();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!socket) return;

    const invalidateLeads = () => {
      queryClient.invalidateQueries(["platformLeadData"]);
      queryClient.invalidateQueries(["leadDetails"]);
    };

    const handleAddOnHold = () => {
      invalidateLeads();
    };
    const handleRemoveOnHold = () => {
      invalidateLeads();
    };
    const handleAddOnSafe = () => {
      invalidateLeads();
    };
    const handleRemoveFromSafe = () => {
      invalidateLeads();
    };
    const handleAddOffer = () => {
      invalidateLeads();
    };
    const handleDeclinedOffer = () => {
      invalidateLeads();
    };
    const handleAcceptOffer = () => {
      invalidateLeads();
    };
    const handleRemoveFromSold = () => {
      invalidateLeads();
    };
    const handleUpdateBalanceFollowPayment = () => {
      invalidateLeads();
    };
    const handleAcceptSoldProduct = () => {
      invalidateLeads();
    };
    const handleAddProduct = () => {
      invalidateLeads();
    };
    const handleDeleteProduct = () => {
      invalidateLeads();
    };

    socket.on("productAddedToHold", handleAddOnHold);
    socket.on("productRemoveToHold", handleRemoveOnHold);
    socket.on("productAddedToSafe", handleAddOnSafe);
    socket.on("productRemoveFromSafe", handleRemoveFromSafe);
    socket.on("offerAdded", handleAddOffer);
    socket.on("offerDeclined", handleDeclinedOffer);
    socket.on("offerAccepted", handleAcceptOffer);
    socket.on("productRemovedFromSold", handleRemoveFromSold);
    socket.on("udpateBalanceFollowPayment", handleUpdateBalanceFollowPayment);
    socket.on("acceptSoldProduct", handleAcceptSoldProduct);
    socket.on("addProduct", handleAddProduct);
    socket.on("deleteProduct", handleDeleteProduct);

    return () => {
      socket.off("productAddedToHold", handleAddOnHold);
      socket.off("productRemoveToHold", handleRemoveOnHold);
      socket.off("productAddedToSafe", handleAddOnSafe);
      socket.off("productRemoveFromSafe", handleRemoveFromSafe);
      socket.off("offerAdded", handleAddOffer);
      socket.off("offerDeclined", handleDeclinedOffer);
      socket.off("offerAccepted", handleAcceptOffer);
      socket.off("productRemovedFromSold", handleRemoveFromSold);
      socket.off(
        "udpateBalanceFollowPayment",
        handleUpdateBalanceFollowPayment
      );
      socket.off("acceptSoldProduct", handleAcceptSoldProduct);
      socket.off("addProduct", handleAddProduct);
      socket.off("deleteProduct", handleDeleteProduct);
    };
  }, [socket, queryClient]);

  return null;
};

export default CrmSocketHandler;
